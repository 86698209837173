import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import TopSection from './TopSection';
import OnlineBodySection from './OnlineBodySection';

/**
 * Online Approval Lists component.
 *
 * @param {object} props Props.
 * @param {Function} props.onSubmit Submit approvals.
 * @param {Function} props.onSaveProgress Save approvals updating progress.
 * @class
 */
const OnlineApprovalListsComponent = ({ onlineApproval, saving, onSaveProgress, onSubmit, onUpdate }) => (
  <div className="container full-height online-approval-list-content">
    <TopSection onlineApproval={onlineApproval} />
    <OnlineBodySection
      onlineApproval={onlineApproval}
      onSaveProgress={onSaveProgress}
      onSubmit={onSubmit}
      onUpdate={onUpdate}
      saving={saving}
    />
  </div>
);

OnlineApprovalListsComponent.propTypes = {
  onlineApproval: PropTypes.instanceOf(Map).isRequired,
  onSaveProgress: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export const OnlineApprovalList = memo(OnlineApprovalListsComponent);
