import React, { memo } from 'react';

import { CriteriaCheckbox } from './CriteriaCheckbox';

const CriteriaCheckboxesComponent = ({ buyer: root, onChange, disabled }) => (
  <form onChange={onChange}>
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Buyout" name="buyout" onChange={onChange} />
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Take Private" name="takePrivate" onChange={onChange} />
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Recap" name="recap" onChange={onChange} />
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Minority" name="minority" onChange={onChange} />
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Mezz" name="mezz" onChange={onChange} />
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Turnaround" name="turnaround" onChange={onChange} />
    <CriteriaCheckbox buyer={root} disabled={disabled} label="Carve-out" name="carveOut" onChange={onChange} />
  </form>
);

/** Criteria checkboxes component. */
export const CriteriaCheckboxes = memo(CriteriaCheckboxesComponent);
