import React, { memo } from 'react';

import { InputProps } from '../helpers/AutoComplete';
import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import FormGroup from '../helpers/FormGroup';
import _Input from '../helpers/Input';
import _Textarea from '../helpers/Textarea';
import { isResearcher } from '../../utils/checkPermissions';

const Input = PrettyError(DisplayError(_Input));
const Textarea = PrettyError(DisplayError(_Textarea));

const CompanyTargetFixedPanelComponent = ({
  companyInfo,
  suggestResearcher,
  onChange,
  showInstruction,
  onClick,
  onDescriptionBlur,
  canEditData,
  currentUser,
}) => {
  const hasAccess = () => {
    if (!currentUser) return false;

    return !isResearcher(currentUser.getIn(['roles', 0, 'slug'], null));
  };

  return (
    <form className="row mb10">
      <div className="col-xs-2">
        <FormGroup className="n-label">
          <Input
            className="input-sm bold-control-md f15"
            disabled={!canEditData}
            label="Revenue"
            name="revenue"
            placeholder="Revenue"
            root={companyInfo}
            deep
            showLabel
          />
          <div className="clearfix" />
        </FormGroup>
        <FormGroup className="n-label">
          <Input
            className="input-sm bold-control-md f15"
            disabled={!canEditData}
            label="Employees"
            name="employees"
            placeholder="Employees"
            root={companyInfo}
            deep
            showLabel
          />
          <div className="clearfix" />
        </FormGroup>
      </div>
      <div className="col-xs-5">
        <FormGroup>
          <Textarea
            className="bold-control-md com-target-desc"
            disabled={!canEditData}
            name="description"
            onBlur={onDescriptionBlur}
            placeholder="Description"
            root={companyInfo}
            rows="4"
            deep
          />
          <div className="clearfix" />
        </FormGroup>
        {showInstruction && (
          <p className="text-danger">
            Please make sure your description conforms to the appropriate format.
            <a href="#" onClick={onClick}>
              Click here
            </a>
            for further instructions.
          </p>
        )}
      </div>
      <div className="col-xs-5">
        <div className="row">
          <div className="col-xs-6">
            <InputProps
              className="form-control input-sm text-bold"
              disabled={!canEditData || !hasAccess()}
              name="target.suggestDirector"
              placeholder="Last Researcher"
            >
              {suggestResearcher}
            </InputProps>
            <div className="clearfix" />
          </div>
          <div className="col-xs-6">
            <FormGroup>
              <Input
                className="input-sm bold-control-md"
                disabled={!canEditData || !hasAccess()}
                name="lastResearchedDate"
                onChange={onChange}
                placeholder="Last Researched Date"
                root={companyInfo}
                type="date"
                deep
              />
              <div className="clearfix" />
            </FormGroup>
          </div>
        </div>
        <div>
          <FormGroup>
            <Textarea
              className="bold-control-md com-target-note"
              disabled={!canEditData}
              name="researchNotes"
              placeholder="Research Notes"
              root={companyInfo}
              rows="3"
              deep
            />
            <div className="clearfix" />
          </FormGroup>
        </div>
      </div>
    </form>
  );
};

/** Company target fixed panel component. */
export const CompanyTargetFixedPanel = memo(CompanyTargetFixedPanelComponent);
