import { List, Map, fromJS } from 'immutable';
import moment from 'moment';

import config from '../../../../config';
import {
  LOADED_APPROVALS_SUCCESS,
  UPDATE_APPROVAL_INFORMATION,
  INSERT_APPROVAL_ROW,
  SORT_APPROVALS,
} from '../../../../actions/company/buyer/addOnProject/approval';
import { ABORT_CURRENT_REQUEST, REVERT_CHANGES } from '../../../../actions/company/buyer/addOnProject/main';
import { sortByNumber, sortByString, sortByDate } from '../../../../utils/sorting';
import { defaultNumber } from '../../../../utils/string';
import { spy, setIn, isChanged, revert } from '../../../../utils/ChangeSpy';

const FIELDS_TO_SPY = ['dateSent', 'dateReceived', 'applistLabel'];
const defaultState = List();

export default function(state = defaultState, action) {
  switch (action.type) {
    case ABORT_CURRENT_REQUEST: {
      return defaultState;
    }

    case LOADED_APPROVALS_SUCCESS: {
      const tmp = getApprovals(action);

      if (action.field && action.direction) {
        return reorder(sortApprovals(tmp, action));
      }

      return reorder(tmp);
    }

    case UPDATE_APPROVAL_INFORMATION:
      return update(state, action);

    case INSERT_APPROVAL_ROW: {
      let empty = createEmptyRow(state.size);

      if (action.data) {
        empty = setIn(empty, 'applistLabel.value', action.data.applistLabel);
        empty = setIn(empty, 'dateReceived.value', action.data.dateReceived);
        empty = setIn(empty, 'dateSent.value', action.data.dateSent);
        empty = setIn(empty, 'projectId', action.data.projectId);
        empty = setIn(empty, 'id', -1);
      }

      return state.push(empty);
    }

    case SORT_APPROVALS:
      return reorder(sortApprovals(state, action));

    case REVERT_CHANGES:
      return revert(state);

    default:
      return state;
  }
}

function reorder(state) {
  return state.map((ap, i) => ap.set('index', i));
}

/**
 * Sort approval list.
 *
 * @param list {Immutable.List} List of target.
 * @param action {Object} Action object.
 * @returns {Immutable.List}
 */
function sortApprovals(list, action = {}) {
  if (!action.field || !action.direction) return list;

  const byDate = ['dateSent', 'dateReceived'];
  const byNumber = [
    'numberTargets',
    'numberApproved',
    'numberOfContacted',
    'numberOfLeads',
    'numberOfCc',
    'numberOfVisit',
    'numberOfNextActions',
    'numberOfPriorityA',
    'numberOfPriorityB',
    'numberOfPriorityC',
    'numberOfApprovedX',
    'percentApproved',
    'numberMailed',
    'percentMailed',
    'numberCalled',
    'percentCalled',
  ];
  const { field, direction } = action;
  const dir = direction === 'up' ? -1 : 1;

  if (byDate.indexOf(field) > -1) {
    return sortByDate(list, field, dir);
  }

  if (byNumber.indexOf(field) > -1) {
    return sortByNumber(list, field, dir);
  }

  return sortByString(list, field, dir);
}

/**
 * Get approvals from response.
 *
 * @param state {Object} Current state.
 * @param action {Object} Response.
 * @return {Immutable.List} Approvals list.
 */
function update(state, action) {
  const { index, name, value } = action;
  const key = state.findKey(a => a.get('index') === index);

  if (key !== undefined && key >= 0) {
    let item = setIn(state.get(key), name, value);

    item = item.set(
      'dirty',
      FIELDS_TO_SPY.reduce((changed, value) => changed || isChanged(item.get(value)), false),
    );

    return state.set(key, item);
  }

  return state;
}

/**
 * Get approvals from response.
 *
 * @param response {Object} Response.
 * @returns {Immutable.List} Approvals list.
 */
function getApprovals({ response, projectId }) {
  if (response.status === 'success') {
    let approval = [];
    let noApproval = [];

    if (response.data.isDefaultAddOn) {
      const { defaultApprovalLists, defaultNoApprovalLists } = response.data;

      approval = defaultApprovalLists;
      noApproval = defaultNoApprovalLists.map(item => ({
        ...item,
        projectId,
      }));
    } else {
      const { approvalLists, noApprovalLists } = response.data;

      approval = approvalLists;
      noApproval = noApprovalLists.map(item => ({
        ...item,
        projectId,
      }));
    }

    const fullFieldNoAppLists = noApproval.map(list => {
      list.id = 'no_approval_lists';
      list.applistLabel = '(No Approval List)';
      list.dateSent = '';
      list.dateReceived = '';

      return list;
    });

    return List(
      [...approval, ...fullFieldNoAppLists].map((approval, index) => {
        const dateSent = moment(approval.dateSent);
        const dateReceived = moment(approval.dateReceived);
        const numberOfTargets = defaultNumber(approval.numberOfTargets);
        const numberOfApprovedTargets = defaultNumber(approval.numberOfApprovedTargets);
        const numberOfMailedTargets = defaultNumber(approval.numberOfMailedTargets);
        const numberOfCalledTargets = defaultNumber(approval.numberOfCalledTargets);
        const numberOfContacted = defaultNumber(approval.numberOfContacted);
        const numberOfLeads = defaultNumber(approval.numberOfLeads);
        const numberOfCc = defaultNumber(approval.numberOfCc);
        const numberOfVisit = defaultNumber(approval.numberOfVisit);
        const numberOfNextActions = defaultNumber(approval.numberOfNextActions);
        const numberOfPriorityA = defaultNumber(approval.numberOfPriorityA);
        const numberOfPriorityB = defaultNumber(approval.numberOfPriorityB);
        const numberOfPriorityC = defaultNumber(approval.numberOfPriorityC);
        const numberOfApprovedX = defaultNumber(approval.numberOfApprovedX);

        return spy(
          Map({
            index,
            id: approval.id,
            projectId: approval.projectId,
            dateSent: dateSent.isValid() ? dateSent : null,
            dateReceived: dateReceived.isValid() ? dateReceived : null,
            applistLabel: approval.applistLabel,
            numberTargets: numberOfTargets,
            numberApproved: numberOfApprovedTargets,
            percentApproved: countPercent(numberOfApprovedTargets, numberOfTargets),
            numberMailed: numberOfMailedTargets,
            percentMailed: countPercent(numberOfMailedTargets, numberOfTargets),
            numberCalled: numberOfCalledTargets,
            percentCalled: countPercent(numberOfCalledTargets, numberOfTargets),
            mode: config.VIEW_MODE,
            numberOfContacted,
            percentContacted: countPercent(numberOfContacted, numberOfTargets),
            numberOfLeads,
            percentLeads: countPercent(numberOfLeads, numberOfTargets),
            numberOfCc,
            percentCc: countPercent(numberOfCc, numberOfTargets),
            numberOfVisit,
            percentVisit: countPercent(numberOfVisit, numberOfTargets),
            numberOfNextActions,
            percentNextActions: countPercent(numberOfNextActions, numberOfTargets),
            numberOfPriorityA,
            percentOfPriorityA: countPercent(numberOfPriorityA, numberOfTargets),
            numberOfPriorityB,
            percentOfPriorityB: countPercent(numberOfPriorityB, numberOfTargets),
            numberOfPriorityC,
            percentOfPriorityC: countPercent(numberOfPriorityC, numberOfTargets),
            numberOfApprovedX,
            percentOfApprovedX: countPercent(numberOfApprovedX, numberOfTargets),
            dirty: false,
          }),
          FIELDS_TO_SPY,
        );
      }),
    );
  }

  return defaultState;
}

/**
 * Get percent.
 */
function countPercent(numerator, denominator) {
  if (denominator === 0) {
    return 0;
  }

  return Math.round((numerator / denominator) * 100);
}

/**
 * Create an empty row and set it to edit mode.
 *
 * @returns {Immutable.Map}
 */
function createEmptyRow(index) {
  return spy(
    fromJS({
      index,
      id: -1,
      dateSent: null,
      dateReceived: null,
      applistLabel: '',
      target: '',
      numberApproved: 0,
      numberOfContacted: 0,
      percentContacted: 0,
      numberTargets: 0,
      numberOfLeads: 0,
      percentLeads: 0,
      numberOfCc: 0,
      percentCc: 0,
      numberOfVisit: 0,
      percentVisit: 0,
      numberOfNextActions: 0,
      percentNextActions: 0,
      numberOfPriorityA: 0,
      numberOfPriorityB: 0,
      numberOfPriorityC: 0,
      numberOfPriorityX: 0,
      percentOfApprovedX: 0,
      numberOfApprovedX: 0,
      percentApproved: 0,
      numberMailed: 0,
      percentMailed: 0,
      numberCalled: 0,
      percentCalled: 0,
      mode: config.EDIT_MODE,
      dirty: false,
    }),
    FIELDS_TO_SPY,
  );
}
