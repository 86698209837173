import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'underscore';
import { createPortal } from 'react-dom';

class AutoComplete extends PureComponent {
  constructor(props) {
    super(props);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);

    this.inputRef = React.createRef();
    this.panelRef = React.createRef();

    this.state = {
      value: this.props.initValue,
    };
  }

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('keydown', this.stopNavigation);
      setTimeout(() => {
        this.inputRef.current.select();
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('keydown', this.stopNavigation);
    }
  }

  stopNavigation(event) {
    if ([32, 35, 36, 37, 38, 39, 40].indexOf(event.keyCode) > -1) {
      event.stopPropagation();
    }
  }

  getSuggestionValue(suggestion) {
    return suggestion.text;
  }

  renderSuggestion(suggestion) {
    return (
      <div className="react-autosuggest__suggestion-wrapper">
        <span className="ind--text">{suggestion.text}</span>
      </div>
    );
  }

  onSuggestionsFetchRequested({ value, reason }) {
    if (reason === 'input-changed' || reason === 'input-focused') {
      debounce(
        this.props.loadSuggestion({
          value,
          rowData: this.props.rowData,
          rowNode: this.props.rowNode,
        }),
        500,
      );
    }
  }

  onSuggestionsClearRequested() {
    this.props.clearSuggestion({
      rowData: this.props.rowData,
      rowNode: this.props.rowNode,
    });
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionSelected(event, { suggestion }) {
    this.props.pickSuggestion({
      rowData: this.props.rowData,
      rowNode: this.props.rowNode,
      selected: suggestion,
      inputText: this.state.value,
    });
  }

  renderSuggestionsContainer({ containerProps, children }) {
    const targetElement = this.panelRef.current;

    if (!targetElement) return null;

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();
    const top = targetRect.bottom - bodyRect.top - 3;
    const left = targetRect.left - bodyRect.left;
    const width = Math.max(targetRect.width, 150);

    const style = {
      position: 'absolute',
      top,
      left,
      width,
      border: '1px solid gray',
      background: '#FFF',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex: 1000,
    };

    const loader = this.props.loading && (
      <ul className="react-autosuggest__suggestions-list">
        <li className="react-autosuggest__suggestion">
          <i className="fa fa-spinner fa-spin" /> Loading...
        </li>
      </ul>
    );

    const container = (
      <div {...containerProps} className="autocomplete-div-hide-on-empty" style={style}>
        {loader}
        {children}
      </div>
    );

    return createPortal(container, document.querySelector('body'));
  }

  renderInputComponent(inputProps) {
    const inputRef = inputProps.ref;

    this.inputRef = inputRef;

    return <input {...inputProps} ref={inputRef} />;
  }

  render() {
    const { value } = this.state;
    const { suggestions, placeholder = 'Input', name = 'autosuggestion' } = this.props;
    const inputProps = {
      className: 'form-control no-border',
      name,
      disabled: false,
      placeholder,
      onChange: this.onChange,
      value,
    };

    return (
      <div ref={this.panelRef}>
        <Autosuggest
          getSuggestionValue={this.getSuggestionValue}
          inputProps={inputProps}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          renderInputComponent={this.renderInputComponent}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          suggestions={suggestions}
          focusInputOnSuggestionClick
        />
      </div>
    );
  }
}

AutoComplete.propTypes = {
  clearSuggestion: PropTypes.func.isRequired,
  initValue: PropTypes.string,
  loading: PropTypes.bool,
  loadSuggestion: PropTypes.func.isRequired,
  pickSuggestion: PropTypes.func.isRequired,
  suggestions: PropTypes.array,
};

AutoComplete.defaultProps = {
  loading: false,
  suggestions: [],
  initValue: '',
};

export default function(connect) {
  const Editor = connect(AutoComplete);

  class AutoCompleteEditor extends PureComponent {
    constructor(props) {
      super(props);
      this.suggestion = null;
      this.getValue = this.getValue.bind(this);
      this.isCancelAfterEnd = this.isCancelAfterEnd.bind(this);
      this.pickSuggestion = this.pickSuggestion.bind(this);
      this.stopEditing = this.stopEditing.bind(this);
      this.suggestionRef = React.createRef();
      this.panelRef = React.createRef();
    }

    componentDidMount() {
      document.addEventListener('click', this.stopEditing);
      this.panelRef.current.addEventListener('click', this.stopPropagation);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.stopEditing);
      this.panelRef.current.removeEventListener('click', this.stopPropagation);
    }

    stopEditing() {
      setTimeout(() => {
        try {
          this.props.api.stopEditing();
        } catch (error) {
          console.warn(error);
        }
      }, 0);
    }

    stopPropagation(event) {
      event.stopPropagation();
    }

    getValue() {
      return this.suggestion ? this.suggestion.selected.text : this.props.initValue;
    }

    isPopup() {
      return false;
    }

    /**
     * If suggestion's ID is not set or == -1, "Add New..." item in suggestion list was clicked.
     */
    pickSuggestion(suggestion) {
      const { selected, inputText } = suggestion;
      const { column, node } = this.props;

      if (selected.id === null || selected.id === undefined || selected.id === -1) {
        if (column.colDef.addNewItem) {
          column.colDef.addNewItem({ rowNode: node, inputText });
        }
      } else {
        this.suggestion = suggestion;
      }
      this.stopEditing();
    }

    isCancelAfterEnd() {
      if (this.suggestion && this.suggestionRef.current) {
        this.suggestionRef.current.props.selectSuggestion(this.suggestion);

        return false;
      }

      return true;
    }

    render() {
      const { node, value, clearSuggestion, loadSuggestion } = this.props;

      return (
        <div ref={this.panelRef} className="all-h100p">
          <Editor
            ref={this.suggestionRef}
            clearSuggestion={clearSuggestion}
            initValue={value || ''}
            loadSuggestion={loadSuggestion}
            pickSuggestion={this.pickSuggestion}
            rowData={node.data}
            rowNode={node}
          />
        </div>
      );
    }
  }

  return AutoCompleteEditor;
}
