import { CALL_API, CHAIN_API } from '../../middleware/api';
import { isNew } from '../../utils/uniqueId';
import config from '../../config';
import { reloadEvents } from './companyEvents';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../utils/industryTagsHelper';

export const FETCHING_COMPANY_FILES_SUCCESS = Symbol('FETCHING_COMPANY_FILES_SUCCESS');
export const DELETE_COMPANY_FILE_SUCCESS = Symbol('DELETE_COMPANY_FILE_SUCCESS');
export const FETCHING_COMPANY_BUYERS_SUCCESS = Symbol('FETCHING_COMPANY_BUYERS_SUCCESS');
export const FETCHING_COMPANY_SUBSIDIARIES_SUCCESS = Symbol('FETCHING_COMPANY_SUBSIDIARIES_SUCCESS');
export const LOADED_COMPANY_CONTACT = Symbol('LOADED_COMPANY_CONTACT');
export const FETCHING_TRADE_SHOWS_SUCCESS = Symbol('FETCHING_TRADE_SHOWS_SUCCESS');

export const ADD_COMPANY_DETAIL_ENTITY = Symbol('ADD_COMPANY_DETAIL_ENTITY');
export const EDIT_COMPANY_DETAIL_ENTITY = Symbol('EDIT_COMPANY_DETAIL_ENTITY');
export const DEL_COMPANY_DETAIL_ENTITY = Symbol('DEL_COMPANY_DETAIL_ENTITY');

export const DEL_COMPANY_CONTACT = Symbol('DEL_COMPANY_CONTACT');
export const OPEN_COMPANY_CONTACT = Symbol('OPEN_COMPANY_CONTACT');
export const UPDATE_COMPANY = Symbol('UPDATE_COMPANY');
export const REVERT_UPDATE = Symbol('REVERT_UPDATE');
export const LOADED_USERS = Symbol('LOADED_USERS');
export const CLEAR_USERS = Symbol('CLEAR_USERS');
export const SAVED_COMPANY_TARGET = Symbol('SAVED_COMPANY_TARGET');
export const ERRORED_COMPANY_TARGET = Symbol('ERRORED_COMPANY_TARGET');
export const CLOSE_COMPANY_TARGET_VALIDATION_ERROR = Symbol('CLOSE_COMPANY_TARGET_VALIDATION_ERROR');

export const LOADED_COMPANY_INDUSTRIES = Symbol('LOADED_COMPANY_INDUSTRIES');
export const LOADED_COMPANY_TRADESHOWS = Symbol('LOADED_COMPANY_TRADESHOWS');
export const LOADED_SELECTED_TRADESHOW = Symbol('LOADED_SELECTED_TRADESHOW');
export const REMOVE_TRADE_SHOW = Symbol('REMOVE_TRADE_SHOW');
export const LOADED_COMPANY_BUYERS_LIST = Symbol('LOADED_COMPANY_BUYERS_LIST');
export const LOADED_COMPANY_PROJECTS = Symbol('LOADED_COMPANY_PROJECTS');
export const LOADED_COMPANY_APPROVAL_LISTS = Symbol('LOADED_COMPANY_APPROVAL_LISTS');
export const LOADED_TITLES = Symbol('LOADED_TITLES');
export const LOADING_TITLES = Symbol('LOADING_TITLES');
export const LOADED_TITLES_FAIL = Symbol('LOADED_TITLES_FAIL');

export const ERROR_SAVE_COMPANY_CONTACT = Symbol('ERROR_SAVE_COMPANY_CONTACT');
export const ERROR_SAVE_COMPANY_CONTACT_CHANNEL = Symbol('ERROR_SAVE_COMPANY_CONTACT_CHANNEL');
export const SAVED_COMPANY_CONTACT = Symbol('SAVED_COMPANY_CONTACT');
export const SAVED_COMPANY_CONTACT_CHANNEL = Symbol('SAVED_COMPANY_CONTACT_CHANNEL');
export const SAVED_COMPANY_ENTITYES = Symbol('SAVED_COMPANY_ENTITYES');
export const SAVE_COMPANY_ENTITYES_ERROR = Symbol('SAVE_COMPANY_ENTITYES_ERROR');
export const LOADED_COMPANY_ENTITYES = Symbol('LOADED_COMPANY_ENTITYES');
export const DEL_CONTACT_CHANNEL = Symbol('DEL_CONTACT_CHANNEL');

export const SAVE_FISCAL_YEAR_SUCCESS = Symbol('SAVE_FISCAL_YEAR_SUCCESS');
export const SAVE_FISCAL_YEAR_ERROR = Symbol('SAVE_FISCAL_YEAR_ERROR');
export const DELETE_FISCAL_YEAR_SUCCESS = Symbol('DELETE_FISCAL_YEAR_SUCCESS');

export const OPEN_COMPANY_BUYER = Symbol('OPEN_COMPANY_BUYER');
export const ACTIVE_COMPANY_BUYER = Symbol('ACTIVE_COMPANY_BUYER');
export const APPROVE_COMPANY_BUYER = Symbol('APPROVE_COMPANY_BUYER');
export const SAVE_COMPANY_BUYER = Symbol('SAVE_COMPANY_BUYER');
export const DEL_COMPANY_BUYER = Symbol('DEL_COMPANY_BUYER');
export const REORDER_CONTACTS = Symbol('REORDER_CONTACTS');
export const REORDER_CHANNELS = Symbol('REORDER_CHANNELS');
export const ADD_VALIDATION_ERROR = Symbol('ADD_VALIDATION_ERROR');

export const CLOSE_FISCAL_ERROR = Symbol('CLOSE_FISCAL_ERROR');
export const REVERT_INDUSTRIES_TO_ORIGIN = Symbol('REVERT_INDUSTRIES_TO_ORIGIN');

export const STATUS_CODE_UPDATE = Symbol('STATUS_CODE_UPDATE');
export const STATUS_CODE_UPDATED_ERROR = Symbol('STATUS_CODE_UPDATED_ERROR');

export const LOADED_PHONE_CODES_SUCCESS = Symbol('LOADED_PHONE_CODES_SUCCESS');
export const DETAIL_ENTITY_VALIDITY = Symbol('DETAIL_ENTITY_VALIDITY');

export const LOADED_BUSINESS_MODELS_SUCCESS = Symbol('LOADED_BUSINESS_MODELS_SUCCESS');
export const RESET_BUSINESS_MODELS = Symbol('RESET_BUSINESS_MODELS');
export const MERGE_BUSINESS_MODELS = Symbol('MERGE_BUSINESS_MODELS');
export const SELECT_BUSINESS_MODEL = Symbol('SELECT_BUSINESS_MODEL');
export const SELECT_BUSINESS_MODELS = Symbol('SELECT_BUSINESS_MODELS');
export const UPDATE_INDUSTRY_CATEGORY = Symbol('UPDATE_INDUSTRY_CATEGORY');
export const SET_LAST_RESEARCHER = Symbol('SET_LAST_RESEARCHER');

/**
 * Load phone code of countries.
 */
export function loadCountryPhoneCodes() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/phone_code_countries',
      unifier: () => '/api/v1/browse/phone_code_countries',
      maxCount: 1,
      successType: LOADED_PHONE_CODES_SUCCESS,
    },
  };
}

/**
 * Load users by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load director by filtering.
 */
export function findDirectors({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.DIRECTOR,
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Clear suggests of users.
 */
export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}

/**
 * Load analyst/associate by filtering.
 */
export function findAnalysts({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users_by_role',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        role: config.ANALYST_ASSOCIATE,
        like: filter,
        limit: 100,
        page,
      },
      successType: LOADED_USERS,
      afterSuccess,
    },
  };
}

/**
 * Load projects by filter.
 *
 * @returns {MiddlewareApi.CallApi}
 */
export function findProjects({ buyerId, afterSuccess, filter, page, ...rest }) {
  return {
    ...rest,
    page,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyers/${buyerId}/projects`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        page,
        like: filter,
      },
      successType: LOADED_COMPANY_PROJECTS,
      afterSuccess,
    },
  };
}

/**
 * Load approvalLists by filter.
 *
 * @returns {MiddlewareApi.CallApi}
 */
export function findApprovalLists({ afterSuccess, projectId, filter, ...rest }) {
  return {
    ...rest,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/projects/${projectId}/approval_lists`,
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      successType: LOADED_COMPANY_APPROVAL_LISTS,
      afterSuccess,
    },
  };
}

/**
 * Load buyers by filter.
 *
 * @returns {MiddlewareApi.CallApi}
 */
export function findBuyers({ afterSuccess, filter, ...rest }) {
  return {
    ...rest,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/buyer_names',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      successType: LOADED_COMPANY_BUYERS_LIST,
      afterSuccess,
    },
  };
}

/**
 * Load titles by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findTitles({ filter, prefix }) {
  return {
    filter,
    prefix,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/people/executive/titles',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        like: filter,
      },
      startType: LOADING_TITLES,
      successType: LOADED_TITLES,
      errorType: LOADED_TITLES_FAIL,
    },
  };
}

export function handleUpdateCompany(info) {
  return {
    type: UPDATE_COMPANY,
    ...info,
  };
}

export function getSubsidiaries({ companyId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/target_subsidiaries`,
      successType: FETCHING_COMPANY_SUBSIDIARIES_SUCCESS,
    },
  };
}

export function getBuyers({ companyId, afterSuccess, ...rest }) {
  return {
    ...rest,
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/target_buyers`,
      successType: FETCHING_COMPANY_BUYERS_SUCCESS,
      afterSuccess,
    },
  };
}

export function getFiles({ companyId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/files`,
      successType: FETCHING_COMPANY_FILES_SUCCESS,
    },
  };
}

/**
 * Gets trade show list for target.
 *
 * @param companyId Targets id.
 * @returns Action.
 */
export function getTradeShows(companyId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}/detail/tradeshow_event_targets`,
      successType: FETCHING_TRADE_SHOWS_SUCCESS,
    },
  };
}

/**
 * Delete file.
 *
 * @param companyId {Number} Conmany Id.
 * @param id {Number} Id.
 * @param afterSuccess {Function}.
 */
export function deleteFile({ companyId, id }, afterSuccess) {
  return {
    [CHAIN_API]: [
      () => ({
        companyId,
        id,
        [CALL_API]: {
          method: 'delete',
          path: `/api/v1/companies/${companyId}/files/${id}`,
          successType: DELETE_COMPANY_FILE_SUCCESS,
          afterSuccess,
        },
      }),
    ],
  };
}

export function uploadFile({ companyId, formData, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/companies/${companyId}/files`,
          body: formData,
        },
      }),
      () => ({
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}/files`,
          successType: FETCHING_COMPANY_FILES_SUCCESS,
          afterSuccess,
        },
      }),
    ],
  };
}

export function openContact({ index }) {
  return {
    type: OPEN_COMPANY_CONTACT,
    index,
  };
}

export function addDetailEntity({ name }) {
  return {
    type: ADD_COMPANY_DETAIL_ENTITY,
    entity: name,
  };
}

export function startEditDetailEntity({ name, index }) {
  return {
    type: EDIT_COMPANY_DETAIL_ENTITY,
    entity: name,
    index,
  };
}

export function checkValidity() {
  return {
    type: DETAIL_ENTITY_VALIDITY,
  };
}

export function delDetailEntity({ companyId, name, id, body, realBody, errorKey }, afterSuccess) {
  const entity = name;

  name = name.replace(/[A-Z]/g, str => `_${str.toLowerCase()}`);

  const method = 'post';
  let path = `/api/v1/companies/${companyId}/bulk/${name}`;

  switch (entity) {
    case 'industries':
      path = `/api/v1/companies/${companyId}/target_industries`;
      break;

    case 'customFields':
      path = `/api/v1/companies/${companyId}/bulk/target_custom_fields`;
      break;

    default:
      break;
  }

  const delAction = {
    errorKey,
    entity,
    id,
    type: DEL_COMPANY_DETAIL_ENTITY,
  };

  if (isNew(id)) {
    return dispatch => {
      dispatch(delAction);
      afterSuccess({ dispatch });
    };
  }

  return {
    [CHAIN_API]: [
      () => ({
        body,
        entity,
        [CALL_API]: {
          method,
          path,
          unifier: `put /api/v1/companies/${companyId}/${entity} {${JSON.stringify(body)}}`,
          body: realBody,
          errorType: SAVE_COMPANY_ENTITYES_ERROR,
          successType: SAVED_COMPANY_ENTITYES,
          afterError: afterSuccess,
        },
      }),
      () => ({
        body,
        entity,
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}/detail/${name}`,
          successType: LOADED_COMPANY_ENTITYES,
          afterError: afterSuccess,
          afterSuccess: ({ dispatch, ...props }, ...rest) => {
            dispatch(delAction);
            dispatch({
              body,
              entity: name,
              type: SAVED_COMPANY_ENTITYES,
            });
            afterSuccess({ dispatch, ...props }, ...rest);
          },
        },
      }),
    ],
  };
}

export function saveInfo({ companyId, body, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        fields: Object.keys(body),
        [CALL_API]: {
          method: 'put',
          unifier: `target put /api/v1/companies/${companyId} {${JSON.stringify(body)}}`,
          path: `/api/v1/companies/${companyId}`,
          body,
          successType: SAVED_COMPANY_TARGET,
          afterSuccess: ({ dispatch }) => {
            dispatch(reloadEvents({ entityId: companyId }));
            if (afterSuccess) {
              afterSuccess(companyId);
            }
          },
          errorType: ERRORED_COMPANY_TARGET,
          skipGlobalErrorHandler: true,
        },
      }),
      () => ({
        entity: ['recordOwnerId', 'recordSubOwnerId', 'recordOwnerEmail'],
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}`,
          successType: LOADED_COMPANY_ENTITYES,
        },
      }),
    ],
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_COMPANY_TARGET_VALIDATION_ERROR,
    field,
  };
}

/**
 * Save contact tags.
 *
 * @param companyId {Number} Contact id.
 * @param name {String} Tags to save.
 * @param body {Object[]} Tags to save.
 * @returns {MiddlewareApi.CallApi}
 */
export function saveCompanyDetailEntity({ companyId, name, body, realBody }) {
  const entity = name;

  name = name.replace(/[A-Z]/g, str => `_${str.toLowerCase()}`);

  const method = 'post';
  let path = `/api/v1/companies/${companyId}/bulk/${name}`;

  switch (entity) {
    case 'industries':
      path = `/api/v1/companies/${companyId}/target_industries`;
      break;

    case 'customFields':
      path = `/api/v1/companies/${companyId}/bulk/target_custom_fields`;
      break;

    default:
      break;
  }

  return {
    [CHAIN_API]: [
      () => ({
        body,
        entity,
        [CALL_API]: {
          method,
          path,
          unifier: `put /api/v1/companies/${companyId}/${name} {${JSON.stringify(body)}}`,
          body: realBody,
          successType: SAVED_COMPANY_ENTITYES,
          errorType: SAVE_COMPANY_ENTITYES_ERROR,
        },
      }),
      () => ({
        body,
        entity,
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}/detail/${name}`,
          successType: LOADED_COMPANY_ENTITYES,
        },
      }),
    ],
  };
}

/**
 * Saves target's trade shows list.
 *
 * @param tradeShows Trade shows list.
 * @param companyId Company id.
 * @returns Action.
 */
export function saveTradeShows(tradeShows, companyId) {
  const data = tradeShows.map(tradeShow => ({
    tradeShowEventId: tradeShow.event.id,
    tradeShowId: tradeShow.id,
    booth: tradeShow.booth,
  }));

  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${companyId}/bulk/tradeshow_event_targets`,
      body: { data },
    },
  };
}

/**
 * Load industries by filter.
 *
 * @param filter {String}.
 * @param page {Number}.
 * @param afterSuccess {Function}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findIndustries({ filter, page, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/industries',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        page,
        limit: 100,
        like: filter,
      },
      afterSuccess,
      successType: LOADED_COMPANY_INDUSTRIES,
    },
  };
}

/**
 * Load tradeshows by filter.
 *
 * @param {object} params Params.
 * @param {string} params.filter Query pattern.
 * @param {number} params.page Query page.
 * @param {string} params.companyId Company id.
 * @param {Function} params.afterSuccess Success callback function.
 * @returns {MiddlewareApi.CallApi}
 */
export function findTradeShows({ filter, page, companyId, afterSuccess }) {
  return {
    filter,
    page,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/tradeshows',
      unifier: params => `${params.path}`,
      maxCount: 2,
      query: {
        page,
        limit: 100,
        like: filter,
        exclude_company: companyId,
      },
      afterSuccess,
      successType: LOADED_COMPANY_TRADESHOWS,
    },
  };
}

export function delContact({ contactId, entityContactsId, companyId }, afterSuccess) {
  const method = 'delete';
  const path = `/api/v1/companies/${companyId}/entity_contacts/${entityContactsId}`;

  return {
    contactId,
    [CALL_API]: {
      method,
      unifier: `delete ${path}`,
      path,
      successType: DEL_COMPANY_CONTACT,
      afterError: afterSuccess,
      afterSuccess,
    },
  };
}

export function revertUpdate({ field }) {
  return {
    type: REVERT_UPDATE,
    field,
  };
}

export function updateLastResearcher(researcher) {
  return {
    type: SET_LAST_RESEARCHER,
    researcher,
  };
}

export function saveCompanyContact({ companyId, contactId, isNew, body, saved }, afterSuccess) {
  let method = 'put';
  let path = `/api/v1/companies/${companyId}/contact/${contactId}`;

  if (isNew) {
    method = 'post';
    path = `/api/v1/companies/${companyId}/contact`;
  }

  return {
    isNew,
    contactId,
    body: saved,
    [CALL_API]: {
      method,
      path,
      unifier: `put /api/v1/people/${contactId} {${JSON.stringify(body)}}`,
      body,
      successType: SAVED_COMPANY_CONTACT,
      afterSuccess: ({ dispatch }) =>
        dispatch({
          [CALL_API]: {
            method: 'get',
            path: `/api/v1/companies/${companyId}`,
            query: {
              include: 'entity_contacts',
            },
            successType: LOADED_COMPANY_CONTACT,
            afterSuccess,
          },
        }),
      skipGlobalErrorHandler: true,
      errorType: ERROR_SAVE_COMPANY_CONTACT,
    },
  };
}

export function setLastResearcher(contactId) {
  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/companies/${contactId}/last_researcher`,
      unifier: `/api/v1/companies/${contactId}/last_researcher`,
    },
  };
}

export function delContactChannel({ companyId, contactId, type, channelId }, afterSuccess) {
  const method = 'delete';
  const path = `/api/v1/people/${contactId}/${type}/${channelId}`;

  return {
    [CHAIN_API]: [
      () => ({
        channelType: type,
        id: channelId,
        contactId,
        [CALL_API]: {
          method,
          unifier: `delete ${path}`,
          path,
          successType: DEL_CONTACT_CHANNEL,
          afterError: afterSuccess,
          afterSuccess,
        },
      }),
      () => setLastResearcher(companyId),
    ],
  };
}

export function saveCompanyContactChannel(options, afterSuccess) {
  const { isNew, contactId, channelType, channelId, channelIndex, body, saved = body, companyId } = options;
  let path = `/api/v1/people/${contactId}/${channelType}/${channelId}`;
  let method = 'put';

  if (isNew) {
    path = `/api/v1/people/${contactId}/${channelType}`;
    method = 'post';
  }

  return {
    [CHAIN_API]: [
      () => ({
        isNew,
        contactId,
        channelType,
        channelId,
        channelIndex,
        body: saved,
        [CALL_API]: {
          method,
          path,
          body,
          successType: SAVED_COMPANY_CONTACT_CHANNEL,
          afterSuccess,
          skipGlobalErrorHandler: true,
          errorType: ERROR_SAVE_COMPANY_CONTACT_CHANNEL,
        },
      }),
      () => setLastResearcher(companyId),
    ],
  };
}

export function openBuyer({ index }) {
  return {
    type: OPEN_COMPANY_BUYER,
    index,
  };
}

export function activeBuyer({ index }) {
  return {
    type: ACTIVE_COMPANY_BUYER,
    index,
  };
}

export function changeBuyerApprove({ target, index }) {
  return {
    type: APPROVE_COMPANY_BUYER,
    index,
    target,
  };
}

export function delBuyer({ companyId, id }, afterSuccess) {
  return {
    [CHAIN_API]: [
      () => ({
        companyId,
        id,
        [CALL_API]: {
          method: 'delete',
          path: `/api/v1/companies/${companyId}/target_buyers/${id}`,
          successType: DEL_COMPANY_BUYER,
          afterError: afterSuccess,
        },
      }),
      () => getBuyers({ companyId, afterSuccess }),
    ],
  };
}

/**
 * Save company buyer.
 *
 * @returns {{id: *}}
 */
export function saveBuyers({ companyId, id, body, realBody = body, afterSuccess, ...rest }) {
  return {
    [CHAIN_API]: [
      () => ({
        companyId,
        id,
        body,
        ...rest,
        [CALL_API]: {
          method: 'put',
          unifier: `put /api/v1/companies/${companyId}/buyers/${id}`,
          path: `/api/v1/companies/${companyId}/buyers/${id}`,
          body: realBody,
          successType: SAVE_COMPANY_BUYER,
          afterSuccess: ({ dispatch }) => {
            dispatch(reloadEvents({ entityId: companyId, popupReserve: true }));
            if (afterSuccess) {
              afterSuccess();
            }
          },
        },
      }),
      () => getBuyers({ companyId, afterSuccess: () => {} }),
    ],
  };
}

/**
 * Add company buyer.
 *
 * @returns {{id: *}}
 */
export function addBuyer({ companyId, id, body, realBody = body, afterError, afterSuccess, ...rest }) {
  return {
    [CHAIN_API]: [
      () => ({
        companyId,
        id,
        body,
        ...rest,
        [CALL_API]: {
          method: 'post',
          unifier: `put /api/v1/companies/${companyId}/buyers`,
          path: `/api/v1/companies/${companyId}/buyers`,
          body: realBody,
          successType: SAVE_COMPANY_BUYER,
          afterError: () => {
            if (afterError) {
              afterError();
            }
          },
        },
      }),
      () => getBuyers({ companyId, afterSuccess }),
    ],
  };
}

export function saveFiscalYear({ companyId, yearId, body, isNew }, afterSuccess) {
  let method = 'put';
  let path = `/api/v1/companies/${companyId}/target_fiscal_years/${yearId}`;

  if (isNew) {
    method = 'post';
    path = `/api/v1/companies/${companyId}/target_fiscal_years`;
  }

  return {
    yearId,
    isNew,
    body,
    [CALL_API]: {
      method,
      path,
      body,
      successType: SAVE_FISCAL_YEAR_SUCCESS,
      afterSuccess,
      skipGlobalErrorHandler: true,
      errorType: SAVE_FISCAL_YEAR_ERROR,
    },
  };
}

export function delFiscalYear({ companyId, yearId }, afterSuccess) {
  return {
    yearId,
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/companies/${companyId}/target_fiscal_years/${yearId}`,
      successType: DELETE_FISCAL_YEAR_SUCCESS,
      afterError: afterSuccess,
      afterSuccess,
    },
  };
}

export function closeFiscalError(name, index) {
  return {
    type: CLOSE_FISCAL_ERROR,
    name,
    index,
  };
}

export function reorderContacts(contacts, companyId, sendToApi) {
  return {
    contacts,
    [CALL_API]: {
      method: 'post',
      body: sendToApi,
      unifier: `REORDER /api/v1/companies/${companyId}/contacts/order`,
      maxCount: 2,
      path: `/api/v1/companies/${companyId}/contacts/order`,
      startType: REORDER_CONTACTS,
      skipGlobalErrorHandler: true,
    },
  };
}

/**
 * Bulk update emails or phones.
 *
 * @param {string} name Can be 'email' or 'phones'.
 * @param {number} contactId Contact id.
 * @param {number} openedContact Index of manipulating contact.
 * @param {object} data Contact data. Example:
 * {
 * 	"data":{
 * 		"0":{
 * 			"contact_id": 280416,
 * 			"deleted_at": "",
 * 			"id": 187051,
 * 			"email": "test@mail.com",
 * 			"priority": 0,
 * 			"type": ""
 * 		},
 * 		"1":{
 * 			"id": 187052,
 * 			"contact_id": 280416,
 * 			"type": "Cell",
 * 			"email": "test2@mail.com",
 * 			"priority": 1,
 * 			"deleted_at": ""
 * 		}.
 * 	}.
 * }.
 */
export function reorderChannels({ name, contactId, data, openedContact }) {
  return {
    name,
    data,
    openedContact,
    [CALL_API]: {
      method: 'post',
      body: { data },
      unifier: `REORDER /api/v1/people/${contactId}/bulk/${name}`,
      maxCount: 2,
      path: `/api/v1/people/${contactId}/bulk/${name}`,
      startType: REORDER_CHANNELS,
      skipGlobalErrorHandler: true,
    },
  };
}

export function addValidationError({ ...rest }) {
  return {
    type: ADD_VALIDATION_ERROR,
    ...rest,
  };
}

export function revertIndustries() {
  return {
    type: REVERT_INDUSTRIES_TO_ORIGIN,
  };
}

export function updateStatusCode({ field, value }) {
  return {
    type: STATUS_CODE_UPDATE,
    field,
    value,
  };
}

export function saveStatusCodes({ companyId, buyerId, start, end, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'put',
          unifier: `put /api/v1/companies/${companyId}/buyers/${buyerId} {${JSON.stringify(start)}}`,
          path: `/api/v1/companies/${companyId}/buyers/${buyerId}`,
          body: start,
          errorType: STATUS_CODE_UPDATED_ERROR,
          skipGlobalErrorHandler: true,
        },
      }),
      () => ({
        companyId,
        id: buyerId,
        buyerId,
        body: end,
        buyerIndex: 0,
        [CALL_API]: {
          method: 'put',
          unifier: `put /api/v1/companies/${companyId}/buyers/${buyerId} {${JSON.stringify(end)}}`,
          path: `/api/v1/companies/${companyId}/buyers/${buyerId}`,
          body: end,
          errorType: STATUS_CODE_UPDATED_ERROR,
          afterSuccess,
          successType: SAVE_COMPANY_BUYER,
          skipGlobalErrorHandler: true,
        },
      }),
    ],
  };
}

/**
 * Load list of business models.
 */
export function loadBusinessModels(ids = []) {
  return {
    ids,
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/business_models',
      successType: LOADED_BUSINESS_MODELS_SUCCESS,
    },
  };
}

/**
 * Update industry tag after closing tags popup.
 */
export function updateIndustryCategory(selectedTags) {
  return {
    type: UPDATE_INDUSTRY_CATEGORY,
    selectedTags,
  };
}

/**
 * Save industry categories.
 *
 * @param {Array} industryCategoryIds Array of tag id.
 * @param {number} companyId.
 * @param {Function} afterSuccess.
 */
export function saveIndustryBoard({ industryCategoryIds, companyId, afterSuccess }) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/companies/${companyId}/bulk/industry_categories`,
          unifier: `/api/v1/companies/${companyId}/bulk/industry_categories`,
          body: { industryCategoryIds },
        },
      }),
      () => ({
        entity: [INDUSTRY_CATEGORIES, BUSINESS_MODELS],
        [CALL_API]: {
          method: 'get',
          path: `/api/v1/companies/${companyId}?include=industry_categories,business_models`,
          successType: LOADED_COMPANY_ENTITYES,
          afterSuccess,
        },
      }),
    ],
  };
}

/**
 * Loads trade show selected from suggests list.
 *
 * @param id Trade show id.
 * @returns Action.
 */
export function loadSelectedTradeShow(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/tradeshows?trade_show_ids[]=${id}`,
      successType: LOADED_SELECTED_TRADESHOW,
    },
  };
}

/**
 * Removes selected trade show from the table.
 *
 * @param id Trade show id.
 * @returns {{id, type: symbol}}
 */
export function removeTradeShow(index) {
  return {
    type: REMOVE_TRADE_SHOW,
    index,
  };
}

export function resetBusinessModels() {
  return {
    type: RESET_BUSINESS_MODELS,
  };
}

export function selectBusinessModel(id) {
  return {
    type: SELECT_BUSINESS_MODEL,
    id,
  };
}

export function selectBusinessModels(ids) {
  return {
    type: SELECT_BUSINESS_MODELS,
    ids,
  };
}

export function mergeBusinessModel(ids) {
  return {
    type: MERGE_BUSINESS_MODELS,
    ids,
  };
}
