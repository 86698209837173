import PropTypes from 'prop-types';
import React, { useEffect, useRef, memo } from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';

import { Spinner } from '../../helpers/Spinner';
import config from '../../../config';
import { execIfFunction } from '../../../utils/isFunction';
import { TemplateTableRow } from '../MidPanel/TemplateTableRow';
import { OneColTableRow } from '../MidPanel/OneColTableRow';

const types = config.values.getIn(['project', 'types']);

/**
 * Table with only one column component.
 *
 * @param props {Object} Component props.
 */
const OneColTableComponent = ({
  title,
  rows,
  contextId,
  isLoading,
  suggestions,
  type,
  onRowClick,
  onRowDoubleClick,
  onRowInsert,
  onSuggestionClose,
  onTextChange,
  onSuggestionSelect,
  onTemplateUpdate,
  onKeyPress,
  skipMenu,
  changed = false,
}) => {
  const containerRef = useRef(null);
  const inputElementRef = useRef(null);

  useEffect(() => {
    if (rows.findLastIndex(r => r.get('id') === -1) > 0) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    if (inputElementRef.current) {
      inputElementRef.current.focus();
    }
  }, [rows]);

  const handleRowInsert = event => {
    event.preventDefault();
    event.stopPropagation();
    execIfFunction(onRowInsert, type);
  };

  const noItems = rows.size === 0 ? <div className="table-message">No items to display</div> : null;
  const spinner = isLoading ? <Spinner /> : null;
  let rowContents = null;

  if (type === types.get('TEMPLATES')) {
    rowContents = rows.map((row, index) => (
      <TemplateTableRow
        key={index}
        contextId={contextId}
        onKeyPress={onKeyPress}
        onRowClick={onRowClick}
        onTemplateUpdate={onTemplateUpdate}
        onTextChange={onTextChange}
        row={row}
        setInputElement={input => (inputElementRef.current = input)}
        type={type}
      />
    ));
  } else {
    rowContents = rows.map((row, index) => (
      <OneColTableRow
        key={index}
        contextId={contextId}
        onRowClick={onRowClick}
        onRowDoubleClick={onRowDoubleClick}
        onSuggestionClose={onSuggestionClose}
        onSuggestionSelect={onSuggestionSelect}
        onTextChange={onTextChange}
        row={row}
        setInputElement={input => (inputElementRef.current = input)}
        skipMenu={skipMenu}
        suggestions={suggestions}
        type={type}
      />
    ));
  }

  const changedClass = classNames({ changed });

  return (
    <div className="col-md-2">
      <label>&nbsp;</label>
      <div ref={containerRef} className="table-edit-one-col">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <span className="add-row" onClick={handleRowInsert} title="Add New">
                  +
                </span>
                <span className={changedClass}>{title}</span>
              </th>
            </tr>
          </thead>
          <tbody>{rowContents}</tbody>
        </table>
        {noItems}
        {spinner}
      </div>
    </div>
  );
};

OneColTableComponent.propTypes = {
  contextId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onKeyPress: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowInsert: PropTypes.func,
  onSuggestionClose: PropTypes.func,
  onSuggestionSelect: PropTypes.func,
  onTemplateUpdate: PropTypes.func,
  onTextChange: PropTypes.func,
  rows: PropTypes.instanceOf(Immutable.List).isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export const OneColTable = memo(OneColTableComponent);
