import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import { AutoSuggestion } from '../helpers/AutoSuggestion';

/**
 * User auto suggestion component.
 *
 * @param props {Object}.
 * @param props.name {string} Name of text control.
 * @param props.label {string} Label of text control.
 * @param props.formMode {function} Form mode (view|edit).
 * @param props.formError {Immutable.Map} Error on form.
 * @param props.selectedUser {Immutable.Map} Selected user.
 * @param props.users {Array} List of users.
 * @param props.onUserSuggestionSelected {function} Handle user selection.
 * @param props.onSuggestionClose {function} Handle to close suggestion.
 * @returns {React.Component}
 * @class
 */
const UserAutocompleteComponent = ({
  id,
  name,
  label,
  formMode,
  formError,
  selectedUser,
  suggestions,
  mode,
  onSuggestionSelect,
  onSuggestionClose,
}) => (
  <AutoSuggestion
    data={{ name }}
    disabled={formMode === 'view'}
    hasError={formMode === 'edit' && formError.get(name, '').length > 0}
    id={id}
    label={label}
    mode={mode}
    name={name}
    onSuggestionClose={onSuggestionClose}
    onSuggestionSelect={onSuggestionSelect}
    placeholder={label}
    suggestions={suggestions}
    value={selectedUser.get(name)}
  />
);

UserAutocompleteComponent.propTypes = {
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSuggestionClose: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List).isRequired,
};

export const UserAutocomplete = memo(UserAutocompleteComponent);
