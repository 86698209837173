import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SelectRange from '../helpers/SelectRange';
import { BrowseCheckbox } from './BrowseCheckbox';

/**
 * Browse statuses filter component.
 *
 * @param props {Object}.
 * @param props.filter {Immutable.Map} Filter Object.
 * @param props.info {Immutable.Map} Info Object.
 * @param props.onChange {Function} Handle event of change.
 * @param props.statuses {Object[]} Statuses.
 * @returns {React.Component}
 * @class
 */
const BrowseStatusesFilterComponent = ({ filter, info, statuses, onChange }) => (
  <BrowseCheckbox filter={filter} info={info} onChange={onChange}>
    {statuses.length > 0 ? (
      <SelectRange
        className="BrowseControl-content BrowseControl-content--slider"
        filter={filter}
        info={info}
        onChange={onChange}
        rangeData={statuses}
      />
    ) : (
      <p>There is no statuses, please contact the system's administrator</p>
    )}
  </BrowseCheckbox>
);

BrowseStatusesFilterComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export const BrowseStatusesFilter = memo(BrowseStatusesFilterComponent);
