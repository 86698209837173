import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

const TextEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const editorRef = useRef(null);

  useEffect(() => {
    const stopNavigation = event => {
      if ([32, 37, 38, 39, 40, 36, 35, 9].includes(event.keyCode)) {
        event.stopPropagation();
      }
    };

    if (editorRef.current) {
      editorRef.current.addEventListener('keydown', stopNavigation);
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', stopNavigation);
      }
    };
  }, []);

  // Expose methods to ag-Grid using `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    getValue: () => value, // Return the updated value
    isPopup: () => false, // Indicate whether it's a popup
    afterGuiAttached: () => {
      if (editorRef.current) {
        editorRef.current.focus();
        editorRef.current.select();
      }
    },
  }));

  const onChange = event => {
    setValue(event.target.value);
  };

  return <input ref={editorRef} className="form-control input-sm" onChange={onChange} type="text" value={value} />;
});

export default TextEditor;
