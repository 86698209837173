import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { Map } from 'immutable';
import triggerChange from 'react-trigger-change';

import { upperCaseWords } from '../../utils/string';
import uniqueId from '../../utils/uniqueId';
import DeepValue, { PureDeepValue } from '../decorators/DeepValue';
import ChangeDetection, { PureChangeDetection } from '../decorators/ChangeDetection';
import DisplayMap from '../decorators/DisplayMap';
import NullAsEmptyString from '../decorators/NullAsEmptyString';
import EmailVerificationInput from './EmailVerificationInput';

/**
 * Inline input with hidden label.
 * If you need to show label - use `showLabel={true}` property.
 *
 * @param props {Object} You can provide any other available on <input/>.
 * @param [props.id = uniqueId()] {string}.
 * @param props.name {string}.
 * @param props.placeholder {string}.
 * @param [props.defaultValue] {any}.
 * @param [props.value] {any} Can be instance of Immutable.Map. Then try to find originalValue and value keys in it.
 * @param [props.originalValue=null] {string|null} Is `null` will not check current value with originalValue.
 *    Else if value !== currentValue mark input by red triangle.
 * @param [props.label] {string|React.Component}.
 * @param [props.type=`'text'`] {string} Input's type attr.
 * @param [props.className=`''`] {string} Extra input`s class names. Need not to add `"form-control"`.
 * @param [props.showLabel=`false`] {boolean}.
 * @returns {React.Component}
 */
class _PureInlineInput extends PureComponent {
  constructor(...rest) {
    super(...rest);
    this.onBlur = this.onBlur.bind(this);
    this.formatPhone = this.formatPhone.bind(this);
    this.id = uniqueId();
  }

  onBlur(event, ...rest) {
    if (!this.props.phoneType && this.props.name !== 'phone') return this._onBlur(event, ...rest);

    const { value = '', id = this.id } = this.props;

    if (value[0] === '+') return this._onBlur(event, ...rest);

    const tmp = value.replace(/[^\d]/g, '');

    if (tmp.length === 10) {
      setImmediate(this.formatPhone(id, tmp, event, ...rest));
    } else if (tmp.length === 11 && String(tmp[0]) === '1') {
      setImmediate(this.formatPhone(id, tmp.substring(1), event, ...rest));
    } else return this._onBlur(event, ...rest);
  }

  formatPhone(id, tmp, event, ...rest) {
    const input = document.getElementById(id);

    input.value = `${tmp[0]}${tmp[1]}${tmp[2]}-${tmp[3]}${tmp[4]}${tmp[5]}-${tmp[6]}${tmp[7]}${tmp[8]}${tmp[9]}`;
    setImmediate(() => {
      triggerChange(input);

      return this._onBlur(event, ...rest);
    });
  }

  _onBlur(...rest) {
    if (this.props.onBlur) return this.props.onBlur(...rest);
  }

  render() {
    const { props } = this;

    const {
      id = this.id,
      label,
      name,
      placeholder,
      type = 'text',
      showLabel = false,
      changed = false,
      value,
      defaultValue,
      originalValue,
      inputRef,
      canDisplayError,
      ...extraProps
    } = props;

    const values = getValues(props);
    const labelClassName = classNames({ hidden: !showLabel });
    const className = classNames('form-control', props.className, { changed });

    if (type === 'money') {
      return (
        <MoneyInput
          {...extraProps}
          className={className}
          id={id}
          label={label}
          name={name}
          onBlur={this.onBlur}
          placeholder={placeholder}
          type="text"
          values={values}
        />
      );
    }

    if (type === 'date') {
      return (
        <DateInput
          {...props}
          className={className}
          labelClassName={labelClassName}
          onBlur={this.onBlur}
          {...values}
          autoComplete="off"
          popperContainer={({ children }) => createPortal(children, document.body)}
          popperModifiers={{ preventOverflow: { enabled: false }, hide: { enabled: false } }}
        />
      );
    }

    if (type === 'currency') {
      return (
        <PureCurrencyInput
          {...extraProps}
          className={className}
          id={id}
          label={label}
          labelClassName={labelClassName}
          name={name}
          onBlur={this.onBlur}
          placeholder={placeholder}
          type="text"
          values={values}
        />
      );
    }

    if (type === 'web') {
      return (
        <PureWebInput
          {...extraProps}
          className={className}
          id={id}
          label={label}
          labelClassName={labelClassName}
          name={name}
          onBlur={this.onBlur}
          placeholder={placeholder}
          type="text"
          values={values}
        />
      );
    }

    if (type === 'emailVerify') {
      return (
        <EmailVerificationInput
          {...extraProps}
          className={className}
          id={id}
          label={label}
          name={name}
          onBlur={this.onBlur}
          placeholder={placeholder}
          showLabel={showLabel}
          type="email"
          values={values}
        />
      );
    }

    return (
      <span className="control-wrap">
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <span className="control-wrap">
          <input
            {...extraProps}
            {...values}
            ref={inputRef}
            className={className}
            id={id}
            name={name}
            onBlur={this.onBlur}
            placeholder={upperCaseWords(placeholder)}
            type={type}
          />
        </span>
      </span>
    );
  }
}

const PureInlineInput = PureDeepValue(PureChangeDetection(NullAsEmptyString(DisplayMap(_PureInlineInput))));

const InlineInput = DeepValue(ChangeDetection(NullAsEmptyString(DisplayMap(props => <PureInlineInput {...props} />))));

InlineInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  originalValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
};

class PureWebInput extends PureComponent {
  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.inputRef = this.inputRef.bind(this);
  }

  inputRef(input) {
    this.input = input;
    if (this.props.inputRef) {
      this.props.inputRef(input);
    }
  }

  onBlur(event) {
    const { value = '' } = event.target;

    if (value) {
      let url = value.replace(/^https?:\/\//gi, '');

      if (url.indexOf('/') > 0) {
        url = url.substring(0, url.indexOf('/'));
      }

      if (this.props.onChange) {
        this.props.onChange({
          target: {
            name: this.props.name,
            value: url,
          },
        });
      }
    }
  }

  render() {
    const { id, label, labelClassName, values, canDisplayError, ...extraProps } = this.props;

    return (
      <span className="control-wrap">
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <span className="control-wrap">
          <input {...extraProps} {...values} ref={this.inputRef} onBlur={this.onBlur} />
        </span>
      </span>
    );
  }
}

class PureCurrencyInput extends PureComponent {
  render() {
    const { id, label, labelClassName, values, canDisplayError, ...extraProps } = this.props;

    if (values.value) {
      values.value = `$${values.value}`;
    }

    return (
      <span className="control-wrap">
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <span className="control-wrap">
          <input id={id} {...extraProps} {...values} />
        </span>
      </span>
    );
  }
}

/**
 * Inline input with hidden label.
 * If you need to show label - use `showLabel={true}` property.
 *
 * @param props {Object} You can provide any other available on <input/>.
 * @param [props.id = uniqueId()] {string}.
 * @param props.name {string}.
 * @param props.placeholder {string}.
 * @param [props.defaultValue] {any}.
 * @param [props.originalValue=null] {string|null} Is `null` will not check current value with originalValue.
 *    Else if value !== currentValue mark input by red triangle.
 * @param [props.label] {string|React.Component}.
 * @param [props.type=`'text'`] {string} Input's type attr.
 * @param [props.className=`''`] {string} Extra input`s class names. Need not to add `"form-control"`.
 * @param [props.showLabel=`false`] {boolean}.
 * @returns {React.Component}
 */
class DateInput extends PureComponent {
  constructor(props) {
    super(props);
    this.id = uniqueId();
  }

  componentDidUpdate(oldProps) {
    if (this.props.autoFocus && !oldProps.autoFocus) {
      setTimeout(() => {
        try {
          const { id = this.id } = this.props;

          document.getElementById(id).focus();
        } catch (e) {
          throw new Error(e);
        }
      }, 0);
    }
  }

  render() {
    const {
      id = this.id,
      label,
      name,
      placeholder,
      showLabel = false,
      changed = false,
      onChange,
      value,
      ...extraProps
    } = this.props;

    const labelClassName = classNames({ hidden: !showLabel });
    const className = classNames('form-control', this.props.className, {
      changed,
    });
    const selected = value && value.isValid && value.isValid() ? { selected: value } : {};

    return (
      <div>
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <div className="control-wrap">
          <DatePicker
            {...extraProps}
            {...selected}
            className={className}
            dropdownMode="select"
            id={id}
            name={name}
            onChange={value => onChange({ target: { name, value } })}
            placeholderText={upperCaseWords(placeholder)}
            popperPlacement="top-end"
            showMonthDropdown
            showYearDropdown
          />
        </div>
      </div>
    );
  }
}

DateInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  originalValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
};

class MoneyInput extends PureComponent {
  render() {
    const { id, label, labelClassName, values, canDisplayError, precision = 0, ...extraProps } = this.props;

    return (
      <span className="control-wrap">
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <span className="control-wrap">
          <CurrencyInput id={id} {...extraProps} {...values} precision={precision} allowEmpty />
        </span>
      </span>
    );
  }
}

/**
 * Input row with hidden label.
 * If you need to show label - use `showLabel={true}` property.
 *
 * @param props {Object}.
 * @param props.id {string}.
 * @param props.name {string}.
 * @param props.placeholder {string}.
 * @param [props.defaultValue] {any} ,.
 * @param [props.label] {string|React.Component}.
 * @param [props.type=`'text'`] {string} Input's type attr.
 * @param [props.className=`''`] {string} Extra input`s class names. Need not to add `"form-control"`.
 * @param [props.showLabel=`false`] {boolean}.
 * @returns {React.Component}
 */
class PureInput extends PureComponent {
  render() {
    return <PureInlineInput {...this.props} />;
  }
}

const Input = props => (
  <div>
    <InlineInput {...props} />
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  type: PropTypes.string,
};

export { InlineInput, PureInlineInput, Input, PureInput };
export default PureInput;

function getValues(props) {
  const result = {};

  if ('defaultValue' in props) {
    result.defaultValue = props.defaultValue;
  }

  if ('value' in props) {
    result.value = props.value;
  }

  if (Map.isMap(result.value)) {
    result.value = result.value.get('value');
  }

  return result;
}
