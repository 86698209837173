import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { ResponsivePie } from '@nivo/pie';

import { toJS } from 'src/utils/ChangeSpy';
import { countAddonKey, countPlatformKey, defaultPieConfig } from './ChartConfig';

const TransactionsChart = ({ data }) => {
  const allTransactions = toJS(data.get('allTransactions'));
  const addOnTransactions = toJS(data.get('addOnTransactions'));
  const platformTransactions = toJS(data.get('platformTransactions'));

  const chartData = [
    {
      id: countAddonKey,
      label: countAddonKey,
      value: addOnTransactions.countTransactions,
    },
    {
      id: countPlatformKey,
      label: countPlatformKey,
      value: platformTransactions.countTransactions,
    },
  ];

  const isFetching = allTransactions.isFetching && addOnTransactions.isFetching && platformTransactions.isFetching;

  const arcLabel = label => {
    if (Number(label.data.value) === 0 || Number(allTransactions.countTransactions) === 0) {
      return '0%';
    }

    return `${((label.data.value / allTransactions.countTransactions) * 100).toFixed()}%`;
  };

  return (
    <div className="chart-container mb20">
      <div className="bg-black text-white d-flex">
        <p className="h5 text-bold pl10">Closed Transactions</p>
      </div>
      <div className="bg-white p20">
        <div className="chart-wrapper">
          {isFetching === false &&
            (Number(addOnTransactions.countTransactions) === 0 &&
              Number(platformTransactions.countTransactions) === 0 ? (
              <h3 className="block-center">No data</h3>
            ) : (
              <ResponsivePie {...defaultPieConfig} arcLabel={arcLabel} data={chartData} />
            ))}
        </div>
      </div>
    </div>
  );
};

TransactionsChart.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default TransactionsChart;
