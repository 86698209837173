import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map, List } from 'immutable';

import { Counter } from './Counter';
import { Filter } from './Filter';
import { Sorter } from './Sorter';

/**
 * Stateless component for showing filter panel.
 *
 * @param props {Object}.
 * @param props.total {Number} Number of total row.
 * @param props.showing {Number} Number of showing row.
 * @param props.sorts {Immutable.Map} List of sorting items.
 * @param props.typeFilters {Immutable.Map} Approach type filters data.
 * @param props.mailFilters {Immutable.Map} Mailing filters data.
 * @param props.activityFilters {Immutable.Map} Activity filters data.
 * @param props.userFilters {Immutable.Map} User type filters data.
 * @param props.onChangeSort {Function} To handle changing a sorting field.
 * @param props.onChangeOrder {Function} To handle changing sorting order.
 * @param props.onFilterChange {Function} To handle changing a filering field.
 * @param props.isFetching {Boolean} Show spinner icon when set to true.
 * @returns {React.Component}
 */
const SettingPanelComponent = ({ total, showing, sorts, onChangeSort, onChangeOrder, isFetching, ...rest }) => {
  const spinner = isFetching ? (
    <div className="text-center">
      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
    </div>
  ) : null;

  return (
    <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1 next-actions-filters">
      <div className="wrap-sort-order">
        <Counter showing={showing} total={total} />
        <Sorter onChangeOrder={onChangeOrder} onChangeSort={onChangeSort} sorts={sorts} />
        <Filter {...rest} />
        {spinner}
      </div>
    </div>
  );
};

SettingPanelComponent.propTypes = {
  activityFilters: PropTypes.instanceOf(Map),
  isFetching: PropTypes.bool.isRequired,
  mailFilters: PropTypes.instanceOf(Map),
  onChangeOrder: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  showing: PropTypes.number.isRequired,
  sorts: PropTypes.instanceOf(List),
  total: PropTypes.number.isRequired,
  typeFilters: PropTypes.instanceOf(Map),
  userFilters: PropTypes.instanceOf(Map),
};

export const SettingPanel = memo(SettingPanelComponent);
