import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS, unwrap } from '../../../../utils/ChangeSpy';
import PrettyError from '../../../decorators/PrettyError';
import DisplayError from '../../../decorators/DisplayError';
import { Broad as _Broad } from '../../../Company/Details/Broad';
import { Users } from '../../CompanyBuyer/AddOnProjectCustom/MidPanel/Users';
import TradeShowUserAutoComplete from '../../../../containers/TradeShow/TradeShowUserAutoComplete';

import { TradeShowTextInput } from './TradeShowTextInput';

const Broad = PrettyError(DisplayError(_Broad));

const TopBlockComponent = ({ data, onTextChange, models, tags, errors, onIndustryClick, onRowDelete }) => (
  <div className="row d-flex mb20">
    <div className="col-md-7 pr13">
      <div className="col-md-4 pl0 pr18">
        <TradeShowTextInput
          error={errors.nameError}
          field={toJS(data.get('name'))}
          label="Trade Show / Industry Association"
          name="name"
          onTextChange={onTextChange}
        />
        <TradeShowTextInput
          error={errors.abbrError}
          field={toJS(data.get('abbr'))}
          label="Trade Show Abbreviated Name"
          name="abbr"
          onTextChange={onTextChange}
        />
        <TradeShowTextInput
          field={toJS(data.get('website'))}
          label="Website"
          name="website"
          onTextChange={onTextChange}
        />
      </div>
      <div className="col-md-8 pl0 pr0">
        <TradeShowTextInput
          error={errors.descriptionError}
          field={toJS(data.get('description'))}
          label="Description"
          name="description"
          onTextChange={onTextChange}
        />
      </div>
    </div>
    <div className="trade-show-top-table-block col-md-2 pr13">
      <div
        className={`trade-show-top-table ${
          errors.industryCategoriesError || errors.businessModelsError ? 'required-error-block' : ''
        }`}
      >
        <Broad
          businessModels={models}
          data={tags}
          deprecatedIndustries={[]}
          headerClass="industries-table-header"
          onClick={onIndustryClick}
          canDisplayError
        />
      </div>
    </div>
    <Users
      className={`trade-show-top-table ${errors.usersError ? 'required-error-block' : ''}`}
      colClass="trade-show-top-table-block"
      data={unwrap(data.get('users'))}
      editor={TradeShowUserAutoComplete}
      isFetching={data.get('isFetching')}
      onDelete={onRowDelete}
      canEditData
    />
  </div>
);

TopBlockComponent.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  errors: PropTypes.instanceOf(Object),
  models: PropTypes.instanceOf(Map).isRequired,
  onIndustryClick: PropTypes.func,
  onRowDelete: PropTypes.func,
  onTextChange: PropTypes.func,
  tags: PropTypes.instanceOf(Map).isRequired,
};

/** Top block component. */
export const TopBlock = memo(TopBlockComponent);
