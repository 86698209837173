import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { getIn, toJS } from '../../utils/ChangeSpy';
import {
  findCompanies,
  findProjects,
  changeField,
  clearSuggest,
  addContactFund,
  closeValidationError,
} from '../../actions/contact/contactExecutive';
import Popups from '../../components/Popup';
import debounce from '../../utils/debounce';
import AutoCompleteContainer from '../AutoComplete';

const renderProjectSuggestion = suggestion => {
  const className = classNames({ 'text-muted': !suggestion.isActive });

  return <span className={className}>{suggestion.text}</span>;
};

class AddFundPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.onErrorClose = this.onErrorClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onFetchCompanies = debounce(this.onFetchCompanies.bind(this));
    this.onFetchProjects = debounce(this.onFetchProjects.bind(this));
    this.onSelectCompany = this.onSelectCompany.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.onUpdateCompany = this.onUpdateSuggestions.bind(this, 'newFund.suggestCompany');
    this.onUpdateProject = this.onUpdateSuggestions.bind(this, 'newFund.suggestProject');
    this.getSuggestProject = this.getSuggestProject.bind(this);
    this.getSuggestCompany = this.getSuggestCompany.bind(this);
  }

  getFields(event) {
    let id = '';
    const value = event.target.name;

    switch (value) {
      case 'newFund.suggestCompany':
        id = 'newFund.fundId';
        break;

      case 'newFund.suggestProject':
        id = 'newFund.eprojectId';
        break;

      default:
        break;
    }

    return { id, value };
  }

  onErrorClose(e, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  onFetchCompanies({ ...rest }) {
    this.props.findCompanies(rest);
  }

  onFetchProjects({ ...rest }) {
    const fundId = getIn(this.props.executive, ['newFund', 'fundId'], 0);

    this.props.findProjects({ fundId, ...rest });
  }

  onClear() {
    this.props.clearSuggest();
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onSelectProject(event, { suggestion }) {
    event.target.name = 'newFund.suggestProject';

    const { value, id } = this.getFields(event);

    setTimeout(() => {
      this.props.changeField({ name: value, value: suggestion.text });
      this.props.changeField({ name: id, value: suggestion.id });
    }, 0);
  }

  onSelectCompany(event, { suggestion }) {
    event.target.name = 'newFund.suggestCompany';

    const { value, id } = this.getFields(event);

    setTimeout(() => {
      this.props.changeField({ name: value, value: suggestion.text });
      this.props.changeField({ name: id, value: suggestion.id });
    }, 0);
  }

  onCreate() {
    const id = this.props.contactId;
    const tempFund = toJS(this.props.executive.get('newFund').filter(field => field !== null));

    if (!tempFund.eprojectId || tempFund.eprojectId <= 0) {
      delete tempFund.eprojectId;
    }
    this.props.addContactFund({ ...tempFund, id }, this.onClose);
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;

    if (!name) return;
    this.props.changeField({ name, value });
  }

  onClose() {
    this.context.closePopup();
  }

  onUpdateSuggestions(suggestionName, param) {
    this.props.changeField(param);
  }

  getSuggestCompany() {
    return (
      <AutoCompleteContainer
        change={this.onUpdateCompany}
        find={this.onFetchCompanies}
        idName="newFund.fundId"
        idValue={getIn(this.props.executive, 'newFund.fundId')}
        inputProps={{
          label: 'Company',
          name: 'newFund.suggestCompany',
          className: 'form-control input-sm',
        }}
        suggestions={getIn(this.props.executive, 'suggests')}
        suggestsName="suggests"
        value={getIn(this.props.executive, 'newFund.suggestCompany', '')}
        valueName="newFund.suggestCompany"
      />
    );
  }

  getSuggestProject() {
    const disabled = getIn(this.props.executive, 'newFund.suggestCompany', '') === '';

    return (
      <AutoCompleteContainer
        change={this.onUpdateProject}
        find={this.onFetchProjects}
        idName="newFund.eprojectId"
        idValue={getIn(this.props.executive, 'newFund.eprojectId', null)}
        inputProps={{
          label: 'Project',
          name: 'newFund.suggestProject',
          disabled,
          className: 'form-control input-sm',
        }}
        render={renderProjectSuggestion}
        suggestions={getIn(this.props.executive, 'suggests')}
        suggestsName="suggests"
        value={getIn(this.props.executive, 'newFund.suggestProject', '')}
        valueName="newFund.suggestProject"
        suggestIfEmpty
      />
    );
  }

  render() {
    const { children, newFund, executive, popup } = this.props;
    const statuses = popup.getIn(['props', 'statuses']);

    const suggestCompany = this.getSuggestCompany();
    const suggestProject = this.getSuggestProject();
    const isValid = this.props.inputErrors && this.props.inputErrors.size === 0;

    return (
      <div>
        <Popups.AddFundPopup
          executive={executive}
          fund={newFund}
          isValid={isValid}
          onChange={this.onChange}
          onClose={this.onClose}
          onCreate={this.onCreate}
          statuses={statuses}
          suggestCompany={suggestCompany}
          suggestProject={suggestProject}
        />
        {children}
      </div>
    );
  }
}

AddFundPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
};

AddFundPopupContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
    contactId: state.contact.info.get('id'),
    inputErrors: state.contact.executive.get('newFundInputErrors', Map()),
    executive: state.contact.executive,
    newFund: state.contact.executive.get('newFund'),
  };
}

export { AddFundPopupContainer };
export default connect(
  mapStateToProps,
  {
    findCompanies,
    findProjects,
    changeField,
    clearSuggest,
    addContactFund,
    closeValidationError,
  },
  mergeProps,
  connectOptions,
)(AddFundPopupContainer);
