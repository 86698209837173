import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Map, List } from 'immutable';
import outsideClick from 'react-click-outside';

import AgGridTable from '../helpers/AgGridTable';
import getCellHeight from '../../utils/getCellHeight';
import TextAreaEditor from './TextAreaEditor';
import { handleSortModelChanged } from '../../utils/sorting';

const MailingInfoTable = ({ columnDefs, data, onChange, onDoubleClickRow, onGetNextPageData, onUpdateCell }) => {
  const [gridApi, setGridApi] = useState(null);
  const colWidthRef = {};

  const lastColumnDefsRef = React.useRef(null);
  const columnDefsRef = React.useRef(null);

  const getColumnDefs = useCallback(() => {
    if (columnDefs !== lastColumnDefsRef.current) {
      lastColumnDefsRef.current = columnDefs;
      columnDefsRef.current = columnDefs.map(columnDef => {
        colWidthRef[columnDef.get('field')] = columnDef.get('width');

        return columnDef.merge({
          cellEditorFramework: TextAreaEditor,
        });
      });
    }

    return columnDefsRef.current;
  }, [columnDefs]);

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    params.api.showLoadingOverlay();
  }, []);

  const handleCellClicked = useCallback(
    data => {
      gridApi.startEditingCell({
        rowIndex: data.node.rowIndex,
        colKey: data.colDef.field,
      });
    },
    [gridApi],
  );

  const handleCellValueChanged = useCallback(
    event => {
      if (event.newValue !== event.oldValue) {
        const { updateType, updateField } = event.colDef;

        onUpdateCell({
          updateType,
          updateField,
          newValue: event.newValue,
          data: event.data,
        });
      }
    },
    [onUpdateCell],
  );

  const handleSort = useCallback(
    sortModel => {
      handleSortModelChanged(sortModel, data, onChange);
    },
    [data, onChange],
  );

  const handleClickOutside = useCallback((event) => {
    if (event.target.tagName !== 'TEXTAREA') {
      gridApi.stopEditing();
    }
  }, [gridApi]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [data, gridApi]);

  const getRowHeight = useCallback(params => {
    const desheight = getCellHeight(params.data.targetDescription, colWidthRef.targetDescription);
    const addHeight = getCellHeight(params.data.contactAddress, colWidthRef.contactAddress);
    const abbrHeight = getCellHeight(params.data.targetAbbrName, colWidthRef.targetAbbrName);
    const legalHeight = getCellHeight(params.data.targetLegalName, colWidthRef.targetLegalName);

    return Math.max(desheight, addHeight, abbrHeight, legalHeight, 20);
  }, []);

  const getRowNodeId = useCallback(data => data.index, []);

  return (
    <div className="MailingCreateTable">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowHeight={getRowHeight}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onCellClicked={handleCellClicked}
        onCellValueChanged={handleCellValueChanged}
        onGetNextPageData={onGetNextPageData}
        onGridReady={onGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
};

MailingInfoTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onUpdateCell: PropTypes.func.isRequired,
};

export default outsideClick(MailingInfoTable);
