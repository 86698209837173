import { fromJS } from 'immutable';

import * as ActionType from '../actions/statistics';
import {
  getFormattedToCurrency,
  getFormattedToLocale,
  getFormattedToTwoDecimalLocale,
} from '../utils/valuesTransformer';

const stateTransactionsObject = {
  isFetching: false,
  countTransactions: null,
  avgMultiple: null,
  avgTransactionSize: null,
  totalTransactionSize: null,
};

const buyersObject = {
  isFetching: false,
  allCount: null,
  financialCount: null,
  financial2Count: null,
  financial5Count: null,
  financial10Count: null,
  strategicCount: null,
  strategic2Count: null,
  strategic5Count: null,
  strategic10Count: null,
};

const dealsClosedObject = {
  isFetching: false,
  countCanada: null,
  countCities: null,
  countCountries: null,
  countEurope: null,
};

const dealsClosedByStateObject = {
  isFetching: false,
  statesCounts: null,
};

const dealsClosedByYearsObject = {
  isFetching: false,
  yearsCounts: [],
};

const executiveDeals = {
  isFetching: false,
  countExecutiveDeals: null,
};

const professionals = {
  isFetching: false,
  countProfessionals: null,
};

const targetsStatsObject = {
  isFetching: false,
  count: null,
  countApproachableTargets: null,
  countApproachedTargets: null,
  countLeadsGenerated: null,
};

const buyersStatsObject = {
  isFetching: false,
  count: null,
  countBuyersApproached: null,
  countAgreementsSigned: null,
  countAllTimeRetainerClients: null,
};

const executivesStatsObject = {
  isFetching: false,
  count: null,
  countProfiledExecutives: null,
  countExecutivesApproached: null,
  countExecutiveLeads: null,
};

const stateTargetsApproachedByYearsObject = {
  isFetching: false,
  yearsCounts: [],
};

const yearsObject = {
  isFetching: false,
  years: [],
};

/** Initial state for Dashboard page. */
const defaultState = fromJS({
  years: { ...yearsObject },
  allTransactions: { ...stateTransactionsObject },
  addOnTransactions: { ...stateTransactionsObject },
  platformTransactions: { ...stateTransactionsObject },
  buyers: { ...buyersObject },
  dealsClosed: { ...dealsClosedObject },
  dealsClosedByState: { ...dealsClosedByStateObject },
  dealsClosedByYears: { ...dealsClosedByYearsObject },
  executiveDeals: { ...executiveDeals },
  professionals: { ...professionals },
  targetsStats: { ...targetsStatsObject },
  buyersStats: { ...buyersStatsObject },
  executivesStats: { ...executivesStatsObject },
  targetsApproachedByYears: { ...stateTargetsApproachedByYearsObject },
});

const wrappedReducer = reducer;

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_YEARS:
      return state.setIn(['years', 'isFetching'], true);

    case ActionType.FETCHING_YEARS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseYearsFetch(action, 'years'));
      }
      break;

    case ActionType.FETCHING_ALL_TRANSACTIONS:
      return state.setIn(['allTransactions', 'isFetching'], true);

    case ActionType.FETCHING_ALL_TRANSACTIONS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseTransactionsFetch(action, 'allTransactions'));
      }
      break;

    case ActionType.FETCHING_ADDON_TRANSACTIONS:
      return state.setIn(['addOnTransactions', 'isFetching'], true);

    case ActionType.FETCHING_ADDON_TRANSACTIONS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseTransactionsFetch(action, 'addOnTransactions'));
      }
      break;

    case ActionType.FETCHING_PLATFORM_TRANSACTIONS:
      return state.setIn(['platformTransactions', 'isFetching'], true);

    case ActionType.FETCHING_PLATFORM_TRANSACTIONS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseTransactionsFetch(action, 'platformTransactions'));
      }
      break;

    case ActionType.FETCHING_BUYERS:
      return state.setIn(['buyers', 'isFetching'], true);

    case ActionType.FETCHING_BUYERS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseByersFetch(action));
      }
      break;

    case ActionType.FETCHING_DEALS_CLOSED:
      return state.setIn(['dealsClosed', 'isFetching'], true);

    case ActionType.FETCHING_DEALS_CLOSED_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseDealsFetch(action));
      }
      break;

    case ActionType.FETCHING_DEALS_CLOSED_BY_STATE:
      return state.setIn(['dealsClosedByState', 'isFetching'], true);

    case ActionType.FETCHING_DEALS_CLOSED_BY_STATE_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseDealsByStateFetch(action));
      }
      break;

    case ActionType.FETCHING_DEALS_CLOSED_BY_YEARS:
      return state.setIn(['dealsClosedByYears', 'isFetching'], true);

    case ActionType.FETCHING_DEALS_CLOSED_BY_YEARS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseDealsByYearsFetch(action));
      }
      break;

    case ActionType.FETCHING_EXECUTIVE_DEALS:
      return state.setIn(['executiveDeals', 'isFetching'], true);

    case ActionType.FETCHING_EXECUTIVE_DEALS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseExecutiveDealsFetch(action));
      }
      break;

    case ActionType.FETCHING_PROFESSIONALS:
      return state.setIn(['professionals', 'isFetching'], true);

    case ActionType.FETCHING_PROFESSIONALS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseProfessionalsFetch(action));
      }
      break;

    case ActionType.FETCHING_TARGETS_STATS:
      return state.setIn(['targetsStats', 'isFetching'], true);

    case ActionType.FETCHING_TARGETS_STATS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseTargetsByStatusFetch(action));
      }
      break;

    case ActionType.FETCHING_BUYERS_STATS:
      return state.setIn(['buyersStats', 'isFetching'], true);

    case ActionType.FETCHING_BUYERS_STATS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseBuyersByStatusFetch(action));
      }
      break;

    case ActionType.FETCHING_EXECUTIVES_STATS:
      return state.setIn(['executivesStats', 'isFetching'], true);

    case ActionType.FETCHING_EXECUTIVES_STATS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseExecutivesByStatusFetch(action));
      }
      break;

    case ActionType.FETCHING_TARGETS_APPROACHED_BY_YEARS:
      return state.setIn(['targetsApproachedByYears', 'isFetching'], true);

    case ActionType.FETCHING_TARGETS_APPROACHED_BY_YEARS_SUCCESS:
      if (action.response) {
        return state.merge(mapResponseTargetsApproachedByYearsFetch(action));
      }
      break;

    default:
      return state;
  }
}

/**
 * Map returned some type years state object.
 *
 * @param action Action object.
 * @param fetchType State object type.
 * @return Mapped state object.
 */
function mapResponseYearsFetch(action, fetchType) {
  const { data } = action.response;

  return {
    [fetchType]: {
      isFetching: false,
      years: data.years,
    },
  };
}

/**
 * Map returned some type transactions state object.
 *
 * @param action Action object.
 * @param fetchType State object type.
 * @return Mapped state object.
 */
function mapResponseTransactionsFetch(action, fetchType) {
  const { data } = action.response;

  return {
    [fetchType]: {
      isFetching: false,
      countTransactions: getFormattedToLocale(data.countTransactions),
      avgMultiple: `${getFormattedToTwoDecimalLocale(data.statsData.avgMultiple)}x`,
      avgTransactionSize: getFormattedToCurrency(data.statsData.avgTransactionSize),
      totalTransactionSize: getFormattedToCurrency(data.statsData.totalTransactionSize),
    },
  };
}

/**
 * Map returned byers state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseByersFetch(action) {
  const { data } = action.response;

  return {
    buyers: {
      isFetching: false,
      allCount: getFormattedToLocale(data.allCount),
      financialCount: getFormattedToLocale(data.financialCount),
      financial2Count: getFormattedToLocale(data.financial2Count),
      financial5Count: getFormattedToLocale(data.financial5Count),
      financial10Count: getFormattedToLocale(data.financial10Count),
      strategicCount: getFormattedToLocale(data.strategicCount),
      strategic2Count: getFormattedToLocale(data.strategic2Count),
      strategic5Count: getFormattedToLocale(data.strategic5Count),
      strategic10Count: getFormattedToLocale(data.strategic10Count),
    },
  };
}

/**
 * Map returned deals state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseDealsFetch(action) {
  const { data } = action.response;

  return {
    dealsClosed: {
      isFetching: false,
      countCanada: getFormattedToLocale(data.countCanada),
      countCities: getFormattedToLocale(data.countCities),
      countCountries: getFormattedToLocale(data.countCountries),
      countEurope: getFormattedToLocale(data.countEurope),
    },
  };
}

/**
 * Map returned deals by State state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseDealsByStateFetch(action) {
  const { data } = action.response;

  return {
    dealsClosedByState: {
      isFetching: false,
      statesCounts: data.map(data => ({ state: data.state, count: getFormattedToLocale(data.count) })),
    },
  };
}

/**
 * Map returned deals by years state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseDealsByYearsFetch(action) {
  const { data } = action.response;

  return {
    dealsClosedByYears: {
      isFetching: false,
      yearsCounts: data.map(data => ({
        year: data.year,
        countPlatform: data.data.countPlatform,
        countAddon: data.data.countAddon,
      })),
    },
  };
}

/**
 * Map returned executive deals state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseExecutiveDealsFetch(action) {
  const { data } = action.response;

  return {
    executiveDeals: {
      isFetching: false,
      countExecutiveDeals: getFormattedToLocale(data.countExecutiveDeals),
    },
  };
}

/**
 * Map returned professionals state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseProfessionalsFetch(action) {
  const { data } = action.response;

  return {
    professionals: {
      isFetching: false,
      countProfessionals: getFormattedToLocale(data.countProfessionals),
    },
  };
}

/**
 * Map returned some type targets by status state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseTargetsByStatusFetch(action) {
  const { data } = action.response;

  return {
    targetsStats: {
      isFetching: false,
      count: getFormattedToLocale(data.count),
      countApproachableTargets: getFormattedToLocale(data.statsData.countApproachableTargets),
      countApproachedTargets: getFormattedToLocale(data.statsData.countApproachedTargets),
      countLeadsGenerated: getFormattedToLocale(data.statsData.countLeadsGenerated),
    },
  };
}

/**
 * Map returned some type buyers by status state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseBuyersByStatusFetch(action) {
  const { data } = action.response;

  return {
    buyersStats: {
      isFetching: false,
      count: getFormattedToLocale(data.count),
      countBuyersApproached: getFormattedToLocale(data.statsData.countBuyersApproached),
      countAgreementsSigned: getFormattedToLocale(data.statsData.countAgreementsSigned),
      countAllTimeRetainerClients: getFormattedToLocale(data.statsData.countAllTimeRetainerClients),
    },
  };
}

/**
 * Map returned some type executives by status state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseExecutivesByStatusFetch(action) {
  const { data } = action.response;

  return {
    executivesStats: {
      isFetching: false,
      count: getFormattedToLocale(data.count),
      countProfiledExecutives: getFormattedToLocale(data.statsData.countProfiledExecutives),
      countExecutivesApproached: getFormattedToLocale(data.statsData.countExecutivesApproached),
      countExecutiveLeads: getFormattedToLocale(data.statsData.countExecutiveLeads),
    },
  };
}

/**
 * Map returned deals by years state object.
 *
 * @param action Action object.
 * @return Mapped state object.
 */
function mapResponseTargetsApproachedByYearsFetch(action) {
  const { data } = action.response;

  return {
    targetsApproachedByYears: {
      isFetching: false,
      yearsCounts: data.map(data => ({
        year: data.year,
        countAddon: data.data.countTargetsApproachedByStrategicBuyer,
        countPlatform: data.data.countTargetsApproachedByFinancialBuyer,
        countBoth: data.data.countTargetsApproachedByAllTypesOfBuyer,
      })),
    },
  };
}
