import React, { useState, useEffect, useMemo, memo } from 'react';

import { ExtensionFormat } from './ExtensionFormat';
import { InternationalFormat } from './InternationalFormat';
import { CustomFormat } from './CustomFormat';
import { OtherFormat } from './OtherFormat';

const PhoneEditorComponent = ({ contact, ...props }) => {
  const determineComponent = type => {
    switch (type) {
      case 'Extension':
        return ExtensionFormat;

      case 'International':
        return InternationalFormat;

      case 'Mobile':
        return OtherFormat;

      case 'Direct':
        return OtherFormat;

      case 'Home':
        return OtherFormat;

      case 'Fax':
        return OtherFormat;

      default:
        return CustomFormat;
    }
  };

  const [componentType, setComponentType] = useState(() => contact.getIn(['type', 'value']));

  const Component = useMemo(() => determineComponent(componentType), [componentType]);

  useEffect(() => {
    const nextType = contact.getIn(['type', 'value']);

    if (nextType !== componentType) {
      setComponentType(nextType);
    }
  }, [contact, componentType]);

  return <Component {...props} contact={contact} />;
};

export const PhoneEditor = memo(PhoneEditorComponent);
