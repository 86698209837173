import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import DropDown, { DropDownElement } from '../../helpers/DropDown';

/**
 * Stateless component for drop down on top page.
 *
 * @param props {Object}.
 * @param dropdownItems {Object} List of available actions.
 * @param onClick {Function} To handle item click event.
 * @returns {React.Component}
 * @class
 */
const SearchDropDownComponent = ({ dropdownItems, hasAccess, onClick }) => {
  const items = dropdownItems.reduce((acc, elem, index) => {
    if (!hasAccess && ['new_buyer', 'new_executive', 'new_lp'].includes(elem.get('key'))) {
      return acc;
    }

    acc.push(
      <DropDownElement key={index} onClick={event => onClick({ event, to: elem.get('to') })} value={elem.get('key')}>
        {elem.get('name')}
      </DropDownElement>,
    );

    return acc;
  }, []);

  return (
    <div className="search__dropdown">
      <DropDown
        btnContent={Immutable.Map({ name: 'New', value: '' })}
        className="btn-default"
        id="searchingDropDown"
        wrapperClassName="pull-right"
      >
        {items}
      </DropDown>
    </div>
  );
};

SearchDropDownComponent.propTypes = {
  dropdownItems: PropTypes.instanceOf(Immutable.List).isRequired,
  onClick: PropTypes.func.isRequired,
};

export const SearchDropDown = memo(SearchDropDownComponent);
