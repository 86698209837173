import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import { SearchInput } from './SearchInput';
import { SearchDropDown } from './SearchDropDown';

/**
 * Stateless component for top search result navigation.
 *
 * @param props {Object}.
 */
const SearchPanelComponent = ({
  hasAccess,
  onOnlyOwnerChange,
  onSearch,
  onSearchBy,
  onSearchingTermChange,
  searchBy,
  searchingTerm,
  userName,
  dropdownItems,
  disabled,
  onClick,
}) => (
  <div className="search">
    <div className="row">
      <div className="col-md-6 col-sm-6">
        <SearchInput
          disabled={disabled}
          hasAccess={hasAccess}
          onOnlyOwnerChange={onOnlyOwnerChange}
          onSearch={onSearch}
          onSearchBy={onSearchBy}
          onSearchingTermChange={onSearchingTermChange}
          searchBy={searchBy}
          searchingTerm={searchingTerm}
          userName={userName}
        />
      </div>
      <div className="col-md-6 col-sm-6">
        <SearchDropDown dropdownItems={dropdownItems} hasAccess={hasAccess} onClick={onClick} />
      </div>
    </div>
  </div>
);

SearchPanelComponent.propTypes = {
  dropdownItems: PropTypes.instanceOf(Immutable.List).isRequired,
  onClick: PropTypes.func.isRequired,
  onOnlyOwnerChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchingTermChange: PropTypes.func.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export const SearchPanel = memo(SearchPanelComponent);
