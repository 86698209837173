import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List } from 'immutable';

import { SearchPanel } from './SearchPanel';
import { SearchResultHead } from './SearchResultHead';
import { SearchResultTable } from './SearchResultTable';

/**
 * Search component.
 *
 * @param props {Object}.
 */
const SearchComponent = ({
  dropdownItems,
  hasAccess,
  onClick,
  onOnlyOwnerChange,
  onSearch,
  onSearchBy,
  onSearchingTermChange,
  searchBy,
  searchingTerm,
  userName,
  activeTab,
  totalCompanies,
  onTabChange,
  totalPeople,
  companies,
  onScroll,
  people,
  searchingCompanies,
  searchingPeople,
}) => {
  const isDisabledSearch = searchingTerm.trim() === '' || searchingCompanies || searchingPeople;

  return (
    <div className="container-fluid full-height flexWrapper">
      <SearchPanel
        disabled={isDisabledSearch}
        dropdownItems={dropdownItems}
        hasAccess={hasAccess}
        onClick={onClick}
        onOnlyOwnerChange={onOnlyOwnerChange}
        onSearch={onSearch}
        onSearchBy={onSearchBy}
        onSearchingTermChange={onSearchingTermChange}
        searchBy={searchBy}
        searchingTerm={searchingTerm}
        userName={userName}
      />
      <SearchResultHead
        activeTab={activeTab}
        companiesCount={totalCompanies}
        hasAccess={hasAccess}
        onTabChange={onTabChange}
        peopleCount={totalPeople}
      />
      <SearchResultTable
        activeTab={activeTab}
        companies={companies}
        hasAccess={hasAccess}
        onScroll={onScroll}
        people={people}
        searchingCompanies={searchingCompanies}
        searchingPeople={searchingPeople}
      />
    </div>
  );
};

SearchComponent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(List).isRequired,
  dropdownItems: PropTypes.instanceOf(List).isRequired,
  onClick: PropTypes.func.isRequired,
  onOnlyOwnerChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchingTermChange: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  people: PropTypes.instanceOf(List).isRequired,
  searchingCompanies: PropTypes.bool.isRequired,
  searchingPeople: PropTypes.bool.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  totalPeople: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

export const Search = memo(SearchComponent);
