import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import TopSection from './TopSection';
import PreviewBodySection from './PreviewBodySection';
import { FootSection } from './FootSection';

/**
 * Preview Online Approval Lists component.
 *
 * @param props {Object}.
 * @param props.onSendLink {Function} Send a link.
 * @class
 */
const PreviewOnlineApprovalListsComponent = ({
  generateEmailLink,
  onGenerateLink,
  onlineApproval,
  sending,
  emailLink,
}) => (
  <div className="container full-height online-approval-list-content">
    <TopSection onlineApproval={onlineApproval} />
    <PreviewBodySection onlineApproval={onlineApproval} />
    <FootSection
      emailLink={emailLink}
      generateEmailLink={generateEmailLink}
      onGenerateLink={onGenerateLink}
      sending={sending}
    />
  </div>
);

PreviewOnlineApprovalListsComponent.propTypes = {
  emailLink: PropTypes.string.isRequired,
  generateEmailLink: PropTypes.func.isRequired,
  onGenerateLink: PropTypes.func.isRequired,
  onlineApproval: PropTypes.instanceOf(Map).isRequired,
  sending: PropTypes.bool.isRequired,
};

export const PreviewOnlineApprovalLists = memo(PreviewOnlineApprovalListsComponent);
