import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Dashboard from '../components/Dashboard';
import {
  getStatsAllTransactions,
  getStatsAddOnTransactions,
  getStatsPlatformTransactions,
  getStatsBuyers,
  getStatsDeals,
  getStatsDealsByState,
  getStatsDealsByYears,
  getStatsExecutiveDeals,
  getStatsProfessionals,
  getTargetsStatsByStatus,
  getBuyersStatsByStatus,
  getExecutivesStatsByStatus,
  getTargetsApproachedByYears,
  getYearsForStats,
} from '../actions/statistics';

/**
 * State container component for dashboard page.
 */
const DashboardContainer = props => {
  const [year, setYear] = useState('');

  useEffect(() => {
    props.getYearsForStats();
    props.getStatsExecutiveDeals();
    props.getStatsProfessionals();
    props.getTargetsStatsByStatus();
    props.getBuyersStatsByStatus();
  }, []);

  useEffect(() => {
    const data = year ? { year } : null;

    props.getStatsAllTransactions(data);
    props.getStatsAddOnTransactions(data);
    props.getStatsPlatformTransactions(data);
    props.getExecutivesStatsByStatus(data);
    props.getTargetsApproachedByYears(false, data);
    props.getStatsDealsByYears(false, data);
    props.getStatsBuyers(data);
    props.getStatsDeals(data);
    props.getStatsDealsByState(data);
  }, [year]);

  return (
    <div className="full-height">
      <Helmet title="Dashboard Page" />
      <Dashboard data={props.statistics} onChangeYear={setYear} />
    </div>
  );
};

const mapStateToProps = state => ({
  statistics: state.statistics,
});

const mapActionToProps = {
  getStatsAllTransactions,
  getStatsAddOnTransactions,
  getStatsPlatformTransactions,
  getStatsBuyers,
  getStatsDeals,
  getStatsDealsByState,
  getStatsDealsByYears,
  getStatsExecutiveDeals,
  getStatsProfessionals,
  getTargetsStatsByStatus,
  getBuyersStatsByStatus,
  getExecutivesStatsByStatus,
  getTargetsApproachedByYears,
  getYearsForStats,
};

export default connect(mapStateToProps, mapActionToProps)(React.memo(DashboardContainer));
