import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Immutable, { fromJS, List } from 'immutable';
import { bindAll } from 'underscore';

import * as mainApprovalActions from '../actions/approval/main';
import * as associateApprovalActions from '../actions/approval/associate';
import { handleTargetSetNextActions } from '../actions/browse';
import { fetchTargetStatuses } from '../actions/statuses';
import { Approval } from '../components/Approval';
import config from '../config';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { getChanged, getIn, toJS } from '../utils/ChangeSpy';
import { showError } from '../utils/MessagePopup';
import { isResearcher } from '../utils/checkPermissions';
import { successWindwardGeneration } from '../utils/successWindwardGeneration';
import downloadFile from '../utils/downloadFile';
import { openInternalLink } from '../utils/url';

const FIELDS = config.constants.getIn(['approval', 'associate', 'fields']);

/**
 * Approval container component.
 *
 * @param props {Object}.
 * @param project {Immutable.Map} Project information.
 * @param approvals {Immutable.List} List approval information.
 * @param associates {Immutable.List} List associates.
 * @param common {Immutable.Map} Common information.
 * @param loggedUser {Immutable.Map} Logged user.
 * @param preLoad {Function} Preload Page.
 * @param select {Function} Select an approval list.
 * @param change (Function) change date sent and returned.
 * @param clearError {Function} Clear errors.
 * @param sort {Function} Sort associate table.
 * @returns {React.Component}
 * @class
 */
class ApprovalContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: List(),
    };
    this.apiLink = config.API_BASE_URL;

    bindAll(
      this,
      'handleSort',
      'handleSelect',
      'handleChange',
      'handleSetAction',
      'handleChangeStatus',
      'handleExport',
      'handleOpenLink',
      'handleUpdateNextActions',
      'onSaveDates',
      'handleClick',
      'handleGridChange',
      'handleGridSubmit',
      'showWarningPopup',
      'handleLoadNext',
      'handleUpdateCompany',
      'handleRunWindwardReport',
      'handleGenerateOnlineApprovalList',
    );
  }

  componentDidMount() {
    const {
      onFetchStatuses,
      loadProject,
      match: { params },
    } = this.props;
    const projectId = parseInt(params.projectId, 10);
    const approvalId = parseInt(params.approvalId, 10) || params.approvalId;

    onFetchStatuses(({ response: { data } }) => {
      this.setState({
        statuses: fromJS(data),
      });
    });

    loadProject(projectId, approvalId, 1);
  }

  componentDidUpdate(prevProps) {
    const {
      common,
      loadLinksList,
      match: { params },
    } = this.props;
    const { common: prevCommon } = prevProps;

    const dateSent = toJS(common.getIn(['approval', 'dateSent']));
    const prevDateSent = toJS(prevCommon.getIn(['approval', 'dateSent']));

    const projectId = parseInt(params.projectId, 10);
    const approvalId = parseInt(params.approvalId, 10) || params.approvalId;

    if (dateSent !== prevDateSent) {
      loadLinksList(projectId, approvalId);
    }

    if (!this.hasAccess()) {
      this.showWarningPopup();
    } else {
      const errors = this.props.common.get('errors');
      const callback = () => this.props.clearError();

      if (errors.size > 0) {
        showError(this.context.openPopup, errors, callback);
      }
    }
  }

  showWarningPopup() {
    showError(this.context.openPopup, config.permisionError, () => {
      this.props.history.push('/');
      this.context.closePopup();
    });
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleSort(sortInfo) {
    const {
      sort,
      loadAssociates,
      match: {
        params: { projectId, approvalId },
      },
    } = this.props;

    if (sortInfo.length) {
      const sorting = getSort(sortInfo[0].colId, sortInfo[0].sort);

      sort(sorting);
      loadAssociates(projectId, approvalId, 1, sorting);
    } else {
      sort('');
      loadAssociates(projectId, approvalId, 1);
    }
  }

  handleSelect(approvalId) {
    const {
      match: { params },
      loadAssociates,
      sort,
      selectApproval,
    } = this.props;
    const projectId = parseInt(params.projectId, 10);

    selectApproval(approvalId);
    sort('');
    loadAssociates(projectId, approvalId, 1);

    this.props.history.push(`/project/${projectId}/approval/${approvalId}`);
  }

  handleChange({ target: { name, value } }) {
    this.props.changeDate(name, value);
  }

  handleGridChange({ target: { value } }, i, field) {
    this.props.changeGrid(i, field, value);
  }

  handleGridSubmit(event, i, field) {
    event.preventDefault();

    const targetId = this.props.associates.getIn([i, 'targetId']);
    const linkId = this.props.associates.getIn([i, 'linkId']);
    const { approvalId, projectId } = this.props.match.params;
    const body = {
      [field]: getIn(this.props.associates, [i, field]),
    };

    this.props.saveAssociate({ targetId, linkId, approvalId, projectId, body });
  }

  handleSetAction() {
    if (this.props.disableSetNA === false) {
      this.context.openPopup('SetNextActionsPopup', {
        currentPage: 'approval',
        onUpdate: this.handleUpdateNextActions,
      });
    }
  }

  /** Open modal window for request a Windward reports generation. */
  handleRunWindwardReport() {
    const selectedApprovalId = this.props.common.getIn(['approval', 'id']);

    this.context.openPopup('RunWindwardReportPopup', {
      callback: (selectedUserId, nameTemplate, templateParams) => {
        this.props.runWindwardReport({
          selectedApprovalId,
          selectedUserId,
          nameTemplate,
          templateParams,
          afterSuccess: ({ response: { data } }) => successWindwardGeneration(data, this.context),
          afterError: () => this.context.endPopupLoading(),
        });
      },
    });
  }

  /** Open modal Generate Online Approval List. */
  handleGenerateOnlineApprovalList() {
    const {
      match: { params },
    } = this.props;

    this.context.openPopup('GenerateOnlineApprovalListPopup', {
      callback: optionalFields => {
        const search = new URLSearchParams(
          optionalFields.map(field => [Object.keys(field)[0], Object.values(field)[0]]),
        );
        const searchString = search.toString();

        openInternalLink(`/project/${params.projectId}/approval/${params.approvalId}/preview?${searchString}`);
        this.context.closePopup();
      },
    });
  }

  /**
   * Click on the approve / no approve icon handler.
   *
   * @param {number} targetBuyerId Id of the buyer target.
   * @param {boolean|""} approved Approved state.
   * @param {number} index Index.
   */
  handleClick({ targetBuyerId, approved, index }) {
    const { common, project, associates } = this.props;
    const approvalId = common.getIn(['approval', 'id']) || (common.get('noApproval') && 'no_approval_lists');
    const projectId = project.get('id');
    const prevApprovedState = associates.toJS().filter(associate => associate.linkId === targetBuyerId)[0].approved
      .value; // Get previous approved state before change it.

    this.props.setApprovedStatus({ targetBuyerId, approved, index, projectId, approvalId, prevApprovedState });
  }

  handleUpdateNextActions(formData, callback) {
    const { statuses } = this.state;
    const {
      associates,
      handleTargetSetNextActions,
      match: { params },
      project,
    } = this.props;
    const selectedList = associates.map(a => a.get('targetId')).toArray();
    const statusId = statuses.findKey(item => item.get('fc') === formData.getIn(['status', 'selected', 'value'], null));

    const bodyParams = {
      selectedIds: selectedList,
      override: formData.get('override'),
      backlogged: formData.get('backlog', false),
      buyerId: project.get('buyerId'),
      statusId,
    };

    if (formData.get('date')) {
      bodyParams.date = formData.get('date').format('YYYY-MM-DD');
    }

    if (formData.getIn(['assignedTo', 'selected'])) {
      bodyParams.userId = formData.getIn(['assignedTo', 'selected', 'id']);
    }

    if (formData.getIn(['activity', 'selected'])) {
      bodyParams.activity = formData.getIn(['activity', 'selected', 'value']);
    }

    if (formData.get('description')) {
      bodyParams.description = formData.get('description');
    }
    ['approved', 'noResponse', 'passed'].forEach(key => {
      bodyParams[key] = false;
      if (formData.get(key)) {
        bodyParams[key] = true;
      }
    });

    const _afterSuccess = callback.afterSuccess;

    callback.afterSuccess = ({ response }) => {
      const { common } = this.props;
      const approvalId = common.getIn(['approval', 'id']);
      const page = common.getIn(['paging', 'currentPage']);

      const reqListId = approvalId || (common.get('noApproval').size > 0 && 'no_approval_lists');

      this.props.selectApproval(reqListId);
      this.props.loadAssociates(params.projectId, reqListId, page, common.get('sorting'));
      _afterSuccess({ response });
    };

    handleTargetSetNextActions({
      body: {
        params: bodyParams,
      },
      callback,
    });
  }

  handleChangeStatus() {
    const { project, common, disableChangeStatus } = this.props;
    const approvalId = common.getIn(['approval', 'id']);
    const projectId = project.get('id');

    const reqListId = approvalId || (common.get('noApproval').size > 0 && 'no_approval_lists');

    if (disableChangeStatus === false) {
      this.context.openPopup('ChangeStatusPopup', { approvalId: reqListId, projectId });
    }
  }

  handleExport() {
    const { project, common } = this.props;
    const projectId = project.get('id');
    const approvalId = common.getIn(['approval', 'id']);
    const { apiLink } = this;

    const listUrl = approvalId
      ? `approval_lists/${approvalId}`
      : common.get('noApproval').size > 0 && 'no_approval_lists';

    downloadFile({
      url: `${apiLink}/api/v1/projects/${projectId}/${listUrl}/export`,
    });
  }

  handleOpenLink({ data }) {
    openInternalLink(`/company/${data.targetId}/${data.isTarget ? 'target' : 'buyer'}`);
  }

  handleLoadNext(page) {
    const {
      match: { params },
      common,
      loadAssociates,
    } = this.props;
    const projectId = parseInt(params.projectId, 10);
    const approvalId = parseInt(params.approvalId, 10) || params.approvalId;

    loadAssociates(projectId, approvalId, page, common.get('sorting'));
  }

  onSaveDates() {
    const projectId = this.props.project.get('id');
    const approvalId = this.props.common.getIn(['approval', 'id']);
    const body = getChanged(this.props.common.get('approval'));
    const bodyToSend = {};

    Object.keys(body).forEach(key => {
      if (/date/.test(key)) {
        try {
          if (key === 'dateReturned') {
            bodyToSend.dateReceived = body[key] && body[key].isValid() ? body[key].format('YYYY-MM-DD') : null;
          } else {
            bodyToSend[key] = body[key] && body[key].isValid() ? body[key].format('YYYY-MM-DD') : null;
          }
        } catch (e) {
          bodyToSend[key] = undefined;
        }
      }
    });
    this.props.saveApproval(projectId, approvalId, bodyToSend, body);
  }

  handleUpdateCompany(body, { companyId, buyerId, merge }) {
    const postData = { ...body };

    if (postData.bsnClient === null) {
      postData.bsnClient = '';
    }

    if (postData.harveyComment === null) {
      postData.harveyComment = '';
    }

    this.props.updateCompany(postData, companyId, buyerId, merge);
  }

  render() {
    const { common } = this.props;

    const approvalLabel = common.getIn(['approval', 'label']);

    let pageTitle = '';

    if (approvalLabel) {
      pageTitle = approvalLabel;
    } else if (common.get('noApproval').size > 0) {
      pageTitle = '(No Approval List)';
    }

    const content = this.hasAccess() ? (
      <Approval
        {...this.props}
        onChange={this.handleChange}
        onChangeStatus={this.handleChangeStatus}
        onClick={this.handleClick}
        onExport={this.handleExport}
        onGenerateOnlineApprovalList={this.handleGenerateOnlineApprovalList}
        onGridChange={this.handleGridChange}
        onGridSubmit={this.handleGridSubmit}
        onLoad={this.handleLoadNext}
        onOpenLink={this.handleOpenLink}
        onSaveDates={this.onSaveDates}
        onSelect={this.handleSelect}
        onSetAction={this.handleSetAction}
        onSort={this.handleSort}
        onUpdateCompany={this.handleUpdateCompany}
        runWindwardReport={this.handleRunWindwardReport}
      />
    ) : null;

    return (
      <div className="full-height">
        <Helmet title={`${pageTitle} [Approval Page]`.trim()} />
        {content}
      </div>
    );
  }
}

ApprovalContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

function getSort(name, direction) {
  try {
    return `${direction === 'desc' ? '-' : ''}${FIELDS.filter(f => f.get('local') === name).toJS()[0].remote}`;
  } catch (e) {
    console.warn(e);
  }
}

const mapStateToProps = state => ({
  ...state.approval,
  disableSetNA: state.approval.associates.size === 0,
  disableChangeStatus: state.approval.project.get('id') === 0,
  loggedUser: state.auth.get('user'),
});

const mapDispatchToProps = dispatch => ({
  onFetchStatuses: callback => dispatch(fetchTargetStatuses(callback)),
  handleTargetSetNextActions: payload => dispatch(handleTargetSetNextActions(payload)),
  ...bindActionCreators(mainApprovalActions, dispatch),
  ...bindActionCreators(associateApprovalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(ApprovalContainer);
