import { CALL_API } from '../../../../middleware/api';
import { unwrap } from '../../../../utils/ChangeSpy';

export const SHOW_USERS_LOADING = Symbol('SHOW_USERS_LOADING');
export const REQUEST_API_USERS_FAILURE = Symbol('REQUEST_API_USERS_FAILURE');
export const LOADED_USERS_SUCCESS = Symbol('LOADED_USERS_SUCCESS');
export const LOADING_USERS_SUGGESTION = Symbol('LOADING_USERS_SUGGESTION');
export const SAVED_USERS_SUCCESS = Symbol('SAVED_USERS_SUCCESS');
export const SELECT_USER_SUGGESTION = Symbol('SELECT_USER_SUGGESTION');
export const DELETED_USER_SUCCESS = Symbol('DELETED_USER_SUCCESS');

export const SET_SUGGESTIONS = Symbol('SET_SUGGESTIONS');
export const LOADED_SUGGESTIONS = Symbol('LOADED_SUGGESTIONS');
export const ERROR_LOADED_SUGGESTIONS = Symbol('ERROR_LOADED_SUGGESTIONS');
export const LOADING_SUGGESTIONS = Symbol('LOADING_SUGGESTIONS');

export const ADD_EMPTY_USER = Symbol('ADD_EMPTY_USER');
export const REMOVE_EMPTY_USER = Symbol('REMOVE_EMPTY_USER');

/**
 * Save users in user table.
 *
 * @returns Action.
 */
export function saveUsers() {
  return (dispatch, getState) => {
    const state = getState().targetCompany.buyer.addOnProject;
    const projectId = state.projectData.get('id');
    const project_users = state.users
      .filter(u => unwrap(u.get('id')) > 0)
      .map(u => unwrap(u.get('id')))
      .toJS();

    dispatch({
      [CALL_API]: {
        method: 'post',
        path: `/api/v1/projects/${projectId}/users`,
        body: { project_users },
        startType: SHOW_USERS_LOADING,
        errorType: REQUEST_API_USERS_FAILURE,
        successType: SAVED_USERS_SUCCESS,
      },
    });
  };
}

/**
 * Load user for users grid.
 *
 * @param id {Number} Project's id.
 * @returns Action.
 */
export function loadUsers(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/projects/${id}`,
      query: { include: 'users' },
      startType: SHOW_USERS_LOADING,
      errorType: REQUEST_API_USERS_FAILURE,
      successType: LOADED_USERS_SUCCESS,
    },
  };
}

/**
 * Delete a user row.
 *
 * @param id {Number} Row id.
 * @returns Action.
 */
export function deleteUserRow(id) {
  return (dispatch, getState) => {
    const projectId = getState().targetCompany.buyer.addOnProject.projectData.get('id');

    dispatch({
      [CALL_API]: {
        method: 'delete',
        path: `/api/v1/projects/${projectId}/users/${id}`,
        startType: SHOW_USERS_LOADING,
        errorType: REQUEST_API_USERS_FAILURE,
        successType: DELETED_USER_SUCCESS,
      },
      id,
    });
  };
}

/**
 * Load user's suggestion list.
 *
 * @param id {Number} User's id.
 * @param text {Number} Inputted text.
 * @returns Action.
 */
export function loadUsersSuggestion(text) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: `get /api/v1/browse/users ${text}`,
      query: { like: text },
      startType: LOADING_SUGGESTIONS,
      errorType: ERROR_LOADED_SUGGESTIONS,
      successType: LOADED_SUGGESTIONS,
    },
    path: 'user',
  };
}

/**
 * Select a user suggestion.
 *
 * @param id {Number} Row id.
 * @param suggestionId {Number} Suggestion id.
 * @returns Action.
 */
export function selectUserSuggestion({ rowIndex, id, text }) {
  return {
    type: SELECT_USER_SUGGESTION,
    rowIndex,
    id,
    text,
  };
}

export function setSuggestion({ path, value }) {
  return {
    type: SET_SUGGESTIONS,
    path,
    value,
  };
}

export function addEmptyUser() {
  return {
    type: ADD_EMPTY_USER,
  };
}

export function removeEmptyUser() {
  return {
    type: REMOVE_EMPTY_USER,
  };
}
