import React, { memo } from 'react';
import classNames from 'classnames';

import { isDeepChanged } from '../../utils/ChangeSpy';
import Table, { Column, Row } from '../helpers/Table';

const getDoubleClickCallback = (subsidiary, disabled) => () => {
  if (!disabled) {
    window.open(`/company/${subsidiary.get('id')}`);
  }
};

const CompanySubsidiariesComponent = ({ disabled, subsidiaries }) => {
  const rows = subsidiaries.map(subsidiary => (
    <Row
      key={subsidiary.get('id')}
      className="clickable"
      data={subsidiary}
      onDoubleClick={getDoubleClickCallback(subsidiary, disabled)}
    />
  ));
  const headerClass = classNames('panel-title', {
    'pl5 changed': isDeepChanged(subsidiaries),
  });

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-3" data-toggle="collapse">
        <h4 className={headerClass}>Subsidiaries</h4>
      </div>
      <div className="panel-collapse collapse" id="acc-slide-3">
        <div className="panel-body">
          <div className="highlight mb5">
            <Table>
              <Column field="name" title="Legal Name" />
              <Column field="revenue" title="Revenue" />
              <Column field="employees" title="Employees" />
              <Column field="city" title="City" />
              <Column field="state" title="State" />
              <Column field="dsplIndustry" title="Primary Industry" />
              {rows}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

/** Company subsidiaries component. */
export const CompanySubsidiaries = memo(CompanySubsidiariesComponent);
