import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';

import { upperCaseWords } from '../../utils/string';
import DeepValue from '../decorators/DeepValue';
import ChangeDetection from '../decorators/ChangeDetection';
import uniqueId from '../../utils/uniqueId';

/**
 * 1 option element that is in select.
 *
 * @param props {Object}.
 * @param.name {string} Option's name for human
 * @param.value {string|number} Option's value (sends to server)
 * @param.showValue {boolean} If `true` displayed name will be `value - name`, else - `name`
 * @private
 * @returns {React.Component}
 */
const SelectOptionComponent = ({ name, value, description, showValue, disabled }) => {
  let content;

  if (showValue) {
    content = `${value} - ${name}`;
  } else if (description) {
    content = `${name} - ${description}`;
  } else {
    content = name;
  }

  return (
    <option disabled={disabled} value={value}>
      {upperCaseWords(content)}
    </option>
  );
};

SelectOptionComponent.propTypes = {
  name: PropTypes.string.isRequired,
  showValue: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
};

const SelectOption = memo(SelectOptionComponent);

/**
 * <select> row with hidden label.
 *
 * @param props {Object} You can provide any other available on <select/>.
 * @param props.id {string}.
 * @param props.name {string} Select's name attribute.
 * @param props.label {string} Select label (human readable).
 * @param props.options {Immutable.List} List of select options (Immutable.Map with #name #value fields).
 * @param [props.selectClassName=`""`] {string} Extra class names for select (`"form-control" is already added).
 * @param [props.showValue=`false`] {boolean} If `true` options' displayed name will be `value - name`, else - `name`.
 * @param [props.showLabel=`false`] {boolean} If true - Display label.
 * @param [props.defaultText=null] {string} Default option.
 * @param [props.valueKey='value'] {string} Key's value.
 * @param [props.nameKey='name'] {string} Key's name.
 * @returns {React.Component}
 */
const Select = DeepValue(
  ChangeDetection(props => {
    const {
      id = uniqueId(),
      label,
      name,
      wrapperClassName: wcls,
      selectClassName = '',
      className: cls = '',
      options,
      showValue = false,
      showLabel = false,
      value: _value,
      changed = false,
      originalValue: _originalValue,
      defaultValue: _defaultValue,
      defaultText = null,
      defaultTextValue = '',
      disableDefault = false,
      valueKey = 'value',
      nameKey = 'name',
      canDisplayError,
      ...rest
    } = props;
    const className = classNames('form-control', selectClassName, cls, {
      changed,
    });
    const labelClassName = classNames('control-label', { hidden: !showLabel });
    const wrapperClassName = classNames(wcls);
    const values = getValues(props);
    const content = options.map((option, i) => (
      <SelectOption
        key={i}
        description={option.get('description')}
        disabled={option.get('disabled', false)}
        name={option.get(nameKey)}
        showValue={showValue}
        value={option.get(valueKey)}
      />
    ));

    let defaultOption = null;

    if (defaultText) {
      defaultOption = (
        <option disabled={disableDefault} value={defaultTextValue}>
          {defaultText}
        </option>
      );
    }

    return (
      <div className={wrapperClassName}>
        <label className={labelClassName} htmlFor={id}>
          {' '}
          {upperCaseWords(label)}{' '}
        </label>
        <select {...rest} {...values} className={className} id={id} name={name}>
          {defaultOption}
          {content}
        </select>
      </div>
    );
  }),
);

Select.propTypes = {
  defaultText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameKey: PropTypes.string,
  options: PropTypes.instanceOf(List).isRequired,
  selectClassName: PropTypes.string,
  showLabel: PropTypes.bool,
  showValue: PropTypes.bool,
  valueKey: PropTypes.string,
};

export default Select;

function getValues(props) {
  const result = {};

  if ('defaultValue' in props) {
    result.defaultValue = props.defaultValue;
  }

  if ('value' in props) {
    result.value = props.value;
  }

  if (Map.isMap(result.value)) {
    result.value = result.value.get('value');
  }

  return result;
}
