import { CALL_API } from '../../middleware/api';
import config from '../../config';
import uniqueId from '../../utils/uniqueId';

export const SELECT_A_NEXT_ACTION = 'SELECT_A_NEXT_ACTION';
export const DESELECT_A_NEXT_ACTION = 'DESELECT_A_NEXT_ACTION';

export const SELECT_ALL_NEXT_ACTION = 'SELECT_ALL_NEXT_ACTION';
export const DESELECT_ALL_NEXT_ACTION = 'DESELECT_ALL_NEXT_ACTION';

export const CHANGE_SORTING_FIELD = 'CHANGE_SORTING_FIELD';
export const CHANGE_FILTERING_FIELD = 'CHANGE_FILTERING_FIELD';

export const FETCHING_NEXT_ACTION = 'FETCHING_NEXT_ACTION';
export const REQUEST_NEXT_ACTION_API_FAILURE = 'REQUEST_NEXT_ACTION_API_FAILURE';
export const FETCH_NEXT_ACTION_SUCCESS = 'FETCH_NEXT_ACTION_SUCCESS';

export const FETCHING_USER_SETTING = 'FETCHING_USER_SETTING';
export const FETCH_USER_SETTING_SUCCESS = 'FETCH_USER_SETTING_SUCCESS';

export const REQUEST_POPUP_API_FAILURE = 'REQUEST_POPUP_API_FAILURE';

export const SETTING_BACKLOG = 'SETTING_BACKLOG';
export const SET_BACKLOG_FAILURE = 'SET_BACKLOG_FAILURE';
export const SET_BACKLOG_SUCCESS = 'SET_BACKLOG_SUCCESS';
export const REMOVE_NEXT_ACTION_ITEMS = 'REMOVE_NEXT_ACTION_ITEMS';

export const UPDATE_SORT_ORDER = 'UPDATE_SORT_ORDER';

export const RESET_POPUP_INFO = 'NA_RESET_POPUP_INFO';
export const UPDATE_EVENT_INFO = Symbol('UPDATE_EVENT_INFO');

/**
 * Push next actions.
 *
 * @param {object} params Params.
 * @param afterSuccess {Function} Function to call after api returning.
 * @param afterError {Function} Function to call what calling api get error.
 */
export function pushNextActions(params, afterSuccess, afterError) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/events/next_actions/push',
      body: {
        ...params,
      },
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Update event information.
 *
 * @param {number} id Event ID.
 */
export function updateEvent(id) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/events/${id}`,
      successType: UPDATE_EVENT_INFO,
    },
  };
}

/**
 * Get next action data.
 *
 * @param page {Number} Page number.
 * @returns Action.
 */
export const getNextAction = (page = 1) => ({
  [CALL_API]: {
    method: 'get',
    path: '/api/v1/events/next_actions',
    query: { limit: config.NEXT_ACTION_REQUEST_THROTTLING, page },
    startType: FETCHING_NEXT_ACTION,
    errorType: REQUEST_NEXT_ACTION_API_FAILURE,
    successType: FETCH_NEXT_ACTION_SUCCESS,
  },
  requestId: uniqueId(),
  firstPage: false,
});

/**
 * Create an action for getting user's setting.
 *
 * @returns Action.
 */
export const getUserSettings = () => ({
  [CALL_API]: {
    method: 'get',
    path: '/api/v1/events/next_actions/config',
    startType: FETCHING_USER_SETTING,
    errorType: REQUEST_NEXT_ACTION_API_FAILURE,
    successType: FETCH_USER_SETTING_SUCCESS,
  },
});

/**
 * Create an action for changing user's sorting.
 *
 * @param field {String} Sorting field.
 * @param direction {Number} Sorting direction.
 * @param included {Boolean} Will sort by this field if set to true.
 * @param order {Number} Order.
 * @returns Action.
 */
export const changeSorting = (field, direction, included, order) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_SORTING_FIELD,
    field,
    direction,
    included,
    order,
  });
  dispatch(
    saveSettings(
      getState()
        .nextAction.main.get('settings')
        .toJS(),
    ),
  );
};

/**
 * Update sort list order.
 *
 * @returns Action.
 */
export const updateSortOrder = orderedList => ({
  type: UPDATE_SORT_ORDER,
  orderedList,
});

/**
 * Create an action for changing user's filtering.
 *
 * @returns Action.
 */
export const changeFiltering = (field, checked) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_FILTERING_FIELD,
    field,
    checked,
  });
  dispatch(
    saveSettings(
      getState()
        .nextAction.main.get('settings')
        .toJS(),
    ),
  );
};

/**
 * Save the filtering and sorting settings,
 * load the first page of next action after saving.
 *
 * @param settings {Object} Settings object.
 * @returns Action.
 */
export const saveSettings = settings => {
  const limit = config.NEXT_ACTION_REQUEST_THROTTLING;

  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/events/next_actions',
      query: { page: 1, limit },
      body: { config: settings },
      maxCount: 2,
      unifier: `post /events/next_actions ${JSON.stringify(limit)}`,
      startType: FETCHING_NEXT_ACTION,
      errorType: REQUEST_NEXT_ACTION_API_FAILURE,
      successType: FETCH_NEXT_ACTION_SUCCESS,
    },
    requestId: uniqueId(),
    firstPage: true,
  };
};

/**
 * Create an action for selecting an action.
 *
 * @param id {Number} Action's id.
 * @returns Action.
 */
export const selectAction = id => ({
  type: SELECT_A_NEXT_ACTION,
  id: parseInt(id, 10),
});

/**
 * Create an action for selecting an action.
 *
 * @param id {Number} Action's id.
 * @returns Action.
 */
export const deselectAction = id => ({
  type: DESELECT_A_NEXT_ACTION,
  id: parseInt(id, 10),
});

/**
 * Create an action for selecting all next actions.
 *
 * @returns Action.
 */
export const selectAll = () => ({
  type: SELECT_ALL_NEXT_ACTION,
});

/**
 * Create an action for deselecting all next actions.
 *
 * @returns Action.
 */
export const deselectAll = () => ({
  type: DESELECT_ALL_NEXT_ACTION,
});

/**
 * Delete companies.
 *
 * @param idList {Array(int)} List of company's id to be deleted.
 * @param beforeStart {Function} Function to call before starting calling api.
 * @param afterSuccess {Function} Function to call after api returning.
 * @param afterError {Function} Function to call what calling api get error.
 * @returns Action.
 */
export function deleteNextActions(idList, beforeStart, afterSuccess, afterError) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/events/delete',
      body: {
        params: {
          selected_ids: idList,
        },
      },
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Remove next action items.
 *
 * @param idList {Array(int)} List of company's id to be deleted.
 * @returns Action.
 */
export function removeNAItems(idList) {
  return {
    type: REMOVE_NEXT_ACTION_ITEMS,
    idList,
  };
}

/**
 * Reset popup information.
 *
 * @returns Action.
 */
export function resetPopup() {
  return {
    type: RESET_POPUP_INFO,
  };
}

/**
 * Set backlog.
 *
 * @param id {Number} Action id.
 * @param backlogged {Boolean} Backlogged's state.
 * @returns Action.
 */
export function toggleBacklog(id, backlogged) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/events/${id}`,
      body: {
        backlogged,
      },
      startType: SETTING_BACKLOG,
      errorType: SET_BACKLOG_FAILURE,
      successType: SET_BACKLOG_SUCCESS,
    },
    id,
    backlogged,
  };
}
