import React, { memo } from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';

import { TopPanel } from './TopPanel';
import { MidPanel } from './MidPanel';
import { Targets } from './Targets';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.projectData {Immutable.Map} Project data.
 * @param props.users {Immutable.List} User list.
 * @param props.tags {Immutable.List} Industry list.
 * @param props.approvals {Immutable.List} Approval list.
 * @param props.templates {Immutable.List} Template list.
 * @param props.targets {Immutable.List} Target list.
 * @param props.common {Immutable.Map} Common object.
 * @param props.approvalColumns {Immutable.List} Approval column configurations.
 * @param props.onRowClick {Function} Handle event of clicking on a row.
 * @param props.onRowDoubleClick {Function} Handle event of double clicking on a row.
 * @param props.onRowInsert {Function} Handle event of adding a new row.
 * @param props.onDeleteRow {Function} Handle event of deleting a row.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onSuggestionClose {Function} Handle event of closing suggestion.
 * @param props.onProjectInputChange {Function} Handle event of changing project input's text.
 * @param props.onSuggestionInputClick {Function} Handle event of clicking on project input.
 * @param props.onSort {Function} Handle event of sorting table.
 * @param props.onSave {Function} Handle event of saving page.
 * @param props.onDownload {Function} Handle event of downloading template file.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @constructor
 */
const ProjectComponent = ({
  canEditData,
  projectData,
  users,
  approvals,
  targets,
  common,
  tags,
  onErrorClose,
  onUpdateTags,
  onRowClick,
  onRowDoubleClick,
  onRowInsert,
  onDeleteRow,
  onDeleteApprovalRow,
  onRowChange,
  onTextChange,
  onSuggestionClose,
  onProjectSuggestionSelect,
  onProjectInputChange,
  onSuggestionInputClick,
  onSort,
  onSave,
  onDownload,
  onApprovalChange,
  UserEditor,
}) => (
  <div className="project__containertab-pane active tab-target add-on-project-section" id="buy-slide-4">
    <div className="add-on-project-panel">
      <a
        className="position-absolute text-right"
        href={`/project/${projectData.get('id')}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <i aria-hidden="true" className="fa fa-external-link" title="Launch full project view in a new window" />
      </a>
      <div className="row add-on-project full-height">
        <form className="container-fluid full-height flexWrapper">
          <TopPanel
            canEditData={canEditData}
            common={common}
            onProjectInputChange={onProjectInputChange}
            onSuggestionClose={onSuggestionClose}
            onSuggestionInputClick={onSuggestionInputClick}
            onSuggestionSelect={onProjectSuggestionSelect}
            onTextChange={onTextChange}
            projectData={projectData}
          />
          <MidPanel
            approvals={approvals}
            canEditData={canEditData}
            common={common}
            onApprovalChange={onApprovalChange}
            onDeleteApprovalRow={onDeleteApprovalRow}
            onDeleteRow={onDeleteRow}
            onDownload={onDownload}
            onErrorClose={onErrorClose}
            onRowChange={onRowChange}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            onRowInsert={onRowInsert}
            onSave={onSave}
            onSort={onSort}
            onUpdateTags={onUpdateTags}
            tags={tags}
            UserEditor={UserEditor}
            users={users}
          />
          <Targets
            canEditData={canEditData}
            common={common}
            onDoubleClick={onRowDoubleClick}
            onSort={onSort}
            targets={targets}
          />
        </form>
      </div>
    </div>
  </div>
);

ProjectComponent.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  canEditData: PropTypes.bool.isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onProjectInputChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onRowDoubleClick: PropTypes.func.isRequired,
  onRowInsert: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onSuggestionClose: PropTypes.func,
  onSuggestionInputClick: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
  projectData: PropTypes.instanceOf(Map).isRequired,
  targets: PropTypes.instanceOf(List).isRequired,
  users: PropTypes.instanceOf(List).isRequired,
};

/** Project component. */
export const Project = memo(ProjectComponent);
