import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../helpers/AgGridTable';
import { noop } from '../../../utils/noop';

const CompanyExecuteTableComponent = ({ data, onDoubleClickRow, columnDefs }) => {
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const getColumnDefs = () =>
    columnDefs.map(columnDef => {
      const field = columnDef.get('field');

      switch (field) {
        default:
          return columnDef;
      }
    });

  const handleGridReady = params => {
    setGridApi(params.api);
  };

  const getRowNodeId = data => data.id;

  return (
    <div className="CompanyExecuteTable full-height">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onGetNextPageData={noop}
        onGridReady={handleGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={noop}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        sortable={false}
        totalPages={data.get('totalPages')}
        enableClientSideSorting
      />
    </div>
  );
};

CompanyExecuteTableComponent.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
};

/** Company execute table component. */
export const CompanyExecuteTable = memo(CompanyExecuteTableComponent);
