import React from 'react';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';
import { AutoSuggestion } from '../helpers/AutoSuggestion';

/**
 * Popup for flip to different team.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
const InternalFlipPopup = props => {
  const {
    module,
    dealmaker,
    associate,
    dealmakerWarning,
    associateWarning,
    suggestions,
    errors,
    waiting,
    onInputChange,
    onSuggestionClose,
    onSuggestionSelect,
    onSave,
    onPopupClose,
  } = props;
  const modValue = module.get('value');
  const modMode = module.get('mode');
  const assoValue = associate.get('value');
  const assoMode = associate.get('mode');
  const dealmakerValue = dealmaker.get('value');
  const dealmakerMode = dealmaker.get('mode');
  const header = 'Internal Flip';
  const footer = [
    waiting ? (
      <Button key="save" className="btn-primary btn-xs" data-dismiss="modal" disabled>
        <i className="fa fa-spinner fa-pulse fa-fw pos-relative" /> Save
      </Button>
    ) : (
      <Button key="save" className="btn-primary btn-xs" data-dismiss="modal" onClick={onSave}>
        Save
      </Button>
    ),
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onPopupClose}>
      Close
    </Button>,
  ];
  const errorMessage = errors.size ? (
    <div className="text-danger mt20 ml10">
      <ul>
        {errors.map((val, i) => (
          <li key={i}>{val}</li>
        ))}
      </ul>
    </div>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="accessWarning">
      <div className="row media-450 flip">
        <p>Flip the selected companies to the following team:</p>
        <div className="form-horizontal">
          <FormGroup>
            <label className="col-sm-3 control-label" htmlFor="moduleInput">
              Module
            </label>
            <div className="col-sm-4">
              <AutoSuggestion
                className="form-control"
                id="moduleInput"
                mode={modMode}
                name="module"
                onSuggestionClose={onSuggestionClose}
                onSuggestionSelect={onSuggestionSelect}
                onTextChange={onInputChange}
                suggestions={suggestions}
                value={modValue}
                autoFocus
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="col-sm-3 control-label" htmlFor="associateInput">
              Dealmaker 1
            </label>
            <div className="col-sm-4">
              <AutoSuggestion
                className="form-control"
                id="associateInput"
                mode={assoMode}
                name="associate"
                onSuggestionClose={onSuggestionClose}
                onSuggestionSelect={onSuggestionSelect}
                onTextChange={onInputChange}
                suggestions={suggestions}
                value={assoValue}
              />
            </div>
          </FormGroup>
          {associateWarning ? (
            <div className="alert alert-danger internal-popup-warning" role="alert">
              {associateWarning}
            </div>
          ) : null}
          <FormGroup>
            <label className="col-sm-3 control-label" htmlFor="associateInput">
              Dealmaker 2
            </label>
            <div className="col-sm-4">
              <AutoSuggestion
                className="form-control"
                id="dealmakerInput"
                mode={dealmakerMode}
                name="dealmaker"
                onSuggestionClose={onSuggestionClose}
                onSuggestionSelect={onSuggestionSelect}
                onTextChange={onInputChange}
                suggestions={suggestions}
                value={dealmakerValue}
              />
            </div>
          </FormGroup>
          {dealmakerWarning ? (
            <div className="alert alert-danger internal-popup-warning" role="alert">
              {dealmakerWarning}
            </div>
          ) : null}
        </div>
      </div>
      <div>{errorMessage}</div>
    </Popup>
  );
};

export default InternalFlipPopup;
