import React, { memo, useEffect, useState } from 'react';
import Grid from '../../../helpers/AgGridTable';
import config from '../../../../config';
import DatePickerEditor from './DatePickerEditor';
import { DateRender } from './DateRender';

const FundCompaniesComponent = ({
  loading,
  funds,
  page,
  totalPages,
  onSort,
  onGetNext,
  onRowDoubleClick,
  onCellClick,
  onCellValueChange,
  onRef,
}) => {
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const getColumnDefs = () =>
    config.tables.getIn(['fund', 'buyer', 'columnDefs', 'columns']).map(column => {
      if (column.get('field') === 'dateAcquired') {
        return column.merge({
          cellRendererFramework: DateRender,
          cellEditorFramework: DatePickerEditor,
        });
      }

      return column;
    });

  const handleGridReady = params => {
    const { api } = params;

    api.sizeColumnsToFit();
    setGridApi(api);
    if (onRef) {
      onRef(params);
    }
  };

  const getRowNodeId = data => data.order;

  return (
    <div className="flexItem scrollable buyer-fund">
      <Grid
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={loading}
        onCellClicked={onCellClick}
        onCellValueChanged={onCellValueChange}
        onGetNextPageData={onGetNext}
        onGridReady={handleGridReady}
        onRowDoubleClicked={onRowDoubleClick}
        onSortModelChanged={onSort}
        page={page}
        rowData={funds}
        totalPages={totalPages}
        sortable
      />
    </div>
  );
};

/** Fund companies component. */
export const FundCompanies = memo(FundCompaniesComponent);
