import React, { useCallback, useEffect, useRef, useMemo, memo } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import { ACTION_DELETE } from 'src/components/helpers/DeleteItem';

import Table from '../../../../helpers/AgGridTable';
import AgHeader from './AgHeader';
import { ChangeSpyCellRender } from '../../AddOnProject/MidPanel/ChangeSpyCellRender';

/**
 * Table users.
 *
 * @param {object} props Params.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @constructor
 */
const UsersComponent = ({ canEditData, editor, isFetching, data, colClass, className, onGridReady, onDelete }) => {
  const apiRef = useRef(null);
  const rowNodeRef = useRef(null);

  const handleInsert = useCallback(() => {
    if (!apiRef.current || !canEditData) return;

    apiRef.current.stopEditing();

    let rowIndex = -1;

    apiRef.current.forEachNode(rowNode => {
      if (rowNode.data.id.value === -1) {
        rowIndex = rowNode.rowIndex;

        return false;
      }

      return true;
    });

    if (rowIndex === -1) {
      const transaction = apiRef.current.applyTransaction({
        add: [{ id: { value: -1 }, text: { value: '' } }],
      });

      rowIndex = transaction.add[0].rowIndex;
    }

    apiRef.current.startEditingCell({ rowIndex, colKey: 'text.value' });
  }, [apiRef, canEditData]);

  const columnDefs = useMemo(
    () =>
      fromJS([
        {
          headerName: 'Users',
          field: 'text.value',
          width: 150,
          headerComponentFramework: AgHeader,
          cellRendererFramework: ChangeSpyCellRender,
          cellEditorFramework: editor,
          onClick: handleInsert,
          editable: canEditData,
          enableSorting: false,
        },
      ]),
    [handleInsert, canEditData, editor],
  );

  const handleCellClicked = useCallback(
    e => {
      if (!canEditData) return;

      const {
        column: { colId },
        node: { rowIndex },
      } = e;

      apiRef.current.startEditingCell({ rowIndex, colKey: colId });
    },
    [canEditData],
  );

  const handleContextMenuClick = useCallback(
    (event, { rowData }) => {
      if (!canEditData) return;
      onDelete(event, { type: 'users', id: rowData.id.value });
    },
    [onDelete, canEditData],
  );

  const handleCellValueChanged = useCallback(
    event => {
      rowNodeRef.current = event.node;
    },
    [rowNodeRef],
  );

  const getRowNodeId = useCallback(({ index }) => index, []);

  const handleGridReady = useCallback(
    params => {
      apiRef.current = params.api;
      apiRef.current.sizeColumnsToFit();
      if (onGridReady) {
        onGridReady(params);
      }
    },
    [apiRef, onGridReady],
  );

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.sizeColumnsToFit();
      apiRef.current.refreshCells({ force: true });
    }
  }, [apiRef, data]);

  return (
    <div className={`col-md-3 ${colClass} full-height flex-1`}>
      <div className={`table-edit full-height ${className}`}>
        <Table
          columnDefs={columnDefs}
          contextActionList={ACTION_DELETE}
          getRowNodeId={getRowNodeId}
          isFetching={isFetching}
          onCellClicked={handleCellClicked}
          onCellValueChanged={handleCellValueChanged}
          onContextMenuClick={handleContextMenuClick}
          onGridReady={handleGridReady}
          rowData={data}
        />
      </div>
    </div>
  );
};

UsersComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  className: PropTypes.string,
  colClass: PropTypes.string,
  data: PropTypes.array,
  editor: PropTypes.func,
  isFetching: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onGridReady: PropTypes.func,
};

export const Users = memo(UsersComponent);
