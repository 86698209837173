import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import { isEqual } from 'underscore';

import Textarea from '../../helpers/Textarea';
import FormGroup from '../../helpers/FormGroup';

import { getIn, getOriginalIn } from '../../../utils/ChangeSpy';
import ContactExecutiveIndustries from './ContactExecutiveIndustries';
import ContactExecutiveHarvcoTags from './ContactExecutiveHarvcoTags';

/**
 * Component is used to display contact executive details (industries, tags, bio...).
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.contact {Immutable.Map} Map with contact data.
 * @param props.onChange {Function} OnChange callback. 1st arg is event.
 * @returns {XML}
 * @private
 * @class
 */
const ContactExecutiveDetailsPanel = props => {
  const {
    contact,
    onChange,
    suggestions,
    suggestIndustry,
    addIndustry,
    delIndustry,
    addTag,
    delTag,
    onClickIndustries,
    onClickTags,
  } = props;

  const changed =
    !isEqual(getIn(contact, 'selectedBICs', []), getOriginalIn(contact, 'selectedBICs', [])) ||
    !isEqual(getIn(contact, 'selectedBMs', []), getOriginalIn(contact, 'selectedBMs', []));

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-2" data-toggle="collapse">
        <h4 className="panel-title"> Exec Details </h4>
      </div>

      <div className="panel-collapse collapse in" id="acc-slide-2">
        <div className="panel-body contact-exec__details-body">
          <div className="highlight pt0">
            <FormGroup>
              <Textarea
                name="legacyPersonalBio"
                onChange={onChange}
                placeholder="Personal Bio"
                rows="5"
                value={contact.get('legacyPersonalBio')}
              />
            </FormGroup>

            <FormGroup>
              <Textarea
                name="trackRecord"
                onChange={onChange}
                placeholder="Track Record"
                rows="5"
                value={contact.get('trackRecord')}
              />
            </FormGroup>

            <FormGroup>
              <Textarea
                name="researchNotes"
                onChange={onChange}
                placeholder="Research Notes"
                rows="2"
                value={contact.get('researchNotes')}
              />
            </FormGroup>
          </div>

          <div className="contact__tables-panel">
            <ContactExecutiveIndustries
              changed={changed}
              industries={contact.get('industryTags')}
              onAdd={addIndustry}
              onChange={onChange}
              onClick={onClickIndustries}
              onDel={delIndustry}
              suggestIndustry={suggestIndustry}
              suggestions={suggestions}
            />

            <ContactExecutiveHarvcoTags
              onAdd={addTag}
              onChange={onChange}
              onClick={onClickTags}
              onDel={delTag}
              tags={contact.get('harvcoTags')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ContactExecutiveDetailsPanel.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContactExecutiveDetailsPanel;
