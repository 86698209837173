import React, { memo } from 'react';
import { Map } from 'immutable';

import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';
import ActivityDropdown from '../helpers/ActivityDropdown';
import config from '../../config';
import { InlineInput } from '../helpers/Input';

const activities = config.values.get('activities');

/**
 * Popup for push next actions.
 *
 * @param props {Object}.
 */
const PushNextActionPopupComponent = ({
  errors,
  formData,
  formError,
  isSubmitting,
  onUpdate,
  onDateChange,
  onActivityChange,
  onClose,
}) => {
  const header = 'Push Next Actions';
  const spinner = isSubmitting ? <i className="fa fa-spinner fa-pulse fa-fw button" /> : null;
  const footer = [
    <Button key="update" className="btn-primary btn-xs" data-dismiss="modal" disabled={isSubmitting} onClick={onUpdate}>
      {spinner} Update
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Close
    </Button>,
  ];
  const errorMessage = errors?.size > 0 && (
    <div className="text-danger mt20 ml10">
      <ul>
        {errors.map((val, i) => (
          <li key={i}>{val}</li>
        ))}
      </ul>
    </div>
  );

  const formErrorContent =
    formError &&
    formError.map((error, index) => (
      <p key={index} className="error text-danger">
        {error}
      </p>
    ));

  return (
    <Popup footer={footer} header={header} id="pushNextActions">
      <div className="row">
        <FormGroup className="col-xs-4">
          <InlineInput
            label="Date"
            name="date"
            onChange={onDateChange}
            placeholder="date"
            type="date"
            value={formData.get('date')}
            autoFocus
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-xs-4">
          <ActivityDropdown
            activities={activities}
            info={formData.get('activity', Map())}
            onChange={onActivityChange}
          />
        </FormGroup>
      </div>
      <div>{formErrorContent}</div>
      <div>{errorMessage}</div>
      <div className="mt15">
        If no Activity is chosen, all existing Next Action Activities will be retained, and set to the chosen Date.
      </div>
    </Popup>
  );
};

export const PushNextActionPopup = memo(PushNextActionPopupComponent);
