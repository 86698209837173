import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

const MultilineTextEditor = (props, ref) => {
  const [value, setValue] = useState(props.value);
  const editorRef = useRef(null);

  useEffect(() => {
    const stopNavigation = event => {
      if ([32, 37, 38, 39, 40, 36, 35, 9, 13].indexOf(event.keyCode) > -1) {
        event.stopPropagation();
      }
    };

    const editor = editorRef.current;

    if (editor) {
      editor.addEventListener('keydown', stopNavigation);
    }

    return () => {
      if (editor) {
        editor.removeEventListener('keydown', stopNavigation);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (editorRef.current) editorRef.current.select();
    }, 1);
  }, []);

  const handleChange = event => {
    setValue(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => false,
    afterGuiAttached: () => {
      setTimeout(() => {
        if (editorRef.current) {
          editorRef.current.focus();
          editorRef.current.select();
        }
      }, 1);
    },
  }));

  return (
    <span className="control-wrap">
      <textarea ref={editorRef} className="h100p" onChange={handleChange} rows={6} value={value} />
    </span>
  );
};

export default forwardRef(MultilineTextEditor);
