import React, { memo } from 'react';
import DropDown, { DropDownElement } from 'src/components/helpers/DropDown/index';
import URLMaker from 'src/routing/UrlMaker';
import { getIn } from 'src/utils/ChangeSpy';

const CompanyProfileComponent = ({ className, id, subsidiaries, disabled, statuses }) => {
  const getLink = company =>
    URLMaker.create('/company/:companyId/buyer').mapParams({
      companyId: company.get('id'),
    });

  const getTitle = status =>
    statuses.size
      ? statuses.find(statusTmp => getIn(statusTmp, ['value']) === status, null, statuses.get(0)).get('name')
      : '';

  const getLine = i => {
    const company = subsidiaries.get(i);
    const currentStatus = company.get('buyerCurrentStatus', '?');
    const highStatus = company.get('buyerHighStatus', '?');

    return (
      <DropDownElement
        key={i}
        className="company-buyer-profile-dropdown display-block"
        href={getLink(company)}
        target="_blank"
      >
        <span className="buyer-profile-dropdown-span">
          <span>{company.get('legalName', '')}</span>
          <span>
            <span title={getTitle(currentStatus)}>{currentStatus}</span>/
            <span title={getTitle(highStatus)}>{highStatus}</span>
          </span>
        </span>
      </DropDownElement>
    );
  };

  const getBtnContent = () => {
    const company = subsidiaries.get(0);
    const currentStatus = company.get('buyerCurrentStatus', '?');
    const highStatus = company.get('buyerHighStatus', '?');

    return (
      <span>
        Portfolio
        <span title={getTitle(currentStatus)}>{currentStatus}</span>/
        <span title={getTitle(highStatus)}>{highStatus}</span>
      </span>
    );
  };

  return subsidiaries.get(0) ? (
    <div className={className}>
      <DropDown
        btnContent={getBtnContent()}
        className="btn btn-xs btn-default"
        disabled={disabled}
        id={id}
        wrapperClassName="portfolio-class"
      >
        {subsidiaries.map((v, i) => getLine(i))}
      </DropDown>
    </div>
  ) : null;
};

/** Company profile component. */
export const CompanyProfile = memo(CompanyProfileComponent);
