import React, { memo } from 'react';

const CheckboxComponent = ({ id, parentId, name, checked = false, collapsed, onChange, onClick }) => (
  <div className="checkbox">
    <label>
      <input checked={checked} onChange={() => onChange(id, parentId)} type="checkbox" />
      {name}
      {onClick && (
        <i
          aria-hidden="true"
          className={collapsed ? 'fa fa-caret-square-o-down' : 'fa fa-caret-square-o-up'}
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onClick(id);
          }}
          title={collapsed ? 'Click to expand' : 'Click to collapse'}
        />
      )}
    </label>
  </div>
);

export const Checkbox = memo(CheckboxComponent);
