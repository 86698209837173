import { fromJS, Map } from 'immutable';
import moment from 'moment';

import * as ActionMarketingType from '../../actions/company/buyer/marketing';
import * as ActionCriteriaType from '../../actions/company/buyer/criteria';
import * as ActionDetailType from '../../actions/companyDetail';
import * as ActionEventType from '../../actions/company/companyEvents';
import * as ActionType from '../../actions/company/companyTarget';
import { LOADED_INDUSTRY_TAGS_SUCCESS } from '../../actions/industryTag';
import config from '../../config';
import * as suggestDecorators from '../decorators/suggest';
import intNormalize from '../decorators/intNormalize';
import handleApiError, { withStatePrefix } from '../decorators/handleApiError';
import getValidator from '../../utils/getValidator';
import { uniqueNewId } from '../../utils/uniqueId';
import { trimWhiteSpaceIf } from '../../utils/string';
import {
  getIn,
  getOriginalIn,
  merge,
  mergeDeepToOriginal,
  revertToOriginal,
  setIn,
  setOriginalIn,
  spy,
  toJS,
  unwrap,
} from '../../utils/ChangeSpy';
import { getAge, getBirthYear } from '../../utils/yearAgeConverter';
import { nonNumericYearSort } from '../../utils/sorting';
import { numberWithCommas } from '../../utils/valuesTransformer';
import isArray from '../../utils/isArray';
import { getLastEventBuyerText } from '../../helpers/getLastEventBuyerText';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../utils/industryTagsHelper';
import { convertDateString } from '../../utils/dateFormat';

const prefixes = config.values.getIn(['contact', 'prefixes']);
const suffixes = config.values.getIn(['contact', 'suffixes']);
const statuses = config.values.getIn(['company', 'statuses']);

const TRADE_SHOW_TARGETS = 'tradeShowTargets';

const SCHEMA = {
  type: 'object',
  properties: {
    revenue: {
      title: 'Revenue',
      description: 'should be a number or empty',
      oneOf: [{ type: 'string', pattern: /^\d*\.?\d*$/ }, { type: 'number', minimum: 0 }, { type: 'null' }],
    },
    employees: {
      title: 'Employees',
      description: 'should be a number or empty',
      oneOf: [{ type: 'string', pattern: /^\d*$/ }, { type: 'number', minimum: 0 }, { type: 'null' }],
    },
    lastResearchedDate: {
      title: 'Last Researched Date',
      description: 'should be a date',
      oneOf: [{ type: 'string' }, { type: 'object' }],
    },
    balanceSheetDate: {
      title: 'Balance Sheet Date',
      description: 'should be a date',
      oneOf: [
        { type: 'null' },
        { type: 'string', pattern: '' },
        { type: 'string', pattern: /^[^\s]{10}$/ },
        { type: 'object' },
      ],
    },
    balanceSheetCurrentCash: {
      title: 'Current Cash',
      description: 'should be valid decimal or empty',
      oneOf: [{ type: 'null' }, { type: 'number' }, { type: 'string', pattern: /^\d*\.?\d*$/ }],
    },
    balanceSheetCurrentDebt: {
      title: 'Current Debt',
      description: 'should be valid decimal or empty',
      oneOf: [{ type: 'null' }, { type: 'number' }, { type: 'string', pattern: /^\d*\.?\d*$/ }],
    },
    ebitda: {
      title: 'EBITDA',
      description: 'should be valid decimal or empty',
      oneOf: [{ type: 'null' }, { type: 'string', pattern: /^$|^-?\d*\.?\d+$/ }],
      required: false,
    },
  },
};

const NEW_BUYER_SCHEMA = {
  type: 'object',
  properties: {
    buyerName: {
      title: 'Buyer name',
      description: 'should be filled',
      type: 'string',
      minLength: 1,
      required: true,
    },
    buyerId: {
      title: 'Buyer name',
      description: 'should be one of existing buyer names',
      type: 'number',
      minValue: 1,
    },
  },
};

const FIELDS_TO_SPY = [
  'revenue',
  'employees',
  'description',
  'lastResearchedDate',
  'researchNotes',
  'suggestDirector',
  'suggestAnalyst',
  'suggestDealMaker',
  'suggestResearcher',
  'lastResearcherId',
  'recordOwnerId',
  'dealMakerId',
  'recordSubOwnerId',
  'fyeMonth',
  'dataQuality',
  'corporationType',
  'balanceSheetDate',
  'balanceSheetCurrentCash',
  'balanceSheetCurrentDebt',
  'ebitda',
  'financialNotes',
  'highestStatus',
  INDUSTRY_CATEGORIES,
  BUSINESS_MODELS,
  'tradeShowTargetsSpyList',
];
const FISCAL_YEAR_FIELDS_TO_SPY = ['revenue', 'gross', 'ebit', 'ebitda', 'net', 'capex', 'fiscalYear'];
const BUYERS_FIELD_TO_SPY = [
  'approvalListId',
  'dsplApplistLabel',
  'dsplBuyerAbbrName',
  'dsplBuyerLegalName',
  'dsplProjectCategory',
  'dsplProjectHarvcoLeadUserName',
  'projectId',
  'activeBuyer',
  'approved',
  'priority',
  'bsnHarvco',
  'bsnClient',
  'harveyComment',
  'currentStatus',
  'highStatus',
];
const EMAIL_FIELDS_TO_SPY = ['email', 'type', 'isVerify'];
const PHONE_FIELDS_TO_SPY = ['phone', 'type', 'code', 'extension', 'country', 'unformattedPhone', 'isVerify'];
const CONTACT_FIELDS_TO_SPY = [
  'prefix',
  'suffix',
  'birthYear',
  'pivot.title',
  'pivot.company',
  'pivot.advTitle',
  'pivot.advWeb',
  'pivot.ownershipPercent',
  'pivot.startYear',
  'pivot.endYear',
  'pivot.notes',
  'age',
  'fullName',
  'firstName',
  'lastName',
  'nick',
  'pronounce',
];

const validator = getValidator(SCHEMA);
const newBuyerValidator = getValidator(NEW_BUYER_SCHEMA);

const defaultState = spy(
  fromJS({
    loaded: false,
    isValid: true,
    errors: {},
    inputErrors: {},
    highestStatus: null,
    statuses,
    phoneCodes: [],

    buyers: [],
    isTarget: false,
    customFields: [],
    harvcoTags: [],
    industries: [],
    targetCompetitors: [],
    targetCustomers: [],
    targetFacilities: [],
    targetMarkets: [],
    targetProducts: [],
    tradeShowTargets: [],
    tradeShowTargetsSpyList: [],

    targetFiscalYears: [],

    subsidiaries: [],
    suggests: [],
    suggestDirector: '',
    suggestDirectorData: {},
    suggestAnalyst: '',
    suggestDealMaker: '',
    suggestResearcher: '',
    contacts: [],
    openedContact: 0,
    files: [],

    industriesSuggests: [],
    tradeShowsSuggests: [],
    businessModels: [],
    industryCategories: [],

    newData: {
      emails: spy(
        {
          email: '',
          type: '',
        },
        EMAIL_FIELDS_TO_SPY,
      ),
      phones: spy(
        {
          phone: '',
          type: '',
          code: '',
          extension: '',
          country: '',
          unformattedPhone: '',
        },
        PHONE_FIELDS_TO_SPY,
      ),
    },
    newBuyer: spy(
      {
        currentStatus: statuses.getIn([0, 'value']),
        buyerId: null,
        buyerName: '',
        dsplProjectCategory: '',
        projectId: null,
        dsplApplistLabel: '',
        approvalListId: null,
        buyerSuggests: [],
        projectSuggests: [],
        approveSuggests: [],
      },
      ['buyerId', 'buyerName', ...BUYERS_FIELD_TO_SPY],
    ),
    newContact: spy(
      {
        fullName: '',
        firstName: '',
        lastName: '',
        nick: '',
        pronounce: '',
        pivot: {
          title: '',
          notes: '',
          company: '',
          advTitle: '',
          advWeb: '',
        },
        prefix: prefixes.get(0),
        suffix: suffixes.get(0),
        phone: '',
        type: 'Direct',
        code: '',
        extension: '',
        country: '',
        unformattedPhone: '',
        email: '',
        suggestions: [],
      },
      ['email', ...PHONE_FIELDS_TO_SPY, ...CONTACT_FIELDS_TO_SPY],
    ),

    newYear: spy({ fiscalYear: '' }, FISCAL_YEAR_FIELDS_TO_SPY),
    newBuyerInputErrors: {},
    statusUpdate: {},
  }),
  FIELDS_TO_SPY,
);

const emptyEntity = {
  customFields: fromJS({ id: 0, fieldName: '', fieldValue: '' }),
  harvcoTags: fromJS({ id: 0, tag: '' }),
  industries: fromJS({ id: 0, industryLabel: '' }),
  targetCompetitors: fromJS({ id: 0, competitor: '' }),
  targetCustomers: fromJS({ id: 0, customer: '', percent: '' }),
  targetFacilities: fromJS({ id: 0, facility: '', sqFootage: '' }),
  targetMarkets: fromJS({ id: 0, market: '', percent: '' }),
  targetProducts: fromJS({ id: 0, product: '', percent: '' }),
  tradeShowTargets: fromJS({
    id: 0,
    name: '',
  }),
};

const ENTITY_FIELDS_TO_SPY = {
  customFields: ['fieldName', 'fieldValue'],
  harvcoTags: ['tag'],
  industries: ['industryLabel', 'id'],
  targetCompetitors: ['competitor'],
  targetCustomers: ['customer', 'percent'],
  targetFacilities: ['sqFootage', 'facility'],
  targetMarkets: ['market', 'percent'],
  targetProducts: ['product', 'percent'],
};

const ENTITY_VALIDATORS = {
  targetMarkets: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        market: {
          title: 'Market',
          description: 'should not be empty',
          type: 'string',
          pattern: /\w+/,
        },
        percent: {
          title: 'Percent',
          description: 'should be empty or correct value (0 - 100)',
          oneOf: [
            { type: 'null' },
            { type: 'number', minimum: 0, maximum: 100 },
            { type: 'string', pattern: /^$/ },
            { type: 'string', pattern: /^\d$/ },
            { type: 'string', pattern: /^\d\d$/ },
            { type: 'string', pattern: /^100$/ },
          ],
        },
      },
    },
  }),
  targetProducts: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        product: {
          title: 'Product',
          description: 'should not be empty',
          type: 'string',
          pattern: /\w+/,
        },
        percent: {
          title: 'Percent',
          description: 'should be empty or correct value (0 - 100)',
          oneOf: [
            { type: 'null' },
            { type: 'number', minimum: 0, maximum: 100 },
            { type: 'string', pattern: /^$/ },
            { type: 'string', pattern: /^\d$/ },
            { type: 'string', pattern: /^\d\d$/ },
            { type: 'string', pattern: /^100$/ },
          ],
        },
      },
    },
  }),
  targetCustomers: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        customer: {
          title: 'Customer',
          description: 'should not be empty',
          type: 'string',
          pattern: /\w+/,
        },
        percent: {
          title: 'Percent',
          description: 'should be empty or correct value (0 - 100)',
          oneOf: [
            { type: 'null' },
            { type: 'number', minimum: 0, maximum: 100 },
            { type: 'string', pattern: /^$/ },
            { type: 'string', pattern: /^\d$/ },
            { type: 'string', pattern: /^\d\d$/ },
            { type: 'string', pattern: /^100$/ },
          ],
        },
      },
    },
  }),
  targetCompetitors: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        competitor: {
          title: 'Competitor',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
  targetFacilities: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        facility: {
          title: 'Facility',
          type: 'string',
          minLength: 1,
        },
        sqFootage: {
          title: 'Footage',
          description: 'should be numeric or empty',
          oneOf: [
            { type: 'number' },
            { type: 'string', pattern: /^$/ },
            { type: 'string', pattern: /^\d{1,3}(,\d{3})*$/ },
          ],
        },
      },
    },
  }),
  customFields: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        fieldName: {
          title: 'Field name',
          description: 'is required',
          type: 'string',
          required: true,
          minLength: 1,
        },
        fieldValue: {
          title: 'Value',
          description: 'is required',
          type: 'string',
          required: true,
          minLength: 1,
        },
      },
    },
  }),
  harvcoTags: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        tag: {
          title: 'Tag',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
  industries: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        pivot: {
          type: 'object',
          properties: {
            id: {
              title: 'Industry',
              description: 'should be valid industry name',
              errorTo: '#.industryLabel',
              type: 'number',
              minimum: 1,
            },
          },
        },
        industryLabel: {
          title: 'Industry',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
  tradeShowTargets: getValidator({
    type: 'array',
    items: {
      type: 'object',
      properties: {
        pivot: {
          type: 'object',
          properties: {
            tradeshowId: {
              title: 'Trade Show',
              description: 'should be valid trade show name',
              errorTo: '#.showName',
              type: 'number',
              minimum: 1,
            },
          },
        },
        name: {
          title: 'Trade Show',
          description: 'should not be empty',
          type: 'string',
          minLength: 1,
        },
      },
    },
  }),
};

let wrappedReducer = reducer; // (state, action) => checkValidity(reducer(state, action), action);

wrappedReducer = suggestDecorators.check('suggestDirector', 'suggests', ActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = suggestDecorators.check('suggestAnalyst', 'suggests', ActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = suggestDecorators.check('suggestDealMaker', 'suggests', ActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = suggestDecorators.check('suggestResearcher', 'suggests', ActionType.LOADED_USERS, wrappedReducer);

wrappedReducer = suggestDecorators.resetFieldByOtherWithDynPaths(
  checkNewBuyerProject,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.resetFieldByOtherWithDynPaths(
  checkNewBuyerProjectId,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
  null,
);
wrappedReducer = suggestDecorators.resetFieldByOtherWithDynPaths(
  checkNewBuyerApproval,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.resetFieldByOtherWithDynPaths(
  checkNewBuyerApprovalId,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
  null,
);

wrappedReducer = suggestDecorators.autoRemoveIdWithDynPaths(
  checkProjectSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoRemoveIdWithDynPaths(
  checkProjectToApprovalSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.resetFieldByOtherWithDynPaths(
  checkProjectToApprovalIdSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
  null,
);
wrappedReducer = suggestDecorators.autoRemoveIdWithDynPaths(
  checkApprovalSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);

wrappedReducer = suggestDecorators.autoNullIdWithDynPaths(
  checkProjectToApprovalIdSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoNullIdWithDynPaths(
  checkProjectSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoNullIdWithDynPaths(
  checkApprovalSuggest,
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);

wrappedReducer = suggestDecorators.checkWithDynPaths(
  checkProjectSuggest,
  ActionType.LOADED_COMPANY_PROJECTS,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.checkWithDynPaths(
  checkApprovalSuggest,
  ActionType.LOADED_COMPANY_APPROVAL_LISTS,
  wrappedReducer,
);

wrappedReducer = suggestDecorators.check(
  'newBuyer.buyerName',
  'newBuyer.buyerSuggests',
  ActionType.LOADED_COMPANY_BUYERS_LIST,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.check(
  'newBuyer.dsplProjectCategory',
  'newBuyer.projectSuggests',
  ActionType.LOADED_COMPANY_PROJECTS,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.check(
  'newBuyer.dsplApplistLabel',
  'newBuyer.approveSuggests',
  ActionType.LOADED_COMPANY_APPROVAL_LISTS,
  wrappedReducer,
);

wrappedReducer = suggestDecorators.autoRemoveId(
  'newBuyer.buyerName',
  'newBuyer.buyerId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoRemoveId(
  'newBuyer.dsplProjectCategory',
  'newBuyer.projectId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoRemoveId(
  'newBuyer.dsplApplistLabel',
  'newBuyer.approvalListId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);

wrappedReducer = suggestDecorators.autoNullId(
  'newBuyer.dsplProjectCategory',
  'newBuyer.projectId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoNullId(
  'newBuyer.dsplApplistLabel',
  'newBuyer.approvalListId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);

wrappedReducer = suggestDecorators.autoNullId(
  'suggestDirector',
  'recordOwnerId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoNullId(
  'suggestAnalyst',
  'recordSubOwnerId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoNullId(
  'suggestDealMaker',
  'dealMakerId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);
wrappedReducer = suggestDecorators.autoNullId(
  'suggestResearcher',
  'lastResearcherId',
  ActionType.UPDATE_COMPANY,
  wrappedReducer,
);

wrappedReducer = intNormalize('employees', null, ActionType.UPDATE_COMPANY, wrappedReducer);

wrappedReducer = handleApiError(ActionType.ERRORED_COMPANY_TARGET, wrappedReducer);

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newContact', 'inputErrors'];

  return ['contacts', state.get('openedContact'), 'inputErrors'];
});

wrappedReducer = withStatePrefix(ActionType.ERROR_SAVE_COMPANY_CONTACT_CHANNEL, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newData', action.channelType, 'inputErrors'];

  return ['contacts', state.get('openedContact'), action.channelType, action.channelIndex, 'inputErrors'];
});

wrappedReducer = withStatePrefix(ActionType.SAVE_FISCAL_YEAR_ERROR, wrappedReducer, (state, action) => {
  if (action.isNew) return ['newYear', 'inputErrors'];

  const index = state.get('targetFiscalYears').findKey(year => year.get('id') === action.yearId);

  return ['targetFiscalYears', index, 'inputErrors'];
});

wrappedReducer = (function(wrappedReducer) {
  return (state, action) => {
    let nextState = wrappedReducer(state, action);

    switch (action.type) {
      case ActionType.UPDATE_COMPANY:

      // fallthrough
      case ActionType.FETCHING_COMPANY_BUYERS_SUCCESS: {
        const buyers = nextState.get('buyers');
        const highestOriginal = Math.max(
          ...buyers.map(buyer => parseFloat(getOriginalIn(buyer, 'highStatus'))),
        ).toFixed(2);
        const highest = Math.max(...buyers.map(buyer => parseFloat(getIn(buyer, 'highStatus')))).toFixed(2);

        nextState = setIn(setOriginalIn(nextState, 'highestStatus', highestOriginal), 'highestStatus', highest);
        break;
      }

      default:
        break;
    }

    return nextState;
  };
})(wrappedReducer);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  emailTrimming(action);
  switch (action.type) {
    case ActionType.UPDATE_COMPANY: {
      if (/sqFootage/i.test(action.name)) {
        action.value = numberWithCommas(action.value.split(',').join(''));
      }

      const stateTemp = checkValidity(changeField(state, action));

      if (action.summary && /buyers\.\d\.currentStatus/i.test(action.name)) {
        const id = action.name.substring(action.name.indexOf('.') + 1, action.name.lastIndexOf('.'));

        return checkValidity(
          changeField(stateTemp, {
            name: `buyers.${id}.bsnHarvco`,
            value: action.summary,
          }),
        );
      }

      if (/targetFiscalYears\.\d\.ebitda/i.test(action.name)) {
        const value = getLastEbitda(stateTemp.get('targetFiscalYears'));

        return checkValidity(changeField(stateTemp, { name: `ebitda.value`, value }));
      }

      if (action.name.split('.')[0] === TRADE_SHOW_TARGETS) {
        const data = action.name.split('.');

        if (data[2] === 'booth') {
          return setIn(stateTemp, `tradeShowTargetsSpyList.value.${data[1]}.${data[2]}`, action.value);
        }
      }

      return stateTemp;
    }

    case ActionType.REVERT_UPDATE:
      return checkValidity(revertToOriginal(state, action.field));

    case ActionDetailType.FETCHING_COMPANY_SUCCESS: {
      return spy(state.merge(mapResponse(state, action)), FIELDS_TO_SPY);
    }

    case ActionDetailType.RELOAD_MAIN_INFO_SUCCESS:
      return spy(state.merge(getMainInfo(action.response)), FIELDS_TO_SPY);

    case ActionCriteriaType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionMarketingType.LOADED_COMPANY_CONTACT:

    // fallthrough
    case ActionType.LOADED_COMPANY_CONTACT:
      return state.merge({
        openedContact: 0,
        contacts: getContacts(action.response),
      });

    case ActionCriteriaType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionMarketingType.DEL_COMPANY_CONTACT:

    // fallthrough
    case ActionType.DEL_COMPANY_CONTACT:
      return state
        .update('contacts', contacts => contacts.filter(contact => contact.get('id') !== action.contactId))
        .set('openedContact', 0);

    case ActionType.FETCHING_COMPANY_BUYERS_SUCCESS:
      return spy(state.merge(mapBuyersResponse(action, state)), FIELDS_TO_SPY);

    case ActionType.FETCHING_COMPANY_SUBSIDIARIES_SUCCESS:
      return spy(state.merge(mapSubsidiariesResponse(action)), FIELDS_TO_SPY);

    case ActionType.LOADING_TITLES:
      return state.setIn([...action.prefix.split('.'), 'loading'], true);

    case ActionType.LOADED_TITLES_FAIL:
      return state.setIn([...action.prefix.split('.'), 'loading'], false);

    case ActionType.LOADED_TITLES: {
      const mask = new RegExp(String(action.filter).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1'), 'i');

      return state
        .setIn(
          [...action.prefix.split('.'), 'suggestions'],
          fromJS(action.response.data.filter(title => mask.test(title)).map(title => ({ text: title }))),
        )
        .setIn([...action.prefix.split('.'), 'loading'], false);
    }

    case ActionType.LOADED_USERS:
      return state.setIn(
        ['suggests'],
        action.page === 1 ? fromJS(action.response.data) : state.get('suggests').concat(action.response.data),
      );

    case ActionType.CLEAR_USERS:
      return state.merge({ suggests: [] });

    case ActionType.FETCHING_COMPANY_FILES_SUCCESS:
      return state.merge({ files: action.response.data });

    case ActionType.DELETE_COMPANY_FILE_SUCCESS:
      return state.set(
        'files',
        state.get('files').filter(file => file.get('id') !== action.id),
      );

    case ActionType.OPEN_COMPANY_CONTACT:
      return state.setIn(['openedContact'], action.index);

    case ActionType.ADD_COMPANY_DETAIL_ENTITY:
      return appendDetailEntity(state, action);

    case ActionType.EDIT_COMPANY_DETAIL_ENTITY:
      return checkValidity(editDetailEntity(state, action), action);

    case ActionType.DEL_COMPANY_DETAIL_ENTITY:
      return deleteDetailEntity(state, action);

    case ActionType.SAVED_COMPANY_TARGET: {
      const tmp = spy(setValueAsOriginalByMap(state, action.fields), FIELDS_TO_SPY);

      return tmp.set('isTarget', !!getIn(state, 'recordOwnerId'));
    }

    case ActionType.ERRORED_COMPANY_TARGET:
      return state;

    case ActionType.CLOSE_COMPANY_TARGET_VALIDATION_ERROR:
      return state
        .get('contacts')
        .reduce(
          (state, _, index) => state.deleteIn(['contacts', index, 'inputErrors', action.field]),
          state.deleteIn(['inputErrors', action.field]).deleteIn(['newContact', 'inputErrors', action.field]),
        );

    case ActionType.LOADED_COMPANY_INDUSTRIES:
      return state.setIn(
        ['industriesSuggests'],
        action.page === 1 ? fromJS(action.response.data) : state.get('industriesSuggests').concat(action.response.data),
      );

    case ActionType.LOADED_COMPANY_TRADESHOWS:
      return state.setIn(
        ['tradeShowsSuggests'],
        action.page === 1 ? fromJS(action.response.data) : state.get('tradeShowsSuggests').concat(action.response.data),
      );

    case ActionType.FETCHING_TRADE_SHOWS_SUCCESS:
      return state.merge({
        tradeShowTargets: mapTradeShowList(action),
      });

    case ActionType.LOADED_SELECTED_TRADESHOW:
      return state
        .set('tradeShowTargets', addSelectedTradeShow(state, action))
        .setIn(['tradeShowTargetsSpyList', 'value'], addSelectedTradeShow(state, action));

    case ActionType.REMOVE_TRADE_SHOW:
      return state
        .set('tradeShowTargets', removeTradeShow(state, action))
        .setIn(['tradeShowTargetsSpyList', 'value'], removeTradeShow(state, action));

    case ActionType.SAVED_COMPANY_CONTACT:
      return saveContact(state, action);

    case ActionMarketingType.SAVED_COMPANY_CONTACT_CHANNEL:

    // fallthrough
    case ActionType.SAVED_COMPANY_CONTACT_CHANNEL:
      return saveContactChannel(state, action);

    case ActionType.SAVED_COMPANY_ENTITYES:
      return mergeDeepToOriginal(state, { [action.entity]: action.body });

    case ActionType.LOADED_COMPANY_ENTITYES:
      return updateCompanyEntities(state, action);

    case ActionType.SET_LAST_RESEARCHER:
      return state.set('suggestResearcher', action.researcher).set('lastResearchedDate', moment(new Date()));

    case ActionType.DEL_CONTACT_CHANNEL:
      return delChannelFromContact(state, action);

    case ActionType.OPEN_COMPANY_BUYER:
      return state.update('buyers', buyers => buyers.map((buyer, i) => buyer.set('isOpened', i === action.index)));

    case ActionType.ACTIVE_COMPANY_BUYER:
      return state.update('buyers', buyers =>
        buyers.map((buyer, i) => setIn(buyer, 'activeBuyer', i === action.index)).sort(sortBuyers),
      );

    case ActionType.APPROVE_COMPANY_BUYER:
      return state.updateIn(['buyers', action.index], buyer => {
        const currentVal = getIn(buyer, 'approved');

        switch (currentVal) {
          case true:

          // fallthrough
          case false:
            return setIn(buyer, 'approved', null);

          default:
            return setIn(buyer, 'approved', action.target === 'on');
        }
      });

    case ActionType.DEL_COMPANY_BUYER:
      return state.update('buyers', buyers => buyers.filter(buyer => buyer.getIn(['id']) !== action.id));

    case ActionType.LOADED_COMPANY_BUYERS_LIST:
      return state.updateIn(['newBuyer', 'buyerSuggests'], suggests => {
        const data = action.response.data.map(buyer => buyer);

        if (action.page === 1) return fromJS(data);

        return suggests.concat(data);
      });

    case ActionType.LOADED_COMPANY_PROJECTS: {
      const path =
        action.buyerIndex === 'newBuyer'
          ? ['newBuyer', 'projectSuggests']
          : ['buyers', action.buyerIndex, 'projectSuggests'];

      return state.updateIn(path, suggests => {
        const data = action.response.data.map(project => ({
          text: project.harvcoLead ? `${project.category} (${project.harvcoLead})` : `${project.category}`,
          isActive: project.active,
          id: project.id,
        }));

        if (action.page === 1) return fromJS(data);

        return suggests.concat(data);
      });
    }

    case ActionType.LOADED_COMPANY_APPROVAL_LISTS: {
      const path =
        action.buyerIndex === 'newBuyer'
          ? ['newBuyer', 'approveSuggests']
          : ['buyers', action.buyerIndex, 'approveSuggests'];

      return state.updateIn(path, suggests => {
        if (action.page === 1) return fromJS(action.response.data);

        return suggests.concat(action.response.data);
      });
    }

    case ActionType.SAVE_COMPANY_BUYER:
      return changeList(state, 'buyers', action, BUYERS_FIELD_TO_SPY);

    case ActionType.SAVE_FISCAL_YEAR_SUCCESS:
      return saveYear(state, action);

    case ActionType.DELETE_FISCAL_YEAR_SUCCESS:
      return state.update('targetFiscalYears', years => years.filter(year => year.get('id') !== action.yearId));

    case ActionType.CLOSE_FISCAL_ERROR:
      return state.deleteIn(['targetFiscalYears', action.index, 'inputErrors', action.name]);

    case ActionType.REORDER_CONTACTS:
      return state.setIn(['contacts'], action.contacts);

    case ActionType.REORDER_CHANNELS: {
      const contacts = state.getIn(['contacts', action.openedContact, action.name]);

      const newOrder = Object.keys(action.data).reduce((ret, key) => {
        const index = ret.findIndex(c => c.get('id') === action.data[key].id);

        if (index > -1) {
          ret = ret.setIn([index, 'priority'], action.data[key].priority);
        }

        return ret;
      }, contacts);

      return state.setIn(['contacts', action.openedContact, action.name], newOrder);
    }

    case ActionEventType.LOADED_EVENT_LIST_SUCCESS:
      return state.merge(getNDAFromState(state, action.response));

    case ActionType.ADD_VALIDATION_ERROR: {
      const path = action.path.split('.').concat(['inputErrors']);
      const inputErrors = state.getIn(path, Map());

      return state.setIn(path, inputErrors.set(action.field, action.message));
    }

    case ActionType.REVERT_INDUSTRIES_TO_ORIGIN:
      return state.set(
        'industries',
        state.get('industries').map(i => {
          const temp = revertToOriginal(i, 'industryLabel');

          return revertToOriginal(temp, 'pivot.industryId');
        }),
      );

    case ActionType.STATUS_CODE_UPDATE:
      return state.setIn(action.field.split('.'), action.value);

    case ActionType.STATUS_CODE_UPDATED_ERROR:
      return state.setIn(action.field.split('.'), action.value);

    case ActionType.LOADED_PHONE_CODES_SUCCESS:
      return state.set(
        'phoneCodes',
        fromJS(action.response.data).insert(
          0,
          Map({
            name: '--',
            code: '',
          }),
        ),
      );

    case ActionType.DETAIL_ENTITY_VALIDITY:
      return checkValidity(state);

    case ActionType.SELECT_BUSINESS_MODELS:
      return setIn(state, [BUSINESS_MODELS], fromJS(action.ids));

    case LOADED_INDUSTRY_TAGS_SUCCESS:
      return state.set('industryTags', fromJS(action.response.data));

    case ActionType.UPDATE_INDUSTRY_CATEGORY:
      return setIn(state, INDUSTRY_CATEGORIES, fromJS(action.selectedTags));

    default:
      return state;
  }
}

function getLastEbitda(targetFiscalYears) {
  const yearsList = targetFiscalYears.map(year => ({
    year: year.getIn(['fiscalYear', 'value']),
    ebitda: year.getIn(['ebitda', 'value']),
  }));
  const lastYearValue = yearsList.reduce((prev, current) => (prev.year > current.year ? prev : current));

  return lastYearValue.ebitda;
}

function updateCompanyEntities(state, action) {
  let entities = action.entity;

  if (!isArray(action.entity)) entities = [action.entity];

  let tmp = state;

  entities.forEach(key => {
    tmp = tmp.set(key, fromJS(detailMapper[key](action.response.data)));
  });

  return tmp;
}

function changeList(state, field, action, FIELDS_TO_SPY) {
  return state.set(
    field,
    state.get(field).map(entity => {
      if ('id' in action && entity.get('id') !== action.id) return entity;

      const updated = mergeDeepToOriginal(entity, action.body);

      return spy(updated, FIELDS_TO_SPY);
    }),
  );
}

function checkProjectToApprovalSuggest({ name, field = name }) {
  if (/^buyers\.\d+\.dsplProjectCategory$/.test(field)) {
    return {
      isSuggest: true,
      namePath: field,
      listPath: field.replace(/\.dsplProjectCategory$/, '.projectSuggests'),
      idPath: field.replace(/\.dsplProjectCategory$/, '.projectId'),
    };
  }

  return { isSuggest: false };
}

function checkNewBuyerProject({ name }) {
  if (name === 'newBuyer.buyerName') {
    return {
      isSuggest: true,
      namePath: name,
      idPath: 'newBuyer.dsplProjectCategory',
    };
  }

  return { isSuggest: false };
}

function checkNewBuyerProjectId({ name }) {
  if (name === 'newBuyer.buyerName') {
    return {
      isSuggest: true,
      namePath: name,
      idPath: 'newBuyer.projectId',
    };
  }

  return { isSuggest: false };
}

function checkNewBuyerApprovalId({ name, field = name }) {
  if (field === 'newBuyer.dsplProjectCategory') {
    return {
      isSuggest: true,
      namePath: field,
      idPath: 'newBuyer.approvalListId',
    };
  }

  return { isSuggest: false };
}

function checkNewBuyerApproval({ name, field = name }) {
  if (field === 'newBuyer.dsplProjectCategory') {
    return {
      isSuggest: true,
      namePath: field,
      idPath: 'newBuyer.dsplApplistLabel',
    };
  }

  return { isSuggest: false };
}

function checkProjectToApprovalIdSuggest({ name, field = name }) {
  if (/^buyers\.\d+\.dsplProjectCategory$/.test(field)) {
    return {
      isSuggest: true,
      namePath: field,
      idPath: field.replace(/\.dsplProjectCategory$/, '.approvalListId'),
    };
  }

  return { isSuggest: false };
}

function checkProjectSuggest({ name, field = name }) {
  if (/^buyers\.\d+\.dsplProjectCategory$/.test(field)) {
    return {
      isSuggest: true,
      namePath: field,
      listPath: field.replace(/\.dsplProjectCategory$/, '.projectSuggests'),
      idPath: field.replace(/\.dsplProjectCategory$/, '.projectId'),
    };
  }

  return { isSuggest: false };
}

function checkApprovalSuggest({ name, field = name }) {
  if (/^buyers\.\d+\.dsplApplistLabel/.test(field)) {
    return {
      isSuggest: true,
      namePath: field,
      listPath: field.replace(/\.dsplApplistLabel/, '.approveSuggests'),
      idPath: field.replace(/\.dsplApplistLabel/, '.approvalListId'),
    };
  }

  return { isSuggest: false };
}

function sortBuyers(buyerA, buyerB) {
  if (getIn(buyerA, 'activeBuyer')) return -1;
  if (getIn(buyerB, 'activeBuyer')) return 1;

  return 0; // order by active last->go upper
}

function setValueAsOriginalByMap(state, map) {
  return spy(
    map.reduce((result, key) => {
      const value = getIn(result, key);

      return result.setIn(key.split('.'), value);
    }, state),
    map,
  );
}

function appendDetailEntity(state, action) {
  const { entity } = action;
  const newEntity = emptyEntity[entity].set('id', uniqueNewId());

  action.index = state.get(entity).size;

  return editDetailEntity(
    state.set(entity, state.get(entity).push(spy(newEntity, ENTITY_FIELDS_TO_SPY[entity]))),
    action,
  );
}

function editDetailEntity(state, action) {
  return state.update(action.entity, table => table.map((row, index) => row.set('isEditing', index === action.index)));
}

function deleteDetailEntity(state, action) {
  let tmpState = state.update(action.entity, details => details.filter(row => getIn(row, 'id') !== action.id));

  if (action.errorKey) {
    tmpState = tmpState.deleteIn(['inputErrors', action.errorKey]);
  }

  return checkValidity(tmpState);
}

function mapResponse(state, action) {
  try {
    action.response.data.events = action.response.data.events
      .filter(event => !event.deletedAt)
      .sort((a, b) => moment(a.date) - moment(b.date));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return {
    ...getLastEventBuyerText(action.response),
    ...getDetails(action.response),
    ...getNDA(action.response),
    ...getMainInfo(action.response),
    ...getSuggests(action.response),
    ...getFinancials(action.response),
    tradeShowTargets: state.get('tradeShowTargets'),
    tradeShowTargetsSpyList: state.get('tradeShowTargets'),
    director: getDirector(action.response),
    isTarget: !!action.response.data.recordOwnerId,
    openedContact: 0,
    contacts: getContacts(action.response),
    loaded: true,
  };
}

/**
 * Try to merge buyer list from API response with the existing list.
 */
function mapBuyersResponse(action, state) {
  let retrieving = getBuyers(action.response);
  const existing = state.get('buyers').map(e => {
    const index = retrieving.findIndex(r => r.get('id') === e.get('id'));

    if (index > -1) {
      const tmp = retrieving.get(index);

      retrieving = retrieving.delete(index);

      return tmp;
    }

    return e;
  });

  return {
    buyers: existing.concat(retrieving),
  };
}

function getMainInfo({ data }) {
  const { revenue, employees, description, lastResearchedDate, researchNotes } = data;

  return {
    revenue,
    employees,
    description,
    lastResearchedDate: moment(lastResearchedDate),
    researchNotes,
  };
}

function mapSubsidiariesResponse(action) {
  return {
    subsidiaries: action.response.data.map(v => ({
      ...v,
      name: v.abbrName || v.legalName,
    })),
  };
}

function getDetails({ data }) {
  const {
    customFields,
    harvcoTags,
    industries,
    targetCompetitors,
    targetCustomers,
    targetFacilities,
    targetMarkets,
    targetProducts,
    industryCategories,
    businessModels,
  } = data;

  return {
    customFields: customFields.map(value => spy(value, ENTITY_FIELDS_TO_SPY.customFields)),
    harvcoTags: detailMapper.harvcoTags(harvcoTags),
    industries: industries
      .sort((a, b) => a.pivot.priority - b.pivot.priority)
      .map(value => spy(value, ENTITY_FIELDS_TO_SPY.industries)),
    targetCompetitors: targetCompetitors.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetCompetitors)),
    targetCustomers: targetCustomers.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetCustomers)),
    targetFacilities: targetFacilities.map(value => {
      value.sqFootage = numberWithCommas(value.sqFootage);

      return spy(value, ENTITY_FIELDS_TO_SPY.targetFacilities);
    }),
    targetMarkets: targetMarkets.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetMarkets)),
    targetProducts: targetProducts.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetProducts)),
    industryCategories: fromJS(industryCategories),
    businessModels: fromJS(businessModels),
  };
}

const detailMapper = {
  customFields: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.customFields)),
  harvcoTags: data =>
    data.map(value =>
      spy(
        {
          ...value,
          tooltip: `${value['user.userName'] || value.userName || 'No user'}
${moment(value.date).format('MM/DD/YYYY')}`,
        },
        ENTITY_FIELDS_TO_SPY.harvcoTags,
      ),
    ),
  industries: data =>
    data.sort((a, b) => a.pivot.priority - b.pivot.priority).map(value => spy(value, ENTITY_FIELDS_TO_SPY.industries)),
  targetCompetitors: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetCompetitors)),
  targetCustomers: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetCustomers)),
  targetFacilities: data =>
    data.map(value => {
      value.sqFootage = numberWithCommas(value.sqFootage);

      return spy(value, ENTITY_FIELDS_TO_SPY.targetFacilities);
    }),
  targetMarkets: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetMarkets)),
  targetProducts: data => data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.targetProducts)),
  tradeShowTargets: data => {
    mutateDateFields(data);

    return data.map(value => spy(value, ENTITY_FIELDS_TO_SPY.tradeShowTargets));
  },
  industryCategories: data =>
    spy(fromJS({ industryCategories: data.industryCategories }), [INDUSTRY_CATEGORIES]).get(INDUSTRY_CATEGORIES),
  recordOwnerId: data => spy(fromJS({ recordOwnerId: data.recordOwnerId }), ['recordOwnerId']).get('recordOwnerId'),
  dealmakerId: data => spy(fromJS({ dealMakerId: data.dealMakerId }), ['dealMakerId']).get('dealMakerId'),
  recordSubOwnerId: data =>
    spy(fromJS({ recordSubOwnerId: data.recordSubOwnerId }), ['recordSubOwnerId']).get('recordSubOwnerId'),
  recordOwnerEmail: data => (data.directorUser || emptyUser).email,
  businessModels: data => spy(fromJS({ businessModels: data.businessModels }), [BUSINESS_MODELS]).get(BUSINESS_MODELS),
};

function getFinancials({ data }) {
  const {
    fyeMonth = 0,
    dataQuality = '',
    corporationType = '',
    targetFiscalYears,
    balanceSheetDate,
    balanceSheetCurrentCash,
    balanceSheetCurrentDebt,
    ebitda,
    financialNotes,
  } = data;
  const date = moment(balanceSheetDate);

  return {
    fyeMonth,
    dataQuality,
    corporationType,
    targetFiscalYears: targetFiscalYears
      .sort((a, b) => nonNumericYearSort(a.fiscalYear, b.fiscalYear))
      .map(year => spy(year, FISCAL_YEAR_FIELDS_TO_SPY)),
    balanceSheetDate: date.isValid() ? date : null,
    balanceSheetCurrentCash,
    balanceSheetCurrentDebt,
    ebitda,
    financialNotes,
  };
}

/**
 * Return director data from action response.
 *
 * @param {object} response Action response.
 * @param {object} response.data Action response data.
 */
function getDirector({ data }) {
  const { id, userName: name } = data;

  return {
    id,
    name,
  };
}

function mutateDateFields(obj) {
  Object.keys(obj).forEach(key => {
    if (!obj) return;
    if (typeof obj[key] === 'object') {
      return mutateDateFields(obj[key]);
    }

    if (/^date$/.test(key) || /Date$/.test(key) || /Date[A-Z]/.test(key)) {
      obj[key] = moment(obj[key]);
    }
  });
}

function clearChangedFieldError(state, action) {
  const split = action.name.split('.');

  for (let i = 0; i < split.length; i++) {
    const pre = split.slice(0, i);
    const post = split.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  const split2 = action.name.replace(/\.pivot\./, '.').split('.');

  for (let i = 0; i < split2.length; i++) {
    const pre = split2.slice(0, i);
    const post = split2.slice(i);

    state = state.deleteIn([...pre, 'inputErrors', post.join('.')]);
  }

  return state;
}

function changeField(state, action) {
  action = trimWhiteSpaceIf(/contacts.\d+.emails.\d+.email/i, action);
  action = trimWhiteSpaceIf(/newData.emails.email/i, action);
  state = clearChangedFieldError(setIn(state, action.name, action.value), action);

  switch (action.name) {
    case 'suggestDirector':
      state = setIn(state, 'recordOwnerId', 0);
      break;

    case 'suggestAnalyst':
      state = setIn(state, 'recordSubOwnerId', 0);
      break;

    case 'suggestDealMaker':
      state = setIn(state, 'dealMakerId', 0);
      break;

    // This code works correctly only for the field "lastResearcherId"
    // (if you do not select some researcher from list of select options, old value will be set).
    // But this code breaks Target store (https://saritasa.atlassian.net/browse/HARV-783)
    // (FYI - same code works perfectly for Buyer store - src/reducers/company/buyer/marketing.js).
    // TODO: uncomment when it's fixed.
    // case 'suggestResearcher':
    //   state = setIn(state, 'lastResearcherId', 0);
    //   break;

    case 'balanceSheetCurrentCash':

    // fallthrough
    case 'balanceSheetCurrentDebt':

    // fallthrough
    case 'ebitda':
      state = setIn(state, action.name, action.value || null);
      break;

    // Do not add "default" for switch - it breaks store.
    // TODO: uncomment and add "default" when it's fixed.
    // (https://saritasa.atlassian.net/browse/HARV-789).

    default:
      break;
  }

  if (/^targetFiscalYears\./.test(action.name)) {
    if (!/fiscalYear$/.test(action.name)) {
      state = setIn(state, action.name, action.value || null);
    }
  } else if (/Year$/.test(action.name)) {
    state = setIn(state, action.name, action.value || null);
  } else if (/currentStatus$/i.test(action.name)) {
    // to properly change high status
    const highName = action.name.replace(/currentStatus$/, 'highStatus').replace(/CurrentStatus$/, 'HighStatus');
    const highOriginalValue = getOriginalIn(state, highName, 0);
    const max = Math.max(highOriginalValue, action.value);
    const highNextValue = parseFloat(highOriginalValue) === max ? highOriginalValue : action.value;

    state = setIn(state, highName, highNextValue);
  } else if (/\.age$/.test(action.name)) {
    const birthYear = getBirthYear(action.value);

    state = setIn(state, action.name.replace(/\.age$/, '.birthYear'), birthYear);
  }

  return state;
}

function getBuyers({ data }) {
  return fromJS(
    data.map((buyer, i) =>
      spy(
        {
          ...buyer,
          id: buyer.targetBuyerId,
          isOpened: i === 0,
          approveSuggests: [],
          projectId: buyer.projectId || null,
          approvalListId: buyer.approvalListId || null,
          projectSuggests: [],
        },
        BUYERS_FIELD_TO_SPY,
      ),
    ),
  ).sort(sortBuyers);
}

function getNDA({ data }) {
  const nda = {};

  data.events.some(event => {
    if (!event.completed) return false;
    if (!event.ndaTerm) return false;

    const months = event.ndaTerm;
    const start = moment(event.date);
    const end = start.clone().endOf('date');

    end.add(months, 'month');
    nda.isActive = !(end < moment());
    nda.end = end.format('MM/DD/YYYY');
    nda.start = start.format('MM/DD/YYYY');
    nda.duration = months > 1 ? `${months} months` : '1 month';
    nda.buyer = event['buyer.legalName'];
    nda.project = event['project.category'];
    nda.client = event.activity.substr(14, 7); // get 'Harvey' or 'Client'

    return true;
  });

  return {
    nda,
  };
}

function getNDAFromState(state, { data }) {
  try {
    data.events = data.events
      .filter(event => !event.deletedAt)
      .sort((a, b) => moment(b.date) - moment(a.date) || b.id - a.id);
  } catch (e) {
    console.error(e);
  }

  const nda = {};

  data.events.some(event => {
    if (!event.completed) return false;
    if (!event.ndaTerm) return false;

    const months = event.ndaTerm;
    const start = moment(event.date);
    const end = start.clone().endOf('date');

    end.add(months, 'month');
    nda.isActive = !(end < moment());
    nda.end = end.format('MM/DD/YYYY');
    nda.start = start.format('MM/DD/YYYY');
    nda.duration = months > 1 ? `${months} months` : '1 month';
    nda.buyer = event['buyer.legalName'];
    nda.project = event['project.category'];
    nda.client = event.activity.substr(14, 7); // get 'Harvey' or 'Client'

    return true;
  });

  return {
    nda,
  };
}

function getContacts({ data }) {
  const existings = {};

  return data.entityContacts.reduce((res, item) => {
    if (existings[item.id]) return res;

    const contact = { ...item };

    contact.prefix = prefixes.find(prefix => prefix.get('value') === contact.prefix, null, prefixes.get(0));
    contact.suffix = suffixes.find(
      suffix => suffix.get('value') === contact.suffix,
      null,
      fromJS({ name: contact.suffix, value: contact.suffix }),
    );

    contact.emails = contact.emails.map(email => spy(email, EMAIL_FIELDS_TO_SPY));
    contact.phones = contact.phones.map(phone => spy(phone, PHONE_FIELDS_TO_SPY));
    contact.age = getAge(contact.birthYear);
    contact.suggestions = [];

    existings[item.id] = item.id;
    res.push(spy(contact, CONTACT_FIELDS_TO_SPY));

    return res;
  }, []);
}

const emptyUser = {
  userName: '',
  email: '',
  id: null,
};

function getSuggests({ data }) {
  const {
    lastResearcherId,
    recordOwnerId,
    recordSubOwnerId,
    dealMakerId,
    dealMaker,
    directorUser,
    analystUser,
    researchUser,
  } = data;

  return {
    suggests: [],
    recordOwnerId,
    dealMakerId,
    recordOwnerEmail: (directorUser || emptyUser).email,
    recordSubOwnerId,
    lastResearcherId,
    suggestDirector: (directorUser || emptyUser).userName,
    suggestDirectorData: {
      id: (directorUser || emptyUser).id,
      name: (directorUser || emptyUser).userName,
    },
    suggestAnalyst: (analystUser || emptyUser).userName,
    suggestDealMaker: (dealMaker || emptyUser).userName,
    suggestResearcher: (researchUser || emptyUser).userName,
  };
}

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);
  let errors = {};

  if (!isValid) {
    errors = validator.getErrors(plainState);
  }

  const nBErrors = newBuyerValidator.getFirstError(plainState.newBuyer);
  const newBuyerInputErrors = Object.keys(nBErrors).reduce((res, key) => {
    res[`newBuyer.${key}`] = nBErrors[key];

    return res;
  }, {});

  const inputErrors = validator.getFirstError(plainState);

  const detailsValidity = Object.keys(ENTITY_VALIDATORS).reduce((res, key) => {
    res[`${key}IsValid`] = ENTITY_VALIDATORS[key].isValid(plainState[key]);
    if (res[`${key}IsValid`]) return res;

    const error = ENTITY_VALIDATORS[key].getFirstError(plainState[key]);
    const errorField = Object.keys(error)[0];

    if (/^\d\.facility$/i.test(errorField)) {
      inputErrors[`${key}.${errorField}`] =
        'Facility entry requires a descriptive title (i.e. Headquarters, Warehouse, etc.)';
    } else {
      inputErrors[`${key}.${errorField}`] = error[errorField];
    }

    return res;
  }, {});

  let tempState = state;

  state.getIn(['contacts']).forEach((contact, contactIndex) => {
    contact.get('phones').forEach((phone, phoneIndex) => {
      tempState = tempState.updateIn(['contacts', contactIndex, 'phones', phoneIndex], phone =>
        phone.merge({
          inputErrors: {},
          isValid: true,
        }),
      );
    });
  });

  tempState = tempState.mergeDeep({
    ...detailsValidity,
    newBuyerInputErrors,
    newBuyerIsValid: Object.keys(newBuyerInputErrors).length === 0,
    inputErrors,
    errors,
    isValid,
  });

  return tempState;
}

function delChannelFromContact(state, action) {
  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  if (contactIndex === undefined) return state;

  const channelIndex = state
    .getIn(['contacts', contactIndex, action.channelType])
    .findKey(channel => channel.get('id') === action.id);

  if (channelIndex === undefined) return state;

  return state.deleteIn(['contacts', contactIndex, action.channelType, channelIndex]);
}

function saveContactChannel(state, action) {
  let { channelIndex } = action;

  if (action.isNew) {
    channelIndex = state.getIn(['contacts', state.get('openedContact'), action.channelType]).size;

    const dummy = merge(defaultState.getIn(['newData', action.channelType]).set('id', action.response.id), {
      ...action.body,
      priority: 0,
      deletedAt: '',
      contactId: action.contactId,
    });

    state = state.setIn(['contacts', state.get('openedContact'), action.channelType, channelIndex], dummy);
  }

  const dataForMerge = {
    contacts: {
      [state.get('openedContact')]: {
        [action.channelType]: {
          [channelIndex]: action.body,
        },
      },
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newData', defaultState.get('newData'));
}

function saveContact(state, action) {
  if (action.isNew) return state.set('newContact', defaultState.get('newContact'));

  const contactIndex = state.get('contacts').findKey(contact => contact.get('id') === action.contactId);

  const dataForMerge = {
    contacts: {
      [contactIndex]: action.body,
    },
  };

  return mergeDeepToOriginal(state, dataForMerge).set('newContact', defaultState.get('newContact'));
}

function saveYear(state, action) {
  let yearIndex;

  if (action.isNew) {
    yearIndex = state.get('targetFiscalYears').size;

    const dummy = merge(defaultState.get('newYear').set('id', action.response.id), action.body);

    state = state.setIn(['targetFiscalYears', yearIndex], dummy);
  } else {
    yearIndex = state.get('targetFiscalYears').findKey(year => year.get('id') === action.yearId);
  }

  const dataForMerge = {
    targetFiscalYears: {
      [yearIndex]: action.body,
    },
  };

  return mergeDeepToOriginal(state, dataForMerge)
    .update('targetFiscalYears', years =>
      years.sort((a, b) => nonNumericYearSort(unwrap(a.get('fiscalYear')), unwrap(b.get('fiscalYear')))),
    )
    .set('newYear', defaultState.get('newYear'));
}

/**
 * Trim white-space for email address.
 */
function emailTrimming(action) {
  if (action.name === 'newContact.email' && action.value) {
    action.value = action.value.trim();
  }
}

function addSelectedTradeShow(state, action) {
  const currentTradeShowList = state.get('tradeShowTargets');
  const tradeShowEvent = action.response.data[0].event;
  const selectedTradeShow = Map({
    ...action.response.data[0],
    event: Map({
      ...tradeShowEvent,
      startDate: tradeShowEvent ? convertDateString(tradeShowEvent.startDate) : '',
    }),
    booth: '',
    year: tradeShowEvent ? `${tradeShowEvent.year} - ${tradeShowEvent.title}` : '',
  });

  return currentTradeShowList.set(-1, selectedTradeShow);
}

function removeTradeShow(state, action) {
  const currentTradeShowList = state.get('tradeShowTargets');

  return currentTradeShowList.filter((tradeShow, index) => index !== action.index);
}

function mapTradeShowList(action) {
  return action.response.data.map(tradeShow => {
    const { event } = tradeShow;

    return {
      ...tradeShow,
      name: tradeShow.showName,
      event: {
        ...event,
        startDate: event ? convertDateString(event.startDate) : '',
      },
      booth: tradeShow.pivot.booth,
      year: event ? `${event.year} - ${event.title}` : '',
    };
  });
}
