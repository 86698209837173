import PropTypes from 'prop-types';
import React, { Children, Component, PureComponent } from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';

/**
 * Table row. Can display default value if no key specified on this row.
 *
 * @param props {Object} You can put any extra props here. They will be put into `<tr>` props.
 * @param props.data {Immutable.Map} Row data. Use Immutable.Map.
 * @param props.fields {String[]} Array defines what values would be displayed and order of it.
 * @param props.defaultValues {String[]} Array with default values for cell. If cell try access
 *     the key is not present in `data`, cell will display value from here.
 * @private
 * @returns {React.Component}
 */
class TableRow extends Component {
  constructor(props, context) {
    super(props, context);

    if (props.shouldRowUpdate) {
      this.shouldUpdate = props.shouldRowUpdate;
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.data !== nextProps.data ||
      this.props.isEditing !== nextProps.isEditing ||
      this.props.checkAlso.some(field => nextProps[field] !== this.props[field])
    );
  }

  render() {
    const {
      data,
      fields,
      defaultValues,
      isEditing,
      index,
      shouldRowUpdate,
      checkAlso,
      onCellClick,
      ...rest
    } = this.props;
    const cells = fields.map((field, i) => {
      // If there is such field in data Map, use its value, else use default
      const fieldValue = data.getIn(field.name.split('.'), defaultValues[i]);
      const value = typeof field.valueWrapper === 'function' ? field.valueWrapper(fieldValue, data, index) : fieldValue;
      const cellProps = onCellClick
        ? {
            onClick: () => {
              onCellClick(field.name);
            },
          }
        : {};

      return (
        <td key={i} {...cellProps}>
          {value}
        </td>
      );
    });

    return <tr {...rest}>{cells}</tr>;
  }
}

TableRow.defaultProps = {
  isEditing: false,
  checkAlso: [],
};

TableRow.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditing: PropTypes.bool,
};

/**
 * An Column component. Has no real render. It is using to definition of table's columns.
 *
 * @param props {Object} You can put any extra props here. They will be put into `<th>` props.
 * @param props.title {*} The title of column. It should be the string usual, but you can pass React.Component.
 * @param props.field {String} Field name, there component should get data for Row for this column.
 * @param [props.defaultValue=""] {String} Used as fallback. If no such field specified in Row, display this value.
 * @class
 */
const Column = () => null;

Column.propTypes = {
  defaultValue: PropTypes.string,
  field: PropTypes.string.isRequired,
  title: PropTypes.any.isRequired,
  valueWrapper: PropTypes.func,
};

class RealColumn extends PureComponent {
  render() {
    const { title, field, valueWrapper, ...rest } = this.props;

    return <th {...rest}>{title}</th>;
  }
}

/**
 * An Row component. Has no real render. It is using to definition of table's rows.
 *
 * @param props {Object} You can put any extra props here. They will be put into `<tr>` props.
 * @param props.data {Immutable.Map} Row data. Use Immutable.Map for provide values for table.
 * @class
 */
const Row = () => {};

Row.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  onDoubleClick: PropTypes.func,
};

/**
 * Table component. It is used to define table without write
 * table boilerplate (map values into cells and columns).
 * Just define Columns, and Rows as Table's children.
 * If no Rows specified as a children - display 1 table row with "No rows to display" content.
 * **Warn** You should define all columns before rows.
 *
 * @link http://screencloud.net/v/yv6w component from example (see bottom)
 * @param props {Object}.
 * @param [props.className] {String} Extra class names for `<table className="table table-bordered"></table>`.
 * @example
 *     import Table, { Column, Row } from '../helpers/Table'
 *     ...
 *     <Table>
 *       <Column title="My column" field="field1"/>
 *       <Column title="My column 2" field="field2"/>
 *       <Column title="My column 3" field="field3" defaultValue="DEFAULT"/>
 *       <Row data={Immutable.fromJS({field1:"1_1", field2: "1_2", field3: "1_3"})}/>
 *       <Row data={Immutable.fromJS({field1:"2_1", field3: "2_3"})}/>
 *       <Row data={Immutable.fromJS({field1:"3_1", field2: "3_2"})}/>
 *     </Table>
 * @returns {React.Component}
 */
class Table extends PureComponent {
  render() {
    const {
      children,
      className = 'table table-bordered',
      bodyClassName,
      loading,
      shouldRowUpdate,
      ...rest
    } = this.props;
    const defaultValues = [];
    const fields = [];
    const columns = [];
    const rows = [];
    let spinner = null;

    Children.forEach(children, (child, i) => {
      switch (child.type) {
        case Column: {
          const {
            props: { field, defaultValue = '', valueWrapper },
          } = child;

          fields.push({ name: field, valueWrapper });

          defaultValues.push(defaultValue);

          return columns.push(<RealColumn {...child.props} key={i} />);
        }

        case Row: {
          const {
            props: { children, ...rest },
          } = child;

          return rows.push(
            <TableRow
              {...rest}
              key={i}
              defaultValues={defaultValues}
              fields={fields}
              index={rows.length}
              shouldRowUpdate={shouldRowUpdate}
            >
              {children}
            </TableRow>,
          );
        }

        default:
          break;
      }
    });

    let isEmpty = false;

    if (rows.length === 0) {
      isEmpty = true;
      rows.push(
        <tr key="noRows" className="empty-row">
          <td className="text-center" colSpan={fields.length}>
            No rows to display
          </td>
        </tr>,
      );
    }

    if (loading) {
      spinner = (
        <tr className="loading-row">
          <td colSpan={fields.length}>
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
          </td>
        </tr>
      );
    }

    return (
      <table {...rest} className={`table ${classNames(className, { 'empty-table': isEmpty })}`}>
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody className={bodyClassName}>
          {rows}
          {spinner}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export { Column, Row };
export default Table;
