import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Table, { Column, Row } from '../helpers/Table';

/**
 * Stateless component for source companies used for merge.
 *
 * @param {object} props Props.
 * @param {List} props.companies Companies list.
 */
const CompanySourcesComponent = ({ companies }) => {
  /** Table rows. */
  const rows = companies.map(company => <Row key={company.get('id')} data={company} />);

  /** Companies cell value wrapper. */
  const handleWrapper = () => (_fieldValue, data) => (
    <Link target="_blank" to={`/company/${data.get('id')}`}>
      {data.get('legalName')}
    </Link>
  );

  return (
    <div className="highlight no-padding wrap-border-full sources-table-container mb0">
      <Table bodyClassName="sources-table__body" className="sources-table table-striped table-hover">
        <Column field="id" title="Source Companies Used To Merge" valueWrapper={handleWrapper()} />
        {rows}
      </Table>
    </div>
  );
};

CompanySourcesComponent.propTypes = {
  companies: PropTypes.instanceOf(List).isRequired,
};

export const CompanySources = memo(CompanySourcesComponent);
