import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Map, fromJS } from 'immutable';

import { BrowseAutoComplete } from './BrowseAutoComplete';
import { BrowseCheckbox } from './BrowseCheckbox';
import { Tag } from './Tag';

const BrowseAutoCompleteTagComponent = ({
  filter,
  info,
  noCheckbox,
  onChange,
  onGetNextSuggestion,
  onGetSuggestion,
}) => {
  const [focusOut, setFocusOut] = useState(false);
  const buttonRef = useRef();

  useEffect(() => {
    buttonRef.current && buttonRef.current.focus();
    setFocusOut(false);
  }, [focusOut]);

  const handleChange = ({ filterName, filterData }) => {
    if (filterData.selected) {
      let selectedList = info.get('selectedList');
      const selected = fromJS(filterData.selected);
      const field = selected.has('id') ? 'id' : 'value';

      if (selectedList.findIndex(s => s.get(field) === selected.get(field)) === -1) {
        selectedList = selectedList.push(selected.set('include', true));
        filterData.selectedList = selectedList;
      }
      setFocusOut(true);
    }
    onChange({
      filterName,
      filterData,
    });
  };

  const handleRemoveTag = event => {
    event.stopPropagation();

    const { name } = event.target;
    const tagIndex = parseInt(name.replace('tag', ''), 10);
    let selectedList = info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  };

  const handleChangeStatus = index => {
    const selectedList = info.get('selectedList');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList: selectedList.setIn([index, 'exclude'], !selectedList.getIn([index, 'exclude'])),
      },
    });
  };

  const handleAddTag = () => {
    if (!filter.get('enableDynamicAdd', false) || info.get('text', '') === '') return false;

    let selectedList = info.get('selectedList');
    const selected = fromJS({
      value: info.get('text'),
      name: info.get('text'),
    });

    if (selectedList.findIndex(s => s.get('value') === selected.get('value')) === -1) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: filter.get('name'),
        filterData: {
          text: '',
          selectedList,
        },
      });
    }
  };

  const renderFilterCheckboxes = () => {
    if (!filter.get('filters')) return null;

    const filters = filter.get('filters').map(filter => (
      <label key={filter.get('name')} className="BrowseControl-label">
        <input
          onChange={event => {
            onChange({
              filterName: event.currentTarget.value,
              filterData: event.currentTarget.checked,
            });
          }}
          type="checkbox"
          value={filter.get('name')}
        />
        {filter.get('title')}
      </label>
    ));

    return <div className="form-inline">{filters}</div>;
  };

  const selectedList = info.get('selectedList');
  const tagListContent = selectedList
    .filter(tag => tag)
    .map((tag, i) => (
      <Tag
        key={i}
        exclude={tag.get('exclude')}
        index={i}
        onChangeStatus={handleChangeStatus}
        onRemoveTag={handleRemoveTag}
        value={tag.get(filter.get('renderField'))}
      />
    ));

  const autocomplete = (
    <>
      {tagListContent}
      <div className="form-inline">
        <BrowseAutoComplete
          filter={filter}
          info={info}
          onChange={handleChange}
          onGetNextSuggestion={onGetNextSuggestion}
          onGetSuggestion={onGetSuggestion}
          highlightFirstSuggestion
        >
          <span ref={buttonRef} className="input-group-addon" onClick={handleAddTag} tabIndex="-1">
            <i aria-hidden="true" className="fa fa-plus" />
          </span>
        </BrowseAutoComplete>
      </div>
      {renderFilterCheckboxes()}
    </>
  );

  if (noCheckbox) {
    return autocomplete;
  }

  return (
    <BrowseCheckbox filter={filter} info={info} onChange={onChange}>
      <div className="BrowseControl-content BrowseControl-content--add">{autocomplete}</div>
    </BrowseCheckbox>
  );
};

BrowseAutoCompleteTagComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetSuggestion: PropTypes.func.isRequired,
};

/** Browse auto complete tag component. */
export const BrowseAutoCompleteTag = memo(BrowseAutoCompleteTagComponent);
