import React, { memo } from 'react';
import { fromJS } from 'immutable';

import FormGroup from '../../helpers/FormGroup';
import Select from '../../helpers/Select';
import { PhoneInput } from './PhoneInput';

const options = fromJS([
  { value: 'Direct', name: 'Direct' },
  { value: 'Extension', name: 'Extension' },
  { value: 'Mobile', name: 'Mobile' },
  { value: 'Home', name: 'Home' },
  { value: 'Fax', name: 'Fax' },
  { value: 'International', name: 'International' },
  { value: 'Custom', name: 'Custom' },
]);

const OtherFormatComponent = ({ onChange, contact, onBlur, phoneError, onErrorClose }) => (
  <div className="row">
    <FormGroup className="col-lg-6 col-sm-6">
      <PhoneInput
        contact={contact}
        error={phoneError}
        onBlur={onBlur}
        onChange={onChange}
        onErrorClose={onErrorClose}
      />
    </FormGroup>
    <FormGroup className="col-lg-6 col-sm-6">
      <Select
        className="input-md"
        label="Type"
        name="type"
        onChange={onChange}
        options={options}
        placeholder="Type"
        root={contact}
        deep
        showLabel
      />
    </FormGroup>
  </div>
);

export const OtherFormat = memo(OtherFormatComponent);
