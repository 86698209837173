import React, { memo, useEffect, useRef, useState } from 'react';

import If from '../helpers/If';
import { unwrap } from '../../utils/ChangeSpy';
import { CompanyTargetFixedPanel } from './CompanyTargetFixedPanel';
import Status from './CompanyStatus';
import { CompanyDetails } from './CompanyDetails';
import { CompanyDealNotes } from './CompanyDealNotes';
import { CompanyEmailPanel } from './CompanyEmailPanel';
import { CompanyFilesPanel } from './CompanyFilesPanel';
import Contacts from './CompanyContacts';
import { CompanyFinancials } from './CompanyFinancials';
import { CompanySubsidiaries } from './CompanySubsidiaries';
import { CompanyBuyers } from './CompanyBuyers';

const CompanyTargetComponent = ({
  companyId,
  hasPermissions,
  isUnapproved,
  statuses,
  dealNotes,
  isClosed,
  onEnterDealNotes,
  onLeaveDealNotes,
  emails,
  onEnterEmail,
  onLeaveEmail,
  contactsProps,
  uploadFile,
  files,
  suggestDirector,
  suggestAnalyst,
  suggestDealMaker,
  suggestResearcher,
  companyInfo,
  onChange,
  onLeadMemoReport,
  isLoadingLeadMemoReport,
  onToggle,
  onUpdateTags,
  onUpdateHighStatus,
  onErrorClose,
  details,
  detailsCallbacks,
  tradeShowSuggestCreate,
  industryCategories,
  businessModels,
  financialsProps,
  error,
  subsidiariesProps,
  showInstruction,
  onInstructionClick,
  onDescriptionBlur,
  buyersProps,
  currentUser,
  findDirectors,
  fetchEmailTemplates,
  emailTemplates,
  lastEventBuyerText,
  onDeleteFile,
  isDuplicateCompany,
  dealNoteSize,
  isOwnerResearcher,
  roleIsResearcher,
  onEditEvent,
}) => {
  const [canEditData, setCanEditData] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [dealNotesDimensions, setDealNotesDimensions] = useState(dealNoteSize);
  const bottomPanelRef = useRef(null);
  const mainPanelRef = useRef(null);
  const topPanelRef = useRef(null);

  const buyers = companyInfo.get('buyers');

  const getHeight = elm => {
    let elmHeight;
    let elmMargin;

    if (document.all) {
      // IE
      elmHeight = parseInt(elm.currentStyle.height, 10);
      elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {
      // Mozilla
      elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
      elmMargin =
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) +
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
    }

    return elmHeight + elmMargin;
  };

  const setHeight = () => {
    const height = getHeight(mainPanelRef.current) - getHeight(topPanelRef.current) - 20;

    bottomPanelRef.current.setAttribute('style', `height: ${height}px;`);
  };

  const updateCanEditData = () => {
    const currentStatus = Number(unwrap(companyInfo.getIn(['buyers', 0, 'currentStatus'])));

    if (isDuplicateCompany) {
      setCanEditData(false);

      return;
    }

    if (!(!roleIsResearcher && isUnapproved)) {
      if (currentStatus < 2 || isOwnerResearcher || hasPermissions) {
        setCanEditData(true);
      }
    } else if (canEditData) {
      setCanEditData(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setHeight);

    if (buyers) {
      updateCanEditData();
    }
    setDealNotesDimensions(prevState => ({
      ...prevState,
      height: bottomPanelRef.current.offsetHeight,
    }));

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  useEffect(() => {
    setHeight();

    const status = Number(unwrap(companyInfo.getIn(['buyers', 0, 'currentStatus'])));

    // To avoid comparison NaN with NaN below.
    if (isNaN(status)) {
      return;
    }
    setCurrentStatus(status);
    updateCanEditData();
  }, [companyInfo, isUnapproved, buyers]);

  useEffect(() => {
    if (
      dealNotesDimensions.hasOwnProperty('height') &&
      dealNotesDimensions.height !== bottomPanelRef.current.offsetHeight
    ) {
      setDealNotesDimensions(prevState => ({
        ...prevState,
        height: bottomPanelRef.current.offsetHeight,
      }));
    }
  }, [dealNotesDimensions, bottomPanelRef]);

  return (
    <div ref={mainPanelRef} className="tab-pane tab-target active full-height" id="slide-1" onChange={onChange}>
      <Status
        canEditData={canEditData}
        companyId={companyId}
        companyInfo={companyInfo}
        currentStatus={currentStatus}
        currentUser={currentUser}
        hasPermissions={hasPermissions}
        isDuplicateCompany={isDuplicateCompany}
        isLoadingLeadMemoReport={isLoadingLeadMemoReport}
        isUnapproved={isUnapproved}
        lastEventBuyerText={lastEventBuyerText}
        onChange={onChange}
        onLeadMemoReport={onLeadMemoReport}
        onRef={topPanelRef}
        statuses={statuses}
        suggestAnalyst={suggestAnalyst}
        suggestDealMaker={suggestDealMaker}
        suggestDirector={suggestDirector}
      />
      <div ref={bottomPanelRef} className="mt10">
        <If value={hasPermissions}>
          <CompanyDealNotes
            dealNotes={dealNotes}
            dealNoteSize={dealNotesDimensions}
            isClosed={isClosed}
            onChange={onChange}
            onEditEvent={onEditEvent}
            onMouseEnter={onEnterDealNotes}
            onMouseLeave={onLeaveDealNotes}
            onToggle={onToggle}
          />
        </If>
        <div className="container-fluid w100p full-height">
          <div className="row flex-row">
            <div className="col-xs-12 col-sm-12 col-md-7 flexWrapper company-target-left-column">
              <div className="panel-group accordion-middle-side company-target-accordion flex-through" id="accordion">
                <CompanyTargetFixedPanel
                  canEditData={canEditData}
                  companyInfo={companyInfo}
                  currentUser={currentUser}
                  onChange={onChange}
                  onClick={onInstructionClick}
                  onDescriptionBlur={onDescriptionBlur}
                  showInstruction={showInstruction}
                  suggestResearcher={suggestResearcher}
                />

                <CompanyDetails
                  {...detailsCallbacks}
                  businessModels={businessModels}
                  canEditData={canEditData}
                  details={details}
                  error={error}
                  hasPermissions={hasPermissions}
                  industryCategories={industryCategories}
                  isDuplicateCompany={isDuplicateCompany}
                  onErrorClose={onErrorClose}
                  onUpdateTags={onUpdateTags}
                  suggestCreate={tradeShowSuggestCreate}
                />

                <If value={hasPermissions}>
                  <CompanyFinancials {...financialsProps} canEditData={canEditData} />
                </If>

                <CompanySubsidiaries {...subsidiariesProps} disabled={isDuplicateCompany} />

                <If value={hasPermissions}>
                  <CompanyEmailPanel
                    emails={emails}
                    onEditEvent={onEditEvent}
                    onMouseEnter={onEnterEmail}
                    onMouseLeave={onLeaveEmail}
                  />
                </If>

                <If value={hasPermissions}>
                  <CompanyFilesPanel
                    canEditData={canEditData}
                    files={files}
                    onDeleteFile={onDeleteFile}
                    uploadFile={uploadFile}
                  />
                </If>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5 full-height">
              <div className="right-siderbar container-fluid full-height flexWrapper">
                <div className="row">
                  <Contacts
                    {...contactsProps}
                    canEditData={!isDuplicateCompany}
                    companyInfo={companyInfo}
                    emailTemplates={emailTemplates}
                    fetchEmailTemplates={fetchEmailTemplates}
                    findDirectors={findDirectors}
                    hasPermissions={hasPermissions}
                  />
                </div>
                <If value={hasPermissions && statuses}>
                  <div className="row">
                    <div className="flexItem">
                      <CompanyBuyers
                        statuses={statuses}
                        {...buyersProps}
                        canEditData={canEditData}
                        onUpdateHighStatus={onUpdateHighStatus}
                      />
                    </div>
                  </div>
                </If>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/** Company target component. */
export const CompanyTarget = memo(CompanyTargetComponent);
