import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import Popups from '../../components/Popup';
import { dateToShortString } from '../../utils/dateFormat';

const PushNextActionPopup = ({ popup }, context) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const idList = popup.getIn(['props', 'idList']);
  const afterSuccess = popup.getIn(['props', 'afterSuccess']);
  const afterError = popup.getIn(['props', 'afterError']);
  const onUpdate = popup.getIn(['props', 'onUpdate']);
  const formData = popup.get('formData');

  const handleClose = () => {
    context.closePopup();
  };

  const handleActivityChange = data => {
    context.updatePopupFormData({
      activity: data,
    });
  };

  const handleDateChange = ({ target }) => {
    if (target.value.isValid()) {
      context.updatePopupFormData({
        [target.name]: target.value,
      });
    }
  };

  const isFormValid = () => {
    const date = popup.getIn(['formData', 'date']);
    const error = [];

    if (!date) {
      error.push('Date field is required.');
    }
    context.updatePopupFormData({ formError: List(error) });

    return error.length === 0;
  };

  const handleUpdate = () => {
    if (isFormValid()) {
      setSubmitting(true);

      const date = popup.getIn(['formData', 'date']);
      const activity = popup.getIn(['formData', 'activity', 'text']);
      const params = {
        selected_ids: idList.toJS(),
        date: dateToShortString(new Date(date)),
        activity,
      };

      onUpdate(
        params,
        () => {
          setSubmitting(false);
          afterSuccess();
        },
        () => {
          setSubmitting(false);
          afterError();
        },
      );
    }
  };

  return (
    <Popups.PushNextActionPopup
      formData={formData}
      formError={formData.get('formError')}
      idList={idList}
      isSubmitting={isSubmitting}
      onActivityChange={handleActivityChange}
      onClose={handleClose}
      onDateChange={handleDateChange}
      onUpdate={handleUpdate}
    />
  );
};

PushNextActionPopup.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...props,
  };
}

export { PushNextActionPopup };
export default connect(mapStateToProps, {}, mergeProps, connectOptions)(PushNextActionPopup);
