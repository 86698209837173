import React, { Component } from 'react';
import classNames from 'classnames';
/**
 * Appends loading animation to component.
 *
 * @param Comp {React.Component} Component has loading state.
 * @param [loadFieldName='loading'] {String} Name of loading field.
 */
export default function LoadAnimationWrapper(Comp, loadFieldName = 'loading', extraClassName, isButton = false) {
  const compName = Comp.displayName || Comp.name;

  class Wrapped extends Component {
    render() {
      const { props } = this;
      const isLoading = props[loadFieldName] || false;

      const loadContent = isLoading ? (
        <div>
          <div className="loading" />
          <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
        </div>
      ) : null;

      if (isButton) {
        return (
          <Comp {...props} disabled={isLoading}>
            {isLoading && <i className="fa fa-spinner fa-spin" style={{ position: 'static' }} />} {props.children}
          </Comp>
        );
      }

      return (
        <div className={classNames('load-wrapper full-height', extraClassName)}>
          {loadContent}
          <Comp {...props} />
        </div>
      );
    }
  }

  Wrapped.displayName = `LoadAnimated$${compName}`;

  return Wrapped;
}
