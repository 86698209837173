import React, { memo } from 'react';

import Checkbox from '../../../helpers/Checkbox';

const CriteriaCheckboxComponent = ({ buyer: root, label, name, onChange, disabled }) => (
  <div className="form-group group-inline b-checkbox ib">
    <Checkbox deep="checked" disabled={disabled} label={label} name={name} onChange={onChange} root={root} />
  </div>
);

/** Criteria checkbox component. */
export const CriteriaCheckbox = memo(CriteriaCheckboxComponent);
