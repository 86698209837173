import PropTypes from 'prop-types';
import React, { memo } from 'react';

/**
 * Stateless component about next action page container.
 *
 * @param props {Object}.
 */
const ContainerPanelComponent = ({ children }) => (
  <div className="container" role="main">
    <div className="row">
      <div className="col-xs-12 wrap-next-actions">{children}</div>
    </div>
  </div>
);

ContainerPanelComponent.propTypes = {
  children: PropTypes.element,
};

export const ContainerPanel = memo(ContainerPanelComponent);
