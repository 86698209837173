import React, { useEffect, useRef, useCallback, memo } from 'react';
import $ from 'jquery';

/**
 * Tooltip component for showing error messages.
 */
const TooltipComponent = ({ name, content, children }) => {
  const spanRef = useRef(null);
  const tooltipId = name.replace(/\./g, '_');

  const createElement = useCallback(
    text => {
      const div = $('<div>')
        .attr('id', tooltipId)
        .attr('class', 'alert alert-danger alert-dismissible input-custom-alert hide')
        .on('click', event => {
          event.stopPropagation();

          return false;
        });

      div.html(
        `<span class="text">${text}</span><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>`,
      );
      div.find('button').on('click', function(event) {
        event.stopPropagation();
        $(this)
          .parent()
          .remove();
      });
      $('body').append(div);
    },
    [tooltipId],
  );

  const hideElement = useCallback(() => {
    $(`#${tooltipId}`).remove();
  }, [tooltipId]);

  const updatePosition = useCallback(() => {
    if (spanRef.current) {
      const rect = spanRef.current.getBoundingClientRect();

      $(`#${tooltipId}`)
        .css({
          position: 'absolute',
          top: `${rect.top + rect.height}px`,
          left: `${rect.left}px`,
        })
        .removeClass('hide');
    }
  }, [tooltipId]);

  const showElement = useCallback(
    text => {
      createElement(text);
      updatePosition();
    },
    [createElement, updatePosition],
  );

  const shouldShowTooltip = useCallback(
    tooltipContent => {
      if (tooltipContent) {
        showElement(tooltipContent);
      } else {
        hideElement();
      }
    },
    [showElement, hideElement],
  );

  useEffect(() => {
    $(document).on('click', hideElement);

    let token;

    if (window.__emitter__) {
      token = window.__emitter__.addListener('invokeErrorTooltip', arg => {
        const tooltipContent = (arg || {})[name];

        shouldShowTooltip(tooltipContent);
      });
    }

    shouldShowTooltip(content);

    return () => {
      $(document).off('click', hideElement);
      if (token) {
        token.remove();
      }
    };
  }, [name, content, shouldShowTooltip, hideElement]);

  return (
    <span ref={spanRef} data-toggle="tooltip">
      {children}
    </span>
  );
};

export const Tooltip = memo(TooltipComponent);
