import React, { memo } from 'react';
import { fromJS } from 'immutable';

import _Table from '../../../../helpers/AgGridTable';
import { unwrap } from '../../../../../utils/ChangeSpy';
import PrettyError from '../../../../decorators/PrettyError';
import DisplayError from '../../../../decorators/DisplayError';
import AgHeader from './AgHeader';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../../../../utils/industryTagsHelper';

const Table = PrettyError(DisplayError(_Table));

/**
 * Table industries.
 */
const IndustriesComponent = ({ isFetching, tags, onErrorClose, onUpdateTags }) => {
  const businessModels = unwrap(tags.get(BUSINESS_MODELS));
  const industryCategories = unwrap(tags.get(INDUSTRY_CATEGORIES));
  const data = fromJS([...businessModels, ...industryCategories]);

  const columnDefs = fromJS([
    {
      headerName: 'Industries',
      field: 'category',
      width: 150,
      headerComponentFramework: AgHeader,
      onClick: onUpdateTags,
    },
  ]);

  const onGridReady = params => {
    const { api } = params;

    api.sizeColumnsToFit();
  };

  const getRowNodeId = data => data.id;

  return (
    <div className="col-md-2 col-industries full-height">
      <div className="table-edit full-height">
        <Table
          columnDefs={columnDefs}
          error={tags.get('validationErrors')}
          getRowNodeId={getRowNodeId}
          isFetching={isFetching}
          name="tags"
          onErrorClose={onErrorClose}
          onGridReady={onGridReady}
          rowData={data}
          style={{ height: '177px' }}
          canDisplayError
          enableSorting
        />
      </div>
    </div>
  );
};

/** Industries component. */
export const Industries = memo(IndustriesComponent);
