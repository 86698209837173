import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { List, Map } from 'immutable';

import Tabs from '../helpers/Tabs';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import UrlMaker from '../../routing/UrlMaker';
import { getViewport } from '../../utils/viewPort';
import { isResearcher } from '../../utils/checkPermissions';
import If from '../helpers/If';
import CompanyRouting from '../../routing/CompanyRouting';
import CompanyActivities from './CompanyActivities';
import { CompanySources } from './CompanySources';
import { CompanyInfo } from './CompanyInfo';

/**
 * Company page component.
 *
 * @param props {Object}.
 * @param props.targetCompany {Immutable.Map} Map with company information.
 * @param props.onCompanyInfoChange {Function} Handle company info change event.
 * @param props.onToggleDBA {Function} Handle toggle dba input event.
 * @param props.onToggleCountry {Function} Handle toggle country input event.
 * @param {object} props.companyMergeInfoProps Props for merged sources table and approve merge.
 */
class Company extends PureComponent {
  constructor(props) {
    super(props);
    this.setHeight = this.setHeight.bind(this);
    this.bodyPanelHeight = 0;
  }

  setHeight() {
    const w = getViewport()[0];
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const height = vh - this.getHeight(this.topPanel) - this.getHeight(this.footerPanel) - (w < 1280 ? 18 : 0);

    if (height !== this.bodyPanelHeight) {
      this.bodyPanelHeight = height;
      this.bodyPanel.setAttribute('style', `height: ${this.bodyPanelHeight}px`);
      if (this.props.onHeightChange) {
        this.props.onHeightChange(height);
      }
    }
  }

  getHeight(elm) {
    let elmHeight;
    let elmMargin;

    if (document.all) {
      // IE
      elmHeight = parseInt(elm.currentStyle.height, 10);
      elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {
      // Mozilla
      elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
      elmMargin =
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) +
        parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
    }

    return elmHeight + elmMargin;
  }

  componentDidMount() {
    window.addEventListener('resize', this.setHeight);
  }

  componentDidUpdate() {
    this.setHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight);
  }

  getTabs(tabs, params, hasPermissions) {
    let tabsContent = tabs.map(tab => {
      let to = UrlMaker.create(tab.get('to')).mapParams(params);

      if (tab.get('name') === 'TRADE GROUPS') {
        to = tab.get('to');
      }

      return tab.set('to', to);
    });

    if (!hasPermissions) {
      tabsContent = tabsContent.filter(tab => {
        const tabValue = tab.get('key');

        return tabValue === 'target' || tabValue === 'buyer';
      });
    }

    return tabsContent;
  }

  getMenuItems(values, footer, hasPermissions) {
    const items = values.get('footerDropDown').reduce((acc, elem) => {
      if (!hasPermissions && ['buyer', 'exec', 'lp', 'del'].includes(elem.get('key'))) {
        return acc;
      }

      const attributes = elem.get('attributes').toJS();

      acc.push(
        <DropDownElement {...attributes} key={elem.get('key')} onClick={footer[elem.get('onClickField')]}>
          {elem.get('name')}
        </DropDownElement>,
      );

      return acc;
    }, []);

    return items;
  }

  render() {
    const {
      hasPermissions,
      canApproveTarget,
      canEditData,
      tabs,
      values,
      availableTabs,
      targetCompany,
      suggestionProps,
      companyInfoProps,
      companyEventProps,
      companyMergeInfoProps,
      countrySuggestion,
      footer,
      params,
      onClick,
      onErrorClose,
      onBlur,
      businessModels,
      error,
    } = this.props;

    const { companyId } = params;
    const isUnapproved = canApproveTarget && !this.props.companyInfo.get('isApproved');
    const footerDropDown = this.getMenuItems(values, footer, hasPermissions);
    const tabsContent = this.getTabs(tabs, { companyId }, hasPermissions);
    const saveButtonClassName = classNames('btn btn-default btn-xs');
    const role = this.context.currentUser.getIn(['roles', 0, 'slug'], null);
    const roleIsResearcher = isResearcher(role);

    return (
      <div className="full-height hidden-flow-y">
        <div
          ref={input => {
            this.topPanel = input;
          }}
          className="top_side"
        >
          <div className="container">
            <div className="row table-dates-flex">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                <CompanyInfo
                  businessModels={businessModels}
                  canEditData={canEditData}
                  companyInfoProps={companyInfoProps}
                  countrySuggestion={countrySuggestion}
                  error={error}
                  onBlur={onBlur}
                  onErrorClose={onErrorClose}
                  suggestionProps={suggestionProps}
                  targetCompany={targetCompany.info}
                />
              </div>
              <If value={hasPermissions}>
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-6 col-xxl-6 flexWrapper">
                  <div className="highlight no-padding wrap-border-full table-responsive contact-activity__container flexWrapper mb0">
                    <CompanyActivities
                      {...companyEventProps}
                      isDuplicateCompany={companyMergeInfoProps.isDuplicateCompany}
                      onAddEvent={this.context.onAddEvent}
                      onDeleteEvent={this.context.onDeleteEvent}
                      onEditEvent={this.context.onEditEvent}
                    />
                  </div>
                </div>
              </If>
              <If value={companyMergeInfoProps.isNotApprovedMerge === true}>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mr5">
                  <div className="table-responsive ml5">
                    <CompanySources companies={companyMergeInfoProps.sourceCompanies} />
                  </div>
                </div>
              </If>
            </div>
          </div>
        </div>

        <div
          ref={input => {
            this.bodyPanel = input;
          }}
          className="container tabsContainer"
          role="main"
        >
          <div className="row full-height flexItem flexWrapper">
            <div className="full-height flexWrapper flexItem">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="highlight wrap-border">
                  <div className="alert-text">
                    <If value={isUnapproved && roleIsResearcher}>
                      <div>COMPANY IS NOT APPROVED</div>
                    </If>
                    <If value={isUnapproved && !roleIsResearcher}>
                      <div>COMPANY IS NOT APPROVED (not editable, until the confirmation)</div>
                    </If>
                    <If value={companyMergeInfoProps.isNotApprovedMerge === true}>
                      <div>COMPANY IS A RESULT OF MERGE</div>
                    </If>
                    <If value={companyMergeInfoProps.isDuplicateCompany === true}>
                      <div className="merged-company-note">
                        COMPANY IS A DUPLICATE AND WAS MERGED. MERGED RESULT -&nbsp;
                        <Link target="_blank" to={`/company/${companyMergeInfoProps.mergeResultCompanyId}`}>
                          New company result of merge
                        </Link>
                      </div>
                    </If>
                  </div>
                  <Tabs availableTabs={availableTabs} onClick={onClick} tabs={tabsContent} />
                </div>
                <div className="clearfix" />
              </div>
              <div className="col-md-12 tab-content top-border-none mt10 tabContainer flexItem flexWrapper no-overflow">
                <CompanyRouting />
              </div>
            </div>
          </div>
        </div>

        <footer
          ref={input => {
            this.footerPanel = input;
          }}
          className="navbar-fixed-bottom"
        >
          <div className="container">
            <div className="row">
              <div className="container-fluid text-right">
                <div className="btns">
                  <If value={isUnapproved}>
                    <button className="btn btn-default btn-xs" onClick={footer.onApproveTarget} type="button">
                      Confirm
                    </button>
                  </If>

                  <If value={companyMergeInfoProps.isNotApprovedMerge === true && !roleIsResearcher}>
                    <button
                      className="btn btn-default btn-xs"
                      onClick={companyMergeInfoProps.onConfirmMergeRevert}
                      type="button"
                    >
                      Merge Revert
                    </button>
                    <button
                      className="btn btn-default btn-xs"
                      onClick={companyMergeInfoProps.onConfirmMerging}
                      type="button"
                    >
                      Merge Confirm
                    </button>
                  </If>

                  <DropDown
                    align="right"
                    btnContent="New"
                    className="btn-default btn-xs"
                    id="dropdownMenu7"
                    wrapperClassName="ib"
                  >
                    {footerDropDown}
                  </DropDown>

                  <If value={!footer.hideSave && companyMergeInfoProps.isDuplicateCompany !== true}>
                    <button
                      className={saveButtonClassName}
                      disabled={footer.isDisabledOnSave}
                      onClick={footer.onSave}
                      type="submit"
                    >
                      <i className="icon-user icon-white" />
                      Save
                    </button>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

Company.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  children: PropTypes.element,
  companyMergeInfoProps: PropTypes.shape({
    isDuplicateCompany: PropTypes.bool.isRequired,
    mergeResultCompanyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isNotApprovedMerge: PropTypes.bool.isRequired,
    sourceCompanies: PropTypes.instanceOf(List).isRequired,
    onConfirmMerging: PropTypes.func.isRequired,
    onConfirmMergeRevert: PropTypes.func.isRequired,
  }),
  hasPermissions: PropTypes.bool.isRequired,
  params: PropTypes.shape({ companyId: PropTypes.string.isRequired }),
  tabs: PropTypes.instanceOf(List).isRequired,
  values: PropTypes.instanceOf(Map).isRequired,
};

Company.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  onAddEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  onEditEvent: PropTypes.func.isRequired,
};

export default Company;
