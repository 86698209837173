import PropTypes from 'prop-types';
import React, { memo } from 'react';

/**
 * Stateless component for result counter.
 *
 * @param props {Object}.
 * @param props.total {number} Total result row.
 * @param props.showing {number} Showing result row.
 * @returns {React.Component}
 */
const CounterComponent = ({ total, showing }) => (
  <p className="next-actions-filters__counter">
    <span>
      {total} total - showing {showing}
    </span>
  </p>
);

CounterComponent.propTypes = {
  showing: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export const Counter = memo(CounterComponent);
