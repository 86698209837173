import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Immutable from 'immutable';
import { push } from 'connected-react18-router';

import config from '../config';
import { showError } from '../utils/MessagePopup';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { Browse } from '../components/Browse';
import { isResearcher } from '../utils/checkPermissions';
import BrowseRouting from '../routing/BrowseRouting';

const tabs = config.tabs.get('browse');

const BrowseContainer = ({ tabs, push }, context) => {
  const hasAccess = () => {
    if (!context.currentUser) return false;

    return !isResearcher(context.currentUser.getIn(['roles', 0, 'slug'], null));
  };

  useEffect(() => {
    if (!hasAccess()) {
      showError(context.openPopup, config.permisionError, () => {
        push('/');
        context.closePopup();
      });
    }
  }, [context, push]);

  if (!hasAccess()) return null;

  return (
    <>
      <Helmet title="Browse Page" />
      <Browse tabs={tabs}>
        <BrowseRouting />
      </Browse>
    </>
  );
};

BrowseContainer.propTypes = {
  push: PropTypes.func.isRequired,
  tabs: PropTypes.instanceOf(Immutable.Map).isRequired,
};

BrowseContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {
    tabs,
  };
}

export { BrowseContainer };
export default connect(mapStateToProps, { push }, mergeProps, connectOptions)(BrowseContainer);
