import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

/**
 * Action button. It is an link with `href=#{action}` now.
 *
 * @link http://screencloud.net/v/uK4m component itself
 * @param props {Object}.
 * @param props.action {String} At this moment - id of action modal. Will be changed.
 * @param [props.className=""] {String} Extras classes for `<a/>` tag. "btn btn-default" are
 *     already present.
 * @param [props.icon=""] {String} Allow to display icon before button content. Only
 *     Font Awesome icons are supported. Use name without `fa-` prefix.
 * @returns {React.Component}
 * @class
 */
const ActionButton = props => {
  const { children, action } = props;
  const { icon = '' } = props;
  let iconContent = '';

  if (icon) {
    iconContent = <i className={`fa fa-${icon}`} />;
  }

  const className = classNames('btn btn-default', props.className);

  return (
    <a className={className} href={`#${action}`} role="button">
      {iconContent} {children}
    </a>
  );
};

ActionButton.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
};

/**
 * Link button. It is an react-router link with `to={to}` now.
 *
 * @link http://screencloud.net/v/uK4m component itself
 * @param props {Object} Any extra props for ReactRouter.Link, you can pass into this object.
 * @param props.to {String} React-router's link's to attribute.
 * @param [props.className=""] {String} Extras classes for `<a/>` tag. "btn btn-default" are
 *     already present.
 * @param [props.icon=""] {String} Allow to display icon before button content. Only
 *     Font Awesome icons are supported. Use name without `fa-` prefix.
 * @returns {React.Component}
 * @class
 */
const LinkButton = props => {
  const { children, to, ...restProps } = props;
  const { icon = '', ...rest } = restProps;
  let iconContent = '';

  if (icon) {
    iconContent = <i className={`fa fa-${icon}`} />;
  }

  const className = classNames('btn btn-default btn-xs', props.className);

  return (
    <Link {...rest} className={className} to={to}>
      {iconContent} {children}
    </Link>
  );
};

LinkButton.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  to: PropTypes.string.isRequired,
};

/**
 * Button component. Children is button content. Uses `<button className="btn" type"button"/>`
 * as base.
 * Label is placed after input and is visible.
 *
 * @param children {*} Button's content.
 * @param [type="button"] {string} Button type.
 * @param [className="btn-default"] {string} Extra button's class names.
 * @param [props] Some other props available on <button/>.
 * @class
 */
const Button = ({ className = 'btn-default', type = 'button', onClick, children, loading, ...props }) => {
  let myOnClick = onClick;

  if (props.form && type === 'submit') {
    myOnClick = (e, ...rest) => {
      const form = document.getElementById(props.form);

      const child = document.createElement('button');

      child.className = 'hidden';
      form.appendChild(child);
      setTimeout(() => {
        try {
          form.removeChild(child);
        } catch (err) {
          console.warn('no child');
        }
      }, 1000);

      try {
        const event = new Event('click');

        event.preventDefault();
        child.dispatchEvent(event);
      } catch (err) {
        e.preventDefault();
        setTimeout(() => {
          const event = document.createEvent('Event');

          event.initEvent('click', true, true);
          event.preventDefault();
          child.dispatchEvent(event);
        }, 0);
      }

      if (onClick !== undefined) {
        onClick(e, ...rest);
      }
    };
  }

  return (
    <button {...props} className={`btn ${className}`} onClick={myOnClick} type={type}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

export { ActionButton, LinkButton };
export default Button;
