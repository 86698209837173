import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import moment from 'moment';

import { unwrap } from 'src/utils/ChangeSpy';
import { dayToString } from 'src/utils/dateFormat';
import Input from 'src/components/helpers/Input';
import FormGroup from 'src/components/helpers/FormGroup';
import Textarea from 'src/components/helpers/Textarea';
import { InputProps } from 'src/components/helpers/AutoComplete';
import AutoCompleteContainer from 'src/containers/AutoComplete';

/**
 * Component is used to one-off type agreements list.
 *
 * @param {object} props Props.
 * @param {boolean} props.isReadOnly Whether all fields will be read-only or not.
 * @param {Function} props.onChange Handler for changing retainers controls.
 * @param {Function} props.onDelete Handler for deleting retainer from list.
 * @param {Function} props.onFindTargets Handler for find targets by search value.
 * @param {Function} props.onSuggestsChange Handler for changing targets suggests.
 * @param {Immutable.Map} props.root Company retainer.
 */
const OneOffAgreementsComponent = ({ isReadOnly, onChange, onDelete, onFindTargets, onSuggestsChange, root }) => {
  const retainers = root.get('companyRetainers');
  const NAME_PREFIX = 'companyRetainers';

  return retainers.map((retainer, index) => {
    const startDate = unwrap(retainer.get('startDate'));
    const dateTitle = moment.isMoment(startDate) && startDate.isValid() ? `- ${dayToString(startDate)}` : null;
    const formClassName = classNames('formRetainersItem', {
      'new-agreement': typeof retainer.get('id') === 'string',
    });

    if (unwrap(retainer.get('type') === 'one-off')) {
      return (
        <div key={retainer.get('id')} className={formClassName}>
          <div className="formRetainersItemTitleWrap">
            <h5 className="formRetainersItemTitle">One-Off Agreement {dateTitle} </h5>
          </div>
          <button
            className="formRetainersItemDelete"
            disabled={isReadOnly}
            onClick={() => onDelete(retainer.get('id'))}
            title="Delete"
            type="button"
          >
            <i className="fa fa-remove" />
          </button>
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-2">
              <div className="form-group">
                <Input
                  className="input-sm one-off-contractual-input"
                  disabled={isReadOnly}
                  label="Contractual Name"
                  name={`${NAME_PREFIX}.${index}.contractualName`}
                  onChange={onChange}
                  value={retainer.get('contractualName')}
                  showLabel
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2">
              <FormGroup>
                <label>Target</label>
                <InputProps className="form-control input-sm" placeholder="Target">
                  <AutoCompleteContainer
                    change={onSuggestsChange}
                    find={onFindTargets}
                    idName={`${NAME_PREFIX}.${index}.targetId`}
                    idValue={root.getIn([`${NAME_PREFIX}`, index, 'targetId'])}
                    suggestions={root.get('targetsSuggests')}
                    suggestsName="targetsSuggests"
                    value={root.getIn([`${NAME_PREFIX}`, index, 'targetName'])}
                    valueName={`${NAME_PREFIX}.${index}.targetName`}
                  />
                </InputProps>
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-2">
              <div className="form-group">
                <Input
                  className="input-sm"
                  disabled={isReadOnly}
                  label="Start Date"
                  name={`${NAME_PREFIX}.${index}.startDate`}
                  onChange={onChange}
                  placeholder="Start Date"
                  title="This is the date, per the contract, that the project kicks off"
                  type="date"
                  value={retainer.get('startDate')}
                  showLabel
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2">
              <div className="form-group">
                <Input
                  className="input-sm"
                  disabled={isReadOnly}
                  label="End Date"
                  name={`${NAME_PREFIX}.${index}.endDate`}
                  onChange={onChange}
                  placeholder="End Date"
                  title="This is the date the agreement officially terminates"
                  type="date"
                  value={retainer.get('endDate')}
                  showLabel
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <Textarea
                disabled={isReadOnly}
                label="Notes"
                name={`${NAME_PREFIX}.${index}.retainerNote`}
                onChange={onChange}
                placeholder=""
                rows="4"
                value={retainer.get('retainerNote')}
                showLabel
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  });
};

OneOffAgreementsComponent.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFindTargets: PropTypes.func.isRequired,
  onSuggestsChange: PropTypes.func.isRequired,
  root: PropTypes.instanceOf(Map).isRequired,
};

/** One off agreements component. */
export const OneOffAgreements = memo(OneOffAgreementsComponent);
