import React, { memo } from 'react';
import classNames from 'classnames';

import ContactActivities from './ContactActivities';
import ContactInfo from './ContactInfo';

const ContactTopPartComponent = ({
  onBlurInfo,
  onSubmitInfo,
  contactInfo,
  suffixSuggest,
  contactEventProps,
  onChange,
  onRef,
}) => {
  const topSideClassName = classNames('top_side container contact-info__top-section-wrapper', {
    'do-not-mail': contactInfo.getIn(['doNotMail', 'value'], false),
  });

  return (
    <div ref={onRef} className={topSideClassName}>
      <div className="row contact-info__top-section">
        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 contact-info__left-part">
          <ContactInfo
            info={contactInfo}
            onBlur={onBlurInfo}
            onChange={onChange}
            onSubmit={onSubmitInfo}
            suffixSuggest={suffixSuggest}
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 contact-info__right-part">
          <div className="highlight no-padding wrap-border-full table-responsive contact-activity__container flexWrapper mb0">
            <ContactActivities {...contactEventProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

/** Contact top part component. */
export const ContactTopPart = memo(ContactTopPartComponent);
