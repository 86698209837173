import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import { TopSection } from './TopSection';
import { BodySection } from './BodySection';
import { FootSection } from './FootSection';

/**
 * Approval component.
 *
 * @param props {Object}.
 * @param props.project {Immutable.Map} Project information.
 * @param props.approvals {Immutable.List} Approval list.
 * @param props.associates {Immutable.List} Associate list.
 * @param props.disableSetNA {Boolean} If set to true, will disable set next action button.
 * @param props.disableChangeStatus {Boolean} If set to true, will disable change status popup.
 * @param props.common {Immutable.Map} Common Object.
 * @param props.onGenerateOnlineApprovalList {Function} Generate Online Approval List modal window.
 * @param props.onSort {Function} Handle event of sorting associate table.
 * @param props.onSelect {Function} Handle event of selecting a menu item.
 * @param props.onChange {Function} Handle date change.
 * @param props.onSetAction {Function} To open set action popup.
 * @param props.onChangeStatus {Function} To open change status popup.
 * @param props.onExport {Function} To export associate list.
 * @param props.onOpenLink {Function} To open a new page when users double click on associate row.
 * @returns {React.Component}
 * @class
 */
const ApprovalComponent = ({
  project,
  approvals,
  associates,
  disableSetNA,
  disableChangeStatus,
  common,
  onSort,
  onLoad,
  onSelect,
  onChange,
  onSetAction,
  onChangeStatus,
  onGenerateOnlineApprovalList,
  onExport,
  onGridChange,
  onGridSubmit,
  onOpenLink,
  onSaveDates,
  onClick,
  onUpdateCompany,
  runWindwardReport,
  match: { params },
}) => {
  const [isNoApproval, setIsNoApproval] = useState(false);

  useEffect(() => {
    setIsNoApproval(params && params.approvalId && params.approvalId === 'no_approval_lists');

    return () => {
      setIsNoApproval(false);
    };
  }, [params]);

  return (
    <div className="container full-height approval-main-content">
      <TopSection common={common} isNoApproval={isNoApproval} project={project} />
      <BodySection
        approvals={approvals}
        associates={associates}
        common={common}
        isNoApproval={isNoApproval}
        onChange={onGridChange}
        onClick={onClick}
        onLoad={onLoad}
        onOpenLink={onOpenLink}
        onSelect={onSelect}
        onSort={onSort}
        onSubmit={onGridSubmit}
        onUpdateCompany={onUpdateCompany}
      />
      <FootSection
        common={common}
        disableChangeStatus={disableChangeStatus}
        disableSetNA={disableSetNA}
        generateOnlineApprovalList={onGenerateOnlineApprovalList}
        isNoApproval={isNoApproval}
        onChange={onChange}
        onChangeStatus={onChangeStatus}
        onExport={onExport}
        onSaveDates={onSaveDates}
        onSetAction={onSetAction}
        runWindwardReport={runWindwardReport}
      />
    </div>
  );
};

ApprovalComponent.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  associates: PropTypes.instanceOf(List).isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  disableChangeStatus: PropTypes.bool.isRequired,
  disableSetNA: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onGenerateOnlineApprovalList: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onOpenLink: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSetAction: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onUpdateCompany: PropTypes.func.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
};

/** Approval component. */
export const Approval = memo(ApprovalComponent);
