import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';

/**
 * Browse checkbox component.
 *
 * @param props {Object}.
 * @param props.filter {Immutable.Map} Filter Object.
 * @param props.info {Immutable.Map} Info Object.
 * @param props.onChange {Function} Handle event of change.
 * @param props.children {React.ReactNode} Children elements.
 * @returns {React.Component}
 * @class
 */
const BrowseCheckboxComponent = ({ filter, info, onChange, children }) => {
  const label = filter.get('label');
  const checked = info.get('checked');
  const dropdownContent = checked ? children : null;

  const handleCheckboxChange = event => {
    const { checked } = event.target;

    onChange({
      filterName: filter.get('name'),
      filterData: {
        checked,
      },
    });
  };

  return (
    <div className="BrowseControl">
      <label className="BrowseControl-label">
        <input checked={info.get('checked')} onChange={handleCheckboxChange} type="checkbox" />
        {label}
      </label>
      {dropdownContent}
    </div>
  );
};

BrowseCheckboxComponent.propTypes = {
  children: PropTypes.element,
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const BrowseCheckbox = memo(BrowseCheckboxComponent);
