import Immutable, { List } from 'immutable';
import moment from 'moment';
import nowImmediate from 'now-immediate';

import * as ActionType from '../../actions/companyDetail';
import * as EventActionType from '../../actions/company/companyEvents';
import config from '../../config';
import { withStatePrefix } from '../decorators/handleApiError';
import { autoNullId, autoRemoveId, check } from '../decorators/suggest';
import { spy, merge, mergeDeep, setIn, unwrap, getIn } from '../../utils/ChangeSpy';
import { getFormattedToCurrency } from '../../utils/valuesTransformer';

const DATE_STRING_FORMAT = 'MM/DD/YYYY';

const MAX_VALUE = 1000;
const INPUT_RATIO = 1000000;

/**
 * Create message if the value entered in $M is not valid.
 *
 * @param value (number) Value.
 */
function createNonValidValueMessage(value) {
  return `This value should be entered in $M.
    You entered ${getFormattedToCurrency(value * INPUT_RATIO)}
    (${value} * $1,000,000). Is this correct?`;
}

const isValidDate = date => {
  if (!date) return '';

  return date.isValid && date.isValid();
};

const dateToString = date => {
  if (!date) return '';
  if (moment(date).isValid()) return moment(date).format(DATE_STRING_FORMAT);

  return date;
};

const FIELDS_TO_SPY = [
  'targetName',
  'buyerName',
  'projectName',
  'completed',
  'userName',
  'date',
  'ownerName',
  'subOwnerName',
  'targetId',
  'buyerId',
  'userId',
  'contactId',
  'ndaTerm',
  'offerSize',
  'projectedCloseDate',
  'projectId',
  'directorName',
  'recordOwnerId',
  'analystName',
  'dealMakerName',
  'recordSubOwnerId',
  'dealMakerId',
  'contactName',
  'activity',
  'description',
  'dealNotes',
  'emailText',
  'emailText',
  'activityMap.text',
  'backlogged',
  'transactionValueAtClose',
  'ttmEbitdaAtClose',
  'transactionCloseDate',
  'dealFeeAtClose',
  'isDeferredCompensation',
  'deferredCompensationAmount',
  'dealFee',
];

const defaultShowAdditionField = Immutable.fromJS({
  showDescription: false,
  showDealNotes: false,
  showEmail: false,
  showNDAExecuted: false,
  showOfferAmount: false,
  showFuture: false,
  showPast: false,
});

const defaultState = Immutable.fromJS({
  all: [],
  dealNotes: [],
  buyerDealNotes: [],
  emails: [],
  isListLoading: false,
  current: spy(
    {
      id: null,
      initialFetch: false,
      analystSuggests: [],
      directorSuggests: [],
      dealMakerSuggests: [],
      userSuggests: [],
      showTooltip: false,
      tooltipText: '',
      tooltipTop: 0,
      tooltipLeft: 0,
      isLoading: false,
      error: '',
      targetName: '',
      buyerName: '',
      projectName: '',
      buyerId: null,
      projectId: null,
      targetId: null,
      contactId: 0,
      completed: null,
      userName: '',
      userId: 0,
      ownerName: '',
      subOwnerName: '',
      directorName: '',
      analystName: '',
      dealMakerName: '',
      description: '',
      dealNotes: '',
      emailText: '',
      ndaTerm: '',
      offerSize: '',
      activityMap: {
        text: '',
        selected: {},
        suggestions: [],
      },
      inputErrors: {},
      backlogged: null,
      transactionValueAtClose: '',
      ttmEbitdaAtClose: '',
      dealFeeAtClose: '',
      isDeferredCompensation: false,
      deferredCompensationAmount: 0,
      dealFee: 0,
    },
    FIELDS_TO_SPY,
  ),
});

const defaultSuggests = Immutable.List();
let wrappedReducer = reducer;

wrappedReducer = withStatePrefix(EventActionType.SAVE_COMPANY_EVENT_ERROR, wrappedReducer, () => [
  'current',
  'inputErrors',
]);

wrappedReducer = check('current.userName', 'current.userSuggests', EventActionType.LOADED_USERS, wrappedReducer);
wrappedReducer = check(
  'current.directorName',
  'current.directorSuggests',
  EventActionType.LOADED_DIRECTORS,
  wrappedReducer,
);
wrappedReducer = check(
  'current.analystName',
  'current.analystSuggests',
  EventActionType.LOADED_ANALYSTS,
  wrappedReducer,
);
wrappedReducer = check(
  'current.dealMakerName',
  'current.dealMakerSuggests',
  EventActionType.LOADED_DEALMAKERS,
  wrappedReducer,
);

wrappedReducer = autoNullId('current.userName', 'current.userId', EventActionType.CHANGE_COMPANY_EVENT, wrappedReducer);
wrappedReducer = autoNullId(
  'current.directorName',
  'current.recordOwnerId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);
wrappedReducer = autoNullId(
  'current.analystName',
  'current.recordSubOwnerId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);
wrappedReducer = autoNullId(
  'current.dealMakerName',
  'current.dealMakerId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);

wrappedReducer = autoRemoveId(
  'current.userName',
  'current.userId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);
wrappedReducer = autoRemoveId(
  'current.directorName',
  'current.recordOwnerId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);
wrappedReducer = autoRemoveId(
  'current.analystName',
  'current.recordSubOwnerId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);
wrappedReducer = autoRemoveId(
  'current.dealMakerName',
  'current.dealMakerId',
  EventActionType.CHANGE_COMPANY_EVENT,
  wrappedReducer,
);

export default wrappedReducer;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHING_COMPANY:
      return defaultState;

    case EventActionType.LOADED_EVENT_LIST: {
      return state.merge({
        isListLoading: true,
      });
    }

    case EventActionType.LOADED_EVENT_LIST_SUCCESS: {
      const tmp = defaultState.merge(mapResponse(action.response));

      return action.popupReserve ? tmp.set('current', state.get('current')) : tmp;
    }

    case EventActionType.LOADED_EVENT_LIST_FAILURE: {
      return state.merge({
        isListLoading: false,
      });
    }

    case ActionType.FETCHING_COMPANY_SUCCESS:
      return defaultState.merge(mapResponse(action.response));

    case EventActionType.ENTER_COMPANY_EMAIL:

    // fallthrough
    case EventActionType.ENTER_COMPANY_DEAL_NOTE:
      return state.updateIn(['all'], events =>
        events.map(event => event.set('isMarked', action.eventId === event.get('id'))),
      );

    case EventActionType.LEAVE_COMPANY_EMAIL:

    // fallthrough
    case EventActionType.LEAVE_COMPANY_DEAL_NOTE:
      return state.updateIn(['all'], events => events.map(event => event.set('isMarked', false)));

    case EventActionType.ENTER_COMPANY_EVENT: {
      const tooltipText = getTooltipString(getCurrentEvent(state, action.eventId));

      return state
        .update('dealNotes', notes => notes.map(note => note.set('isMarked', action.eventId === note.get('eventId'))))
        .update('buyerDealNotes', notes =>
          notes.map(note => note.set('isMarked', action.eventId === note.get('eventId'))),
        )
        .update('emails', emails => emails.map(email => email.set('isMarked', action.eventId === email.get('eventId'))))
        .mergeDeep({
          current: {
            id: action.eventId,
            showTooltip: !!tooltipText,
            tooltipTop: action.top,
            tooltipLeft: action.left,
            tooltipText,
          },
        });
    }

    case EventActionType.LEAVE_COMPANY_EVENT:
      return state
        .update('dealNotes', notes => notes.map(note => note.set('isMarked', false)))
        .update('buyerDealNotes', notes => notes.map(note => note.set('isMarked', false)))
        .update('emails', emails => emails.map(email => email.set('isMarked', false)))
        .mergeDeep({
          current: {
            showTooltip: false,
          },
        });

    case EventActionType.FETCHING_EVENT:
      return state.merge({
        current: defaultState.get('current').merge({
          isLoading: true,
          initialFetch: true,
          error: null,
        }),
      });

    case EventActionType.FETCHING_EVENT_FAILURE:
      return state.mergeDeep({
        current: {
          isLoading: false,
          initialFetch: false,
          error: action.response,
        },
      });

    case EventActionType.FETCHING_EVENT_SUCCESS:
      return state.mergeDeep({
        current: spy(
          {
            ...eventMapper(action.response.data),
            initialFetch: false,
            isLoading: false,
          },
          FIELDS_TO_SPY,
        ),
      });

    case EventActionType.START_EDIT_COMPANY_EVENT:
      return startEditCompanyEvent(state, action);

    case EventActionType.CHANGE_COMPANY_EVENT: {
      action.field = action.field.replace(/^current\./, '');

      if (action.field === 'activityMap') {
        Object.keys(action.value).forEach(key => {
          if (key === 'selected') {
            state = setIn(state, 'current.activityMap.selected', defaultShowAdditionField.merge(action.value.selected));
          } else if (Immutable.Iterable.isIterable(action.value[key])) {
            state = setIn(state, `current.activityMap.${key}`, action.value[key]);
          } else {
            state = mergeDeep(state, {
              [`current.activityMap.${key}`]: action.value[key],
            });
          }
        });

        return state;
      }

      if (action.field === 'date') {
        state = setIn(state, 'current.dateString', dateToString(action.value));
        if (isValidDate(action.value)) {
          action.value = action.value.endOf('day');
          state = state.deleteIn(['current', 'inputErrors', 'date']);
        } else {
          state = state.setIn(['current', 'inputErrors', 'date'], 'Date should be valid date');
        }
      }

      if (action.field === 'projectedCloseDate') {
        state = setIn(state, 'current.projectedCloseDate', dateToString(action.value));
      }

      if (action.field === 'transactionCloseDate') {
        state = setIn(state, 'current.transactionCloseDate', dateToString(action.value));
      }

      if (action.field === 'backlogged') {
        if (!config.BACKLOGGED_EVENTS.includes(getIn(state, 'current.activityMap.selected.name'))) {
          return setIn(state, 'current.backlogged', false);
        }

        return setIn(state, 'current.backlogged', action.value);
      }

      if (action.field === 'transactionValueAtClose') {
        state = setIn(state, 'current.transactionValueAtClose', Immutable.fromJS(action.value));
        if (action.value <= MAX_VALUE) {
          state = state.deleteIn(['current', 'inputErrors', 'transactionValueAtClose']);
        } else {
          state = state.setIn(
            ['current', 'inputErrors', 'transactionValueAtClose'],
            createNonValidValueMessage(action.value),
          );
        }
      }

      if (action.field === 'ttmEbitdaAtClose') {
        state = setIn(state, 'current.ttmEbitdaAtClose', Immutable.fromJS(action.value));
        if (action.value <= MAX_VALUE) {
          state = state.deleteIn(['current', 'inputErrors', 'ttmEbitdaAtClose']);
        } else {
          state = state.setIn(['current', 'inputErrors', 'ttmEbitdaAtClose'], createNonValidValueMessage(action.value));
        }
      }

      return setIn(state, `current.${action.field}`, Immutable.fromJS(action.value));
    }

    case EventActionType.SAVE_COMPANY_EVENT:
      return state.mergeDeep({
        current: {
          isLoading: true,
          error: null,
        },
      });

    case EventActionType.SAVE_COMPANY_EVENT_ERROR:
      return state.mergeDeep({ current: { isLoading: false } });

    case EventActionType.DELETE_COMPANY_EVENT:
      return state
        .update('all', events => events.filter(event => event.get('id') !== action.eventId))
        .update('emails', emails => emails.filter(email => email.get('eventId') !== action.eventId))
        .update('dealNotes', dealNotes => dealNotes.filter(dn => dn.get('eventId') !== action.eventId));

    case EventActionType.CLOSE_VALIDATION_ERROR:
      return state.deleteIn(['current', 'inputErrors', action.field]);

    case EventActionType.RESET_CURRENT_EVENT:
      return state.set('current', defaultState.get('current'));

    default:
      return state;
  }
}

function getCurrentEvent(state, id) {
  return state.get('all').find(value => value.get('id') === id);
}

function eventMapper(event) {
  const result = {};

  result.id = event.id;
  result.userName = event.userUserName || event['user.userName'];
  result.userId = event.userId;
  result.backlogged = event.backlogged;
  result.ndaTerm = event.ndaTerm;
  result.offerSize = event.offerSize;
  result.projectedCloseDate = event.projectedCloseDate ? moment(event.projectedCloseDate).endOf('day') : null;
  result.isDeferredCompensation = event.isDeferredCompensation;
  result.transactionValueAtClose = event.transactionValueAtClose;
  result.ttmEbitdaAtClose = event.ttmEbitdaAtClose;
  result.dealFeeAtClose = event.dealFeeAtClose;
  result.transactionCloseDate = moment(event.transactionCloseDate).endOf('day');
  result.deferredCompensationAmount = event.deferredCompensationAmount;
  result.dealFee = event.dealFee;
  result.date = moment(event.date).endOf('day');
  if (!result.date.isValid() || result.date.format('YYYYMMDD') === '19700101') {
    result.dateString = 'no date';
  } else {
    result.dateString = result.date.format(DATE_STRING_FORMAT);
  }
  result.activity = event.activity;
  if (event.description) result.fullActivity = `${event.activity} - ${event.description}`;
  else result.fullActivity = event.activity;
  result.description = event.description || '';
  result.dealNotes = event.dealNotes || '';
  result.emailText = event.emailText || '';
  result.abbrName = event['buyer.abbrName'] || event.buyerAbbrName;
  result.legalName = event['buyer.legalName'] || event.buyerLegalName;
  result.completed = event.completed;
  result.isNext = !event.completed;
  result.meta = event.meta;
  result.targetName = event['target.legalName'] || event.targetLegalName;
  result.contactName = event.targetContactFLName;
  result.contactId = event['target.contactId'] || event.targetContactId;
  if (event.approach === 'buyer') {
    result.contactId = event['buyer.contactId'] || event.buyerContactId;
    result.contactName = event.buyerContactFLName;
  }
  result.buyerName = event['buyer.legalName'] || event.buyerLegalName;
  result.projectName = event['project.category'] || event.projectCategory;
  result.ownerUsername = event.recordOwnerUserName;
  result.subOwnerUsername = event.recordSubOwnerUserName;
  result.dealMakerUserName = event.dealMakerUserName;
  result.approach = event.approach;
  result.buyerId = event.buyerId;
  result.createdBy = event.createdBy;
  result.createdAt = moment(event.createdAt);
  result.createdByUser = event['createdBy.userName'];
  result.updatedBy = event.updatedBy;
  result.updatedAt = moment(event.updatedAt);
  result.updatedByUser = event['updatedBy.userName'];

  const activities = config.values.getIn(['eventActivities', event.approach], List());

  result.activityMap = {
    suggestions: [],
    selected: activities.filter(item => item.get('value') === event.activity).get(0),
    text: event.activity,
  };
  result.activityMap.selected = defaultShowAdditionField.merge(result.activityMap.selected);
  if (result.isNext) {
    const date = moment(event.date);

    if (
      date
        .clone()
        .add(5180000, 'seconds')
        .valueOf() < nowImmediate()
    ) {
      result.warnLevel = 3;
    } else if (
      date
        .clone()
        .add(1210000, 'seconds')
        .valueOf() < nowImmediate()
    ) {
      result.warnLevel = 2;
    } else if (date.valueOf() < nowImmediate()) {
      result.warnLevel = 1;
    } else {
      result.warnLevel = 0;
    }
  }

  if (moment(event.date).endOf('day') <= moment()) {
    result.isFuture = false;
  } else {
    result.isFuture = true;
  }

  if (/status change/i.test(event.activity)) {
    result.fullActivity =
      event.approach === 'target'
        ? `Status Change ${event.oldStatus} => ${event.newStatus} (${result.buyerName})`
        : `Status Change ${event.oldStatus} => ${event.newStatus}`;
  }

  if (event.emailText) {
    result.activityMap.selected = result.activityMap.selected.set('showEmail', true);
  }

  result.recordOwnerId = event.recordOwnerId || null;
  result.recordSubOwnerId = event.recordSubOwnerId || null;
  result.dealMakerId = event.dealMakerId || null;
  result.directorName = result.ownerUsername || '';
  result.analystName = result.subOwnerUsername || '';
  result.dealMakerName = result.dealMakerUserName || '';
  result.userName = result.userName || '';
  result.directorSuggests = result.analystSuggests = result.dealMakerSuggests = result.userSuggests = defaultSuggests;

  return result;
}

function mapResponse({ data }) {
  const { events = [] } = data;

  const all = events
    .filter(event => !event.deletedAt)
    .map(eventMapper)
    .sort((a, b) => b.date - a.date || b.id - a.id);

  return {
    ...getEmailsAndNotesFromData(data),
    all,
    isListLoading: false,
  };
}

function getEmails(events) {
  return events
    .filter(v => !!v.emailText)
    .map(v => ({
      isMarked: false,
      content: v.emailText,
      fullActivity: v.fullActivity,
      legalName: v.legalName,
      date: v.date.isValid() ? v.dateString : 'no date',
      projectName: v.projectName,
      userName: v.userName,
      eventId: v.id,
    }));
}

function getDealNotes(events) {
  return events
    .filter(v => v.approach === 'target' && v.dealNotes)
    .map(v => ({
      isMarked: false,
      content: v.dealNotes,
      legalName: v.legalName,
      projectName: v.projectName,
      date: v.date.isValid() ? v.dateString : 'no date',
      userName: v.userName,
      eventId: v.id,
    }));
}

function getBuyerDealNotes(events) {
  return events
    .filter(v => v.approach === 'buyer' && v.dealNotes)
    .map(v => ({
      isMarked: false,
      content: v.dealNotes,
      legalName: v.legalName,
      projectName: v.projectName,
      date: v.date.isValid() ? v.dateString : 'no date',
      userName: v.userName,
      eventId: v.id,
    }));
}

function getEmailsAndNotesFromData(data) {
  let events = (data && data.events) || [];

  events = events
    .filter(event => !event.deletedAt)
    .map(eventMapper)
    .sort((a, b) => b.date - a.date || b.id - a.id);

  return {
    buyerDealNotes: getBuyerDealNotes(events),
    dealNotes: getDealNotes(events),
    emails: getEmails(events),
  };
}

function getEventFills(action) {
  if (action.approach === 'target') {
    const completed = false;

    if (!action.companyBuyer) {
      throw new Error('This is not an target company');
    }

    const buyerId = unwrap(action.companyBuyer.get('buyerId'));
    const buyerName =
      unwrap(action.companyBuyer.get('dsplBuyerLegalName')) || unwrap(action.companyBuyer.get('dsplBuyerAbbrName'));
    const projectId = unwrap(action.companyBuyer.get('projectId'));
    const projectName = unwrap(action.companyBuyer.get('dsplProjectCategory'));
    const targetId = unwrap(action.companyInfo.get('id'));
    const targetName = unwrap(action.companyInfo.get('legalName')) || unwrap(action.companyInfo.get('abbrName'));
    const contactId = String(unwrap(action.companyContacts.getIn([0, 'id'])));
    const contactName = unwrap(action.companyContacts.getIn([0, 'fullName']));

    return {
      completed,
      buyerId,
      buyerName,
      projectId,
      projectName,
      targetId,
      targetName,
      contactId,
      contactName,
    };
  }

  if (action.approach === 'buyer') {
    return {
      completed: false,
      buyerId: unwrap(action.companyInfo.get('id')),
      buyerName: unwrap(action.companyInfo.get('legalName')) || unwrap(action.companyInfo.get('abbrName')),
      contactId: String(unwrap(action.companyContacts.getIn([0, 'id']))),
      contactName: unwrap(action.companyContacts.getIn([0, 'fullName'])),
    };
  }

  return {};
}

function getTooltipString(event) {
  if (['target', 'buyer'].includes(event.get('approach'))) {
    const name = `${event.get('abbrName') || event.get('legalName') || ''}`;
    const project = event.get('projectId') ? ` - ${event.get('projectName')}` : '';

    return `${name}${project}`;
  }

  return '';
}

function startEditCompanyEvent(state, action) {
  let current = spy((action.eventId ? state : defaultState).get('current'), FIELDS_TO_SPY);

  current = mergeDeep(current, eventMapper({ approach: action.approach }));
  current = current.updateIn(['activityMap', 'suggestions'], a => Immutable.fromJS(a));
  current = merge(current, action.eventId ? {} : getEventFills(action));
  current = setIn(current, ['activityMap', 'text'], '');

  if (action.defaultDirector) {
    current = setIn(current, ['directorName'], action.defaultDirector.text);
    current = setIn(current, ['recordOwnerId'], action.defaultDirector.id);
  }

  if (action.defaultAnalyst) {
    current = setIn(current, ['analystName'], action.defaultAnalyst.text);
    current = setIn(current, ['recordSubOwnerId'], action.defaultAnalyst.id);
  }

  if (action.defaultDealMaker) {
    current = setIn(current, ['dealMakerName'], action.defaultDealMaker.text);
    current = setIn(current, ['dealMakerId'], action.defaultDealMaker.id);
  }

  if (action.defaultUser) {
    current = setIn(current, ['userName'], action.defaultUser.text);
    current = setIn(current, ['userId'], action.defaultUser.id);
  }

  if ('completed' in action) {
    current = setIn(current, ['completed'], action.completed);
  }

  if (action.eventId) {
    return state.merge({ current });
  }

  return selectDefaultActivity(state.merge({ current }), '');
}

function selectDefaultActivity(state, activity) {
  const activities = config.values.getIn(['eventActivities', 'target'], Immutable.List());
  const updateCall = activities.find(v => v.get('value') === activity);

  if (updateCall) {
    return reducer(state, {
      type: EventActionType.CHANGE_COMPANY_EVENT,
      field: 'activityMap',
      value: {
        text: activity,
        selected: updateCall,
      },
    });
  }

  return state;
}
