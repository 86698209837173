import PropTypes from 'prop-types';
import React from 'react';

/**
 * Tooltip component. **Warn** It is not really implemented.
 *
 * @param props {Object}.
 * @param props.content {string}.
 * @param props.children {*}.
 * @returns {React.Component}
 */
const DataToolTip = props => {
  const { content, children } = props;

  return (
    <span data-toggle="tooltip" title={content}>
      {children}
    </span>
  );
};

DataToolTip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.string.isRequired,
};

export default DataToolTip;
