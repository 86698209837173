import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Checkbox from '../../helpers/Checkbox';
import Button from '../../helpers/Button';
import FormGroup from '../../helpers/FormGroup';

/**
 * Stateless component for search text input.
 *
 * @param props {Object}.
 */
const SearchInputComponent = ({
  disabled,
  onSearch,
  hasAccess,
  userName,
  onOnlyOwnerChange,
  onSearchingTermChange,
  searchingTerm,
  searchBy,
  onSearchBy,
}) => {
  const handleSearch = event => {
    if (disabled) {
      return;
    }

    if (event.key === 'Enter') {
      onSearch(event.key);
    }
  };
  const searchByUser = hasAccess ? (
    <Checkbox
      capitalizeLabel={false}
      id="onlyUserCheckbox"
      label={`Only ${userName}`}
      name="onlyOwner"
      onChange={onOnlyOwnerChange}
    />
  ) : null;

  return (
    <div className="search__form">
      <div className="input-group">
        <input
          className="form-control search-text"
          id="searchingInput"
          name="searchingTerm"
          onChange={onSearchingTermChange}
          onKeyPress={handleSearch}
          placeholder="a search term..."
          value={searchingTerm}
        />
        <span className="input-group-btn">
          <Button className="btn btn-default btn-xs" disabled={disabled} onClick={onSearch}>
            <i aria-hidden="true" className="fa fa-search" /> Search
          </Button>
        </span>
      </div>
      <div className="group-checkbox flex mt5">
        {searchByUser}
        <FormGroup>
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="searchByWebsiteCheckbox">Search by Website</label>
            <input
              checked={searchBy === 'searchByWebsite'}
              id="searchByWebsiteCheckbox"
              name="searchByWebsite"
              onChange={onSearchBy}
              type="checkbox"
              value="searchByWebsite"
            />
          </div>
        </FormGroup>
        <FormGroup className="ml20">
          <div className="radio-wrap radio-wrap--reverse">
            <label htmlFor="searchByPhoneCheckbox">Search by Phone Number</label>
            <input
              checked={searchBy === 'searchByPhone'}
              id="searchByPhoneCheckbox"
              name="searchByPhone"
              onChange={onSearchBy}
              type="checkbox"
              value="searchByPhone"
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

SearchInputComponent.propTypes = {
  onOnlyOwnerChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchingTermChange: PropTypes.func.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export const SearchInput = memo(SearchInputComponent);
