import PropTypes from 'prop-types';

import React from 'react';
import { List, Map, fromJS } from 'immutable';

import ActiveRouterLink from './ActiveRouterLink';

const defaultActive = fromJS({});

/**
 * Tab component. Uses `<li><a href={to}>{children content}</a></li>`.
 * Also `<li>` has .active class if it is current route.
 *
 * @param props {Object}.
 * @param props.to {String} Url to change window.location on click.
 * @returns {JSX.Element}
 * @class
 */
const Tab = props => {
  const { children, to, name, available = false, onClick } = props;
  let { className } = props;

  if (available) className += ' available-tab';

  const handleClick = event => {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick({ name, to });
    }
  };

  return (
    <ActiveRouterLink classes={className} name={name} onClickCapture={handleClick} to={to} wrapper="li">
      {children}
    </ActiveRouterLink>
  );
};

Tab.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.string,
};

/**
 * Tabs row component.
 *
 * @param props {Object}.
 * @param props.tabs {Immutable.List} List of Immutable.Maps. Every map is an tab with #name and #to keys.
 *     Tabs are react-router links.
 * @returns {React.Component}
 */
const Tabs = props => {
  const { tabs, tabClass, availableTabs = defaultActive, onClick } = props;
  const tabClassContent = tabClass || 'nav nav-tabs main-nav block-center';

  const content = tabs.map((tab, i) => (
    <Tab
      key={i}
      available={availableTabs.get(tab.get('key'))}
      className={tab.get('className')}
      name={tab.get('name')}
      onClick={onClick}
      to={tab.get('to')}
    >
      {tab.get('name')}
    </Tab>
  ));

  return <ul className={tabClassContent}>{content}</ul>;
};

Tabs.propTypes = {
  availableTabs: PropTypes.instanceOf(Map),
  tabs: PropTypes.instanceOf(List).isRequired,
};

export default Tabs;
