import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import outsideClick from 'react-click-outside';
import classNames from 'classnames';
import { ContextMenuTrigger } from 'react-contextmenu';

import config from '../../../../../config';
import { unwrap, isDeepChanged } from '../../../../../utils/ChangeSpy';
import Table from '../../../../helpers/AgGridTable';
import getPercent from '../../../../../utils/getPercent';
import DateEditor from './DateEditor';
import TextEditor from './TextEditor';
import AgHeader from './AgHeader';

export const DeleteItem = (() => (
  <div style={{ padding: '0 5px' }}>
    <span className="fa fa-trash mr5"> Delete</span>
  </div>
))();

/**
 * To fix issue: "Cannot refresh cells with cellRendererFramework".
 */
const GeneralFormat = ({ value, column: { colId }, data, colDef, rowIndex }) => {
  const collect = () => ({
    rowIndex,
    rowData: data,
  });

  const isNoApproval = data.id === 'no_approval_lists';
  const divClassName = classNames('ag-cell ag-cell-not-inline-editing', {
    'cell cell--marked': isDeepChanged(data),
  });

  let content = null;

  if (colId === 'dateReceived' || colId === 'dateSent') {
    const dt = value.value;

    content = <div className={divClassName}>{dt && dt.isValid() ? dt.format('MM/DD/YYYY') : ''}</div>;
  } else if (colId === 'applistLabel') {
    content = <div className={divClassName}>{value.value}</div>;
  } else {
    content = <div className={divClassName}>{value}</div>;
  }

  if (isNoApproval) return <div className="no-approval-list__row">{content}</div>;

  return (
    <ContextMenuTrigger collect={collect} id={colDef.contextMenuId}>
      {content}
    </ContextMenuTrigger>
  );
};

/**
 * Return sum by field.
 *
 * @param {List} approvals Immutable List of approvals.
 * @param {string} field Field name.
 */
const getSum = (approvals, field) => approvals.reduce((sum, approval) => (sum += approval.get(field)), 0);

/**
 * Return total value.
 *
 * @param {List} approvals List of approved.
 * @param {string} numberOf Field name.
 * @param {number} percentOf Sum of approved.
 * @returns {string}
 */
const getTotalValue = (approvals, numberOf, percentOf) => {
  const sum = getSum(approvals, numberOf);
  const percent = getPercent(sum, percentOf);

  return `${sum} (${percent}%)`;
};

/**
 * Return total count of approved and percents of  ex.:1 (100%).
 *
 * @param {number} approved Count of approved.
 * @param {number} targets Count of targets.
 * @returns {string}
 */
const getTotalApprovedValue = (approved, targets) => {
  const percent = getPercent(approved, targets);

  return `${approved} (${percent}%)`;
};

/**
 * Return an array with an object which contains total stats data.
 *
 * @param {List} approvals Immutable List of approvals.
 */
export const getTotalStats = approvals => {
  const approvalsReturned = approvals.filter(approval => approval.getIn(['dateReceived', 'value'], 0) !== null);
  const sumReturnedTargets = getSum(approvalsReturned, 'numberTargets');
  const sumApproved = getSum(approvalsReturned, 'numberApproved');
  const sumTotal = getSum(approvals, 'numberApproved');
  const sumTargets = getSum(approvals, 'numberTargets');
  const totalNumberApproved = getSum(approvals, 'numberApproved');

  return [
    {
      applistLabel: { value: 'Total (Returned Approval Lists):' },
      dateSent: '',
      dateReceived: '',
      numberTargets: getSum(approvalsReturned, 'numberTargets'),
      numberApproved: getTotalValue(approvalsReturned, 'numberApproved', sumReturnedTargets),
      numberOfContacted: getTotalValue(approvalsReturned, 'numberOfContacted', sumApproved),
      numberOfLeads: getTotalValue(approvalsReturned, 'numberOfLeads', sumApproved),
      numberOfCc: getTotalValue(approvalsReturned, 'numberOfCc', sumApproved),
      numberOfVisit: getTotalValue(approvalsReturned, 'numberOfVisit', sumApproved),
      numberOfNextActions: getTotalValue(approvalsReturned, 'numberOfNextActions', sumReturnedTargets),
      numberOfPriorityA: getTotalValue(approvalsReturned, 'numberOfPriorityA', sumReturnedTargets),
      numberOfPriorityB: getTotalValue(approvalsReturned, 'numberOfPriorityB', sumReturnedTargets),
      numberOfPriorityC: getTotalValue(approvalsReturned, 'numberOfPriorityC', sumReturnedTargets),
      numberOfApprovedX: getTotalValue(approvalsReturned, 'numberOfApprovedX', sumReturnedTargets),
    },
    {
      applistLabel: { value: 'Total (All):' },
      dateSent: '',
      dateReceived: '',
      numberTargets: sumTargets,
      numberApproved: getTotalApprovedValue(totalNumberApproved, sumTargets),
      numberOfContacted: getTotalValue(approvals, 'numberOfContacted', sumTotal),
      numberOfLeads: getTotalValue(approvals, 'numberOfLeads', sumTotal),
      numberOfCc: getTotalValue(approvals, 'numberOfCc', sumTotal),
      numberOfVisit: getTotalValue(approvals, 'numberOfVisit', sumTotal),
      numberOfNextActions: getTotalValue(approvals, 'numberOfNextActions', sumTargets),
      numberOfPriorityA: getTotalValue(approvals, 'numberOfPriorityA', sumTargets),
      numberOfPriorityB: getTotalValue(approvals, 'numberOfPriorityB', sumTargets),
      numberOfPriorityC: getTotalValue(approvals, 'numberOfPriorityC', sumTargets),
      numberOfApprovedX: getTotalValue(approvals, 'numberOfApprovedX', sumTargets),
    },
  ];
};

/**
 * Approval list component.
 *
 * @param props {Object}.
 * @param approvals {Immutable.List} Approval list.
 * @param common {Immutable.Map} Common object.
 * @param approvalColumns {Immutable.List} Approval column configuration.
 * @param onInsert {Function} Handle event of adding a new row.
 * @param onChange {Function} Handle event of switching a row into edit mode.
 * @param onClick {Function} Handle event of clicking on a row.
 * @param onRowDoubleClicked {Function} Handle event of double clicking on a row.
 * @param onSort {Function} Handle event of sorting a column.
 * @returns {React.Component}
 * @class
 */
const Approvals = ({ approvals, common, onSort, onChange, onRowInsert, onDelete, onRowDoubleClick }) => {
  const gridApi = useRef(null);
  const cellClickToEdit = useRef(false);

  const actions = List([
    Map({
      key: 'delete',
      name: DeleteItem,
    }),
  ]);

  const checkEditable = useCallback(
    params => params.node.data.id !== 'no_approval_lists' && cellClickToEdit.current,
    [],
  );

  const handleInsert = useCallback(() => {
    if (!gridApi.current) return;
    gridApi.current.stopEditing();

    let rowIndex = -1;

    gridApi.current.forEachNode(rowNode => {
      if (rowNode.data.id === 0) {
        rowIndex = rowNode.rowIndex;

        return false;
      }

      return true;
    });

    if (rowIndex === -1) {
      const transaction = gridApi.current.applyTransaction({
        add: [
          {
            id: 0,
            applistLabel: { value: '' },
            dateReceived: { value: null },
            dateSent: { value: null },
            numberApproved: 0,
            numberCalled: 0,
            numberMailed: 0,
            numberTargets: 0,
            percentApproved: 0,
            percentCalled: 0,
            percentMailed: 0,
          },
        ],
      });

      rowIndex = transaction.add[0].rowIndex;
    }

    cellClickToEdit.current = true;
    gridApi.current.startEditingCell({ rowIndex, colKey: 'applistLabel' });
  }, []);

  const columns = config.tables.getIn(['project', 'approval', 'columns']).map(col => {
    const field = col.get('field');

    if (['dateReceived', 'dateSent'].includes(field)) {
      return col.merge({
        editable: checkEditable,
        cellRendererFramework: GeneralFormat,
        headerComponentFramework: AgHeader,
        sortable: true,
        cellEditorFramework: DateEditor,
      });
    }

    if (field === 'applistLabel') {
      return col.merge({
        editable: checkEditable,
        cellEditorFramework: TextEditor,
        headerComponentFramework: AgHeader,
        onClick: handleInsert,
        sortable: true,
        cellRendererFramework: GeneralFormat,
      });
    }

    if (field.startsWith('numberOf')) {
      const headerTooltip = {
        numberApproved: 'Approved% = #Approved / #Targets',
        numberOfContacted: 'Contacted% = #Contacted / #Approved',
        numberOfLeads: 'Leads% = #Leads / #Approved',
        numberOfCc: 'CC% = #CC / #Approved',
        numberOfVisit: 'Visit% = #Visit / #Approved',
        numberOfNextActions: 'NAs% = #NAs / #Targets',
        numberOfPriorityA: 'A% = #A / #Targets',
        numberOfPriorityB: 'B% = #B / #Targets',
        numberOfPriorityC: 'C% = #C / #Targets',
        numberOfApprovedX: 'X% = #X / #Targets',
      }[field];

      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip,
      });
    }

    return col.merge({
      headerComponentFramework: AgHeader,
      sortable: true,
      cellRendererFramework: GeneralFormat,
    });
  });

  const preventEditing = useCallback(() => {
    cellClickToEdit.current = false;
  }, []);

  const handleCellClicked = useCallback(
    e => {
      const {
        column: { colId },
        node: { rowIndex },
      } = e;

      if (common.get('approvalLoading')) return;

      if (['applistLabel', 'dateReceived', 'dateSent'].includes(colId)) {
        cellClickToEdit.current = true;
        gridApi.current.startEditingCell({
          rowIndex,
          colKey: colId,
        });
      } else {
        gridApi.current.stopEditing();
      }
    },
    [common],
  );

  const handleRowValueChanged = useCallback(
    ({ data }) => {
      if (data.id > 0 || data.mode === 'edit') {
        onChange({
          type: 'approvals',
          rowIndex: data.index,
          field: 'applistLabel',
          value: data.applistLabel.value,
        });
        onChange({
          type: 'approvals',
          rowIndex: data.index,
          field: 'dateReceived',
          value: data.dateReceived.value,
        });
        onChange({
          type: 'approvals',
          rowIndex: data.index,
          field: 'dateSent',
          value: data.dateSent.value,
        });
      } else if (data.id === 0 && (data.applistLabel.value || data.dateReceived.value || data.dateSent.value)) {
        onRowInsert({
          applistLabel: data.applistLabel.value,
          dateReceived: data.dateReceived.value,
          dateSent: data.dateSent.value,
          projectId: data.projectId,
        });
      }
    },
    [onChange, onRowInsert],
  );

  const mapApprovalsData = useCallback(
    () =>
      approvals.map(approval => {
        const percentApproved = `${approval.get('percentApproved')}%`;
        const numberTargets = approval.get('numberTargets');
        const numberApproved = approval.get('numberApproved');
        const numberOfCc = approval.get('numberOfCc');
        const numberOfVisit = approval.get('numberOfVisit');
        const numberOfNextActions = approval.get('numberOfNextActions');
        const numberOfPriorityA = approval.get('numberOfPriorityA');
        const numberOfPriorityB = approval.get('numberOfPriorityB');
        const numberOfPriorityC = approval.get('numberOfPriorityC');
        const numberOfApprovedX = approval.get('numberOfApprovedX');

        return approval
          .set('numberApproved', `${numberApproved} (${percentApproved})`)
          .set(
            'numberOfContacted',
            `${approval.get('numberOfContacted')} (${getPercent(approval.get('numberOfContacted'), numberApproved)}%)`,
          )
          .set(
            'numberOfLeads',
            `${approval.get('numberOfLeads')} (${getPercent(approval.get('numberOfLeads'), numberApproved)}%)`,
          )
          .set('numberOfCc', `${numberOfCc} (${getPercent(numberOfCc, numberApproved)}%)`)
          .set('numberOfVisit', `${numberOfVisit} (${getPercent(numberOfVisit, numberApproved)}%)`)
          .set('numberOfNextActions', `${numberOfNextActions} (${getPercent(numberOfNextActions, numberTargets)}%)`)
          .set('numberOfPriorityA', `${numberOfPriorityA} (${getPercent(numberOfPriorityA, numberTargets)}%)`)
          .set('numberOfPriorityB', `${numberOfPriorityB} (${getPercent(numberOfPriorityB, numberTargets)}%)`)
          .set('numberOfPriorityC', `${numberOfPriorityC} (${getPercent(numberOfPriorityC, numberTargets)}%)`)
          .set('numberOfApprovedX', `${numberOfApprovedX} (${getPercent(numberOfApprovedX, numberTargets)}%)`);
      }),
    [approvals],
  );

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.setPinnedBottomRowData(getTotalStats(approvals));
      gridApi.current.sizeColumnsToFit();
      gridApi.current.refreshCells({ force: true });
    }

    if (approvals.size && !unwrap(approvals.getIn([approvals.size - 1, 'id'])) && gridApi.current) {
      const rowIndex = approvals.size - 1;

      gridApi.current.startEditingCell({ rowIndex, colKey: 'applistLabel' });
      gridApi.current.setFocusedCell(rowIndex, 'applistLabel');
      gridApi.current.ensureIndexVisible(rowIndex);
    }
    cellClickToEdit.current = false;
  }, [approvals, common]);

  const handleContextMenuClick = (event, data) => {
    const { id, numberTargets } = data.rowData;

    if (id !== 'no_approval_lists') {
      onDelete(event, id, numberTargets);
    }
  };

  return (
    <div className="col-md-8 col-approvals full-height">
      <div className="table-edit ag-approval full-height">
        <Table
          columnDefs={columns}
          contextActionList={actions}
          editType="fullRow"
          getRowNodeId={data => data.index}
          isFetching={common.get('approvalLoading')}
          onCellClicked={handleCellClicked}
          onContextMenuClick={handleContextMenuClick}
          onGridReady={params => {
            gridApi.current = params.api;
            gridApi.current.addEventListener('rowEditingStarted', preventEditing);
          }}
          onRowDoubleClicked={params => {
            if (params.data.id > 0) {
              onRowDoubleClick({
                row: Map({
                  id: params.data.id,
                  projectId: params.data.projectId,
                }),
                type: 'approvals',
              });
            } else if (params.data.id === 'no_approval_lists') {
              onRowDoubleClick({
                row: Map({
                  id: 'no_approval_lists',
                  projectId: params.data.projectId,
                }),
                type: 'approvals',
              });
            }
          }}
          onRowValueChanged={handleRowValueChanged}
          onSortModelChanged={e => {
            if (gridApi.current) {
              gridApi.current.stopEditing(true);
            }

            if (e && e.length) {
              onSort(e, {
                type: 'approvals',
                direction: e[0].sort === 'asc' ? 'down' : 'up',
                field: e[0].colId,
              });
            }
          }}
          rowData={mapApprovalsData()}
          sortable
        />
      </div>
    </div>
  );
};

Approvals.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func,
  onSort: PropTypes.func.isRequired,
};

export default outsideClick(Approvals);
