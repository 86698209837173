import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List, Map } from 'immutable';

import config from '../../../../config';
import FormGroup from '../../../helpers/FormGroup';
import { ComboSelectBox } from '../../../helpers/ComboSelectBox';
import { CompanyBuyerLeadsTable } from './CompanyBuyerLeadsTable';

const columnDefs = config.tables.getIn(['companyBuyerLeads', 'columnDefs']);

const CompanyBuyerLeadsComponent = ({
  leads,
  statuses,
  onDoubleClickRow,
  onGetNextPageData,
  onCurrentStatusChange,
  onChange,
  isDuplicateCompany,
}) => (
  <div className="flexWrapper full-height flexItem">
    <div className="form-inline row mb5">
      <FormGroup className="col-xs-4">
        <div>
          <label>Current Status</label>
        </div>
        <ComboSelectBox
          className="na-cb-sel-box"
          disabled={isDuplicateCompany}
          name="currentStatus"
          onChange={onCurrentStatusChange}
          options={statuses}
          value={leads.getIn(['status', 'selected'])}
        />
      </FormGroup>
    </div>
    <CompanyBuyerLeadsTable
      columnDefs={columnDefs}
      data={leads}
      onChange={onChange}
      onDoubleClickRow={onDoubleClickRow}
      onGetNextPageData={onGetNextPageData}
    />
  </div>
);

CompanyBuyerLeadsComponent.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
  leads: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onCurrentStatusChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  statuses: PropTypes.instanceOf(List).isRequired,
};

/** Company buyer leads component. */
export const CompanyBuyerLeads = memo(CompanyBuyerLeadsComponent);
