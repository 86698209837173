import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import uniqueId from '../../utils/uniqueId';

const SortableItem = Comp => {
  const WrappedComponent = SortableElement(Comp);

  const Composed = props => {
    const id = uniqueId();

    return <WrappedComponent id={id} {...props} />;
  };

  Composed.displayName = `SortableItem(${Comp.displayName || Comp.name || 'Component'})`;

  return Composed;
};

export { SortableItem };
