import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import IdleTracker from 'idle-tracker';
import { connect } from 'react-redux';

import { LoginForm } from '../components/LoginForm';
import {
  handleLogin,
  handleInputValidation,
  sendGoogle2FAQRCode,
  resetGoogle2FAStatus,
  getStatusByActivityDetector,
} from '../actions/auth';
import { clearCode, hasCode, getIsTwoFa } from '../middleware/activate';

const IDLE_TIME = 3 * 60 * 60 * 1000;
const IDLE_TIMESTAMP = 'idle_timestamp';

const ActivityDetector = ({
  children,
  error,
  isFetching,
  google2FaStatus,
  google2FaSecret,
  handleLogin,
  handleInputValidation,
  resetGoogle2FAStatus,
  sendGoogle2FAQRCode,
  getStatusByActivityDetector,
}) => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    runActivityDetector();
    window.addEventListener('beforeunload', confirmLeavePage);
    window.addEventListener('storage', openModalLogin);
    window.addEventListener('visibilitychange', changeVisibility);

    return () => {
      stopActivityDetector();
      window.removeEventListener('beforeunload', confirmLeavePage);
      window.removeEventListener('storage', openModalLogin);
      window.removeEventListener('visibilitychange', changeVisibility);
    };
  }, []);

  useEffect(() => {
    if (google2FaStatus && google2FaStatus.get('isGoogle2FAPassed')) {
      setIsActive(true);
    }
  }, [google2FaStatus]);

  const deactivateApp = useCallback(() => {
    setIsActive(false);
    localStorage.removeItem(IDLE_TIMESTAMP);
    resetGoogle2FAStatus();
    clearCode();
  }, [resetGoogle2FAStatus, clearCode]);

  const checkDeactivation = useCallback(() => {
    const timeout = Number(localStorage.getItem(IDLE_TIMESTAMP)) + IDLE_TIME;

    return Date.now() > timeout;
  }, []);

  const idleTracker = useMemo(
    () =>
      new IdleTracker({
        timeout: IDLE_TIME,
        onIdleCallback: () => {
          if (checkDeactivation()) {
            deactivateApp();
          }
        },
      }),
    [],
  );

  const confirmLeavePage = event => {
    if (!hasCode()) {
      event.returnValue = '';
    }
  };

  const openModalLogin = useCallback(() => {
    if (google2FaStatus && google2FaStatus.get('isGoogle2FAPassed')) {
      setIsActive(true);
    }
  }, [google2FaStatus]);

  const changeVisibility = () => {
    if (document.visibilityState === 'hidden') {
      localStorage.setItem(IDLE_TIMESTAMP, Date.now().toString());
      stopActivityDetector();
    }

    if (document.visibilityState === 'visible') {
      setTimeout(() => {
        if (getIsTwoFa()) {
          if (checkDeactivation() || !hasCode()) {
            deactivateApp();
            stopActivityDetector();
          } else {
            getStatusByActivityDetector({ afterSuccess: runActivityDetector });
          }
        }

        if (!getIsTwoFa()) {
          if (checkDeactivation() || !hasCode()) {
            deactivateApp();
            stopActivityDetector();
          } else {
            runActivityDetector();
          }
        }
      }, 200);
    }
  };

  const runActivityDetector = useCallback(() => {
    if (!getIsTwoFa()) {
      setIsActive(true);
    }

    if (getIsTwoFa() && google2FaStatus) {
      if (google2FaStatus.get('isGoogle2FAPassed')) {
        setIsActive(true);
      }
    }

    localStorage.setItem(IDLE_TIMESTAMP, Date.now().toString());
    idleTracker.start();
  }, [idleTracker, google2FaStatus]);

  const stopActivityDetector = useCallback(() => {
    idleTracker.end();
  }, [idleTracker]);

  const getValue = (form, fieldName, id) => {
    try {
      return form[fieldName].value;
    } catch (e) {
      try {
        return document.getElementById(id).value;
      } catch (e2) {
        return new window.FormData(form).get(fieldName);
      }
    }
  };

  const handleGoogle2FAAuth = event => {
    event.preventDefault();

    const form = event.currentTarget;
    const confirmationCode = getValue(form, 'confirmationCode', 'confirmationCode');

    sendGoogle2FAQRCode({
      confirmationCode,
      afterSuccess: () => {
        getStatusByActivityDetector({ afterSuccess: runActivityDetector });
      },
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;
    const inputUsername = getValue(form, 'username', 'inputUsername');
    const inputPassword = getValue(form, 'password', 'inputPassword');

    if (!inputUsername) return handleInputValidation('Please input username!');
    if (!inputPassword) return handleInputValidation('Please input password!');

    if (getIsTwoFa()) {
      handleLogin({
        skipOnResetLogin: true,
        inputUsername,
        inputPassword,
        afterSuccess: () => {
          getStatusByActivityDetector({
            afterSuccess: () => {
              if (google2FaStatus && !google2FaStatus.get('isGoogle2FAPassed')) {
                setIsActive(false);
                localStorage.removeItem(IDLE_TIMESTAMP);
                stopActivityDetector();
              } else {
                runActivityDetector();
              }
            },
          });
        },
      });
    } else {
      handleLogin({
        skipOnResetLogin: true,
        inputUsername,
        inputPassword,
        afterSuccess: runActivityDetector,
      });
    }
  };

  return (
    <>
      {!isActive && (
        <div className="modal-activity-detector">
          <LoginForm
            caption={<div className="caption-activity-detector">Session has expired. Please login to continue.</div>}
            error={error}
            google2FaSecret={google2FaSecret}
            google2FaStatus={google2FaStatus}
            isFetching={isFetching}
            onGoogle2FAAuth={handleGoogle2FAAuth}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      {children}
    </>
  );
};

ActivityDetector.propTypes = {
  error: PropTypes.string.isRequired,
  getStatusByActivityDetector: PropTypes.func.isRequired,
  handleInputValidation: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resetGoogle2FAStatus: PropTypes.func.isRequired,
  sendGoogle2FAQRCode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFetching: state.auth.get('isFetching'),
  google2FaStatus: state.auth.get('google2FaStatus'),
  google2FaSecret: state.auth.get('google2FaSecret'),
  error: state.auth.get('error'),
  location: state.router.location,
});

export { ActivityDetector };
export default memo(
  connect(mapStateToProps, {
    handleLogin,
    sendGoogle2FAQRCode,
    handleInputValidation,
    resetGoogle2FAStatus,
    getStatusByActivityDetector,
  })(ActivityDetector),
);
