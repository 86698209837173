import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { Map } from 'immutable';
import DatePicker from 'react-datepicker';

import { BrowseCheckbox } from './BrowseCheckbox';

const MAX_DATE_TOOLTIP =
  'Selecting the End Date criteria while leaving the values blank will search for Retainers with No End Date';

const BrowseDateFilterComponent = ({ onChange, filter, info }) => {
  const handleStartDateChange = useCallback(date => {
    onChange({
      filterName: filter.get('name'),
      filterData: {
        min: date.isValid() ? date : null,
      },
    });
  }, []);

  const handleEndDateChange = useCallback(date => {
    onChange({
      filterName: filter.get('name'),
      filterData: {
        max: date.isValid() ? date : null,
      },
    });
  }, []);

  const handleRemoveDate = dateType => {
    onChange({
      filterName: filter.get('name'),
      filterData: {
        [dateType]: null,
      },
    });
  };

  return (
    <BrowseCheckbox filter={filter} info={info} onChange={onChange}>
      <div className="BrowseControl-content BrowseControl-content--activity">
        <div className="form-inline">
          <div className="form-group">
            <label>From</label>
            <div className="d-flex">
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={handleStartDateChange}
                selected={info.get('min')}
                showMonthDropdown
                showYearDropdown
              />
              <button
                className="ml10 close btn-xs"
                onClick={() => {
                  handleRemoveDate('min');
                }}
              >
                &times;
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>To</label>
            <div className="d-flex">
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                onChange={handleEndDateChange}
                selected={info.get('max')}
                showMonthDropdown
                showYearDropdown
              />
              <button
                className="ml10 close btn-xs"
                onClick={() => {
                  handleRemoveDate('max');
                }}
              >
                &times;
              </button>
              <i
                aria-hidden="true"
                className="fa fa-question max-date-help"
                onClick={event => event.preventDefault()}
                title={MAX_DATE_TOOLTIP}
              />
            </div>
          </div>
        </div>
      </div>
    </BrowseCheckbox>
  );
};

BrowseDateFilterComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  submitOnEnter: PropTypes.bool,
};

/** Browse date filter component. */
export const BrowseDateFilter = memo(BrowseDateFilterComponent);
