import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

/**
 * Stateless component for text input.
 *
 * @param props {Object}.
 * @param props.formError {Immutable.Map} Form error object.
 * @param props.formMode {String} Disabled state of control (view|edit).
 * @param props.errors {Immutable.List} Error list from api response.
 * @class
 */
const ErrorSummaryComponent = ({ formError, errors, formMode }) => {
  if (formMode === 'view') {
    return null;
  }

  const formErrorContent = printFormError(formError);
  const errorContent = printError(errors);

  return (
    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-2">
      {formErrorContent}
      {errorContent}
    </div>
  );
};

ErrorSummaryComponent.propTypes = {
  errors: PropTypes.instanceOf(Immutable.List).isRequired,
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
};

export const ErrorSummary = memo(ErrorSummaryComponent);

const printFormError = errors => {
  const errorArray = errors.toArray().filter(error => error && error.length > 0);

  if (errorArray.length > 0) {
    return (
      <ul className="mt10">
        {errorArray.map((error, i) => (
          <li key={i} className="text-danger">
            {error}
          </li>
        ))}
      </ul>
    );
  }

  return null;
};

const printError = errors => {
  if (errors.size > 0) {
    return (
      <ul className="mt10">
        {errors.map((error, i) => (
          <li key={i} className="text-danger">
            {error}
          </li>
        ))}
      </ul>
    );
  }

  return null;
};
