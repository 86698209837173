import React, { useState, useEffect, useRef, memo } from 'react';

const AgHeader = ({ column, setSort, api }) => {
  const [sorted, setSorted] = useState('');
  const headerRef = useRef(null);

  useEffect(() => {
    const handleSortChanged = () => {
      if (column.isSortAscending()) {
        setSorted('asc');
      } else if (column.isSortDescending()) {
        setSorted('desc');
      } else {
        setSorted('');
      }
    };

    column.addEventListener('sortChanged', handleSortChanged);
    handleSortChanged();

    return () => {
      column.removeEventListener('sortChanged', handleSortChanged);
    };
  }, [column]);

  const handleSort = event => {
    let newSort = '';

    if (sorted === '') {
      newSort = 'asc';
    } else if (sorted === 'asc') {
      newSort = 'desc';
    }
    setSort(newSort, event.shiftKey);
  };

  const handleClick = event => {
    if (column.colDef.onClick) {
      column.colDef.onClick(event, api);
    }
  };

  return (
    <div ref={headerRef} className="ag-header-cell-label">
      {column.colDef.onClick && (
        <span onClick={handleClick}>
          <i className="fa fa-plus mr5" />
        </span>
      )}
      <span className="mr5" onClick={column.colDef.enableSorting || column.colDef.sortable ? handleSort : undefined}>
        {column.colDef.headerName}
      </span>
      {sorted === 'asc' && <i className="fa fa-caret-down" onClick={handleClick} />}
      {sorted === 'desc' && <i className="fa fa-caret-up" onClick={handleClick} />}
    </div>
  );
};

export default memo(AgHeader);
