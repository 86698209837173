import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import Helmet from 'react-helmet';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import config from '../config';
import Main from '../components/Main';
import debounce from '../utils/debounce';
import UrlMaker from '../routing/UrlMaker';
import { subscribe } from '../decorators/tabChange';
import { handleLogout } from '../actions/auth';
import * as dashboardsActionCreators from '../actions/dashboards';
import { checkAccess } from '../utils/checkPermissions';
import downloadFile from '../utils/downloadFile';

const { main } = config;

/** @class
 *  Main page container with dashboards.
 *  Change title to `"Main Page"`.
 */
export class MainContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hasAccess: false,
      isDataFiltered: true,
    };

    this.handleCCVisitsReport = this.handleCCVisitsReport.bind(this);
    this.onOffersAndBetterClick = this.onOffersAndBetterClick.bind(this);
    this.onRetainerReportClick = this.onRetainerReportClick.bind(this);
    this.onLeadsAndBetterClick = this.onLeadsAndBetterClick.bind(this);
    this.handleOutreachReport = this.handleOutreachReport.bind(this);
    this.handleCeoOutreachReport = this.handleCeoOutreachReport.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onLogOff = this.onLogOff.bind(this);
    this.onConfirmLogout = this.onConfirmLogout.bind(this);
    this.onCancelLogout = this.onCancelLogout.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.triggerVisibility = this.triggerVisibility.bind(this);
    this.onWidgetClick = this.onWidgetClick.bind(this);
    this.onResizeEnd = this.onResizeEnd.bind(this);
    this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onColumnResize = this.onColumnResize.bind(this);
    this.saveWidgetState = debounce(this.saveWidgetState.bind(this), 100);
    this.handleTabNotification = this.handleTabNotification.bind(this);
    this.onSwitchFilterData = this.onSwitchFilterData.bind(this);
    this.handleChangePD = this.handleChangePD.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const load = this.props.loadWidgetData;
    const names = nextProps.widgets
      .filter(
        widget =>
          widget.get('visible') &&
          !widget.get('loaded') &&
          !widget.get('loading') &&
          !this.props.widgets.get(widget.get('portlet')).get('visible'),
      )
      .map(w => w.get('portlet'))
      .toList()
      .toJS();

    if (!names.length) return;
    names.map(name => load({ name }));
  }

  componentDidMount() {
    const hasAccess = checkAccess(this.props.roles.get(0));

    this.setState({ hasAccess });
    this.props.loadWidgets({ userId: this.props.user.get('id') });
  }

  /**
   * Calls from DashboardPanel for Research widget.
   * Switch isDataFiltered value.
   */
  onSwitchFilterData() {
    if (this.state.isDataFiltered) {
      this.props.loadWidgetData({ name: 'researches', query: { all: 1 } });
    } else {
      this.props.loadWidgetData({ name: 'researches' });
    }

    this.setState(prevState => ({ isDataFiltered: !prevState.isDataFiltered }));
  }

  onConfirmLogout() {
    this.context.closePopup();
    this.props.onLogout();
  }

  onCancelLogout() {
    this.context.closePopup();
  }

  onLogOff(event) {
    event.preventDefault();

    const name = 'LogoutPopup';
    const props = {
      onConfirm: this.onConfirmLogout,
      onCancel: this.onCancelLogout,
    };

    this.context.openPopup(name, props);
  }

  onDragStart(event, name) {
    const drag = event.currentTarget;
    const rect = drag.getBoundingClientRect();

    this.props.startDrag({
      name,
      x: rect.left,
      y: rect.top,
    });
  }

  onDragEnd(event, name) {
    event.preventDefault();
    this.saveWidgetState();

    // do not remove this (any e.*target can display to document, and find no other way to do this pretty)
    const elem = document.querySelector(`div[name="${name}"]`).parentElement;
    const rect = elem.getBoundingClientRect();

    this.props.drag({
      name,
      x: rect.left,
      y: rect.top,
    });
  }

  triggerVisibility(event, name) {
    this.saveWidgetState();
    this.props.triggerWidgetVisibility({ name });
  }

  onWidgetClick(event, name) {
    this.saveWidgetState();
    this.props.moveWidgetToTop({ name });
  }

  onSearchChange(event) {
    this.props.changeSearchText(event.target.value);
  }

  handleSearch() {
    const { searchText } = this.props;

    window.open(`/search?text=${encodeURIComponent(searchText)}`, '_blank');
  }

  onColumnResize(columns, name) {
    this.props.changeField({
      field: ['widgets', name, 'columns'],
      value: fromJS(columns),
    });
  }

  onResizeEnd(event, extra, name) {
    const { size } = extra;
    const { width, height } = size;

    this.props.setWidgetSizes({
      name,
      height,
      width,
    });
  }

  handleCCVisitsReport(event) {
    event.preventDefault();
    this.context.openPopup('CCVisitReportPopup', {
      onOk: () => {
        this.context.closePopup();
      },
    });
  }

  handleOutreachReport(event) {
    event.preventDefault();
    this.context.openPopup('OutreachReportPopup', {
      onOk: () => {
        this.context.closePopup();
      },
    });
  }

  handleCeoOutreachReport(event) {
    event.preventDefault();
    this.context.openPopup('CeoOutreachReportPopup', {
      onOk: () => {
        this.context.closePopup();
      },
    });
  }

  /**
   * Handle click activity report.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  handleActivityReportClick = event => {
    event.preventDefault();

    this.context.openPopup('ActivityReportPopup');
  };

  /**
   * Handle click conflict report.
   *
   * @param {SyntheticEvent} event The react `SyntheticEvent`.
   */
  handleConflictReportClick = event => {
    event.preventDefault();

    this.context.openPopup('ConflictReportPopup');
  };

  handleChangePD = (event, data) => {
    this.props.selectIds({ name: 'pd', order: data.order });
  };

  handleChangeAllPD = event => {
    const { selectAllIds, resetAllIds } = this.props;

    if (event.target.checked) {
      selectAllIds({ name: 'pd' });
    } else {
      resetAllIds({ name: 'pd' });
    }
  };

  handleConfirmDeletion = () => {
    const { widgets, loadWidgetData, confirmOrRejectDeletion } = this.props;
    const selectedIds = widgets
      .getIn(['pd', 'data'])
      .filter(selected => selected.get('selected'))
      .map(selected => selected.get('id'))
      .toJS();

    this.context.openPopup('ConfirmPopup', {
      message: 'Are you sure you want to confirm deletion?',
      yes: 'Confirm',
      no: 'Cancel',
      onOk: () => {
        confirmOrRejectDeletion('confirm', selectedIds, () => {
          loadWidgetData({ name: 'pd' });
        });
        this.context.closePopup();
      },
      onCancel: () => this.context.closePopup(),
    });
  };

  handleConfirmReject = () => {
    const { widgets, loadWidgetData, confirmOrRejectDeletion } = this.props;
    const selectedIds = widgets
      .getIn(['pd', 'data'])
      .filter(selected => selected.get('selected'))
      .map(selected => selected.get('id'))
      .toJS();

    confirmOrRejectDeletion('reject', selectedIds, () => {
      loadWidgetData({ name: 'pd' });
    });
  };

  onOffersAndBetterClick(event) {
    event.preventDefault();
    downloadFile({ url: this.props.offersAndBetterReportUrl });
  }

  onRetainerReportClick(event) {
    event.preventDefault();
    downloadFile({ url: this.props.retainerReportUrl });
  }

  onLeadsAndBetterClick(event) {
    event.preventDefault();
    downloadFile({ url: this.props.leadsAndBetterReportUrl });
  }

  onRowDoubleClick(meta, name) {
    if (!name) return;

    if (name === 'backlog') {
      return;
    }

    if (name === 'tcna') {
      return MainContainer.onTCNARowClick(meta.data);
    }

    const template = this.props.widgets.getIn([name, 'urlTemplate'], '');

    if (!template) return;

    const tempUrl = UrlMaker.create(template || '')
      .mapParams(meta.data)
      .replace(/\/\?.*$/, '');

    window.open(tempUrl);
  }

  /**
   * Dashboard panel double click handler.
   *
   * @param {object} meta Dashboard panel meta data.
   * @param {string} name Dashboard panel name.
   */
  static onCellDoubleClick(meta, name) {
    if (!name) return;

    if (name === 'backlog') {
      return MainContainer.onBacklogCellClick(meta.column.colId, meta.data);
    }

    if (name === 'researches') {
      return MainContainer.onResearchCellClick(meta.column.colId, meta.data);
    }

    if (name === 'tcp') {
      return MainContainer.onTcpCellDoubleClick(meta.column.colId, meta.data);
    }

    if (name === 'pd') {
      return MainContainer.onPDCellDoubleClick(meta.column.colId, meta.data);
    }
  }

  static onPDCellDoubleClick(colName, meta) {
    if (colName === 'companyLegalName') {
      window.open(`/company/${meta.companyId}`);
    }
  }

  static onTcpCellDoubleClick(colName, meta) {
    if (colName === 'buyerLegalName') {
      window.open(`/company/${meta.buyerId}/buyer/marketing`);
    }
  }

  static onBacklogCellClick(colName, meta) {
    switch (colName) {
      case 'projectCategory':
        window.open(`/project/${meta.projectId}`);
        break;

      case 'buyerLegalName':
        window.open(`/company/${meta.buyerId}/buyer/`);
        break;

      case 'activity':

      // fallthrough
      case 'targetCount':
        window.open(
          `/mailing-create?recordOwnerId=${meta.recordOwnerId}&recordSubOwnerId=${meta.recordSubOwnerId}&buyerId=${meta.buyerId}&projectId=${meta.projectId}&activity=${meta.activity}&isEmail=${meta.isemail}&isLetter=${meta.isletter}&beforeDate=${meta.date}`,
        );
        break;

      default:
        break;
    }
  }

  static onResearchCellClick(colName, meta) {
    colName = colName.replace('_1', '');

    if (colName === 'legalName') {
      window.open(`/company/${meta.id}`);
    } else if (colName === 'web') {
      if (meta[colName].search(/http|s\:\/\//) === -1) {
        window.open(`http://${meta[colName]}`);

        return;
      }
      window.open(meta[colName]);
    } else if (colName === 'userName' && meta[colName]) {
      window.open(`/browse/targets?researcher=${meta[colName]}&userId=${meta.userId}`);
    }
  }

  static onTCNARowClick({ targetId, buyerId }) {
    if (targetId) {
      window.open(`/company/${targetId}`);
    } else {
      window.open(`/company/${buyerId}/buyer`);
    }
  }

  onSort(order, widget) {
    this.props.sortWidgetData({ widget, order });
  }

  handleTabNotification(val, key) {
    const portlets = { onEventChange: ['tcna', 'ttl'] };
    const portletNames = portlets[key];
    const load = this.props.loadWidgetData;
    const names = this.props.widgets.filter(
      widget => widget.get('visible') && portletNames.indexOf(widget.get('portlet')) > -1,
    );

    names.forEach(widget => {
      load({ name: widget.get('portlet') });

      return true;
    });
  }

  saveWidgetState() {
    const userId = this.props.user.get('id');
    const widgets = this.props.widgetsOrder
      .filter(w => w)
      .map(widget => this.props.widgets.get(widget))
      .filter(widget => widget.get('visible') === true)
      .map(widget => ({
        portlet: widget.get('portlet'),
        x: widget.getIn(['style', 'left'], 0),
        y: widget.getIn(['style', 'top'], 0),
        w: widget.getIn(['style', 'width'], 200),
        h: widget.getIn(['style', 'height'], 200),
        c: widget.get('columns').toJS(),
      }))
      .toArray();

    this.props.saveWidgets({ userId, widgets });
  }

  render() {
    const { props } = this;
    const isDisabledSearch = props.searchText.trim() === '';

    return (
      <div className="flexWrapper full-height">
        <Helmet title="Main Page" />
        <Main
          {...props}
          currentUser={props.user}
          hasAccess={this.state.hasAccess}
          isDataFiltered={this.state.isDataFiltered}
          isDisabledSearch={isDisabledSearch}
          onActivityReportClick={this.handleActivityReportClick}
          onCCVisitsReport={this.handleCCVisitsReport}
          onCellDoubleClick={MainContainer.onCellDoubleClick}
          onCeoOutreachReport={this.handleCeoOutreachReport}
          onChangeAllPD={this.handleChangeAllPD}
          onChangePD={this.handleChangePD}
          onColumnResize={this.onColumnResize}
          onConfirmDeletion={this.handleConfirmDeletion}
          onConfirmReject={this.handleConfirmReject}
          onConflictReportClick={this.handleConflictReportClick}
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
          onLeadsAndBetterClick={this.onLeadsAndBetterClick}
          onLogOff={this.onLogOff}
          onOffersAndBetterClick={this.onOffersAndBetterClick}
          onOutreachReport={this.handleOutreachReport}
          onResizeEnd={this.onResizeEnd}
          onRetainerReportClick={this.onRetainerReportClick}
          onRowDoubleClick={this.onRowDoubleClick}
          onSearch={this.handleSearch}
          onSearchChange={this.onSearchChange}
          onSort={this.onSort}
          onSwitchFilterData={this.onSwitchFilterData}
          onWidgetClick={this.onWidgetClick}
          triggerVisibility={this.triggerVisibility}
        />
      </div>
    );
  }
}

MainContainer.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

MainContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    headerButtons: main.getIn(['header', 'buttons']),
    offersAndBetterReportUrl: main.get('offersAndBetterReport'),
    retainerReportUrl: main.get('retainerReport'),
    leadsAndBetterReportUrl: main.get('leadsAndBetterReport'),
    controls: main.get('controls'),
    roles: state.auth.getIn(['user', 'roles']),
    searchText: state.dashboards.get('searchText'),
    widgets: state.dashboards.get('widgets'),
    widgetsOrder: state.dashboards.get('widgetsOrder'),
    user: state.auth.get('user', null),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(dashboardsActionCreators, dispatch),
    onLogout: () => dispatch(handleLogout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  connectOptions,
)(subscribe(MainContainer, ['onEventChange']));
