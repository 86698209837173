import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

import { convertDateString } from '../../../utils/dateFormat';
import copyToClipboard from '../../../utils/copyToClipboard';

const LinksTableComponent = props => {
  const { linksList } = props;

  return (
    <div className="links-table-wrap">
      <table className="links-table">
        <thead>
          <tr>
            <th>Links</th>
            <th>Date Created</th>
            <th>Exp Date</th>
          </tr>
        </thead>
        <tbody>
          {linksList.map(link => (
            <>
              <tr key={link.id}>
                <td>
                  <ContextMenuTrigger id={link.id.toString()}>
                    <div className="link-cell">{link.link}</div>
                  </ContextMenuTrigger>
                  <ContextMenu id={link.id.toString()}>
                    <MenuItem data={link} onClick={() => copyToClipboard(link.link)}>
                      <i className="fa fa-copy" /> Copy
                    </MenuItem>
                  </ContextMenu>
                </td>
                <td>{convertDateString(link.createdAt)}</td>
                <td>{convertDateString(link.expiresAt)}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

LinksTableComponent.propTypes = {
  linksList: PropTypes.instanceOf(Array).isRequired,
};

/** Links table component. */
export const LinksTable = memo(LinksTableComponent);
