import React, { useEffect, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { List, Map } from 'immutable';
import { push } from 'connected-react18-router';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import { Search } from '../components/Search';
import config from '../config';
import { detectBottom } from '../utils/scrollbar';
import { searchAll, fetchNextPage, changeSearchingTerm, changeTab } from '../actions/search';
import { openInternalLink } from '../utils/url';
import { checkAccess } from '../utils/checkPermissions';

const SearchContainer = props => {
  const [hasAccess, setHasAccess] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [onlyOwner, setOnlyOwner] = useState(null);
  const [searchByParams, setSearchByParams] = useState([]);

  useEffect(() => {
    const params = [onlyOwner, searchBy].filter(Boolean);

    setSearchByParams(params);
  }, [onlyOwner, searchBy]);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const keyword = searchParams.get('text');
    const access = checkAccess(props.loggedUser.getIn(['roles', 0]));

    setHasAccess(access);

    if (keyword) {
      props.changeSearchingTerm(keyword);
      props.searchAll(keyword, searchByParams);
    }
  }, []);

  const handleSearch = useCallback(() => {
    if (props.searchingTerm.trim() === '') {
      return;
    }

    props.searchAll(props.searchingTerm, searchByParams);
    props.history.push({
      pathname: props.location.pathname,
      search: `text=${props.searchingTerm}`,
    });
  }, [props, searchByParams]);

  const handleOnlyOwnerChange = ({ target: { checked } }) => {
    setOnlyOwner(checked ? 'onlyOwner' : null);
  };

  const handleSearchByChange = ({ target: { name, checked } }) => {
    setSearchBy(checked ? name : null);
  };

  const handleSearchingTermChange = useCallback(
    ({ target: { value } }) => {
      props.changeSearchingTerm(value);
    },
    [props],
  );

  const handleScroll = useCallback(
    event => {
      const { target: container } = event;

      if (detectBottom(container)) {
        const {
          searchingTerm,
          activeTab,
          companiesNextPage,
          peopleNextPage,
          searchingCompanies,
          searchingPeople,
        } = props;

        if (activeTab === 'people' && peopleNextPage && !searchingPeople) {
          props.fetchNextPage(searchingTerm, 'people', peopleNextPage);
        } else if (activeTab === 'companies' && companiesNextPage && !searchingCompanies) {
          props.fetchNextPage(searchingTerm, 'companies', companiesNextPage);
        }
      }
    },
    [props],
  );

  const handleItemClick = useCallback(({ to }) => {
    openInternalLink(to);
  }, []);

  const handleTabChange = useCallback(
    tabName => {
      props.changeTab(tabName);
    },
    [props],
  );

  const name = props.loggedUser.get('module') || '';

  return (
    <div className="full-height">
      <Helmet title="Search Page" />
      <Search
        {...props}
        hasAccess={hasAccess}
        onClick={handleItemClick}
        onOnlyOwnerChange={handleOnlyOwnerChange}
        onScroll={handleScroll}
        onSearch={handleSearch}
        onSearchBy={handleSearchByChange}
        onSearchingTermChange={handleSearchingTermChange}
        onTabChange={handleTabChange}
        searchBy={searchBy}
        userName={name}
      />
    </div>
  );
};

SearchContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(List).isRequired,
  companiesNextPage: PropTypes.number.isRequired,
  dropdownItems: PropTypes.instanceOf(List).isRequired,
  errors: PropTypes.instanceOf(Map).isRequired,
  loggedUser: PropTypes.instanceOf(Map).isRequired,
  people: PropTypes.instanceOf(List).isRequired,
  peopleNextPage: PropTypes.number.isRequired,
  searchingCompanies: PropTypes.bool.isRequired,
  searchingPeople: PropTypes.bool.isRequired,
  searchingTerm: PropTypes.string.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  totalPeople: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  loggedUser: state.auth.get('user'),
  people: state.search.get('people'),
  companies: state.search.get('companies'),
  searchingPeople: state.search.get('searchingPeople'),
  searchingCompanies: state.search.get('searchingCompanies'),
  searchingTerm: state.search.get('searchingTerm'),
  activeTab: state.search.get('activeTab'),
  errors: state.search.get('errors'),
  totalPeople: state.search.get('totalPeople'),
  totalCompanies: state.search.get('totalCompanies'),
  companiesNextPage: state.search.get('companiesNextPage'),
  peopleNextPage: state.search.get('peopleNextPage'),
  dropdownItems: config.values.getIn(['search', 'menu']),
});

export default memo(
  connect(
    mapStateToProps,
    {
      searchAll,
      fetchNextPage,
      changeSearchingTerm,
      changeTab,
      push,
    },
    mergeProps,
    connectOptions,
  )(SearchContainer),
);
