import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { List, Map } from 'immutable';

import Tabs from '../helpers/Tabs';
import UrlMaker from '../../routing/UrlMaker';
import LoadAnimation from '../decorators/LoadAnimation';
import ContactRouting from '../../routing/ContactRouting';

import { ContactFooter } from './ContactFooter';
import { ContactTopPart } from './ContactTopPart';

const ContactComponent = LoadAnimation(
  ({
    showSave,
    onBlurInfo,
    onSubmitInfo,
    onSave,
    tabs,
    values,
    contactInfo,
    contactEventProps,
    children,
    onChange,
    params,
    suffixSuggest,
    ...rest
  }) => {
    const [topHeight, setTopHeight] = useState(0);
    const [topPanel, setTopPanel] = useState();

    const getHeight = elm => {
      let elmHeight;
      let elmMargin;

      if (document.all) {
        // IE
        elmHeight = parseInt(elm.currentStyle.height, 10);
        elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
      } else {
        // Mozilla
        elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
        elmMargin =
          parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) +
          parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
      }

      return elmHeight + elmMargin;
    };

    const onTopPanelRef = input => {
      setTopPanel(input);
    };

    useEffect(() => {
      if (topPanel) {
        setTopHeight(getHeight(topPanel));
      }
    }, [topPanel]);

    const tabsContent = tabs.map(tab => {
      const to = UrlMaker.create(tab.get('to')).mapParams(params);

      return tab.set('to', to);
    });

    const vCardUrl = UrlMaker.create(values.get('vCardUrl')).mapParams(params);

    const availableTabs = Map({
      target: contactInfo.get('hasTargets'),
      buyer: contactInfo.get('hasBuyers'),
      exec: contactInfo.get('isExecutive'),
      lp: contactInfo.get('isLP'),
    });

    if (isNaN(params.contactId)) return children;

    return (
      <div className="full-height contact__main">
        <ContactTopPart
          contactEventProps={contactEventProps}
          contactInfo={contactInfo}
          onBlurInfo={onBlurInfo}
          onChange={onChange}
          onRef={onTopPanelRef}
          onSubmitInfo={onSubmitInfo}
          suffixSuggest={suffixSuggest}
        />

        <div className="container contact__tab-section" role="main" style={{ height: `calc(100% - ${topHeight}px)` }}>
          <div className="highlight wrap-border">
            <Tabs availableTabs={availableTabs} tabs={tabsContent} />
          </div>

          <div className="pt10 top-border-none" style={{ height: 'calc(100% - 36px)' }}>
            <ContactRouting />
          </div>
        </div>

        <ContactFooter {...rest} onSave={onSave} showSave={showSave} values={values} vCardUrl={vCardUrl} />
      </div>
    );
  },
);

ContactComponent.propTypes = {
  /**
   * This component needs children property (passed by `<Comp>{children}</Comp>`).
   * Children should be React.Component.
   */
  children: PropTypes.element,
  onChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  tabs: PropTypes.instanceOf(List).isRequired,
  values: PropTypes.instanceOf(Map).isRequired,
};

/** Contact component. */
export const Contact = memo(ContactComponent);
