import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { CompanyTradeGroups } from '../../components/Company/CompanyTradeGroups';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { getTradeShowList, handleTableSorting } from '../../actions/tradeShow';
import { buildSortByParam } from '../../helpers/paramBuilder';

const CompanyTradeGroupsContainer = props => {
  useEffect(() => {
    const sortBy = buildSortByParam(props.data);

    props.getTradeShowList({ page: 1, sortBy });
  }, [props.sortModel]);

  /**
   * Opens specific tradeshow page for editing.
   *
   * @param row Table's row data.
   */
  const onDoubleClickRow = row => {
    window.open(`/trade-show/${row.data.id}`, '_blank');
  };

  /**
   * Opens trade show add page.
   */
  const openAddPage = () => {
    window.open(`/trade-show/add`, '_blank');
  };

  const onSorting = data => {
    props.handleTableSorting({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  };

  return (
    <CompanyTradeGroups
      data={props.data}
      onAdd={openAddPage}
      onChange={onSorting}
      onDoubleClickRow={onDoubleClickRow}
    />
  );
};

function mapStateToProps(state) {
  return {
    data: state.companyTradeGroups,
    sortModel: state.companyTradeGroups.getIn(['sortBy', 'sortModel']),
  };
}

export default connect(
  mapStateToProps,
  {
    getTradeShowList,
    handleTableSorting,
  },
  mergeProps,
  connectOptions,
)(CompanyTradeGroupsContainer);
