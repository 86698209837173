import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '../helpers/Button';

/**
 * Stateless component for footer with export data.
 *
 * @param {object} props Props.
 * @param {Function} props.onClick Handle export button click.
 * @param {boolean} props.disabled If set to true, disable export button.
 */
const FootWithExportComponent = ({ onClick, disabled }) => (
  <footer className="navbar-fixed-bottom">
    <div className="container">
      <div className="pull-right row">
        <Button className="btn btn-xs" disabled={disabled} onClick={onClick}>
          Export
        </Button>
      </div>
    </div>
  </footer>
);

FootWithExportComponent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const FootWithExport = memo(FootWithExportComponent);
