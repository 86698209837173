import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { ResizableBox } from 'react-resizable';
import Draggable from 'react-draggable';
import classNames from 'classnames';

/**
 * Helper for popup's building.
 *
 * @param props {Object} - Popup's props.
 * @param props.id {String}.
 * @param [props.header = null] {String|React.Element} - Content of Popup's header's <h4> tag.
 * @param props.children {React.Element|React.Component} - Popup's content.
 * @param [props.footer = null] {String|React.Element[]|React.Element} - Content of Popup's header's <h4> tag.
 * @param context {Object} - React's component's context.
 * @returns {React.Component}
 * @class
 */
class Popup extends PureComponent {
  constructor(...rest) {
    super(...rest);

    this.defaultPosition = {
      x: Math.floor((window.innerWidth - 600) / 2),
      y: 25,
    };
  }

  render() {
    const { id, header = null, children, footer = null, loading = null, subheader = null } = this.props;
    const { hasPopup } = this.context;
    const className = classNames('move', hasPopup() ? 'modal fade in' : 'modal fade');
    const display = hasPopup() ? 'block' : 'none';

    const footerContent = footer === null ? footer : <PopupFooter>{footer}</PopupFooter>;
    const renderSubheader = subheader && <div>{subheader}</div>;

    /**
     * Prevent lagging by adding a div element under Draggable element.
     */
    return (
      <div aria-labelledby="myModalLabel" className={className} id={id} role="dialog" style={{ display }} tabIndex="-1">
        <Draggable
          axis="both"
          bounds="parent"
          defaultPosition={this.defaultPosition}
          handle=".handle-draggable"
          position={null}
        >
          <div
            ref={input => {
              this.modal = input;
            }}
            className="modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <PopupHeader draggable>
                {header}
                <div className="subheader">{renderSubheader}</div>
              </PopupHeader>

              <div className="modal-body">{children}</div>

              {footerContent}
              {loading}
            </div>
          </div>
        </Draggable>
      </div>
    );
  }
}

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  footer: PropTypes.element,
  header: PropTypes.element,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Popup.contextTypes = {
  hasPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  isClosablePopup: PropTypes.func.isRequired,
};

/**
 * Helper for popup's building.
 *
 * @param props {Object} - Popup's props.
 * @param props.id {String}.
 * @param [props.header = null] {String|React.Element} - Content of Popup's header's <h4> tag.
 * @param props.children {React.Element|React.Component} - Popup's content.
 * @param [props.footer = null] {String|React.Element[]|React.Element} - Content of Popup's header's <h4> tag.
 * @param context {Object} - React's component's context.
 * @returns {React.Component}
 * @class
 */
class ResizablePopup extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.width = this.props.width;
    this.height = this.props.height;
    this.minConstraints = this.props.minConstraints;
    this.maxConstraints = [600, 500];

    this.updateMaxConstraints = this.updateMaxConstraints.bind(this);

    this.defaultPosition = this.props.defaultPosition || {
      x: Math.floor((window.innerWidth - 600) / 2),
      y: 25,
    };

    this.state = {
      maxConstraints: this.getMaxConstraints(),
      height: 56,
    };
  }

  updateMaxConstraints() {
    this.setState({
      maxConstraints: this.getMaxConstraints(),
    });
  }

  getMaxConstraints() {
    const maxWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 500) - 20;
    const maxHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 450) - 50;

    return [maxWidth, maxHeight];
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateMaxConstraints);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMaxConstraints);
  }

  componentDidUpdate() {
    if (this.header.clientHeight > this.state.height) {
      this.setState(prevState => ({
        maxConstraints: prevState.maxConstraints,
        height: this.header.clientHeight,
      }));
    }
  }

  render() {
    const { id, header, children, footer, loading, minConstraints, onResizeStop } = this.props;
    const { maxConstraints } = this.state;
    const { hasPopup } = this.context;
    const className = hasPopup() ? 'modal fade in' : 'modal fade';
    const display = hasPopup() ? 'block' : 'none';
    const footerContent = footer === null ? footer : <PopupFooter>{footer}</PopupFooter>;

    /**
     * Prevent lagging by adding a div element under Draggable element.
     */
    return (
      <div aria-labelledby="myModalLabel" className={className} id={id} role="dialog" style={{ display }} tabIndex="-1">
        <Draggable
          axis="both"
          bounds="parent"
          defaultPosition={this.defaultPosition}
          handle=".handle-draggable"
          onDrag={event => event.preventDefault()}
          position={null}
        >
          <div>
            <ResizableBox
              className="modal-dialog"
              height={this.height}
              maxConstraints={maxConstraints}
              minConstraints={minConstraints}
              onResizeStop={onResizeStop}
              role="document"
              width={this.width}
            >
              <div className="modal-content h100p">
                <PopupHeader
                  onRef={input => {
                    this.header = input;
                  }}
                  draggable
                  noWrapText
                >
                  {header}
                </PopupHeader>
                <div
                  className="modal-body"
                  style={{
                    height: `calc(100% - ${this.state.height}px - 52px)`,
                  }}
                >
                  {children}
                </div>
                {footerContent}
                {loading}
              </div>
            </ResizableBox>
          </div>
        </Draggable>
      </div>
    );
  }
}

ResizablePopup.propTypes = {
  children: PropTypes.element,
  footer: PropTypes.element,
  header: PropTypes.element,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  minConstraints: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onResizeStop: PropTypes.func,
  width: PropTypes.number.isRequired,
};

ResizablePopup.contextTypes = {
  hasPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  isClosablePopup: PropTypes.func.isRequired,
};

ResizablePopup.defaultProps = {
  header: null,
  footer: null,
  loading: null,
  minConstraints: [500, 450],
};

/**
 * Popup footer's wrapper.
 *
 * @param props {Object}.
 * @param props.children {*} Popup footer content.
 * @returns {React.Component}
 * @private
 * @class
 */
const PopupFooter = props => {
  const { children } = props;

  return <div className="modal-footer">{children}</div>;
};

PopupFooter.propTypes = {
  children: PropTypes.element.isRequired,
};

/**
 * Popup header's wrapper.
 *
 * @param props {Object} Popup header's props.
 * @param [props.children=''] {*} Popup header's content. Will be wrapped into `<h4 class="modal-title">`.
 * @param context {Object} React's component's context.
 * @param context.closePopup {Function} Callback to close current popup.
 * @returns {React.Component}
 * @private
 * @class
 */
const PopupHeader = (props, context) => {
  const { children = '', className = '', noWrapText = false, draggable = false, onRef } = props;
  const { closePopup, isClosablePopup } = context;

  const headerButton = isClosablePopup() ? (
    <button aria-label="Close" className="close" onClick={closePopup} tabIndex="-1" type="button">
      <span aria-hidden="true"> × </span>
    </button>
  ) : null;
  const headClass = classNames('modal-header', className, {
    'truncate-text': noWrapText,
    'handle-draggable': draggable,
  });

  return (
    <div ref={onRef} className={headClass}>
      {headerButton}
      <h4 className="modal-title"> {children} </h4>
    </div>
  );
};

PopupHeader.propTypes = {
  children: PropTypes.node,
};

PopupHeader.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  isClosablePopup: PropTypes.func.isRequired,
};

export default Popup;
export { ResizablePopup };
