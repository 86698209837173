import moment from 'moment';

import { isIsoString } from './isISOString';

/**
 * Check object's fields on ISO date format and transform it to the moment() instance.
 *
 * @param {object} obj Input object.
 */
export function momentizeObjValues(obj) {
  Object.keys(obj).forEach(key => {
    if (isIsoString(obj[key])) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = moment(obj[key]);
    }
  });
}
