import React, { memo } from 'react';

import { Checkbox } from './Checkbox';

const TagRowComponent = ({ children }) => <div className="tag-row">{children}</div>;

export const TagRow = memo(TagRowComponent);

export const ItemComponent = ({ tag, onChange, onClick }) => {
  if (!tag) return <div />;

  const children = tag.get('children');
  const collapsedElements =
    children.size > 0 && !tag.get('collapsed', true) ? (
      <ul>
        {children.map(sub => (
          <li key={sub.get('id')}>
            <Checkbox
              checked={sub.get('checked', false)}
              id={sub.get('id')}
              name={sub.get('category')}
              onChange={onChange}
              parentId={tag.get('id')}
            />
          </li>
        ))}
      </ul>
    ) : null;

  return (
    <div>
      <Checkbox
        checked={tag.get('checked', false)}
        collapsed={tag.get('collapsed', true)}
        id={tag.get('id')}
        name={tag.get('category')}
        onChange={onChange}
        onClick={onClick}
      />
      {collapsedElements}
    </div>
  );
};

export const Item = memo(ItemComponent);
