import React, { useRef, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { createPortal } from 'react-dom';

import config from '../../config';
import uniqueId from '../../utils/uniqueId';
import isFunction, { execIfFunction } from '../../utils/isFunction';

/**
 * Auto-suggestion functional component.
 *
 * @param {object} props
 */
const AutoSuggestionComponent = ({
  id,
  name,
  value = '',
  className = 'form-control',
  suggestions,
  mode,
  data,
  onTextChange,
  setInputElement,
  onSuggestionSelect,
  onSuggestionClose,
  onSuggestionInputClick,
  disabled,
  hasError,
  placeholder,
  autoFocus = false,
  width,
}) => {
  const menuId = useRef(uniqueId());
  const inputId = useRef(id || uniqueId());
  const inputRef = useRef(null);

  useEffect(() => {
    const menu = document.getElementById(menuId.current);
    const input = document.getElementById(inputId.current);

    if (menu && input) {
      const rect = input.getBoundingClientRect();

      menu.style.display = 'block';
      menu.style.left = `${rect.left}px`;
      menu.style.top = `${rect.top + rect.height}px`;
      menu.style.width = width ? `${width}px` : `${rect.width}px`;
      menu.style.zIndex = '1200';
    }

    return () => {
      if (menu) menu.style.display = 'none';
    };
  }, [mode, suggestions, width]);

  const handleInputChange = useCallback(
    event => {
      execIfFunction(onTextChange, { event, data });
    },
    [onTextChange, data],
  );

  const handleInputClick = useCallback(
    event => {
      if (isFunction(onSuggestionInputClick)) {
        onSuggestionInputClick({ event });
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [onSuggestionInputClick],
  );

  const handleSuggestionSelect = useCallback(
    (event, suggestion) => {
      event.preventDefault();
      event.stopPropagation();
      execIfFunction(onSuggestionSelect, {
        event: { target: { name, value } },
        data,
        suggestion,
      });
    },
    [onSuggestionSelect, name, value, data],
  );

  const renderMenu = useCallback(
    (mode, suggestions) => {
      const empty =
        suggestions && suggestions.size === 0 && mode !== config.LOADING_MODE ? (
          <li>
            <a role="menuitem">No data</a>
          </li>
        ) : null;

      if (mode === config.SUGGEST_MODE) {
        const container = (
          <ul className="auto-suggestion dropdown-menu" id={menuId.current}>
            {empty}
            {suggestions.map((suggestion, index) => (
              <li key={index}>
                <a onClick={event => handleSuggestionSelect(event, suggestion)}>{suggestion.get('name')}</a>
              </li>
            ))}
          </ul>
        );

        return createPortal(container, document.body);
      }

      if (mode === config.LOADING_MODE) {
        const container = (
          <ul className="dropdown-menu" id={menuId.current}>
            <li className="text-center">
              <i className="fa fa-spinner fa-pulse fa-fw" />
            </li>
          </ul>
        );

        return createPortal(container, document.body);
      }

      return null;
    },
    [handleSuggestionSelect],
  );

  useEffect(() => {
    if (isFunction(setInputElement)) {
      setInputElement(inputRef.current);
    }
  }, [setInputElement]);

  const errorClass = hasError ? 'has-error' : '';

  return (
    <div className={errorClass}>
      <input
        ref={inputRef}
        autoComplete="off"
        autoFocus={autoFocus}
        className={className}
        disabled={disabled}
        id={inputId.current}
        name={name}
        onChange={handleInputChange}
        onClick={handleInputClick}
        placeholder={placeholder}
        type="text"
        value={value}
      />
      {renderMenu(mode, suggestions)}
    </div>
  );
};

AutoSuggestionComponent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSuggestionClose: PropTypes.func,
  onSuggestionInputClick: PropTypes.func,
  onSuggestionSelect: PropTypes.func,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  setInputElement: PropTypes.func,
  suggestions: PropTypes.instanceOf(Immutable.List).isRequired,
  value: PropTypes.string,
  width: PropTypes.number,
};

export const AutoSuggestion = memo(AutoSuggestionComponent);
