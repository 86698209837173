import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'connected-react18-router';
import { bindActionCreators } from 'redux';

import * as browseActionCreators from '../../actions/browse';
import { BrowseAutoComplete } from './BrowseAutoComplete';
import { BrowseCheckbox } from './BrowseCheckbox';
import { Tag } from './Tag';
import Checkbox from '../helpers/Checkbox';

class BrowseCountryFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleGetNextAllCountriesSuggestion = this.handleGetNextAllCountriesSuggestion.bind(this);
    this.focusOut = false;
  }

  componentDidUpdate() {
    if (this.focusOut) {
      this.button.focus();
      this.focusOut = false;
    }
  }

  handleCheckboxChange() {
    const { info, type, onChange, handleGetContinentSuggestion, handleGetAllCountriesSuggestion } = this.props;
    const checked = info.get('checked');

    if (!checked) {
      handleGetContinentSuggestion(type, '');
      handleGetAllCountriesSuggestion();
    }

    onChange({
      filterName: 'country',
      filterData: {
        checked: !checked,
      },
    });
  }

  handleGetNextAllCountriesSuggestion({ value }) {
    const { allCountriesSuggestions, filter, onChange } = this.props;
    const suggestions = allCountriesSuggestions.filter(country =>
      country.text.toLowerCase().includes(value.toLowerCase()),
    );

    onChange({
      filterName: filter.get('name'),
      filterData: {
        suggestions,
      },
    });
  }

  handleChange(filterName, filterData, info) {
    if (filterData.selected) {
      let selectedList = info.get('selectedList');
      const selected = fromJS(filterData.selected);
      const field = selected.has('id') ? 'id' : 'value';

      if (selectedList.findIndex(s => s.get(field) === selected.get(field)) === -1) {
        selectedList = selectedList.push(selected.set('include', true));
        filterData.selectedList = selectedList;
      }
      this.focusOut = true;
    }
    this.props.onChange({
      filterName,
      filterData,
    });
  }

  handleRemoveTag(event, info, filter) {
    event.stopPropagation();

    const { name } = event.target;
    const tagIndex = parseInt(name.replace('tag', ''), 10);
    let selectedList = info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    this.props.onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  handleChangeStatus(index, info, filter) {
    const { onChange } = this.props;
    const selectedList = info.get('selectedList');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList: selectedList.setIn([index, 'exclude'], !selectedList.getIn([index, 'exclude'])),
      },
    });
  }

  handleAddTag(info, filter) {
    const { onChange } = this.props;

    if (!filter.get('enableDynamicAdd', false) || info.get('text', '') === '') return false;

    let selectedList = info.get('selectedList');
    const selected = fromJS({
      value: info.get('text'),
      name: info.get('text'),
    });

    if (selectedList.findIndex(s => s.get('value') === selected.get('value')) === -1) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: filter.get('name'),
        filterData: {
          text: '',
          selectedList,
        },
      });
    }
  }

  render() {
    const { filter, continents, info, onGetCountriesByContinent } = this.props;

    const tagListContentCountry = info
      .get('selectedList')
      .filter(tag => tag)
      .map((tag, index) => {
        const continent = continents.find(continent => continent.id === tag.get('continentId'));
        const value = (
          <>
            <span>{tag.get('text')}</span>{' '}
            {continent && (
              <>
                (<i className="font-size-10">{continent.name}</i>)
              </>
            )}
          </>
        );

        return (
          <Tag
            key={index}
            exclude={tag.get('exclude')}
            index={index}
            onChangeStatus={index => this.handleChangeStatus(index, info, filter)}
            onRemoveTag={event => this.handleRemoveTag(event, info, filter)}
            value={value}
          />
        );
      });

    const continentCheckboxes =
      continents &&
      continents.map(continent => {
        const id = `continent_${continent.id}`;

        return (
          <Checkbox
            key={id}
            className="mt3"
            id={id}
            label={continent.name}
            name={id}
            onChange={event => onGetCountriesByContinent(continent.id, event)}
            value={continent.id}
          />
        );
      });

    const autocompleteCountry = (
      <>
        <div className="form-inline">
          <BrowseAutoComplete
            filter={filter}
            info={info}
            onChange={({ filterName, filterData }) => this.handleChange(filterName, filterData, info)}
            onGetNextSuggestion={this.handleGetNextAllCountriesSuggestion}
            onGetSuggestion={this.handleGetNextAllCountriesSuggestion}
            highlightFirstSuggestion
          >
            <span
              ref={input => {
                this.button = input;
              }}
              className="input-group-addon"
              onClick={() => this.handleAddTag(info, filter)}
              tabIndex="-1"
            >
              <i aria-hidden="true" className="fa fa-plus" />
            </span>
          </BrowseAutoComplete>
        </div>
        {tagListContentCountry}
      </>
    );

    return (
      <BrowseCheckbox filter={filter} info={info} onChange={this.handleCheckboxChange}>
        <div className="BrowseControl-content BrowseControl-content--add">
          <div className="mb10">{continentCheckboxes}</div>
          <div className="mb10">{autocompleteCountry}</div>
        </div>
      </BrowseCheckbox>
    );
  }
}
BrowseCountryFilter.propTypes = {
  continents: PropTypes.instanceOf(Map).isRequired,
  filter: PropTypes.instanceOf(Map).isRequired,
  handleGetAllCountriesSuggestion: PropTypes.func.isRequired,
  handleGetContinentSuggestion: PropTypes.func.isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetCountriesByContinent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(browseActionCreators, dispatch),
  push,
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowseCountryFilter);
