import { fromJS } from 'immutable';
import isFunction from '../../utils/isFunction';

const DEFAULT_OPTIONS = {
  fields: {
    input: 'inputErrors',
    message: 'error',
    valid: 'isValid',
  },
};

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param type {String|Symbol} Action type that should handle error.
 * @param [options] {Object} Options object.
 * @param reducer {Function}.
 * @returns {*}
 */
export default function handleError(type, options = DEFAULT_OPTIONS, reducer) {
  if (isFunction(options)) return handleError(type, undefined, options);
  options = mergeWithDefaults(options);
  options = prepareFields(options);

  return function(state, action) {
    if (action.type === type) {
      let isValid = true;
      let message = 'Unknown error';
      let inputErrors = {};

      if (action.response) {
        switch (action.response.statusCode) {
          case 400:
            isValid = false;
            message = action.response.message || message;
            inputErrors = getInputErrors(action);
            break;

          default:
            break;
        }
      }
      state = state
        .setIn(options.fields.input, fromJS(inputErrors).set('errorsFromApi', true))
        .setIn(options.fields.message, message)
        .setIn(options.fields.valid, isValid);
    }

    return reducer(state, action);
  };
}

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param type {String|Symbol} Action type that should handle error.
 * @param [options] {Object} Options object.
 * @param reducer {Function}.
 * @param prefixGetter {Function}.
 * @returns {*}
 */
export function withFieldPrefix(type, options = DEFAULT_OPTIONS, reducer, prefixGetter) {
  if (isFunction(options)) return withFieldPrefix(type, undefined, options, reducer);
  options = mergeWithDefaults(options);
  options = prepareFields(options);

  return function(state, action) {
    if (action.type === type) {
      let isValid = true;
      let message = 'Unknown error';
      let inputErrors = {};

      if (action.response) {
        switch (action.response.statusCode) {
          case 400:
            isValid = false;
            message = action.response.message || message;
            inputErrors = prefixGetter(state, action, getInputErrors(action));
            break;

          default:
            break;
        }
      }

      state = state
        .setIn(options.fields.input, fromJS(inputErrors).set('errorsFromApi', true))
        .setIn(options.fields.message, message)
        .setIn(options.fields.valid, isValid);
    }

    return reducer(state, action);
  };
}

/**
 * Normalize #value name in action object to int or default value.
 *
 * @param type {String|Symbol} Action type that should handle error.
 * @param [options] {Object} Options object.
 * @param reducer {Function}.
 * @param prefixGetter {Function}.
 * @returns {*}
 */
export function withStatePrefix(type, options = DEFAULT_OPTIONS, reducer, prefixGetter) {
  if (isFunction(options)) return withStatePrefix(type, undefined, options, reducer);
  options = mergeWithDefaults(options);
  options = prepareFields(options);

  return function(state, action) {
    if (action.type === type) {
      let isValid = true;
      let message = 'Unknown error';
      let inputErrors = {};

      if (action.response) {
        switch (action.response.statusCode) {
          case 400:
            isValid = false;
            message = action.response.message || message;
            inputErrors = getInputErrors(action);
            break;

          default:
            break;
        }
      }
      state = state
        .setIn(prefixGetter(state, action), fromJS(inputErrors).set('errorsFromApi', true))
        .setIn(options.fields.message, message)
        .setIn(options.fields.valid, isValid);
    }

    return reducer(state, action);
  };
}

function mergeWithDefaults(options) {
  const result = {};

  result.fields = Object.assign({}, DEFAULT_OPTIONS.fields, options.fields || {});

  return Object.assign({}, DEFAULT_OPTIONS, options, result);
}

function prepareFields(options) {
  const result = {};

  result.fields = Object.keys(options.fields).reduce((result, key) => {
    result[key] = options.fields[key].split('.');

    return result;
  }, {});

  return Object.assign({}, options, result);
}

function getInputErrors(action) {
  const result = {};

  if (action.response.errors) {
    const { errors } = action.response;

    Object.keys(errors).forEach(field => {
      result[field] = errors[field].join('\n');
    });
  }

  return result;
}
