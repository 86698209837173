import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { isEqual } from 'underscore';

import DropDown, { DropDownElement } from '../helpers/DropDown';

const Dropdown = ({ onDelete }) => (
  <div className="btn-group">
    <DropDown btnContent={<span />} className="btn-default btn-xs" id="main-stats-dropdown">
      <DropDownElement onClick={onDelete}>Delete</DropDownElement>
    </DropDown>
  </div>
);

const S3FilesTreeComponent = ({ data, onDelete, readOnly }, context) => {
  const [searchString, setSearchString] = useState('');
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [treeData, setTreeData] = useState([]);

  const treeRef = useRef();

  useEffect(() => {
    if (!isEqual(data, treeData)) {
      setTreeData(data);
    }
  }, [data]);

  const scrollToFound = useCallback(() => {
    if (!treeRef.current) {
      return;
    }

    const element = treeRef.current.querySelector('.rstcustom__rowSearchFocus');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  useEffect(() => {
    scrollToFound();
  }, [searchFocusIndex, searchFoundCount]);

  const handleUpdateTreeData = useCallback(updatedTreeData => {
    setTreeData(updatedTreeData);
  }, []);

  const handleConfirmDelete = node => {
    context.openPopup('ConfirmPopup', {
      header: 'Delete file',
      message: 'Are you sure you want to permanently delete this file?',
      yes: 'Confirm',
      no: 'Cancel',
      onOk: () => {
        onDelete(node);
        context.closePopup();
      },
      onCancel: () => context.closePopup(),
    });
  };

  const handleUpdateSearch = useCallback(event => {
    setSearchString(event.target.value);
  }, []);

  const handleSelectPrevMatch = useCallback(() => {
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount : searchFoundCount - 1,
    );
  }, [searchFoundCount, searchFocusIndex]);

  const handleSelectNextMatch = useCallback(() => {
    setSearchFocusIndex(searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0);
  }, [searchFocusIndex, searchFoundCount]);

  const handleSearchCallback = useCallback(
    matches => {
      setSearchFoundCount(matches.length);
      setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0);
    },
    [searchFocusIndex],
  );

  const handleSearch = useCallback(
    ({ node, searchQuery }) => searchQuery && node.title.toLowerCase().includes(searchQuery.toLowerCase()),
    [],
  );

  return (
    <div ref={treeRef}>
      <div className="s3-search-group input-group input-group-sm">
        <span className="input-group-addon">
          {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}/{searchFoundCount || 0}
        </span>

        <input
          className="s3-search form-control"
          onChange={handleUpdateSearch}
          placeholder="Search"
          type="search"
          value={searchString}
        />

        <span className="input-group-btn">
          <button
            className="btn btn-default"
            disabled={!searchFoundCount}
            onClick={handleSelectPrevMatch}
            type="button"
          >
            <i className="fa fa-angle-left mr0" />
          </button>
          <button
            className="btn btn-default"
            disabled={!searchFoundCount}
            onClick={handleSelectNextMatch}
            type="button"
          >
            <i className="fa fa-angle-right mr0" />
          </button>
        </span>
      </div>

      <SortableTree
        canDrag={false}
        generateNodeProps={rowInfo => ({
          icons: rowInfo.node.isDirectory
            ? [<i key="folderIcon" className="fa fa-folder s3-folder" />]
            : [<i key="fileIcon" className="fa fa-file" />],
          title: rowInfo.node.isDirectory
            ? [<span key="folder">{rowInfo.node.title}</span>]
            : [
                <a key="file" className="s3-filename" href={rowInfo.node.url} rel="noopener noreferrer" target="_blank">
                  {rowInfo.node.title}
                </a>,
              ],
          buttons:
            rowInfo.node.isDirectory || readOnly
              ? []
              : [<Dropdown key="dropdown" onDelete={() => handleConfirmDelete(rowInfo.node)} />],
        })}
        isVirtualized={false}
        onChange={handleUpdateTreeData}
        searchFinishCallback={handleSearchCallback}
        searchFocusOffset={searchFocusIndex}
        searchMethod={handleSearch}
        searchQuery={searchString}
        theme={FileExplorerTheme}
        treeData={treeData}
      />
    </div>
  );
};

S3FilesTreeComponent.propTypes = {
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

S3FilesTreeComponent.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export const S3FilesTree = memo(S3FilesTreeComponent);
