import PropTypes from 'prop-types';
import { push } from 'connected-react18-router';
import React, { useEffect, useCallback, memo } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authActionCreators from '../actions/auth';
import { LoginForm } from '../components/LoginForm';
import { hasCode } from '../middleware/activate';

/**
 * Login container as a functional component.
 */
const Login = ({
  authGoogle2FA,
  google2FaStatus,
  google2FaSecret,
  error,
  handleInputValidation,
  handleLogin,
  isFetching,
  location,
  checkAuth,
  push,
  caption,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const backUrl = searchParams.get('backUrl') || '/main';

  const redirectToBackUrl = useCallback(
    url => {
      if (hasCode() && url) {
        push(url);
      }
    },
    [push],
  );

  useEffect(() => {
    const afterSuccess = () => {
      push(backUrl);
    };

    checkAuth({ afterSuccess });

    const handleVisibilityChange = () => redirectToBackUrl(backUrl);

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [backUrl, checkAuth, push, redirectToBackUrl]);

  const getValue = (form, fieldName, id) => {
    try {
      return form[fieldName].value;
    } catch (e) {
      try {
        return document.getElementById(id).value;
      } catch (e2) {
        return new window.FormData(form).get(fieldName);
      }
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;
    const inputUsername = getValue(form, 'username', 'inputUsername');
    const inputPassword = getValue(form, 'password', 'inputPassword');

    if (!inputUsername) return handleInputValidation('Please input username!');
    if (!inputPassword) return handleInputValidation('Please input password!');

    handleLogin({
      inputUsername,
      inputPassword,
      backUrl,
    });
  };

  const handleGoogle2FAAuth = event => {
    event.preventDefault();

    const form = event.currentTarget;
    const confirmationCode = getValue(form, 'confirmationCode', 'confirmationCode');

    authGoogle2FA(confirmationCode, backUrl, google2FaStatus, google2FaSecret);
  };

  return (
    <>
      <Helmet
        link={[
          {
            rel: 'prefetch',
            as: 'font',
            href: '/fonts/fontawesome-webfont.woff2',
          },
          {
            rel: 'prefetch',
            as: 'font',
            href: '/fonts/fontawesome-webfont.woff',
          },
        ]}
        title="Login Page"
      />

      <div className="container bg-width">
        <LoginForm
          caption={caption}
          error={error}
          google2FaSecret={google2FaSecret}
          google2FaStatus={google2FaStatus}
          isFetching={isFetching}
          onGoogle2FAAuth={handleGoogle2FAAuth}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

Login.propTypes = {
  authGoogle2FA: PropTypes.func.isRequired,
  checkAuth: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  handleInputValidation: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
};

export default memo(
  connect(
    state => ({
      isFetching: state.auth.get('isFetching'),
      google2FaStatus: state.auth.get('google2FaStatus'),
      google2FaSecret: state.auth.get('google2FaSecret'),
      error: state.auth.get('error'),
      location: state.router.location,
    }),
    dispatch => ({ ...bindActionCreators(authActionCreators, dispatch), push }),
  )(Login),
);
