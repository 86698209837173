import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List } from 'immutable';

import { ResultGrid } from './ResultGrid';
import { CompanyResultRow } from './CompanyResultRow';
import { PeopleResultRow } from './PeopleResultRow';

/**
 * Stateless component for search result table.
 *
 * @param props {Object}.
 * @param props.people {Immutable.List} List of person returned in api request.
 * @param props.companies {Immutable.List} List of target returned in api request.
 * @param props.searchingPeople {Boolean} Is loading person list.
 * @param props.searchingCompanies {Boolean} Is loading buyer list.
 * @param props.activeTab {String} Name of the current active tab.
 */
const SearchResultTableComponent = ({
  people,
  companies,
  searchingPeople,
  searchingCompanies,
  activeTab,
  hasAccess,
  onScroll,
}) => (
  <div className="tab-content flexItem scrollable" onScroll={onScroll}>
    <ResultGrid id="companyGrid" isActive={activeTab === 'companies'} loading={searchingCompanies}>
      {companies.map(company => (
        <CompanyResultRow key={company.get('id')} company={company} hasAccess={hasAccess} />
      ))}
    </ResultGrid>
    <ResultGrid id="peopleGrid" isActive={activeTab === 'people'} loading={searchingPeople}>
      {people.map(person => (
        <PeopleResultRow key={person.get('id')} person={person} />
      ))}
    </ResultGrid>
  </div>
);

SearchResultTableComponent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(List).isRequired,
  people: PropTypes.instanceOf(List).isRequired,
  searchingCompanies: PropTypes.bool.isRequired,
  searchingPeople: PropTypes.bool.isRequired,
};

export const SearchResultTable = memo(SearchResultTableComponent);
