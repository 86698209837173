import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';

const TextAreaEditor = forwardRef(({ value: initialValue }, ref) => {
  const [value, setValue] = useState(initialValue);
  const editorRef = useRef(null);

  useEffect(() => {
    const stopNavigation = event => {
      if ([32, 37, 38, 39, 40, 36, 35].includes(event.keyCode)) {
        event.stopPropagation();
      }
    };

    const editor = editorRef.current;

    if (editor) {
      editor.addEventListener('keydown', stopNavigation);
    }

    return () => {
      if (editor) {
        editor.removeEventListener('keydown', stopNavigation);
      }
    };
  }, []);

  const handleChange = event => {
    setValue(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => false,
    afterGuiAttached: () => {
      if (editorRef.current) {
        editorRef.current.select();
      }
    },
  }));

  return <textarea ref={editorRef} onChange={handleChange} placeholder="Input..." value={value} />;
});

export default TextAreaEditor;
