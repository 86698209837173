import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../utils/connectOptions';

import { isDeepChanged } from '../utils/ChangeSpy';

/**
 * Wrapper that adds beforeUpload reaction. If has some changes in state
 * will display a dialogue about unsaved changes.
 *
 * @augments React.Component
 */
class UnloadWrapper extends Component {
  /**
   * Constructor of component.
   *
   * @param props {Object} Instance's props.
   * @param context {Object} Instance's context.
   */
  constructor(props, context) {
    super(props, context);

    this.beforeUnload = this.beforeUnload.bind(this);
    this.redirectForce = this.redirectForce.bind(this);
  }

  getChildContext() {
    return {
      redirectForce: this.redirectForce,
    };
  }

  redirectForce(location) {
    this.componentWillUnmount();
    window.location = location;
  }

  /**
   * Append listener to window#beforeunload event after mount.
   */
  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  /**
   * Remove listener from window#beforeunload event before unmount.
   */
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  beforeUnload(e) {
    if (!this.hasChangedState()) return;

    const dialogText = 'You did not save some data. Should reload?';

    e.returnValue = dialogText;

    return dialogText;
  }

  /**
   * Checks if app's state has any change.
   *
   * @returns {boolean} `true` if has change. `false` if not.
   */
  hasChangedState() {
    return isDeepChanged(this.props.appState);
  }

  render() {
    const { children } = this.props;

    return <div className="full-height">{children}</div>;
  }
}

UnloadWrapper.propTypes = {
  appState: PropTypes.object,
  children: PropTypes.element,
};

UnloadWrapper.childContextTypes = {
  redirectForce: PropTypes.func,
};

/**
 * Map application's state to appState property.
 *
 * @param state {Object} Application's state.
 * @returns {{appState: object}}
 */
function mapStateToProps(state) {
  return {
    appState: state,
  };
}

export { UnloadWrapper };
export default connect(mapStateToProps, {}, mergeProps, connectOptions)(UnloadWrapper);
