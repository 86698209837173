import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { getIn } from 'src/utils/ChangeSpy';
import { exportStats } from 'src/actions/statistics';
import logo from '../../assets/img/content/logo-2.png';
import TopPanel from './TopPanel';
import BottomPanel from './BottomPanel';
import ChartsPanel from './ChartsPanel';
import { Spinner } from '../helpers/Spinner';

const Dashboard = ({ data, onChangeYear }) => {
  const selectYearRef = useRef();
  const isFetching =
    getIn(data, ['years', 'isFetching']) ||
    getIn(data, ['allTransactions', 'isFetching']) ||
    getIn(data, ['addOnTransactions', 'isFetching']) ||
    getIn(data, ['platformTransactions', 'isFetching']) ||
    getIn(data, ['buyers', 'isFetching']) ||
    getIn(data, ['dealsClosed', 'isFetching']) ||
    getIn(data, ['dealsClosedByState', 'isFetching']) ||
    getIn(data, ['targetsStats', 'isFetching']) ||
    getIn(data, ['buyersStats', 'isFetching']) ||
    getIn(data, ['executivesStats', 'isFetching']) ||
    getIn(data, ['dealsClosedByYears', 'isFetching']) ||
    getIn(data, ['executiveDeals', 'isFetching']) ||
    getIn(data, ['professionals', 'isFetching']) ||
    getIn(data, ['targetsApproachedByYears', 'isFetching']);
  const spinner = isFetching ? <Spinner className="bg-white" /> : null;

  const handleChangeYear = event => {
    onChangeYear(event.target.value);
  };

  const handleExport = () => {
    exportStats(selectYearRef.current.value);
  };

  const years = getIn(data, ['years', 'years']);

  return (
    <>
      <header className="p20 border-bottom">
        <img alt="logo" className="img-responsive img-logo dashboard-logo" src={logo} />
      </header>
      <div className="container-fluid dashboard-page pl20 pr20">
        <div className="d-flex justify-content-space-between align-items-center">
          <div className="d-flex align-items-center mt25 mb15">
            <h1 className="text-bold m0 pr10">Statistics for</h1>
            <div>
              <select ref={selectYearRef} className="form-control select-year" name="year" onChange={handleChangeYear}>
                <option key="defaultYear" value="">
                  All
                </option>
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button className="btn btn-lg btn-primary fs-2" onClick={handleExport} type="button">
            Export
          </button>
        </div>
        <div className="row">
          <div className="col-xs-8">
            <TopPanel data={data} />
            <BottomPanel data={data} />
          </div>
          <div className="col-xs-4">
            <ChartsPanel data={data} />
          </div>
          {spinner}
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default Dashboard;
