import React, { memo } from 'react';

import { CriteriaRange } from './CriteriaRange';

const CriteriaRangesComponent = ({ buyer: root, onChange, disabled }) => (
  <form onChange={onChange}>
    <CriteriaRange buyer={root} disabled={disabled} label="Revenue" name="revenue" onChange={onChange} />
    <CriteriaRange buyer={root} disabled={disabled} label="EBITDA" name="ebitda" onChange={onChange} />
    <CriteriaRange buyer={root} disabled={disabled} label="EV" name="ev" onChange={onChange} />
  </form>
);

export const CriteriaRanges = memo(CriteriaRangesComponent);
