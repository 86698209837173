import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';

import ActiveRouterLink from './ActiveRouterLink';

/**
 * Tab component. Uses `<li><a href={to}>{children content}</a></li>`.
 * Also `<li>` has .active class if it is current route.
 *
 * @param props {Object}.
 * @param props.to {String} Url to change window.location on click.
 */
const SubTabComponent = ({ className, children, to, available = false, onClick, exact }) => {
  const classes = classNames(className, { hide: !available }, 'sub-tabs-item');

  if (typeof onClick === 'function') {
    return (
      <li className={classes} onClick={onClick}>
        <a href="#">{children}</a>
      </li>
    );
  }

  return (
    <ActiveRouterLink classes={classes} exact={exact} to={to} wrapper="li">
      {children}
    </ActiveRouterLink>
  );
};

SubTabComponent.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.string,
};

export const SubTab = memo(SubTabComponent);

const isAvailable = (availableTabs, tab, i) => {
  if (availableTabs === 'all') return true;

  const key = tab.get('key', i);

  return availableTabs.get(key);
};

/**
 * Tabs row component.
 *
 * @param props {Object}.
 * @param props.tabs {Immutable.List} List of Immutable.Maps. Every map is an tab with #name and #to keys.
 *     Tabs are react-router links.
 * @returns {React.Component}
 */
const SubTabsComponent = ({ tabs, tabClass, availableTabs = 'all', getLink = tab => tab.get('to') }) => {
  const tabClassContent = tabClass || 'nav nav-tabs left-nav block-highlight nav-scroll';

  const content = tabs.map((tab, i) => {
    const renderRemoveButton =
      typeof tab.get('onRemoveTab') === 'function' ? (
        <button className="btn-remove-tab" onClick={tab.get('onRemoveTab')} title="Remove Tab" type="button">
          <i className="fa fa-close" />
        </button>
      ) : null;

    return (
      <SubTab
        key={i}
        available={isAvailable(availableTabs, tab, i)}
        className={tab.get('className')}
        onClick={tab.get('onClick')}
        to={getLink(tab)}
        exact
      >
        {tab.get('name')}
        {renderRemoveButton}
      </SubTab>
    );
  });

  return <ul className={tabClassContent}>{content}</ul>;
};

SubTabsComponent.propTypes = {
  availableTabs: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.string]),
  tabs: PropTypes.instanceOf(List).isRequired,
};

export const SubTabs = memo(SubTabsComponent);
