import React, { memo } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { SortableItem } from '../../decorators/SortableItem';

import { ContactLabel } from './ContactLabel';

const SortableListItem = SortableItem(ContactLabel);

const SortableListComponent = SortableContainer(({ items, onClick, onDel, opened, entityId, disabled, contacts }) => {
  const labels = items.map((contactJS, i) => {
    let indexInRedux = -1;
    let contact;

    contacts.some((cont, i) => {
      const isSame = cont.get('id') === contactJS.id;

      if (isSame) {
        contact = cont;
        indexInRedux = i;

        return true;
      }
    });

    return (
      <SortableListItem
        key={i}
        childProps={{
          onClick: event => onClick(event, indexInRedux),
          contact,
          onDel,
          isOpen: indexInRedux === opened,
          entityId,
          className: 'sortable--item',
        }}
        disabled={disabled}
        index={i}
      />
    );
  });

  return <ul className="project-contacts__nav nav nav-pills nav-stacked list">{labels}</ul>;
});

/** Sortable list component. */
export const SortableList = memo(SortableListComponent);
