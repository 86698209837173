/**
 * Check whether or not project data need to be updated.
 *
 * @param state {Immutable.Map} Project object.
 * @returns {boolean}
 * @class
 */
const checkProject = state => {
  const fields = ['category', 'status', 'details', 'clientLead', 'harvcoLead', 'exec', 'active'];

  for (let i = 0, len = fields.length; i < len; i++) {
    if (state.getIn([fields[i], 'dirty'])) {
      return true;
    }
  }

  return false;
};

/**
 * Check whether or not tags, user, approval list data need to be updated.
 *
 * @param state {Immutable.Map} Object to be checked.
 * @returns {boolean}
 * @constructor
 */
const checkGeneral = state => {
  for (let i = 0, len = state.size; i < len; i++) {
    if (state.getIn([i, 'dirty'])) {
      return true;
    }
  }

  return false;
};

/**
 * Check whether or not project page need to be updated.
 *
 * @param state {Immutable.Map} Project page object.
 * @returns {boolean}
 * @class
 */
export const detectDirty = state => {
  const { users, tags, approvals, projectData } = state;

  if (checkProject(projectData) || checkGeneral(users) || checkGeneral(tags) || checkGeneral(approvals)) {
    return true;
  }

  return false;
};
