import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../../helpers/AgGridTable';
import { handleSortModelChanged } from '../../../../utils/sorting';

const ApprovedCellRenderer = ({ data }) => {
  const className = data.approved ? 'fa-check' : 'fa-close';
  const content = data.approved === '' ? null : <i className={`fa ${className}`} />;

  return <div className="approvedWrapper">{content}</div>;
};

const ProjectCell = ({ data }) => {
  const projectCategory = data.dsplProjectCategory ? data.dsplProjectCategory : '';
  const username = data.dsplProjectHarvcoLeadUserName ? ` (${data.dsplProjectHarvcoLeadUserName})` : '';

  return <span>{projectCategory + username}</span>;
};

const CompanyBuyerLeadsTableComponent = ({ data, onDoubleClickRow, onGetNextPageData, columnDefs, onChange }) => {
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const getColumnDefs = () =>
    columnDefs.map(columnDef => {
      const field = columnDef.get('field');

      switch (field) {
        case 'approved':
          return columnDef.set('cellRendererFramework', ApprovedCellRenderer);

        case 'dsplProjectCategory':
          return columnDef.set('cellRendererFramework', ProjectCell);

        default:
          return columnDef;
      }
    });

  const handleGridReady = params => {
    setGridApi(params.api);
  };

  const handleSort = sortModel => {
    handleSortModelChanged(sortModel, data, onChange);
  };

  const getRowNodeId = data => data.order;

  return (
    <div className="flexItem">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onGetNextPageData={onGetNextPageData}
        onGridReady={handleGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
};

CompanyBuyerLeadsTableComponent.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
};

/** Company buyer leads table component. */
export const CompanyBuyerLeadsTable = memo(CompanyBuyerLeadsTableComponent);
