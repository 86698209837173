import PropTypes from 'prop-types';
import React, { memo, useState, Fragment } from 'react';
import { Map, List, fromJS } from 'immutable';

import { BrowseAutoComplete } from './BrowseAutoComplete';
import { BrowseCheckbox } from './BrowseCheckbox';
import { Tag } from './Tag';

const BrowseStateFilterComponent = ({ filter, info, onChange, onGetStates, onGetCities, onStateFilterChange }) => {
  const [suggestions, setSuggestions] = useState(List());

  const handleCheckboxChange = () => {
    const checked = info.get('checked');

    if (!checked) {
      onGetStates();
    }

    onChange({
      filterName: filter.get('name'),
      filterData: {
        checked: !checked,
      },
    });
  };

  const handleGetStatesSuggestion = ({ value }) => {
    const suggestionsList = info
      .getIn(['state', 'suggestions'])
      .filter(suggestion => suggestion.state.toLowerCase().includes(value.toLowerCase()));

    setSuggestions(suggestionsList);
  };

  const handleGetCitiesSuggestion = ({ value }) => {
    const stateSelected = info.getIn(['state', 'selected']);

    if (stateSelected) {
      onGetCities(stateSelected.get('state'), value);
    }
  };

  const handleRemoveTag = (event, target, stateIndex, cityIndex = -1) => {
    event.stopPropagation();

    let selectedList = info.get('selectedList');

    if (target === 'state') {
      selectedList = selectedList.delete(stateIndex);
    }

    if (target === 'cities') {
      selectedList = selectedList.updateIn([stateIndex, 'cities'], cities => cities.delete(cityIndex));
    }

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  };

  const handleChangeStatus = (target, stateIndex, cityIndex = -1) => {
    let selectedList = info.get('selectedList');

    if (target === 'state') {
      const path = [stateIndex, 'state', 'exclude'];

      selectedList = selectedList.setIn(path, !selectedList.getIn(path));
    }

    if (target === 'cities') {
      const path = [stateIndex, 'cities', cityIndex, 'exclude'];

      selectedList = selectedList.setIn(path, !selectedList.getIn(path));
    }

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  };

  const handleAddTag = () => {
    const stateSelected = info.getIn(['state', 'selected', 'state']);
    const citySelected = info.getIn(['city', 'selected', 'city']);

    if (!stateSelected) {
      return;
    }

    const foundState = info.get('selectedList').find(state => state.getIn(['state', 'include']) === stateSelected);
    const foundStateIndex =
      info.get('selectedList').findIndex(state => state.getIn(['state', 'include']) === stateSelected) ?? 0;
    const isExistCity = foundState && foundState.get('cities').find(city => city.get('include') === citySelected);

    // Prevent adding the existing city.
    if (isExistCity) {
      return;
    }

    const city = Map({
      include: citySelected,
      exclude: false,
    });

    const statesList = fromJS({
      state: {
        include: stateSelected,
        exclude: foundState ? foundState.getIn(['state', 'exclude']) : false,
      },
      cities: foundState === undefined ? [city] : [...foundState.get('cities').toJS(), city],
    });

    const selectedList = foundState
      ? info.setIn(['selectedList', foundStateIndex], statesList).get('selectedList')
      : info.get('selectedList').push(statesList);

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
        state: {
          text: '',
          selected: null,
        },
        city: {
          text: '',
          selected: null,
          suggestions: [],
        },
      },
    });
  };

  const selectedList = info.get('selectedList');
  const tagListStates = selectedList.map((tag, stateIndex) => (
    <Fragment key={tag.get('state')}>
      <Tag
        exclude={tag.getIn(['state', 'exclude'])}
        index={stateIndex}
        onChangeStatus={() => handleChangeStatus('state', stateIndex)}
        onRemoveTag={event => handleRemoveTag(event, 'state', stateIndex)}
        value={tag.getIn(['state', 'include'])}
      />
      <div className="pl20">
        {tag.get('cities').map((city, cityIndex) => {
          if (!city.get('include')) {
            return null;
          }

          return (
            <Tag
              key={cityIndex}
              exclude={city.get('exclude')}
              index={cityIndex}
              onChangeStatus={() => handleChangeStatus('cities', stateIndex, cityIndex)}
              onRemoveTag={event => handleRemoveTag(event, 'cities', stateIndex, cityIndex)}
              value={city.get('include')}
            />
          );
        })}
      </div>
    </Fragment>
  ));

  const autocomplete = (
    <>
      <div className="form-inline">
        <BrowseAutoComplete
          filter={filter.get('state')}
          info={info.get('state').set('suggestions', suggestions)}
          onChange={onStateFilterChange}
          onGetNextSuggestion={() => undefined}
          onGetSuggestion={handleGetStatesSuggestion}
        />
        <div className="form-group inline-input-button">
          <BrowseAutoComplete
            filter={filter.get('city')}
            info={info.get('city')}
            onChange={onStateFilterChange}
            onGetNextSuggestion={() => undefined}
            onGetSuggestion={handleGetCitiesSuggestion}
          />
          <button className="btn btn-default btn-xs" onClick={handleAddTag}>
            <span className="fa fa-plus mr0" />
          </button>
        </div>
      </div>
      {tagListStates}
    </>
  );

  return (
    <BrowseCheckbox filter={filter} info={info} onChange={handleCheckboxChange}>
      <div className="BrowseControl-content BrowseControl-content--form">{autocomplete}</div>
    </BrowseCheckbox>
  );
};

BrowseStateFilterComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetStates: PropTypes.func.isRequired,
};

/** Browse state filter component. */
export const BrowseStateFilter = memo(BrowseStateFilterComponent);
