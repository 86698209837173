import PropTypes from 'prop-types';
import React, { memo } from 'react';

import uniqueId from '../../utils/uniqueId';

const UserCheckBoxComponent = ({ userId, userName, checked, onChange, id = uniqueId() }) => (
  <div className="form-group group-inline group-checkbox">
    <input checked={checked} id={id} onChange={onChange} type="checkbox" value={userId} />
    <label htmlFor={id}>{userName}</label>
  </div>
);

UserCheckBoxComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

/** User check box component. */
export const UserCheckBox = memo(UserCheckBoxComponent);
