import React, { memo } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

const GeneralFormatComponent = ({ value, colDef, rowIndex, data }) => {
  const collect = () => ({
    rowIndex,
    rowData: data,
  });

  return (
    <ContextMenuTrigger collect={collect} id={colDef.contextMenuId}>
      {value}
    </ContextMenuTrigger>
  );
};

export const GeneralFormat = memo(GeneralFormatComponent);
