import PropTypes from 'prop-types';
import React, { Children, PureComponent } from 'react';
import { List, Map } from 'immutable';

import AgGridTable from './AgGridTable';

/**
 * TableToAgGridTable row. Can display default value if no key specified on this row.
 *
 * @param props {Object} You can put any extra props here. They will be put into `<tr>` props.
 * @param props.data {Immutable.Map} Row data. Use Immutable.Map.
 * @param props.fields {String[]} Array defines what values would be displayed and order of it.
 * @param props.defaultValues {String[]} Array with default values for cell. If cell try access
 *     the key is not present in `data`, cell will display value from here.
 * @private
 * @returns {React.Component}
 */
const TableToAgGridTableRow = props => {
  const { data, fields, defaultValues, index, ...rest } = props;
  const cells = fields.map((field, i) => {
    // If there is such field in data Map, use its value, else use default
    const fieldValue = data.get(field.name, defaultValues[i]);
    const value = typeof field.valueWrapper === 'function' ? field.valueWrapper(fieldValue, data, index) : fieldValue;

    return <td key={i}>{value}</td>;
  });

  return <tr {...rest}>{cells}</tr>;
};

TableToAgGridTableRow.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditing: PropTypes.bool,
};

/**
 * An Column component. Has no real render. It is using to definition of table's columns.
 *
 * @param props {Object} You can put any extra props here. They will be put into `<th>` props.
 * @param props.title {*} The title of column. It should be the string usual, but you can pass React.Component.
 * @param props.field {String} Field name, there component should get data for Row for this column.
 * @param [props.defaultValue=""] {String} Used as fallback. If no such field specified in Row, display this value.
 * @class
 */
const Column = () => {};

Column.propTypes = {
  defaultValue: PropTypes.string,
  field: PropTypes.string.isRequired,
  title: PropTypes.any.isRequired,
  valueWrapper: PropTypes.func,
};

/**
 * An Row component. Has no real render. It is using to definition of table's rows.
 *
 * @param props {Object} You can put any extra props here. They will be put into `<tr>` props.
 * @param props.data {Immutable.Map} Row data. Use Immutable.Map for provide values for table.
 * @class
 */
const Row = () => {};

Row.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  onDoubleClick: PropTypes.func,
};

/**
 * TableToAgGridTable component. It is used to define table without write
 * table boilerplate (map values into cells and columns).
 * Just define Columns, and Rows as TableToAgGridTable's children.
 * If no Rows specified as a children - display 1 table row with "No rows to display" content.
 * **Warn** You should define all columns before rows.
 *
 * @param props {Object}.
 * @example
 *     import TableToAgGridTable, { Column, Row } from '../helpers/TableToAgGridTable'
 *     ...
 *     <TableToAgGridTable>
 *       <Column title="My column" field="field1"/>
 *       <Column title="My column 2" field="field2"/>
 *       <Column title="My column 3" field="field3" defaultValue="DEFAULT"/>
 *       <Row data={Immutable.fromJS({field1:"1_1", field2: "1_2", field3: "1_3"})}/>
 *       <Row data={Immutable.fromJS({field1:"2_1", field3: "2_3"})}/>
 *       <Row data={Immutable.fromJS({field1:"3_1", field2: "3_2"})}/>
 *     </TableToAgGridTable>
 * @returns {React.Component}
 */
class TableToAgGridTable extends PureComponent {
  constructor(props) {
    super(props);

    this.onGridReady = this.onGridReady.bind(this);
    this.onColumnResized = this.onColumnResized.bind(this);
    this.autoSizeAllColumns = this.autoSizeAllColumns.bind(this);
  }

  onGridReady(params) {
    const { onGridReady, autoSizeColumnsToFit } = this.props;

    this.api = params.api;
    this.columnsApi = params.columnApi;
    if (onGridReady) {
      onGridReady(params);
    }

    if (autoSizeColumnsToFit) {
      this.api.sizeColumnsToFit();
    }
  }

  componentDidUpdate(oldProps) {
    const { autoSizeColumnsForData, loading } = this.props;

    if (oldProps.loading !== loading) {
      if (!loading && autoSizeColumnsForData && this.columnsApi) {
        this.autoSizeAllColumns(); // Auto size columns when data loaded.
      }
    }
  }

  /**
   * Make all columns with auto sized width.
   *
   * @link https://www.ag-grid.com/javascript-grid-resizing Usage example and documentation here.
   */
  autoSizeAllColumns() {
    const allCols = [];

    this.columnsApi.getAllColumns().map(c => allCols.push(c.colId));
    this.columnsApi.autoSizeColumns(allCols);
  }

  getRowNodeId(data) {
    return data.order;
  }

  onColumnResized(params) {
    if (params.finished && this.props.onColumnResize) {
      this.props.onColumnResize(this.columnsApi.getAllColumns().map(c => ({ ...c.colDef, width: c.actualWidth })));
    }
  }

  render() {
    const { children, height, loading, page = 0, ...rest } = this.props;

    let { columns = List(), data: rows = List() } = this.props;
    const rowClassNames = [];

    Children.forEach(children, child => {
      const { type } = child;

      if (type === Column) {
        const {
          props: { field, title, ...restProps },
        } = child;

        columns = columns.push({ ...restProps, headerName: title, field });

        return;
      }

      if (type === Row) {
        const {
          props: { className = '', data },
        } = child;

        rowClassNames.push(className);
        rows = rows.push(data);
      }
    });

    return (
      <AgGridTable
        {...rest}
        columnDefs={columns}
        getRowClass={params => rowClassNames[params.rowIndex]}
        getRowNodeId={this.getRowNodeId}
        isFetching={loading}
        layoutInterval={100}
        onColumnResized={this.onColumnResized}
        onGridReady={this.onGridReady}
        page={page}
        rowData={rows}
        style={{ height }}
        autoFit
        sortable
      />
    );
  }
}

TableToAgGridTable.propTypes = {
  autoSizeColumnsForData: PropTypes.bool,
  autoSizeColumnsToFit: PropTypes.bool,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

TableToAgGridTable.defaultProps = {
  autoSizeColumnsToFit: true,
};

export { Column, Row };
export default TableToAgGridTable;
