import React, { memo } from 'react';
import getPercent from '../../../utils/getPercent';

const StatsRowComponent = ({ approvals, noApprovals, columns }) => {
  const approvalsReturned = approvals.filter(approval => approval.getIn(['dateReceived', 'value'], 0) !== null);
  const mergedApprovalsWithNoApprovals = approvals.concat(noApprovals);

  const renderCell = (col, i, data, total = false) => {
    if (i === 2) return null;

    if (col.get('field') === 'applistLabel') {
      return (
        <td key={col.get('field')} colSpan="2">
          Total ({total ? 'All' : 'Returned Approval Lists'}):
        </td>
      );
    }

    if (col.get('field') === 'dateSent') {
      return <td key={col.get('field')} />;
    }

    const sum = getSum(data, col.get('field'));
    const percent = (() => {
      if (col.get('field') === 'numberApproved') {
        return getPercent(sum, getSum(data, 'numberTargets'));
      }

      if (['numberOfContacted', 'numberOfLeads', 'numberOfCc', 'numberOfVisit'].includes(col.get('field'))) {
        return getPercent(sum, getSum(data, 'numberApproved'));
      }

      if (
        [
          'numberOfNextActions',
          'numberOfPriorityA',
          'numberOfPriorityB',
          'numberOfPriorityC',
          'numberOfApprovedX',
        ].includes(col.get('field'))
      ) {
        return getPercent(sum, getSum(data, 'numberTargets'));
      }

      return null;
    })();

    const content =
      percent !== null ? (
        <>
          {sum} ({percent}%)
        </>
      ) : (
        sum
      );

    const alignment =
      col.get('field').startsWith('numberOfPriority') || col.get('field') === 'numberOfApprovedX'
        ? 'text-center'
        : 'text-right';

    return (
      <td key={col.get('field')} className={alignment}>
        {content}
      </td>
    );
  };

  const cellsReturned = columns.map((col, i) => renderCell(col, i, approvalsReturned));
  const cellsTotal = columns.map((col, i) => renderCell(col, i, mergedApprovalsWithNoApprovals, true));

  return (
    <tfoot>
      <tr className="stats-row font-bold">{cellsReturned}</tr>
      <tr className="stats-row">{cellsTotal}</tr>
    </tfoot>
  );
};

const getSum = (data, field) => data.reduce((sum, item) => sum + item.get(field, 0), 0);

export const StatsRow = memo(StatsRowComponent);
