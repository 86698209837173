import React, { useState, useEffect, memo } from 'react';
import { Resizable } from 'react-resizable';
import classNames from 'classnames';
import 'react-resizable/css/styles.css';

const ResizablePanelComponent = ({
  width: propWidth,
  height: propHeight,
  onResize,
  onResizeEnd,
  className: cls,
  children,
  ...rest
}) => {
  const [size, setSize] = useState({ width: propWidth, height: propHeight });

  useEffect(() => {
    setSize({ width: propWidth, height: propHeight });
  }, [propWidth, propHeight]);

  const handleResize = (event, { size: newSize }) => {
    setSize(newSize);
    if (onResize) {
      onResize(event, { size: newSize });
    }
  };

  const handleResizeStop = (...args) => {
    if (onResizeEnd) {
      onResizeEnd(...args);
    }
  };

  const className = classNames('panel panel-default panel-widget', cls);

  return (
    <Resizable
      height={size.height}
      minConstraints={[560, 70]}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      width={size.width}
    >
      <div {...rest} className={className} style={size}>
        {children}
      </div>
    </Resizable>
  );
};

export const ResizablePanel = memo(ResizablePanelComponent);

export const PanelHeaderComponent = ({ className: cls, children, onClose, ...rest }) => {
  const className = classNames('panel-heading', cls);

  return (
    <div {...rest} className={className}>
      {children}
      <button aria-label="close" className="close" onClick={onClose} type="button">
        <span aria-hidden="true"> &times; </span>
      </button>
    </div>
  );
};

export const PanelHeader = memo(PanelHeaderComponent);

export const PanelBodyComponent = ({ className: cls, children, ...rest }) => {
  const className = classNames('panel-body', cls);

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  );
};

export const PanelBody = memo(PanelBodyComponent);
