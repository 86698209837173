import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List } from 'immutable';

import Tabs from '../helpers/Tabs';

const BrowseComponent = ({ tabs, children }) => (
  <div className="container">
    <div className="row">
      <div className="container-fluid">
        <div className="BrowseTabs">
          <Tabs tabClass="BrowseTabs-nav nav nav-tabs" tabs={tabs} />
          <div className="BrowseTabs-content tab-content">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

BrowseComponent.propTypes = {
  tabs: PropTypes.instanceOf(List).isRequired,
};

/** Browse component. */
export const Browse = memo(BrowseComponent);
