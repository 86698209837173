import AddPositionPopup from './AddPositionPopup';
import AddAddressPopup from './AddAddressPopup';
import EditAddressPopup from './EditAddressPopup';
import LPRolePopup from './LPRolePopup';
import ExecutiveRolePopup from './ExecutiveRolePopup';
import AddCompanyBuyerPopup from './AddCompanyBuyerPopup';
import AddEmailTemplatePopup from './AddEmailTemplatePopup';
import NewBuyerProfilePopup from './NewBuyerProfilePopup';
import NewAddFinancePopup from './NewAddFinancePopup';
import NewAddOnePopup from './NewAddOnePopup';
import AddExecutiveContactPopup from './AddExecutiveContactPopup';
import AddLpContactPopup from './AddLpContactPopup';
import AddFundPopup from './AddFundPopup';
import NewLPProfilePopup from './NewLPProfilePopup';
import { EventPopup } from './EventPopup';
import NewPlatformProjectPopup from './NewPlatformProjectPopup';
import NewPositionPopup from './NewPositionPopup';
import NewTargetCompanyPopup from './NewTargetCompanyPopup';
import UploadPopup from './UploadPopup';
import LogoutPopup from './LogoutPopup';
import NewIndustryPopup from './NewIndustryPopup';
import InformationPopup from './InformationPopup';
import ConfirmPopup from './ConfirmPopup';
import CCVisitReportPopup from './CCVisitReportPopup';
import OutreachReportPopup from './OutreachReportPopup';
import CeoOutreachReportPopup from './CeoOutreachReportPopup';
import ChangeStatusPopup from './ChangeStatusPopup';
import InternalFlipPopup from './InternalFlipPopup';
import EditContactPopup from './EditContactPopup';
import EditPhoneContactChannelPopup from './EditPhoneContactChannelPopup';
import EditEmailContactChannelPopup from './EditEmailContactChannelPopup';
import EmailFinderPopup from './EmailFinderPopup';
import EditFiscalYearPopup from './EditFiscalYearPopup';
import { ErrorPopup } from './ErrorPopup';
import AddUpdateBuyerPopup from './AddUpdateBuyerPopup';
import AddCompanyContactPopup from './AddCompanyContactPopup';
import AddCompanyTargetPopup from './AddCompanyTargetPopup';
import BuyerRolePopup from './BuyerRolePopup';
import AddBuyerCompanyPopup from './AddBuyerCompanyPopup';
import StatusCodePopup from './StatusCodePopup';
import ActiveTargetPopup from './ActiveTargetPopup';
import { TagsManagementPopup } from './TagsManagementPopup';
import UpdateHighStatusPopup from './UpdateHighStatusPopup';
import DescriptionChangePopup from './DescriptionChangePopup';
import RunWindwardReportPopupOld from './RunWindwardReportPopupOld';
import RunWindwardReportPopup from './RunWindwardReportPopup';
import GenerateOnlineApprovalListPopup from './GenerateOnlineApprovalListPopup';
import AddAddonProjectPopup from './AddAddonProjectPopup';
import OneOffApproachPopup from './OneOffApproachPopup';
import ActivityReportPopup from './ActivityReportPopup';
import DownloadMergeSourcePopup from './DownloadMergeSourcePopup';
import UploadTemplatePopup from './UploadTemplatePopup';
import ClientSummaryReportPopup from './ClientSummaryReportPopup';
import AddTradeShowEventPopup from './AddTradeShowEventPopup';
import AssignTradeShowPopup from './AssignTradeShowPopup';
import ConflictReportPopup from './ConflictReportPopup';
import { PushNextActionPopup } from './PushNextActionPopup';

/**
 * All application's popups.
 *
 * @type {object}
 */
export default {
  AddPositionPopup,
  AddLpContactPopup,
  AddAddressPopup,
  AddBuyerCompanyPopup,
  AddCompanyTargetPopup,
  AddCompanyContactPopup,
  AssignTradeShowPopup,
  EditAddressPopup,
  ExecutiveRolePopup,
  LPRolePopup,
  AddCompanyBuyerPopup,
  AddEmailTemplatePopup,
  NewBuyerProfilePopup,
  NewAddFinancePopup,
  NewAddOnePopup,
  AddExecutiveContactPopup,
  AddFundPopup,
  AddTradeShowEventPopup,
  NewLPProfilePopup,
  EventPopup,
  EditFiscalYearPopup,
  NewPlatformProjectPopup,
  NewPositionPopup,
  NewTargetCompanyPopup,
  UploadPopup,
  LogoutPopup,
  ConfirmPopup,
  NewIndustryPopup,
  InformationPopup,
  ChangeStatusPopup,
  CCVisitReportPopup,
  OutreachReportPopup,
  CeoOutreachReportPopup,
  BuyerRolePopup,
  InternalFlipPopup,
  ErrorPopup,
  ClientSummaryReportPopup,
  EditContactPopup,
  EditPhoneContactChannelPopup,
  EditEmailContactChannelPopup,
  EmailFinderPopup,
  AddUpdateBuyerPopup,
  StatusCodePopup,
  ActiveTargetPopup,
  TagsManagementPopup,
  UpdateHighStatusPopup,
  DescriptionChangePopup,
  RunWindwardReportPopupOld,
  RunWindwardReportPopup,
  GenerateOnlineApprovalListPopup,
  AddAddonProjectPopup,
  OneOffApproachPopup,
  ActivityReportPopup,
  DownloadMergeSourcePopup,
  UploadTemplatePopup,
  ConflictReportPopup,
  PushNextActionPopup,
};
