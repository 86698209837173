import { downloadBrowseFile } from 'src/utils/downloadFile';
import { CALL_API, CLEAN_QUEUE, abortRequest } from '../middleware/api';
import uniqueId from '../utils/uniqueId';
import { momentizeObjValues } from '../helpers/momentizeObjValues';
import { FETCHING_POPUP, handleEndPopupLoading } from './popup';

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_TARGETS_BUYER_FILTER = 'UPDATE_TARGETS_BUYER_FILTER';
export const UPDATE_TARGETS_ACTIVITY_FILTER = 'UPDATE_TARGETS_ACTIVITY_FILTER';
export const UPDATE_BUYERS_ACTIVITY_FILTER = 'UPDATE_BUYERS_ACTIVITY_FILTER';
export const UPDATE_EXECUTIVES_ACTIVITY_FILTER = 'UPDATE_EXECUTIVES_ACTIVITY_FILTER';
export const UPDATE_EXECUTIVES_FUND_FILTER = 'UPDATE_EXECUTIVES_FUND_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_SELECTED_ROW = 'UPDATE_SELECTED_ROW';
export const UPDATE_ALL_ROWS = 'UPDATE_ALL_ROWS';
export const UPDATE_QUERY_RESULTS = 'UPDATE_QUERY_RESULTS';
export const FETCHING_BROWSE = 'FETCHING_BROWSE';
export const STOP_FETCHING_BROWSE = 'STOP_FETCHING_BROWSE';
export const FETCHING_BROWSE_SUCCESS = 'FETCHING_BROWSE_SUCCESS';
export const FETCHING_BROWSE_FAILURE = 'FETCHING_BROWSE_FAILURE';
export const FETCHING_SUGGESTIONS_START = 'FETCHING_SUGGESTIONS_START';
export const FETCHING_SUGGESTIONS_SUCCESS = 'FETCHING_SUGGESTIONS_SUCCESS';
export const FETCHING_BUYER_SUGGESTIONS_SUCCESS = 'FETCHING_BUYER_SUGGESTIONS_SUCCESS';
export const FETCHING_FUND_SUGGESTIONS_SUCCESS = 'FETCHING_FUND_SUGGESTIONS_SUCCESS';
export const UPDATE_TAB_CONTROL_VISIBILITY = 'UPDATE_TAB_CONTROL_VISIBILITY';
export const FETCHING_BUYER_SUGGESTIONS_START = 'FETCHING_BUYER_SUGGESTIONS_START';
export const FETCHING_FUND_SUGGESTIONS_START = 'FETCHING_FUND_SUGGESTIONS_START';
export const CLEAR_SEARCH_RESULTS = 'BROWSE/CLEAR_SEARCH_RESULTS';
export const MASS_ROW_SELECTIONS = 'MASS_ROW_SELECTIONS';
export const RESET_SELECTION = 'RESET_SELECTION';
export const ABORT_CURRENT_REQUEST = 'ABORT_CURRENT_REQUEST';
export const SEND_SEARCH_CRITERIA = Symbol('SEND_SEARCH_CRITERIA');
export const SEND_SEARCH_CRITERIA_SUCCESS = Symbol('SEND_SEARCH_CRITERIA_SUCCESS');
export const RESET_SEARCH_CRITERIA_HASH = Symbol('RESET_SEARCH_CRITERIA_HASH');
export const FETCHING_ALL_COUNTRIES_SUGGESTION = Symbol('FETCHING_ALL_COUNTRIES_SUGGESTION');
export const FETCHING_ALL_COUNTRIES_SUGGESTION_SUCCESS = Symbol('FETCHING_ALL_COUNTRIES_SUGGESTION_SUCCESS');
export const FETCHING_ALL_COUNTRIES_SUGGESTION_FAILURE = Symbol('FETCHING_ALL_COUNTRIES_SUGGESTION_FAILURE');
export const FETCHING_STATE_SUGGESTIONS_START = Symbol('FETCHING_STATE_SUGGESTIONS_START');
export const FETCHING_STATE_SUGGESTIONS_SUCCESS = Symbol('FETCHING_STATE_SUGGESTIONS_SUCCESS');
export const FETCHING_CITY_SUGGESTIONS_START = Symbol('FETCHING_CITY_SUGGESTIONS_START');
export const FETCHING_CITY_SUGGESTIONS_SUCCESS = Symbol('FETCHING_CITY_SUGGESTIONS_SUCCESS');
export const UPDATE_STATE_FILTER = Symbol('UPDATE_STATE_FILTER');

function updateFilter({ key, name, filterName, filterData }) {
  return {
    type: UPDATE_FILTER,
    name,
    key,
    filterName,
    filterData,
  };
}

function updateStateFilter({ filterName, filterData }) {
  return {
    type: UPDATE_STATE_FILTER,
    filterName,
    filterData,
  };
}

function updateTargetsBuyerFilter({ filterName, filterData }) {
  return {
    type: UPDATE_TARGETS_BUYER_FILTER,
    filterName,
    filterData,
  };
}

function updateTargetsActivityFilter({ filterName, filterData }) {
  return {
    type: UPDATE_TARGETS_ACTIVITY_FILTER,
    filterName,
    filterData,
  };
}

function updateBuyersActivityFilter({ filterName, filterData }) {
  return {
    type: UPDATE_BUYERS_ACTIVITY_FILTER,
    filterName,
    filterData,
  };
}

function updateExecutivesActivityFilter({ filterName, filterData }) {
  return {
    type: UPDATE_EXECUTIVES_ACTIVITY_FILTER,
    filterName,
    filterData,
  };
}

function updateExecutivesFundFilter({ filterName, filterData }) {
  return {
    type: UPDATE_EXECUTIVES_FUND_FILTER,
    filterName,
    filterData,
  };
}

function updateSelectedRow({ name, index, field, value }) {
  return {
    type: UPDATE_SELECTED_ROW,
    name,
    index,
    field,
    value,
  };
}

function updateAllRows({ name, field, value }) {
  return {
    type: UPDATE_ALL_ROWS,
    name,
    field,
    value,
  };
}

function clearFilter({ name }) {
  return {
    type: CLEAR_FILTER,
    name,
  };
}

function updateQueryResult(actionFrom, data) {
  return {
    actionFrom,
    type: UPDATE_QUERY_RESULTS,
    data,
  };
}

export function clearResult(tab) {
  return {
    type: CLEAR_SEARCH_RESULTS,
    tab,
  };
}

export function handleUpdateFilter(data) {
  return function(dispatch) {
    dispatch(updateFilter(data));
  };
}

export function handleUpdateTargetsStateFilter(data) {
  return function(dispatch) {
    dispatch(updateStateFilter(data));
  };
}

export function handleUpdateTargetsBuyerFilter(data) {
  return function(dispatch) {
    dispatch(updateTargetsBuyerFilter(data));
  };
}

export function handleUpdateBuyersActivityFilter(data) {
  return function(dispatch) {
    dispatch(updateBuyersActivityFilter(data));
  };
}

export function handleUpdateTargetsActivityFilter(data) {
  return function(dispatch) {
    dispatch(updateTargetsActivityFilter(data));
  };
}

export function handleUpdateExecutivesActivityFilter(data) {
  return function(dispatch) {
    dispatch(updateExecutivesActivityFilter(data));
  };
}

export function handleUpdateExecutivesFundFilter(data) {
  return function(dispatch) {
    dispatch(updateExecutivesFundFilter(data));
  };
}

export function handleUpdateSelectedRow(data) {
  return function(dispatch) {
    dispatch(updateSelectedRow(data));
  };
}

export function handleUpdateAllRows(data) {
  return function(dispatch) {
    dispatch(updateAllRows(data));
  };
}

export function handleClearFilter(data) {
  return function(dispatch) {
    dispatch({ type: CLEAN_QUEUE });
    dispatch(clearFilter(data));
  };
}

export function handleAbortRequest(name) {
  return function(dispatch) {
    dispatch({ type: ABORT_CURRENT_REQUEST, name });
    dispatch(abortRequest('handle browse'));
  };
}

export function handleUpdateQueryResult(actionFrom, data) {
  return function(dispatch) {
    dispatch(updateQueryResult(actionFrom, data));
  };
}

export function getRecords({ name, params, page, limit, sort = null }) {
  const query = {
    page,
    limit,
  };

  if (sort) {
    query.sort = sort;
  }

  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/browse/${name}`,
      query,
      body: {
        params,
      },
      dislodging: true,
      unifier: `post /api/v1/browse/${name} ${JSON.stringify(query)}`,
      startType: FETCHING_BROWSE,
      errorType: FETCHING_BROWSE_FAILURE,
      successType: FETCHING_BROWSE_SUCCESS,
    },
    questId: uniqueId(),
    actionFrom: name,
  };
}

export function handleBrowse({ name, params, page = 1, sortBy = null, keepSelection = false }) {
  const query = {
    page,
    limit: 1000,
  };

  if (sortBy) query.sort = sortBy;

  return {
    [CALL_API]: {
      method: 'post',
      path: `/api/v1/browse/${name}`,
      query,
      body: {
        params,
      },
      dislodging: false,
      unifier: 'handle browse',
      maxCount: 1,
      startType: FETCHING_BROWSE,
      errorType: FETCHING_BROWSE_FAILURE,
      successType: FETCHING_BROWSE_SUCCESS,
    },
    questId: uniqueId(),
    actionFrom: name,
    keepSelection,
  };
}

function handleGetSuggestion(params) {
  const { path, actionFrom, fieldName, value, next } = params;

  return {
    [CALL_API]: {
      method: 'get',
      path,
      unifier: `get ${path.replace(/[?&](like=.*)/, '$1')}`,
      startType: FETCHING_SUGGESTIONS_START,
      successType: FETCHING_SUGGESTIONS_SUCCESS,
    },
    actionFrom,
    fieldName,
    value,
    next,
    questId: uniqueId(),
  };
}

export function handleGetContinentSuggestion(actionFrom, value, page = 1) {
  return handleGetSuggestion({
    path: `/api/v1/browse/continents?like=${value}&page=${page}`,
    actionFrom,
    fieldName: 'continent',
    value,
  });
}

export function handleGetCompanyStateSuggestion(actionFrom, value) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/company_states?company_type=${value}`,
      startType: FETCHING_STATE_SUGGESTIONS_START,
      successType: FETCHING_STATE_SUGGESTIONS_SUCCESS,
    },
    actionFrom,
    fieldName: 'state',
    value,
    questId: uniqueId(),
  };
}

export function handleGetCitySuggestion(actionFrom, type, state, value = '', page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/company_state_cities?page=${page}&limit=1000&like=${value}&company_type=${type}&state=${state}`,
      startType: FETCHING_CITY_SUGGESTIONS_START,
      successType: FETCHING_CITY_SUGGESTIONS_SUCCESS,
    },
    actionFrom,
    fieldName: 'city',
    value,
    questId: uniqueId(),
  };
}

export function handleGetCountrySuggestion(actionFrom, value, page = 1) {
  return handleGetSuggestion({
    path: `/api/v1/browse/countries?like=${value}&page=${page}`,
    actionFrom,
    fieldName: 'country',
    value,
  });
}

export function handleGetAllCountriesSuggestion() {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/countries?limit=500`,
      startType: FETCHING_ALL_COUNTRIES_SUGGESTION,
      errorType: FETCHING_ALL_COUNTRIES_SUGGESTION_FAILURE,
      successType: FETCHING_ALL_COUNTRIES_SUGGESTION_SUCCESS,
    },
  };
}

export function handleGetModuleSuggestion(actionFrom, value, page = 1) {
  return handleGetSuggestion({
    path: `/api/v1/browse/${actionFrom}/modules?like=${value}&page=${page}`,
    actionFrom,
    fieldName: 'module',
    value,
    next: page > 1,
  });
}

export function handleGetUsersSuggestion(actionFrom, fieldName, value, page = 1, withInactive) {
  let query = `?like=${value}&page=${page}`;

  if (withInactive) {
    query += '&with_inactive=1';
  }

  return handleGetSuggestion({
    path: `/api/v1/browse/users${query}`,
    actionFrom,
    fieldName,
    value,
    next: page > 1,
  });
}

export function handleGetHarvCoLeadsSuggestion(actionFrom, value, page = 1) {
  return handleGetSuggestion({
    path: `/api/v1/browse/harvco_leads?like=${value}&page=${page}`,
    actionFrom,
    fieldName: 'harvcoContact',
    value,
    next: page > 1,
  });
}

export function handleGetCategorySuggestion(actionFrom, value, page = 1) {
  return handleGetSuggestion({
    path: `/api/v1/browse/categories?like=${value}&page=${page}`,
    actionFrom,
    fieldName: 'category',
    value,
    next: page > 1,
  });
}

export function handleGetBuyerSuggestion(actionFrom, value, page = 1) {
  return handleGetSuggestion({
    path: `/api/v1/browse/buyer_names?like=${value}&page=${page}`,
    actionFrom,
    fieldName: 'buyer',
    value,
    next: page > 1,
  });
}

export function handleGetBuyerNameSuggestion(value, page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyer_names?like=${value}&page=${page}`,
      startType: FETCHING_BUYER_SUGGESTIONS_START,
      successType: FETCHING_BUYER_SUGGESTIONS_SUCCESS,
    },
    fieldName: 'buyer',
    value,
    next: page > 1,
    questId: uniqueId(),
  };
}

export function handleGetBuyerProjectSuggestion(buyerId, value, page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/buyers/${buyerId}/projects?like=${value}&page=${page}`,
      startType: FETCHING_BUYER_SUGGESTIONS_START,
      successType: FETCHING_BUYER_SUGGESTIONS_SUCCESS,
    },
    fieldName: 'project',
    value,
    next: page > 1,
    questId: uniqueId(),
  };
}

export function handleGetBuyerApprovalSuggestion(projectId, value, page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/projects/${projectId}/approval_lists?like=${value}&page=${page}`,
      startType: FETCHING_BUYER_SUGGESTIONS_START,
      successType: FETCHING_BUYER_SUGGESTIONS_SUCCESS,
    },
    fieldName: 'approval',
    value,
    next: page > 1,
    questId: uniqueId(),
  };
}

export function handleGetFundSuggestion(value, page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/funds?like=${value}&page=${page}`,
      startType: FETCHING_FUND_SUGGESTIONS_START,
      successType: FETCHING_FUND_SUGGESTIONS_SUCCESS,
    },
    fieldName: 'fund',
    value,
    next: page > 1,
    questId: uniqueId(),
  };
}

export function handleGetFundProjectSuggestion(buyerId, value, page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/funds/${buyerId}/projects?like=${value}&page=${page}`,
      startType: FETCHING_FUND_SUGGESTIONS_START,
      successType: FETCHING_FUND_SUGGESTIONS_SUCCESS,
    },
    fieldName: 'project',
    value,
    next: page > 1,
    questId: uniqueId(),
  };
}

export function handleTargetsAddTag({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/add_tag',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleExecutivesAddTag({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/executives/add_tag',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleTargetSetNextActions({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/set_next_actions',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleTargetPushNextActionsDates({
  body,
  callback: { beforeStart, afterSuccess, afterError, ...rest },
}) {
  return {
    [CALL_API]: {
      ...rest,
      method: 'post',
      path: '/api/v1/browse/targets/push_next_action_dates',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleSaveAddUpdateBuyer({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/add_update_buyer',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleTargetDelete({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/delete',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleTargetDeleteConfirmation({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/delete_confirmation',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function handleConfirmMerging({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/companies/confirm_merging',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function updateTabVisibility({ field, value }) {
  return {
    type: UPDATE_TAB_CONTROL_VISIBILITY,
    field,
    value,
  };
}

export function massSelectRows(values) {
  return {
    type: MASS_ROW_SELECTIONS,
    ...values,
  };
}

export function handleResetSelection({ name }) {
  return function(dispatch) {
    dispatch({
      type: RESET_SELECTION,
      name,
    });
  };
}

export function updateIndustryTags({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/set_industry_categories',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

export function setBacklog({ body, callback: { beforeStart, afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/targets/set_backlogs',
      body,
      beforeStart,
      afterSuccess,
      afterError,
    },
  };
}

/**
 * Send request to make windward report.
 *
 * @param {string} name Browse section name.
 * @param {number} selectedUserId Id of selected user.
 * @param {string} nameTemplate Template name.
 * @param {Array} templateParams Template params.
 * @param {Function} afterSuccess Success callback.
 * @param {Function} afterError Failure callback.
 * @param {number|null} searchBuyerId The first id of buyers from search params.
 */
export function runWindwardReport({
  name,
  selectedUserId,
  nameTemplate,
  templateParams,
  selectedTargets,
  searchBuyerId,
  searchParams,
  afterSuccess,
  afterError,
}) {
  const path = `/api/v1/windward/reports`;
  const params =
    name === 'targets'
      ? selectedTargets.map(target => {
          if (searchBuyerId === null) {
            return {
              approvalListId: target.approvalListId,
              projectId: target.projectId,
              targetId: target.id,
              buyerId: target.buyerId,
              searchBuyerId,
            };
          }

          const buyersParams = searchParams.buyers.included[0];

          return {
            approvalListId: target.approvalListId,
            projectId: target.projectId,
            targetId: target.id,
            buyerId: target.buyerId,
            searchBuyerId,
            search_project_id: buyersParams.project_id,
            search_app_list_id: buyersParams.approval_list_id,
            search_priority: buyersParams.priority,
            search_approved: buyersParams.approved,
            search_active_buyer: buyersParams.active,
            search_cs_from: buyersParams.current_status_range.min,
            search_cs_to: buyersParams.current_status_range.max,
            search_hs_from: buyersParams.high_status_range.min,
            search_hs_to: buyersParams.high_status_range.max,
          };
        })
      : selectedTargets.map(target => ({
          buyerId: target.id,
        }));

  return {
    [CALL_API]: {
      method: 'post',
      path,
      body: {
        moduleId: selectedUserId,
        templateType: nameTemplate,
        templateParams,
        params,
      },
      afterSuccess,
      afterError,
    },
  };
}

/** Send filters params to the server. */
function sendSearchCriteria(params) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/search_params',
      body: { params },
      successType: SEND_SEARCH_CRITERIA_SUCCESS,
      startType: FETCHING_POPUP,
      afterSuccess: ({ dispatch }) => {
        dispatch(handleEndPopupLoading());
      },
    },
  };
}

/**
 * Send request and state data thunk.
 *
 * @param {object} requestParams Params needed to make search request.
 * @param {object} stateParams Params needed to restore state.
 */
export function handleSendSearchCriteria({ requestParams, stateParams }) {
  return function(dispatch) {
    dispatch(sendSearchCriteria({ requestParams, stateParams }));
  };
}

/**
 * Export file by set params with hash for filters params from server.
 *
 * @param {string} url URL for request's params.
 * @param {number[]} idList List Ids for request's params.
 * @param {object} params Params with "requestParams" for to make search request and with "stateParams" for to restore state.
 */
export function handleExportWithSearchCriteria(url, idList, params) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/browse/search_params',
      body: { params },
      afterSuccess: ({ response }) => downloadBrowseFile(url, { selected_ids: idList, hash: response.hash }),
    },
  };
}

/** Clean hash state action. */
export function handleResetSearchCriteriaHash() {
  return {
    name: 'browse',
    type: RESET_SEARCH_CRITERIA_HASH,
  };
}

/**
 * Get params from the server by hash.
 *
 * @param {string} hash Criteria hash.
 */
export function handleFetchCriteria({ name, hash }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/browse/search_params/${hash}`,
      startType: FETCHING_BROWSE,
      successType: STOP_FETCHING_BROWSE,
      errorType: FETCHING_BROWSE_FAILURE,
      afterSuccess: ({ dispatch, response }) => {
        dispatch(restoreCriteriaState({ params: response.data.params, name }));
      },
    },
    actionFrom: name,
    questId: uniqueId(),
  };
}

/**
 * Restore page filters state and make search request.
 *
 * @param {Object} data request payload data with the { requestParams, stateParams } structure.
 */
function restoreCriteriaState({ params, name }) {
  return function(dispatch) {
    const { requestParams, stateParams } = params;

    // Sends search request with the gotten params.
    dispatch(
      handleBrowse({
        name,
        params: requestParams,
      }),
    );

    // Run over the gotten state data and restore it in store.
    // eslint-disable-next-line array-callback-return
    Object.keys(stateParams).map(key => {
      restoreFilterStateParam(name, `${key}`, stateParams[key], dispatch);
    });
  };
}

/**
 * Restore single filter state.
 *
 * @param {string} browseName Browse section name.
 * @param {string} filterName Name of the filter.
 * @param {object|boolean} data State data.
 * @param {Function} dispatch Redux dispatch function.
 */
function restoreFilterStateParam(browseName, filterName, data, dispatch) {
  if (data) {
    momentizeObjValues(data); // Check if object filed is ISO data -> make it moment instance.

    // Firstly make filter active.
    dispatch(
      updateFilter({
        name: browseName,
        filterName: `${filterName}`,
        filterData: {
          checked: true,
        },
      }),
    );

    // Set filter state data.
    dispatch(
      updateFilter({
        name: browseName,
        filterName: `${filterName}`,
        filterData: data,
      }),
    );
  }
}

export function checkExportAvailability({ params, callback: { afterSuccess, afterError } }) {
  return {
    [CALL_API]: {
      method: 'post',
      body: {
        params,
      },
      path: '/api/v1/browse/targets/export/check',
      afterSuccess,
      afterError,
    },
  };
}
