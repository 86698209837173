import PropTypes from 'prop-types';
import React, { useEffect, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import ContactBuyer from '../../components/Contact/ContactBuyer';
import { loadContactBuyer } from '../../actions/contact/contactBuyer';

import config from '../../config';

const columns = config.tables.getIn(['contactBuyer', 'columns']);

/**
 * ContactBuyers tab container.
 *
 * @param props {Object}.
 * @param props.buyers {Immutable.List} Container gets this from app's state.
 * It is a list of buyers to display.
 * @param props.columns {Immutable.List} Container gets this from app's config.
 * It is meta info about table's columns.
 * @param loadContactBuyer {Function} Action creator to load buyer list for contact.
 */
const ContactBuyerContainer = ({ buyersState, loadContactBuyer, match, ...rest }) => {
  const { contactId } = match.params;

  // Fetch buyer data on mount
  useEffect(() => {
    loadContactBuyer({ id: contactId });
  }, [contactId, loadContactBuyer]);

  // Callback for double-click event
  const handleDoubleClick = useCallback(
    (e, index) => {
      const companyId = buyersState.getIn(['buyers', index, 'entityId']);

      window.open(`/company/${companyId}`);
    },
    [buyersState],
  );

  const loading = buyersState.get('loading');
  const buyers = buyersState.get('buyers');

  return <ContactBuyer {...rest} buyers={buyers} loading={loading} onDoubleClick={handleDoubleClick} />;
};

function mapStateToProps(state) {
  return {
    buyersState: state.contact.buyers,
    columns,
  };
}

ContactBuyerContainer.propTypes = {
  buyersState: PropTypes.instanceOf(Map).isRequired,
  columns: PropTypes.instanceOf(List).isRequired,
  loadContactBuyer: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const MemoizedContactBuyerContainer = memo(ContactBuyerContainer);

export { MemoizedContactBuyerContainer };
export default connect(
  mapStateToProps,
  { loadContactBuyer },
  mergeProps,
  connectOptions,
)(MemoizedContactBuyerContainer);
